var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mmPagination"
  }, [_vm.selectPageAsDot && _vm.pagesList.length > 1 ? _c('div', {
    staticClass: "table__pagination"
  }, [_c('a', {
    staticClass: "page-link",
    class: {
      'hidden': _vm.prevHidden
    },
    on: {
      "click": _vm.prev
    }
  }, [_vm._v("‹")]), _vm._l(_vm.pagesList, function (page) {
    return _c('a', {
      staticClass: "page-link",
      class: {
        'active': page == _vm.mutableCurrentPage
      },
      on: {
        "click": function ($event) {
          _vm.mutableCurrentPage = page;
        }
      }
    }, [_vm._v(_vm._s(page) + " ")]);
  }), _c('a', {
    staticClass: "page-link",
    class: {
      'hidden': _vm.nextHidden
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("›")])], 2) : _vm._e(), _vm.selectPageAsDropdown ? _c('div', {
    staticClass: "table__pagination"
  }, [_c('a', {
    staticClass: "page-link",
    class: {
      'hidden': _vm.prevHidden
    },
    on: {
      "click": _vm.prev
    }
  }, [_vm._v("‹")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mutableCurrentPage,
      expression: "mutableCurrentPage"
    }],
    staticClass: "pag__select",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.mutableCurrentPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.selectOptions, function (n) {
    return _c('option', {
      domProps: {
        "value": n
      }
    }, [_vm._v(_vm._s(n))]);
  }), 0), _c('a', {
    staticClass: "page-link",
    class: {
      'hidden': _vm.nextHidden
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("›")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }