export default {
    methods: {

        canAddHoursToUser: function (targetUser = null) {

            if (targetUser?.id) {
                let currentUser = this.getCurrentUser();
                if (window.ROLES_OFFICE.includes(targetUser.role_id) && currentUser.role_id == window.ROLE_MAIN_MISTER) {
                    return false;
                }
                return true;
            }

            return this.userCan('assign-hours');
        },

        canAddHolidaysToUser: function (uid) {

            if (this.getUserID() == uid) {
                return this.userCan('profile-add-holidays');
            } else {
                return this.userCan('assign-holidays');
            }

            return false;
        },

        userCan: function (ability) {
            return this.$store.state.user?.abilities?.[ability] ?? false;
        },

        userGateConfig: function (ability) {
            return this.$store.state.user?.gates_params?.[ability] ?? false;
        },

        isThisUserMe: function (uid) {
            return this.getUserID() == uid;
        },

        isOffice: function () {
            return [1, 7].includes(this.getUserID());
        },

        showSuccess(msg = null) {
            this.$toast.success(msg);
        },

        showError(error) {
            let errorMessage = null;
            if (error.response) {
                errorMessage = error.response?.data?.message;
            } else if (error.request) {
                let status = error.request.status;
                if (status == 0) {
                    errorMessage = "Nemáte přístup k internetu.";
                } else {
                    errorMessage = "Error - code " + status;
                }
            } else {
                errorMessage = error.message;
            }
            this.$toast.error(errorMessage ?? "Neznámý error");
        },

        dateRenderer(params) {
            let value = params.value;
            return this.$moment(value).format("DD. MM. YYYY");
        },

        getCurrentUser() {
            return this.$store.state.user ?? null;
        },

        getUserID() {
            return this.$store.state.user?.id ?? null;
        },

        getRoleID() {
            return this.$store.state.user?.role_id ?? null;
        },

        isAdmin() {
            return this.$store.state.user?.role_id == 1;
        },

        hasRole(roles = []) {
            let role = this.$store.state.user?.role_id;
            return roles.includes(role);
        },

    }
}