var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "holidayLegend"
  }, [_c('span', [_vm._v("Legenda:")]), _vm._l(_vm.computedTypes, function (type) {
    return _c('span', {
      staticClass: "holidayLegend__item"
    }, [_c('span', [_vm._v(_vm._s(type[0]))]), _vm._v(" = " + _vm._s(type[1]) + " ")]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }