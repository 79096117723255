<template>
    <div>
        <merge-shops-modal ref="mergeShopsModal" @refresh="fetchData"/>
        <h2>Správa obchodů</h2>
        <button class="btn btn-primary" @click="$refs.mergeShopsModal.open()">Spojit obchody</button><br><br>
        <page-loader v-if="pageLoading"/>
        <form v-else @submit.prevent="saveRow">
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>ID</th>
                        <th>Název</th>
                        <th v-if="allowedEditing"></th>
                    </tr>
                    <tr v-for="item in items">
                        <td>{{ item.id }}</td>
                        <td>
                            <input class="form-control" v-if="editing == item.id" type="text" v-model="item.name"/>
                            <div v-else>{{ item.name }}</div>
                        </td>
                        <td class="flex-buttons" v-if="allowedEditing">
                            <template v-if="editing == item.id">
                                <button type="submit" class="btn btn-primary" key="edit_save">
                                    Uložit
                                </button>
                                <button type="button" class="btn btn-danger" @click="editing = null" key="edit_cancel">
                                    Zrušit
                                </button>
                            </template>
                            <template v-else>
                                <button type="button" class="btn btn-danger btn-icon" @click="editing = item.id; addNew.visible = false" key="edit">
                                    <icon name="edit"/>
                                </button>
                                <button type="button" class="btn btn-danger btn-icon" @click="deleteItem(item)" key="delete">
                                    <icon name="trash"/>
                                </button>

                            </template>
                        </td>
                    </tr>
                    <tr v-if="allowedEditing">
                        <td></td>
                        <td>
                            <input v-if="addNew.visible" class="form-control" type="text" v-model="addNew.name"/>
                        </td>
                        <td class="flex-buttons">
                            <template v-if="addNew.visible">
                                <button type="submit" class="btn btn-primary" key="new_save">
                                    Uložit
                                </button>
                                <button type="button" class="btn btn-danger" @click="addNew.visible = false" key="new_cancel">
                                    Zrušit
                                </button>
                            </template>
                            <button v-else type="button" class="btn btn-primary" @click="() => {addNew.visible = true; editing = null}" key="new">
                                Nový obchod
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </form>
    </div>
</template>

<script>
import projectService from "@services/projectService";
import MergeShopsModal from "@components/mergeShopsModal";

export default {
    name: "shop_index",
    components: {MergeShopsModal},
    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {
            this.pageLoading = true;
            projectService.getAllShops().then(res => {
                this.items = res.data.items;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });
        },

        deleteItem(item = null) {
            if (!item)
                return;

            let id = item.id ?? null;

            let data = {
                id,
                delete: true,
            }
            projectService.saveShop(data).finally(r => {
                this.fetchData();
                if (!id) {
                    this.addNew.visible = false;
                    this.addNew.name = "";
                }
            });
        },

        saveRow(item = null) {

            if (this.editing) {
                item = this.removeReactivity(this.items).find(i => i.id == this.editing);
            } else {
                item = this.removeReactivity(this.addNew);
            }

            let id = item.id ?? null;

            if (id) {
                this.editing = null;
            }

            let data = {
                name: item.name,
                id,
            };

            projectService.saveShop(data).finally(r => {
                this.fetchData();
                if (!id) {
                    this.addNew.visible = false;
                    this.addNew.name = "";
                }
            });

        }
    },

    data() {
        return {
            pageLoading: true,
            editing: null,
            addNew: {
                visible: false,
                name: "",
            },
            allowedEditing: true,
            items: []
        }
    }
}
</script>