<template>
    <div class="holidayLegend">
        <span>Legenda:</span>
        <span class="holidayLegend__item" v-for="type in computedTypes">
            <span>{{ type[0] }}</span> = {{ type[1] }}
        </span>
    </div>
</template>

<script>
export default {
    name: "holidayLegend",
    props: {
        type: {
            type: String,
            default: "all"
        }
    },
    computed: {
        computedTypes: function () {
            let items = this.removeReactivity(this.types);

            if (this.type == "spent") {
                items = {
                    "DP": items["DP"],
                    "§P": items["§P"],
                    "N": items["N"],
                }
            }

            return Object.entries(items);
        },
    },
    data() {
        return {
            types: {
                "DP": "Placená dovolená",
                "DN": "Neplacená dovolená",
                "§P": "Placený paragraf",
                "§N": "Neplacený paragraf",
                "N": "Nemocenská",
            }
        }
    }
}
</script>