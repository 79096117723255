import axios from "@app/helpers/apiServer";

export default {

    thingsList() {
        return axios({
            url: "/ajax-thing/list",
            method: "get",
            responseType: "json"
        });
    },

    createThing(data) {
        return axios({
            url: "/ajax-thing/create",
            method: "post",
            responseType: "json",
            data
        });
    },

    showThing(thing_id) {
        return axios({
            url: "/ajax-thing/show/"+thing_id,
            method: "get",
            responseType: "json"
        });
    },

    getAllRents() {
        return axios({
            url: "/ajax-thing/rent/list",
            method: "get",
            responseType: "json"
        });
    },

    rentThing(data) {
        return axios({
            url: "/ajax-thing/rent/rent-thing",
            method: "post",
            responseType: "json",
            data
        });
    },

    returnThing(data) {
        return axios({
            url: "/ajax-thing/rent/return-thing",
            method: "post",
            responseType: "json",
            data
        });
    },

}