var render = function () {
  var _vm$item, _vm$item2, _vm$item2$weather, _vm$item2$weather$, _vm$item3, _vm$item3$weather, _vm$item3$weather$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$item = _vm.item) !== null && _vm$item !== void 0 && _vm$item.day ? _c('div', {
    staticClass: "weather"
  }, [_vm._v(" " + _vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : (_vm$item2$weather = _vm$item2.weather) === null || _vm$item2$weather === void 0 ? void 0 : (_vm$item2$weather$ = _vm$item2$weather[0]) === null || _vm$item2$weather$ === void 0 ? void 0 : _vm$item2$weather$.description) + ", " + _vm._s(_vm.item.temp.day) + " °C, " + _vm._s(_vm.item.wind_speed) + " m/s "), !_vm.small ? _c('img', {
    attrs: {
      "loading": "lazy",
      "src": 'http://openweathermap.org/img/wn/' + ((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : (_vm$item3$weather = _vm$item3.weather) === null || _vm$item3$weather === void 0 ? void 0 : (_vm$item3$weather$ = _vm$item3$weather[0]) === null || _vm$item3$weather$ === void 0 ? void 0 : _vm$item3$weather$.icon) + '.png'
    }
  }) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }