<template>
    <div>
        <personal-notes/>
    </div>
</template>

<script>
import PersonalNotes from "@components/personalNotes";
export default {
    name: "pinboard",
    components: {PersonalNotes}
}
</script>