var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    class: _vm.computedItem.approved ? '' : 'text-danger',
    attrs: {
      "to": {
        name: 'cost_show',
        params: {
          cost_id: _vm.cost_id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }