import axios from "@app/helpers/apiServer";

export default {

    invoiceProject(data) {
        return axios({
            url: "/ajax-project/invoice",
            method: "post",
            data,
        });
    },

    mergeProjects(data) {
        return axios({
            url: "/ajax-project/merge",
            method: "post",
            data,
        });
    },

    getProjectHour(data) {
        return axios({
            url: "/ajax-project/get-project-hour",
            method: "post",
            data,
        });
    },

    approveCost(data) {
        return axios({
            url: "/ajax-project/approve-cost",
            method: "post",
            data,
        });
    },

    verifyProject(data) {
        return axios({
            url: "/ajax-project/verify",
            method: "post",
            data,
        });
    },

    setStatus(data) {
        return axios({
            url: "/ajax-project/set-status",
            method: "post",
            data,
        });
    },

    deleteProject(data) {
        return axios({
            url: "/ajax-project/delete-project",
            method: "post",
            responseType: "json",
            data,
        });
    },

    getWeekPlan(week) {
        return axios({
            url: "/ajax-project/get-week-plan/" + week,
            method: "get",
            responseType: "json",
        });
    },

    deletePlan(data) {
        return axios({
            url: "/ajax-project/delete-plan",
            method: "post",
            responseType: "json",
            data,
        });
    },

    addToPlan(data) {
        return axios({
            url: "/ajax-project/add-to-plan",
            method: "post",
            responseType: "json",
            data,
        });
    },

    assignProjectToCost(data) {
        return axios({
            url: "/ajax-project/cost/assign-project",
            method: "post",
            responseType: "json",
            data,
        });
    },

    deleteSubcost(data) {
        return axios({
            url: "/ajax-project/cost/unassign-project",
            method: "post",
            responseType: "json",
            data,
        });
    },

    getAllCosts() {
        return axios({
            url: "/ajax-project/cost/all",
            method: "GET",
            responseType: "json"
        });
    },

    getDashboard() {
        return axios({
            url: "/ajax-project/get-dashboard",
            method: "GET",
            responseType: "json"
        });
    },

    getAllProjects() {
        return axios({
            url: "/ajax-project/index",
            method: "GET",
            responseType: "json"
        });
    },

    updateProject(data) {
        return axios({
            method: 'post',
            url: '/ajax-project/update',
            data,
        });
    },

    getProjectCosts(payload = {}) {

        let {project_id} = payload;

        if (!project_id)
            project_id = "";

        return axios({
            url: "/ajax-project/show/" + project_id,
            method: "GET",
            responseType: "json"
        });

    },

    exportProject(zipUrl) {
        return axios({
            method: 'get',
            url: zipUrl,
            responseType: 'blob', // Important for downloading files
        })
    },

    deleteCost(cost_id) {
        return axios({
            method: 'get',
            url: '/ajax-project/delete-cost/' + cost_id,
        });
    },

    getAllActiveProjects() {
        return axios({
            method: 'get',
            url: '/ajax-project/get-all-active-projects',
        });
    },

    saveUserHoursForProject(data = {}) {
        return axios({
            method: 'post',
            url: '/ajax-project/saveUserHoursForProject',
            data,
        });
    },

    getCost(cost_id) {
        return axios({
            method: 'get',
            url: '/ajax-project/cost/show/' + cost_id,
        });
    },

    getWather() {
        return axios({
            method: 'get',
            url: '/ajax-project/get-weather',
        });
    },

    createClosingProposal(data) {
        return axios({
            method: 'post',
            url: '/ajax-project/create-closing-proposal',
            data
        });
    },

    dismisProposal(data) {
        return axios({
            method: 'post',
            url: '/ajax-project/dismis-proposal',
            data
        });
    },

    forceDeleteCost(data) {
        return axios({
            method: 'post',
            url: '/ajax-project/force-delete-cost',
            data
        });
    },

    getAllShops() {
        return axios({
            method: 'get',
            url: '/ajax-shops/all',
        });
    },

    saveShop(data) {
        return axios({
            method: 'post',
            url: '/ajax-shops/update',
            data
        });
    },

    mergeShops(data) {
        return axios({
            method: 'post',
            url: '/ajax-shops/merge-shops',
            data
        });
    },

    getPaidCosts(){
        return axios({
            url: "/ajax-project/cost/paid",
            method: "GET",
            responseType: "json"
        });
    },

    getInternalProjects(){
        return axios({
            url: "/ajax-project/internal-projects",
            method: "GET",
            responseType: "json"
        });
    },

}
