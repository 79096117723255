var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('rent-modal', {
    ref: "rentModal",
    on: {
      "updated": _vm.fetchData
    }
  }), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h2', [_vm._v("Půjčený majetek")])]), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.$refs.rentModal.open();
      }
    }
  }, [_vm._v(" Nové zapůjčení ")])])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v("ID zapůjčky")]), _c('th', [_vm._v("Předmět")]), _c('th', [_vm._v("Kdo si půjčil")]), _c('th', [_vm._v("Zapůjčeno")]), _c('th', [_vm._v("Popis")]), _c('th')]), _vm._l(_vm.items, function (item) {
    var _item$thing;

    return _c('tr', [_c('td', [_vm._v(_vm._s(item.id))]), _c('td', [_vm._v(_vm._s((_item$thing = item.thing) === null || _item$thing === void 0 ? void 0 : _item$thing.name))]), _c('td', [_c('user-link', {
      attrs: {
        "item": item.user
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.rented_at)) + " ")]), _c('td', [_vm._v(_vm._s(item.text))]), _c('td', {
      staticClass: "flex-buttons"
    }, [_vm.isAdmin() ? _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function ($event) {
          return _vm.$refs.rentModal.open(item);
        }
      }
    }, [_vm._v("Upravit")]) : _vm._e(), _c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function ($event) {
          return _vm.$refs.rentModal.open(item, true);
        }
      }
    }, [_vm._v("Vrátit")])])]);
  })], 2)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h2', [_vm._v("Historie půjčování")])])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v("ID zapůjčky")]), _c('th', [_vm._v("Předmět")]), _c('th', [_vm._v("Kdo si půjčil")]), _c('th', [_vm._v("Zapůjčeno")]), _c('th', [_vm._v("Vráceno")]), _c('th', [_vm._v("Popis")]), _c('th')]), _vm._l(_vm.items_returned, function (item) {
    var _item$thing2, _item$user;

    return _c('tr', [_c('td', [_vm._v(_vm._s(item.id))]), _c('td', [_vm._v(_vm._s((_item$thing2 = item.thing) === null || _item$thing2 === void 0 ? void 0 : _item$thing2.name))]), _c('td', [_vm._v(_vm._s((_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name))]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.rented_at)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.returned_at)) + " ")]), _c('td', [_vm._v(_vm._s(item.text))]), _c('td', {
      staticClass: "flex-buttons"
    }, [_vm.isAdmin() ? _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function ($event) {
          return _vm.$refs.rentModal.open(item);
        }
      }
    }, [_vm._v("Upravit")]) : _vm._e()])]);
  })], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }