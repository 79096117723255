<template>
    <div>
        <ag-grid-vue v-if="columnDefs" ref="gridEl" class="ag-grid-table ag-theme-alpine" :class="gridClasses" @grid-ready="onGridReady" @grid-size-changed="onGridSizeChanged" :columnDefs="columnDefs" :rowData="data" :pagination="true" :paginationPageSize="10" dom-layout="autoHeight" :suppressFieldDotNotation="false" :enableCellTextSelection="true" v-bind="gridOptions" @modelUpdated="onModelUpdated($event)"></ag-grid-vue>
    </div>
</template>

<script>
import {AgGridVue} from 'ag-grid-vue';
import userLink from "@components/userLink";
import costLink from "@components/costLink";

export default {
    name: "agGrid",
    components: {
        AgGridVue,
        userLink,
        costLink,
    },
    props: {
        fields: {
            type: Array,
            default: null,
        },
        data: {
            type: Array,
            default: () => []
        },
        sort: {
            type: Object,
            default: null
        },
    },

    watch: {

        "perPage": {
            handler() {
                this.gridApi.paginationSetPageSize(Number(this.perPage));
                this.gridColumnApi.autoSizeColumns();
                this.gridApi.setDomLayout('autoHeight');
            }
        },
        "currentPage": {
            handler() {
                let page = Number(this.currentPage) - 1;
                this.gridApi.paginationGoToPage(page);
                this.gridColumnApi.autoSizeColumns();
                this.gridApi.setDomLayout('autoHeight');
            }
        }

    },


    beforeMount() {
    },

    mounted() {
        this.prepareHeader();
    },

    methods: {

        onModelUpdated($event) {
            if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length == 0) {
                this.gridApi.showNoRowsOverlay();
            }
            if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length > 0) {
                this.gridApi.hideOverlay();
            }
        },

        prepareHeader() {
            this.computedFields = this.removeReactivity(this.fields)
        },

        onGridSizeChanged() {
            this.gridApi.sizeColumnsToFit();
        },

        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            this.gridApi.sizeColumnsToFit();

            if (this.sort) {
                let {column, direction} = this.sort;
                this.gridColumnApi.applyColumnState({
                    state: [{colId: column, sort: direction}],
                    defaultState: {sort: null},
                });
            }
        },

        cellRender(params) {
            let {value, colDef} = params;
            let {type} = colDef.originalData;
            let out = value;

            let cellStyles = {};
            let cellClasses = ['ag-cell-inner'];
            let cellValClasses = [];

            if (this.isNull(value)) {
                out = "<span class='table--na'></span>";
                cellClasses.push("cell--na");
            } else if (type == "integer") {
                out = this.formatter_integer(params);
                cellClasses.push("cell--integer");
            } else if (type == "number") {
                out = this.formatter_number(params);
                cellClasses.push("cell--number");
            } else if (type == "percentage") {
                cellClasses.push("cell--percentage");
            } else if (type == "url") {
                cellClasses.push("cell--url");
            } else if (type == "currency") {
                out = this.formatter_currency(params);
                cellClasses.push("cell--currency");
            } else {
                cellClasses.push("cell--string");
            }

            let inlineStyle = this.obj2style(cellStyles);
            return "<div class='" + cellClasses.join(" ") + "' style='" + inlineStyle + "'><span class='ag-value " + cellValClasses.join(" ") + "'>" + out + "</span></div>"
        },

        obj2style(style) {
            return Object.entries(style).map(([k, v]) => `${k}:${v}`).join(';')
        },


        formatter_integer(params) {
            let {value} = params;

            if (value && (["string", "number"].includes(typeof value))) {
                return value.formatNumber();
            }

            return value;
        },

        isNull(value) {
            return typeof (value) == "undefined" || value == null;
        },

        formatter_currency(params) {
            let {value} = params;
            let currency = params?.colDef?.originalData?.unit ?? null;
            value = parseFloat(value);

            return value.formatCurrency(currency, 2);
        },

        formatter_number(params) {
            let {value} = params;
            if (value && (["string", "number"].includes(typeof value))) {
                return value.formatNumber(2);
            }
            return value;
        },

    },

    computed: {

        columnDefs: function () {
            let fields = this.removeReactivity(this.computedFields);

            if (!fields)
                return false;

            return fields.map(field => {

                let out = {
                    cellRenderer: this.cellRender,
                };

                let {type, key, label} = field;

                if (type == "userLink") {
                    out["cellRenderer"] = "userLink";
                }
                if (type == "costLink") {
                    out["cellRenderer"] = "costLink";
                }

                if (["currency", "percentage", "number", "integer"].includes(type)) {
                    out['filter'] = "agNumberColumnFilter";
                }

                if (["string", "email", "url", "userLink", "costLink"].includes(type)) {
                    out['filter'] = "agTextColumnFilter";
                }

                out = {
                    ...out,
                    headerName: label,
                    field: key,
                    sortable: true,
                    lockPosition: true,
                    wrapText: true,
                    originalData: field
                }

                return out;
            });
        },
    },

    data() {
        return {
            gridApi: null,
            gridColumnApi: null,
            currentPage: 1,
            perPage: 10,

            computedFields: [],
            rewritedFields: {},
            gridClasses: [],
            gridOptions: {
                rowHeight: 50,
            },
        }
    }
}
</script>
<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-header-cell {
    min-width: 250px;
}

</style>