import axios from "@app/helpers/apiServer";

export default {

    getAllClients() {
        return axios({
            url: "/ajax-client/index",
            method: "GET",
            responseType: "json"
        });
    },

    clientDetail(client_id = null) {
        return axios({
            url: "/ajax-client/show/"+client_id,
            method: "GET",
            responseType: "json"
        });
    },

    clientUpdate(data = {}) {
        return axios({
            url: "/ajax-client/update",
            method: "post",
            responseType: "json",
            data,
        });
    },

    mergeClients(data = {}) {
        return axios({
            url: "/ajax-client/merge",
            method: "post",
            responseType: "json",
            data,
        });
    },

}
