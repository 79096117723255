import {Fancybox} from "@fancyapps/ui";

Fancybox.Plugins.Toolbar.defaults.items.zoomIn = {
    type: "button",
    class: "fancybox__button--zoomIn",
    label: "Zoom in",
    html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"/><path d="M10.5 7v3.5m0 0V14m0-3.5H14m-3.5 0H7"/></svg>',
    click: function (event) {
        event.preventDefault();
        const panzoom = this.fancybox.getSlide().Panzoom;
        if (panzoom) {
            panzoom.zoomIn();
        }
    },
};

Fancybox.Plugins.Toolbar.defaults.items.zoomOut = {
    type: "button",
    class: "fancybox__button--zoomOut",
    label: "Zoom in",
    html: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"/><path d="M13 10.5H8"/></svg>',
    click: function (event) {
        event.preventDefault();
        const panzoom = this.fancybox.getSlide().Panzoom;
        if (panzoom) {
            panzoom.zoomOut();
        }
    },
};

Fancybox.Plugins.Toolbar.defaults.display = [
    "zoom", "zoomIn", "zoomOut", "thumbs", "close"
];

Fancybox.Plugins.Image.defaults.Panzoom.maxScale = () => {
    return 20;
};

export default Fancybox;