<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <h2>Úkoly</h2>
            <router-link v-if="userCan('task.create')" :to="{name: 'task_create'}" class="btn btn-primary">
                Nový úkol
            </router-link>
            <br><br>
            <h2>Úkoly ke kontrole</h2>
            <div class="table-wrapper">
                <table class="table table-striped">
                    <tr>
                        <th>Název</th>
                        <th>Pro koho</th>
                        <th>Od koho</th>
                        <th>Stav</th>
                    </tr>
                    <tr v-for="item in items.to_check">
                        <td>
                            <task-link :item="item"/>
                        </td>
                        <td>
                            {{ item.created_for_user.name }}
                        </td>
                        <td>
                            {{ item.created_by_user.name }}
                        </td>
                        <td>
                            {{ item.nice_status }}
                        </td>
                    </tr>
                </table>
            </div>
            <br><br>
            <h2>Otevřené úkoly</h2>
            <div class="table-wrapper">
                <table class="table table-striped">
                    <tr>
                        <th>Název</th>
                        <th>Pro koho</th>
                        <th>Od koho</th>
                        <th>Stav</th>
                    </tr>
                    <tr v-for="item in items.opened">
                        <td>
                            <task-link :item="item"/>
                        </td>
                        <td>
                            {{ item.created_for_user.name }}
                        </td>
                        <td>
                            {{ item.created_by_user.name }}
                        </td>
                        <td>
                            {{ item.nice_status }}
                        </td>
                    </tr>
                </table>
            </div>
            <br><br>
            <template v-if="userCan('task.show_finished')">
                <h2>Hotové úkoly</h2>
                <div class="table-wrapper">
                    <table class="table table-striped">
                        <tr>
                            <th>Název</th>
                            <th>Pro koho</th>
                            <th>Od koho</th>
                            <th>Stav</th>
                            <th>Hotovo</th>
                        </tr>
                        <tr v-for="item in items.finished">
                            <td>
                                <task-link :item="item"/>
                            </td>
                            <td>
                                {{ item.created_for_user.name }}
                            </td>
                            <td>
                                {{ item.created_by_user.name }}
                            </td>
                            <td>
                                {{ item.nice_status }}
                            </td>
                            <td>
                                {{ localizeDate(item.finished_at) }}
                            </td>
                        </tr>
                    </table>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import taskService from "@services/taskService";

export default {
    name: "task_index",

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {

            this.pageLoading = true;
            taskService.getTasks().then(res => {
                let tasks = res.data.tasks;
                this.items = {
                    opened: tasks.filter(i => i.state == 'open'),
                    to_check: tasks.filter(i => i.state == 'check'),
                    finished: tasks.filter(i => i.state == 'done'),
                };
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },
    },

    data() {
        return {
            items: {
                opened: [],
                to_check: [],
                finished: [],
            },
            pageLoading: false,
        }
    }
}
</script>