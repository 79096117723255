import axios from "@app/helpers/apiServer";

export default {

    getAllUsersAndHoursByMonth(data) {
        return axios({
            url: "/ajax-user/get-report-by-positions",
            method: "post",
            responseType: "json",
            data
        });
    },

    changeMyPass(data) {
        return axios({
            url: "/ajax-user/change-my-pass",
            method: "post",
            responseType: "json",
            data
        });
    },

    saveHolidayFunds(data) {
        return axios({
            url: "/ajax-user/save-holiday-funds",
            method: "post",
            responseType: "json",
            data
        });
    },

    getRoles() {
        return axios({
            url: "/ajax-user/roles/list",
            method: "get",
            responseType: "json",
        });
    },

    saveRole(data) {
        return axios({
            url: "/ajax-user/roles/save",
            method: "post",
            responseType: "json",
            data
        });
    },

    getPositions() {
        return axios({
            url: "/ajax-user/positions/list",
            method: "get",
            responseType: "json",
        });
    },

    savePosition(data) {
        return axios({
            url: "/ajax-user/positions/save",
            method: "post",
            responseType: "json",
            data
        });
    },

    getMainConfig() {
        return axios({
            url: "/ajax-app/main-config",
            method: "get",
            responseType: "json",
        });
    },

    getUser(user_id) {
        return axios({
            url: "/ajax-user/show/" + user_id,
            method: "get",
            responseType: "json",
        });
    },

    saveUser(data) {
        return axios({
            url: "/ajax-user/update",
            method: "post",
            responseType: "json",
            data
        });
    },

    getAllUsers() {
        return axios({
            url: "/ajax-user/list",
            method: "GET",
            responseType: "json"
        });
    },

    login(payload = {}) {
        let url = "/ajax-auth/login";
        return axios.post(url, payload);
    },

    getCurrentUser(payload = {}) {
        let url = "/ajax-auth/current-user";
        return axios.get(url);
    },

    logout(payload = {}) {
        let url = "/ajax-auth/logout";
        return axios.get(url);
    },

    getUserReport(payload = {}) {

        let {user_id, date} = payload;

        return axios({
            url: "/ajax-user/get-user-report/" + user_id + "?date=" + date,
            method: "GET",
            responseType: "json"
        });
    },

    addAdvance(payload) {
        return axios({
            method: 'post',
            url: '/ajax-user/add-advance',
            data: payload
        });
    },

    addHolidays(payload) {
        return axios({
            method: 'post',
            url: '/ajax-user/add-holidays',
            data: payload,
        });
    },

    getMonthReport(payload) {
        return axios({
            method: 'post',
            url: '/ajax-user/get-month-report',
            data: payload
        });
    },

    getTodaysHours() {
        return axios({
            method: 'get',
            url: '/ajax-user/get-todays-hours',
        });
    },

    getSpentHoursByDay(data) {
        return axios({
            method: 'post',
            url: '/ajax-user/get-spent-hours',
            data,
        });
    },

}
