<template>
    <div class="form-date form-control">
        <div class="dp__label">
            {{ dayLabel }}
        </div>
        <datepicker class="form-date1" v-bind="$attrs" :input-attr="inputAtts" value-type="format" :editable="false" :clearable="clearable" @input="setVal" :value="value"/>
    </div>
</template>

<script>
export default {
    name: "appDate",
    inheritAttrs: false,
    props: {
        value: {
            default: ""
        },
        name: {
            default: "date"
        },
        clearable: {
            type: Boolean,
            default: false,
        }
    },

    mounted() {
    },

    methods: {

        setVal(val) {
            val = val ? this.$moment(val).format("YYYY-MM-DD") : null;
            this.$emit("input", val);
        }

    },

    computed: {
        dayLabel() {
            if (!this.value)
                return "";

            return this.$moment(this.value).format("LL");
        },

        inputAtts() {

            let out = {};
            if(this.name) {
                out["name"] = this.name;
            }
            return out;
        }

    }
}
</script>