var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("Správa pozic")]), _c('users-nav'), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("Název")]), _vm.allowedEditing ? _c('th') : _vm._e()]), _vm._l(_vm.items, function (item) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(item.id))]), _c('td', [_vm.editing.includes(item.id) ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.name,
        expression: "item.name"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.name
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "name", $event.target.value);
        }
      }
    }) : _c('div', [_vm._v(_vm._s(item.name))])]), _vm.allowedEditing ? _c('td', {
      staticClass: "flex-buttons"
    }, [_vm.editing.includes(item.id) ? [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function ($event) {
          return _vm.saveRow(item);
        }
      }
    }, [_vm._v(" Uložit ")]), _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function ($event) {
          return _vm.editing.pull(item.id);
        }
      }
    }, [_vm._v(" Zrušit ")])] : _c('button', {
      staticClass: "btn btn-secondary",
      on: {
        "click": function ($event) {
          return _vm.editing.push(item.id);
        }
      }
    }, [_vm._v(" Upravit ")])], 2) : _vm._e()]);
  }), _vm.allowedEditing ? _c('tr', [_c('td'), _c('td', [_vm.addNew.visible ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.addNew.name,
      expression: "addNew.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.addNew.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.addNew, "name", $event.target.value);
      }
    }
  }) : _vm._e()]), _c('td', {
    staticClass: "flex-buttons"
  }, [_vm.addNew.visible ? [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.saveRow(_vm.addNew);
      }
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.addNew.visible = false;
      }
    }
  }, [_vm._v(" Zrušit ")])] : _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        _vm.addNew.visible = true;
      }
    }
  }, [_vm._v(" Nová pozice ")])], 2)]) : _vm._e()], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }