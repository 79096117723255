<template>
    <div>
        <rent-modal ref="rentModal" @updated="fetchData"/>

        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-6">
                    <h2>Půjčený majetek</h2>
                </div>
                <div class="col-6 flex-buttons">
                    <button @click="$refs.rentModal.open()" class="btn btn-primary">
                        Nové zapůjčení
                    </button>
                </div>
            </div>

            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th width="100">ID zapůjčky</th>
                        <th>Předmět</th>
                        <th>Kdo si půjčil</th>
                        <th>Zapůjčeno</th>
                        <th>Popis</th>
                        <th></th>
                    </tr>
                    <tr v-for="item in items">
                        <td>{{ item.id }}</td>
                        <td>{{ item.thing?.name }}</td>
                        <td>
                            <user-link :item="item.user"/>
                        </td>
                        <td>
                            {{ localizeDate(item.rented_at) }}
                        </td>
                        <td>{{ item.text }}</td>
                        <td class="flex-buttons">
                            <button v-if="isAdmin()" class="btn btn-danger" @click="$refs.rentModal.open(item)">Upravit</button>
                            <button class="btn btn-primary" @click="$refs.rentModal.open(item, true)">Vrátit</button>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="row">
                <div class="col-6">
                    <h2>Historie půjčování</h2>
                </div>
            </div>

            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th width="100">ID zapůjčky</th>
                        <th>Předmět</th>
                        <th>Kdo si půjčil</th>
                        <th>Zapůjčeno</th>
                        <th>Vráceno</th>
                        <th>Popis</th>
                        <th></th>
                    </tr>
                    <tr v-for="item in items_returned">
                        <td>{{ item.id }}</td>
                        <td>{{ item.thing?.name }}</td>
                        <td>{{ item.user?.name }}</td>
                        <td>
                            {{ localizeDate(item.rented_at) }}
                        </td>
                        <td>
                            {{ localizeDate(item.returned_at) }}
                        </td>
                        <td>{{ item.text }}</td>
                        <td class="flex-buttons">
                            <button v-if="isAdmin()" class="btn btn-danger" @click="$refs.rentModal.open(item)">Upravit</button>
                        </td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import rentModal from "@components/rentModal";
import thingService from "@services/thingService";

export default {
    name: "thing_rents",
    components: {rentModal},
    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {

            let current_user = this.getUserID();

            this.pageLoading = true;
            thingService.getAllRents().then(res => {

                let rents = res.data.rents;
                let still_rented = rents.filter(i => !i.returned_at);
                let returned = rents.filter(i => i.returned_at);

                if(!this.isAdmin()) {
                    still_rented = still_rented.filter(i => i.user_id == current_user);
                    returned = returned.filter(i => i.user_id == current_user);
                }

                this.items = still_rented;
                this.items_returned = returned;

            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });
        },
    },

    data() {
        return {
            pageLoading: true,
            items: [],
            items_returned: [],
        }
    }
}
</script>