<template>
    <modal v-show="visible" @close="close">
        <h3>Notifikace</h3>

        <div class="form-group">
            <label>Auto</label>
            <select class="form-control" v-model="car.id">
                <option v-for="car_item in $store.state.allCars" :value="car_item.id">{{ car_item.name }}</option>
            </select>
        </div>

        <div class="form-group">
            <label>Krátký popis</label>
            <input class="form-control" type="text" v-model="text"/>
        </div>

        <div class="form-group">
            <label>Datum oznámení</label>
            <app-date v-model="notify_at"/>
        </div>

        <app-button slug="btn_createNotification" @click="createNotification" class="btn-primary">Uložit</app-button>
    </modal>
</template>

<script>

import carService from "@services/carService";

export default {
    name: "addCarNotification",

    mounted() {
        this.fetchData();
    },

    methods: {

        createNotification() {
            this.setLoading("btn_createNotification");

            let data = {
                item: {
                    car_id: this.car.id,
                    text: this.text,
                    notify_at: this.notify_at,
                    id: this.event_id
                },
            };

            carService.createNotification(data).then(res => {
                this.$emit("updated");
                this.$toast.success("Uloženo.");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_createNotification");
            });

        },

        fetchData() {
        },

        open(atts = {}) {
            let {car, event} = atts;

            this.text = event.text ?? "";
            this.notify_at = event.notify_at ?? null;
            this.event_id = event.id ?? null;

            this.car = car;
            this.visible = true;
        },

        close() {
            this.$emit("close");
            this.visible = false;
        }

    },

    data() {
        return {
            visible: false,
            event_id: null,
            car: {
                id: null,
            },
            text: null,
            notify_at: null,
        }
    }

}
</script>