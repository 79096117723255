<template>
    <div>
        <template v-if="loading">
            <div class="form-control">Načítání..</div>
        </template>
        <template v-else>
            <input type="hidden" :name="$attrs.name" :value="mutableValue"/>
            <div v-if="mutableOptions.length">
                <app-multiselect :value="mutableValue" @input="select" :options="mutableOptions.map(o => o[optionKey])" :custom-label="val => mutableOptions.find(o => o[optionKey] == val)[optionLabel]" :allow-empty="clearable"/>
            </div>
            <div v-else class="form-control">Nic k výběru</div>
        </template>
    </div>
</template>

<script>
import projectService from "@services/projectService";
import cityService from "@services/cityService";
import clientService from "@services/clientService";

export default {
    name: "appSelect",
    inheritAttrs: false,
    props: {
        autoLoad: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: [String, Number],
            default: null,
        },
        optionKey: {
            type: String,
            default: 'value'
        },
        optionLabel: {
            type: String,
            default: 'label'
        },
        clearable: {
            type: Boolean,
            default: false,
        }
    },

    watch: {

        options: {
            handler() {
                this.setOptions();
            },
        },

        autoLoad: {
            handler() {
                this.setOptions();
            },
        },

        value: {
            handler() {
                this.mutableValue = this.value;
            },
            immediate: true,
        },

    },

    mounted() {
        this.setOptions();
    },

    methods: {

        loadUsers() {
            let items = this.removeReactivity(this.$store.state.allUsers);
            this.mutableOptions = items.map(item => {
                return {
                    value: item.id,
                    label: item?.name,
                }
            });
            this.loading = false;
        },

        loadCars() {
            let items = this.removeReactivity(this.$store.state.allCars);
            this.mutableOptions = items.map(item => {
                return {
                    value: item.id,
                    label: item?.name,
                }
            });
            this.loading = false;
        },

        loadProjects() {
            this.loading = true;
            projectService.getAllActiveProjects().then(res => {
                let projects = res.data.projects;
                this.mutableOptions = projects.map(item => {
                    return {
                        value: item.id,
                        label: item?.client?.name + " - " + item?.name,
                    }
                });
            }).catch(e => {
                this.showError(e);
                this.mutableOptions = [];
            }).finally(res => {
                this.loading = false;
            });
        },

        loadCities(type = "all") {
            this.loading = true;

            let r = null;

            if (type == "all") {
                r = cityService.getCities();
            }

            if (type == "used") {
                r = cityService.getUsedCities();
            }

            r.then(res => {
                let items = res.data.cities;
                this.mutableOptions = items.map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                });
            }).catch(e => {
                this.showError(e);
                this.mutableOptions = [];
            }).finally(res => {
                this.loading = false;
            });

            return r;
        },

        loadClients() {
            this.loading = true;
            let r = clientService.getAllClients();

            r.then(res => {
                let items = res.data.clients;
                this.mutableOptions = items.map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                });
            }).catch(e => {
                this.showError(e);
                this.mutableOptions = [];
            }).finally(res => {
                this.loading = false;
            });

            return r;
        },

        setOptions() {
            if (this.autoLoad) {

                if (this.autoLoad == "projects") {
                    this.loadProjects();
                }

                if (this.autoLoad == "cities") {
                    this.loadCities("all");
                }

                if (this.autoLoad == "used_cities") {
                    this.loadCities("used");
                }

                if (this.autoLoad == "clients") {
                    this.loadClients();
                }

                if (this.autoLoad == "cars") {
                    this.loadCars();
                }

                if (this.autoLoad == "users") {
                    this.loadUsers();
                }

            } else {
                this.mutableOptions = this.options;
                this.loading = false;
            }
        },

        select(value) {
            this.mutableValue = value;
            this.$emit("input", value);
            this.$emit("fullInput", this.mutableOptions.find(o => o[this.optionKey] == value));
        },
    },

    data() {
        return {
            mutableValue: null,
            mutableOptions: [],
            loading: true,
        }
    }

}
</script>