<template>
    <div>
        <add-car-notification ref="addCarNotification" @updated="fetchData"/>
        <add-event ref="addEvent" @updated="fetchData"/>

        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-6">

                    <div class="tabs">
                        <h2 class="tab__item" @click="tab = 'main'" :class="{'active': tab=='main'}">Detail auta</h2>
                        <h2 class="tab__item" @click="tab = 'history'" :class="{'active': tab=='history'}">Historie auta</h2>
                    </div>

                </div>
                <div class="col-6 flex-buttons">
                    <router-link v-if="userCan('car.update')" class="btn btn-danger" :to="{name: 'car_update', params: {car_id: car.id}}">
                        Upravit auto
                    </router-link>
                </div>
            </div>

            <template v-if="tab == 'main'">

                <div class="row">
                    <div class="col-12 col-lg-4">
                        <div class="task__row">
                            <div class="task__label">Název:</div>
                            {{ car.name }}
                        </div>

                        <div class="task__row">
                            <div class="task__label">SPZ:</div>
                            {{ car.meta?.plate }}
                        </div>

                        <div class="task__row">
                            <div class="task__label">Popis:</div>
                            {{ car.meta?.info }}
                        </div>

                        <div class="task__row">
                            <div class="task__label">VIN kód:</div>
                            {{ car.meta?.vin_number }}
                        </div>

                        <div class="task__row">
                            <div class="task__label">Expirace STK:</div>
                            {{ car.stkExpirationDate ? car.stkExpirationDate : "Nezadáno" }}
                        </div>

                        <div class="task__row">
                            <div class="task__label">Údržba auta provedena:</div>
                            {{ car.meta?.car_maintenance_at ? $moment(car.meta?.car_maintenance_at).format("DD. MM. YYYY") : "Nezadáno" }}
                        </div>

                        <div class="task__row">
                            <div class="task__label">Dálniční známka do:</div>
                            {{ car.meta?.toll_license_expires ? $moment(car.meta?.toll_license_expires).format("DD. MM. YYYY") : "Nezadáno" }}
                        </div>

                        <template v-if="!car?.meta?.disable_refueling">
                            <h3>Historie tankování</h3>
                            <div class="task__row" v-for="event in gas_receipts">
                                <div class="task__label">{{ localizeDate(event.target_date) }}</div>
                                <router-link :to="{name: 'cost_show', params: {cost_id: event.id}}">{{ event?.meta?.liters ?? 0 }} litrů</router-link>
                            </div>
                        </template>

                    </div>
                    <div class="col-12 col-lg-4">

                        <div class="row align-items-center">
                            <div class="col-6">
                                <h3>Čekající události</h3>
                            </div>
                            <div class="col-6 flex-buttons">
                                <button v-if="userCan('car.update')" class="btn btn-primary" @click="createNotification">Vytvořit upomínku</button>
                            </div>
                        </div>

                        <table class="table" v-if="unProcessedEvents.length">
                            <thead>
                                <tr>
                                    <th>Poznámka</th>
                                    <th>Upozornit</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="event in unProcessedEvents">
                                    <td>{{ event.text }}</td>
                                    <td>{{ localizeDate(event.notify_at) }}</td>
                                    <td>
                                        <div v-if="userCan('offices')" class="flex-buttons">
                                            <app-button :slug="'btn_notification_'+event.id" @click="removeNotification(event)" class="btn-primary">Hotovo</app-button>
                                            <button class="btn btn-danger" @click="createNotification(event)">Upravit</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else>Žádné čekající události.</div>

                        <manage-cars v-if="car.id" :by-car="car.id"/>

                    </div>
                    <div class="col-12 col-lg-4">
                        <h3>Fotky a soubory</h3>
                        <project-files :allowUpload="userCan('car.update')" :files="car.files" :model-id="car.id" model-type="car" @updated="fetchData"/>
                    </div>
                </div>
            </template>
            <template v-if="tab == 'history'">

                <div class="row">
                    <div class="col-12 col-lg-4">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <h3>Historie</h3>
                            </div>
                            <div class="col-6 flex-buttons">
                                <button class="btn btn-primary" @click="addNote">Přidat poznámku</button>
                            </div>
                        </div>
                        <div class="cards">
                            <div class="card" v-for="item in historyItems">
                                <div class="card-body">
                                    <p class="card-text">{{ item.text }}</p>
                                    <project-files class="gallery--small" :files="item.files"/>
                                    <h6 class="text-muted">{{ item?.user?.name ?? "Anonym" }} | {{ localizeDate(item.created_at) }}</h6>
                                    <div class="flex-buttons">
                                        <button v-if="userCan('office')" class="btn btn-danger btn-icon" @click="editNote(item)">
                                            <icon name="edit"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>


        </template>
    </div>
</template>

<script>
import carService from "@services/carService";
import AddCarNotification from "@components/addCarNotification";
import AddEvent from "@components/addEvent";
import ManageCars from "@components/manageCars";

export default {
    name: "car_detail",
    components: {ManageCars, AddEvent, AddCarNotification},
    mounted() {
        this.car_id = this.$route.params.car_id;
        this.fetchData();
    },

    methods: {

        addNote() {
            this.$refs.addEvent.open({
                type: "note",
                model_id: this.car.id,
                model_type: "car",
                item: null,
            });
        },

        editNote(item) {
            this.$refs.addEvent.open({
                type: "note",
                model_id: this.car.id,
                model_type: "car",
                item,
            });
        },

        removeNotification(event) {

            let event_id = event.id;
            this.setLoading("btn_notification_" + event_id);
            carService.processNotification({
                event_id
            }).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_notification_" + event_id);
            });
        },

        createNotification(event = null) {

            let atts = {
                car: this.car,
                event,
            };

            this.$refs.addCarNotification.open(atts);
        },

        fetchData() {

            this.pageLoading = true;
            carService.getCar(this.car_id, {with_events: true}).then(res => {
                this.car = res.data.car;
                this.gas_receipts = res.data.gas_receipts;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },
    },

    computed: {

        events: function () {
            return this.car.events ?? [];
        },

        alerts() {
            return this.events.filter(i => i.type == "alert");
        },

        processedEvents() {
            return this.alerts.filter(i => i.processed_at);
        },

        unProcessedEvents() {
            return this.alerts.filter(i => !i.processed_at);
        },

        historyItems() {
            let notes = this.events.filter(e => e.type == "note").map(e => {
                e.sortby = this.$moment(e.created_at).format("YYYYMMDDHHmm");
                return e;
            });
            let processedEvents = this.processedEvents.map(e => {
                e.text = "Hotovo: " + e.text;
                e.created_at = e.processed_at;
                e.sortby = this.$moment(e.created_at).format("YYYYMMDDHHmm");
                return e;
            });

            let items = [
                ...notes,
                ...processedEvents
            ];

            items = items.sort((b, a) => {
                return a.sortby - b.sortby
            });

            return items;
        },

    },

    data() {
        return {
            car: {},
            car_id: null,
            pageLoading: false,
            gas_receipts: [],
            tab: 'main'
        }
    }
}
</script>
