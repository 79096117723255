var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('add-event', {
    ref: "addEvent",
    on: {
      "updated": _vm.fetchData
    }
  }), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h2', [_vm._v("Úkol - " + _vm._s(_vm.task.name))])]), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.task.created_by == this.getUserID() ? [_vm.task.state !== 'open' ? _c('app-button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "slug": "btn_task_set_open"
    },
    on: {
      "click": function ($event) {
        return _vm.setState('open');
      }
    }
  }, [_vm._v(" Znovu otevřít úkol ")]) : _vm._e(), _vm.task.state !== 'done' ? _c('app-button', {
    staticClass: "btn btn-success",
    attrs: {
      "slug": "btn_task_set_done"
    },
    on: {
      "click": function ($event) {
        return _vm.setState('done');
      }
    }
  }, [_vm._v(" Označit jako hotový ")]) : _vm._e(), _c('router-link', {
    staticClass: "btn btn-danger",
    attrs: {
      "to": {
        name: 'task_create',
        params: {
          task_id: _vm.task.id
        }
      }
    }
  }, [_vm._v("Upravit")])] : _vm._e(), _vm.task.state == 'open' && _vm.task.created_for == this.getUserID() ? [!_vm.task.notes.length ? _c('div', [_vm._v("Nejprve přidejte poznámku")]) : _vm._e(), _c('app-button', {
    staticClass: "btn btn-success",
    attrs: {
      "slug": "btn_task_set_check",
      "disabled": !_vm.task.notes.length
    },
    on: {
      "click": function ($event) {
        return _vm.setState('check');
      }
    }
  }, [_vm._v(" Odeslat ke kontrole ")])] : _vm._e()], 2)]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Stav:")]), _vm._v(" " + _vm._s(_vm.task.nice_status) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Zadáno:")]), _vm._v(" " + _vm._s(_vm.localizeDate(_vm.task.created_at)) + " ")]), _vm.task.state == 'done' ? _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Dokončeno:")]), _vm._v(" " + _vm._s(_vm.localizeDate(_vm.task.finished_at)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Zadal:")]), _vm._v(" " + _vm._s(_vm.task.created_by_user.name) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Pověřená osoba:")]), _vm._v(" " + _vm._s(_vm.task.created_for_user.name) + " ")]), _c('p', [_c('b', [_vm._v("Zadání:")]), _vm._v(" " + _vm._s(_vm.task.text))]), _c('hr'), _c('div', {
    staticClass: "flex-buttons mb-2"
  }, [_vm.task.state != 'done' ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v("Přidat poznámku")]) : _vm._e()]), _c('div', {
    staticClass: "cards"
  }, _vm._l(_vm.task.notes, function (item) {
    var _item$user;

    return _c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card-body row"
    }, [_c('div', {
      staticClass: "col-12 col-lg-6"
    }, [_c('h5', {
      staticClass: "card-title"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name))]), _c('h6', {
      staticClass: "card-subtitle text-muted"
    }, [_vm._v(_vm._s(_vm.localizeDate(item.created_at)))]), _c('p', {
      staticClass: "card-text"
    }, [_vm._v(_vm._s(item.text))])]), _c('div', {
      staticClass: "col-12 col-lg-6"
    }, [_c('project-files', {
      staticClass: "gallery",
      attrs: {
        "files": item.files
      }
    })], 1)])]);
  }), 0)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }