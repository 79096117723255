<template>
    <button class="btn btn-icon" v-on="$listeners">
        <template v-if="!loading">
            <slot></slot>
        </template>
        <loading-icon v-else/>
    </button>
</template>

<script>
import loadingIcon from "./loadingIcon";
export default {
    name: "appButton",
    inheritAttrs: true,
    components: {loadingIcon},
    props: {
        slug: {
            type: String,
            default: null
        },
    },
    computed: {
        loading: function () {
            let slug = this.slug;
            if (!slug)
                return false;
            return this.$store.state.loadingButtons.includes(slug);
        }
    },
}
</script>
