import userService from "@services/userService";

let state = {
    user: {
        id: null
    },
    loadingButtons: [],
    allUsers: [],
    allCars: [],
    allRoles: [],
    allPositions: [],
    publicHolidays: [],
    myCars: [],
};

let actions = {

    getUserData({state, commit}) {
        return new Promise((resolve, reject) => {
            let q = userService.getCurrentUser();
            q.then(res => {
                let user = res.data.user_data;
                if (user) {
                    commit("setCurrentUser", user);
                    resolve(user);
                } else {
                    reject(null);
                }
            }).catch(res => {
                reject(null);
            });
        });
    },

    loadMainConfig({state, commit}) {
        return new Promise((resolve, reject) => {
            let q = userService.getMainConfig();
            q.then(res => {
                let {users, cars, roles, positions, public_holidays, my_cars} = res.data;

                let data = {
                    allUsers: users,
                    allCars: cars,
                    allRoles: roles,
                    allPositions: positions,
                    publicHolidays: public_holidays,
                    myCars: my_cars,
                };

                commit("setState", data);
                resolve(data);
            }).catch(res => {
                reject(null);
            });
        });

    },

};

let mutations = {

    publicHolidays(state, data) {
      state.publicHolidays = data;
    },

    setCurrentUser(state, data) {
        state.user = data;
    },

    setState(state, data) {
        let keys = Object.keys(data);
        if (keys.length) {
            keys.forEach(key => {
                let val = data[key];
                if (state.hasOwnProperty(key)) {
                    state[key] = val;
                }
            })
        }
    },

    setLoading(state, slug) {
        let items = state.loadingButtons;
        if (!items.includes(slug))
            state.loadingButtons.push(slug);
    },

    unsetLoading(state, slug) {
        let items = state.loadingButtons;
        if (items.includes(slug))
            state.loadingButtons.pull(slug);
    },

};

let getters = {

    getUser: (state) => (user_id) => {
        return state.allUsers.find(u => u.id == user_id);
    },

    getCar: (state) => (car_id) => {
        return state.allCars.find(i => i.id == car_id);
    },

};

export default {
    state,
    actions,
    mutations,
    getters,
}
