export default {
    methods: {

        apolloQuery({query, variables = {}}) {
            return this.$apollo.query({
                query,
                variables,
                fetchPolicy: 'no-cache'
            })
        }

    }
}