<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <h2>Správa aut</h2>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <router-link v-if="userCan('car.update')" :to="{name: 'car_update'}" class="btn btn-primary">
                        Nové auto
                    </router-link>
                    <router-link v-if="userCan('car.update')" :to="{name: 'car_reports'}" class="btn btn-primary">
                        Měsíční reporty
                    </router-link>
                </div>
                <div class="col-12 col-lg-6 flex-buttons" v-if="isAdmin()">
                    <button v-if="!table_attributes.sortable" class="btn btn-danger" @click="table_attributes.sortable = true" key="chen">Změnit pořadí</button>
                    <div v-else>
                        <app-button slug="btn_saveOrder" @click="saveOrder" class="btn btn-success" key="dochange">Uložit pořadí</app-button>
                        <button class="btn btn-danger" @click="table_attributes.sortable = false" key="cancelchange">Zrušit změnu</button>
                    </div>
                </div>
            </div>

            <br> <br>


            <app-table v-bind="table_attributes" :items="items" :local-sort="!table_attributes.sortable" :hide-sorting="table_attributes.sortable">
                <template slot="table-content" slot-scope="data">
                    <draggable tag="tbody" v-model="items" :options="{disabled : !table_attributes.sortable}">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>{{ row.order }}</td>
                            <td>
                                <router-link :to="{name: 'car_detail', params: {car_id: row.id}}">
                                    {{ row.name }}
                                </router-link>
                            </td>
                            <td>
                                <div v-for="user in row.owners">
                                    <user-link :item="user" :key="user.id"/>
                                </div>
                            </td>
                            <td>{{ row?.meta?.plate }}</td>
                            <td>{{ row?.meta?.info }}</td>
                            <td :class="{'td--invalid': row.stkExpires}">{{ row?.stkExpirationDate }}</td>
                            <td :class="{'td--invalid': row.tollLicenseExpires}">
                                {{ localizeDate(row?.meta?.toll_license_expires, 'D. M. Y') }}
                            </td>
                        </tr>
                    </draggable>
                </template>
            </app-table>

        </template>
    </div>
</template>

<script>
import carService from "@services/carService";
import generalService from "@services/generalService";

export default {
    name: "car_index",
    mounted() {
        this.fetchData();
    },
    methods: {


        saveOrder() {

            let items = this.removeReactivity(this.items).map(item => {
                return item.id;
            });

            let data = {
                model: "cars",
                items,
            };

            this.setLoading("btn_saveOrder");
            generalService.saveOrder(data).then(res => {
                this.$toast.success("Uloženo!");
                this.fetchData();
                this.table_attributes.sortable = false;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_saveOrder");
            });
        },

        fetchData() {
            this.pageLoading = true;
            carService.getCars().then(res => {
                this.items = res.data.cars;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });
        },
    },

    data() {
        return {
            items: [],
            pageLoading: false,
            table_attributes: {
                currentPage: 1,
                perPage: 100,
                sortBy: 'order',
                sortDirection: 'asc',
                fields: [
                    {key: 'order', label: "Pořadí", sortable: true},
                    {key: 'name', label: "Auto", sortable: true},
                    {key: 'users', label: "Správci"},
                    {key: 'meta.plate', label: "SPZ", sortable: true},
                    {key: 'meta.info', label: "Popis", sortable: true},
                    {key: 'stkExpirationDate', label: "Expirace STK", sortable: true},
                    {key: 'meta.toll_license_expires', label: "Dálniční známka končí", sortable: true},
                ],
                pagination: true,
                sortable: false,
            },
        }
    }
}
</script>
