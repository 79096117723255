var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    on: {
      "close": _vm.close
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._l(_vm.hiddenFields, function (field) {
    return _c('input', {
      attrs: {
        "type": "hidden",
        "name": field.name
      },
      domProps: {
        "value": field.value
      }
    });
  }), _vm._l(_vm.fields, function (field) {
    var _vm$formValues$field$, _vm$fields;

    return _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v(_vm._s(field.label))]), field.type == 'radio' ? _c('div', {
      staticClass: "form-control form-radios"
    }, _vm._l(field.options, function (option) {
      return _c('label', [_c('input', {
        attrs: {
          "type": "radio",
          "name": field.key,
          "disabled": _vm.viewMode
        },
        domProps: {
          "checked": option.value == field.default,
          "value": option.value
        }
      }), _vm._v(" " + _vm._s(option.label) + " ")]);
    }), 0) : field.type == 'tag' ? _c('div', [_c('app-multiselect', {
      attrs: {
        "value": _vm.formValues[field.key],
        "options": _vm.shops,
        "disabled": _vm.viewMode,
        "taggable": true,
        "multiple": false
      },
      on: {
        "tag": function (val) {
          return _vm.addShop(val, field.key);
        },
        "input": function (val) {
          return _vm.addShop(val, field.key);
        }
      }
    }), _c('input', {
      key: field.key,
      attrs: {
        "type": "hidden",
        "name": field.key
      },
      domProps: {
        "value": (_vm$formValues$field$ = _vm.formValues[field.key][0]) !== null && _vm$formValues$field$ !== void 0 ? _vm$formValues$field$ : ''
      }
    })], 1) : field.type == 'dropdown' ? _c('div', [_c('app-multiselect', {
      attrs: {
        "options": field.options,
        "track-by": "value",
        "label": "label",
        "disabled": _vm.viewMode,
        "placeholder": "Vyberte"
      },
      model: {
        value: _vm.formValues[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.formValues, field.key, $$v);
        },
        expression: "formValues[field.key]"
      }
    }), _c('input', {
      attrs: {
        "type": "hidden",
        "name": field.key
      },
      domProps: {
        "value": JSON.stringify(_vm.formValues[field.key])
      }
    })], 1) : field.type == 'files' ? _c('div', [_vm.visible ? _c('project-files', {
      attrs: {
        "handle-upload": false,
        "allow-upload": true,
        "files": field.default,
        "model-type": "cost",
        "model-id": (_vm$fields = _vm.fields) === null || _vm$fields === void 0 ? void 0 : _vm$fields.id
      },
      on: {
        "newFiles": function (files) {
          return _vm.appendFiles = files;
        }
      }
    }) : _vm._e()], 1) : field.type == 'date' ? _c('div', [_c('app-date', {
      attrs: {
        "name": field.key,
        "disabled-date": field === null || field === void 0 ? void 0 : field.disabledDate
      },
      model: {
        value: _vm.formValues[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.formValues, field.key, $$v);
        },
        expression: "formValues[field.key]"
      }
    })], 1) : field.type == 'number' ? _c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formValues[field.key],
        expression: "formValues[field.key]"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "number",
        "step": ".01",
        "name": field.key,
        "disabled": _vm.viewMode
      },
      domProps: {
        "value": _vm.formValues[field.key]
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.formValues, field.key, $event.target.value);
        }
      }
    })]) : _c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formValues[field.key],
        expression: "formValues[field.key]"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text",
        "name": field.key,
        "disabled": _vm.viewMode
      },
      domProps: {
        "value": _vm.formValues[field.key]
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(_vm.formValues, field.key, $event.target.value);
        }
      }
    })])]);
  }), !_vm.viewMode ? [_c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_addCost"
    }
  }, [_vm._v("Uložit")]), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Zrušit")])] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }