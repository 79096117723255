var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? [_c('div', {
    staticClass: "form-control"
  }, [_vm._v("Načítání..")])] : [_c('input', {
    attrs: {
      "type": "hidden",
      "name": _vm.$attrs.name
    },
    domProps: {
      "value": _vm.mutableValue
    }
  }), _vm.mutableOptions.length ? _c('div', [_c('app-multiselect', {
    attrs: {
      "value": _vm.mutableValue,
      "options": _vm.mutableOptions.map(function (o) {
        return o[_vm.optionKey];
      }),
      "custom-label": function (val) {
        return _vm.mutableOptions.find(function (o) {
          return o[_vm.optionKey] == val;
        })[_vm.optionLabel];
      },
      "allow-empty": _vm.clearable
    },
    on: {
      "input": _vm.select
    }
  })], 1) : _c('div', {
    staticClass: "form-control"
  }, [_vm._v("Nic k výběru")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }