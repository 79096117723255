<template>
    <svg class="icon">
        <use :xlink:href="'/sprite_symbol.svg#'+name"></use>
    </svg>
</template>

<script>
export default {
    name: "icon",
    props: {
        name: {
            type: String,
            default: ""
        }
    }
}
</script>
