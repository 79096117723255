import axios from "@app/helpers/apiServer";

export default {

    saveOrder(data) {
        return axios({
            url: "/ajax-app/save-order",
            method: "post",
            responseType: "json",
            data,
        });
    }

}