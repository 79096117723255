<template>
    <div>
        <h2>Správa rolí</h2>
        <users-nav/>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>ID</th>
                        <th>Název</th>
                        <th v-if="allowedEditing"></th>
                    </tr>
                    <tr v-for="item in items">
                        <td>{{item.id}}</td>
                        <td>
                            <input class="form-control" v-if="editing.includes(item.id)" type="text" v-model="item.name"/>
                            <div v-else>{{ item.name }}</div>
                        </td>
                        <td class="flex-buttons" v-if="allowedEditing">
                            <template v-if="editing.includes(item.id)">
                                <button class="btn btn-primary" @click="saveRow(item)">
                                    Uložit
                                </button>
                                <button class="btn btn-danger" @click="editing.pull(item.id)">
                                    Zrušit
                                </button>
                            </template>
                            <button v-else class="btn btn-secondary" @click="editing.push(item.id)">
                                Upravit
                            </button>
                        </td>
                    </tr>
                    <tr v-if="allowedEditing">
                        <td></td>
                        <td>
                            <input v-if="addNew.visible" class="form-control" type="text" v-model="addNew.name"/>
                        </td>
                        <td class="flex-buttons">
                            <template v-if="addNew.visible">
                                <button class="btn btn-primary" @click="saveRow(addNew)">
                                    Uložit
                                </button>
                                <button class="btn btn-danger" @click="addNew.visible = false">
                                    Zrušit
                                </button>
                            </template>
                            <button v-else class="btn btn-primary" @click="addNew.visible = true">
                                Nová role
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import userService from "@services/userService";
import UsersNav from "@components/usersNav";

export default {
    name: "user_roles",
    components: {UsersNav},
    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {
            this.pageLoading = true;
            userService.getRoles().then(res => {
                this.items = res.data.roles;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });
        },

        saveRow(item) {
            let id = item.id ?? null;

            if (id) {
                this.editing.pull(id);
            }

            let data = {
                name: item.name,
                id,
            };

            userService.saveRole(data).finally(r => {
                this.fetchData();
                if (!id) {
                    this.addNew.visible = false;
                    this.addNew.name = "";
                }
            });

        }
    },

    data() {
        return {
            pageLoading: true,
            editing: [],
            addNew: {
                visible: false,
                name: "",
            },
            allowedEditing: false,
        }
    }
}
</script>