<template>
    <div>
        <add-cost-modal ref="addCostModal" @updated="fetchData"/>
        <add-hours-modal ref="addHoursModal" @saved="fetchData"/>
        <close-project-modal ref="closeProjectModal" @updated="fetchData"/>
        <add-event ref="addEventModal" @updated="fetchData"/>

        <ProjectInvoicesModal
            v-if="invoiceModal.visible"
            :invoice-data="invoiceModal"
            :project-id="project.id"
            @close="invoiceModal.visible = false"
            @refresh="fetchData"
        />

        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row no-print">
                <div class="col-12 col-lg-6 buttons">
                    <button @click="hoursSaveToday" class="btn btn-primary">Zapsat hodiny</button>
                    <button @click="addNote" class="btn btn-primary">Přidat fotky</button>
                    <a @click="addOtherCost('shop')" class="btn btn-secondary">Přidat z obchodu</a>
                    <a @click="addOtherCost('stock')" class="btn btn-secondary">Přidat ze skladu</a>
                    <a @click="addOtherCost('transport')" class="btn btn-secondary">Přidat dopravu</a>
                    <template v-if="showClosingProposal">
                        <app-button slug="btn_createProposal" @click="createProposal" class="btn-secondary">
                            Návrhnout uzavření zakázky
                        </app-button>
                    </template>
                </div>
                <div class="col-12 col-lg-6 flex-buttons">
                    <a :href="project.export_link" v-if="userCan('offices')" slug="btn_exportProject" download class="btn btn-primary btn-icon">
                        <icon name="download"/>
                        Exportovat
                    </a>
                    <router-link v-if="userCan('project.update')" :to="{name: 'project_edit', params: {project_id: project.id}}" class="btn btn-danger btn-icon">
                        Upravit zakázku
                        <icon name="edit"/>
                    </router-link>
                </div>
            </div>

            <h2>Zakázka - {{ project?.name }}</h2>
            <div class="alert alert-success" v-if="project.state.slug !== 'closed' && project.closing_proposal">
                Byl podán návrh na uzavření zakázky.
            </div>

            <div class="tabs">
                <h3 class="tab__item" @click="tab = 'main'" :class="{'active': tab=='main'}">Detail</h3>
                <h3 class="tab__item" @click="tab = 'history'" :class="{'active': tab=='history'}">Historie hodin</h3>
            </div>

            <template v-if="tab == 'main'">
                <div class="row">
                    <div class="col-12 col-lg-4">

                        <table class="table">
                            <tr>
                                <td class="bold">Zákazník:</td>
                                <td>
                                    <client-link :item="project?.client"/>
                                </td>
                            </tr>
                            <tr>
                                <td class="bold">Město:</td>
                                <td>{{ project?.city?.name ?? "Nevyplněno" }}</td>
                            </tr>
                            <tr v-if="project.note">
                                <td class="bold">Popis:</td>
                                <td>{{ project.note }}</td>
                            </tr>
                            <tr v-if="isAdmin() && project?.meta?.online_document">
                                <td class="bold">Online tabulka</td>
                                <td>
                                    <a class="btn btn-dark" target="_blank" :href="project?.meta?.online_document">Otevřít tabulku</a>
                                </td>
                            </tr>
                            <tr v-if="project.meta?.address || project.map_link">
                                <td class="bold">Adresa:</td>
                                <td>{{ project.meta.address }}
                                    <a v-if="project.map_link" class="btn btn-dark" target="_blank" :href="project.map_link">Otevřít mapu</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-12 col-lg-4"></div>
                    <div class="col-12 col-lg-4">

                        <table class="table">
                            <tr>
                                <td class="bold">Verifikace:</td>
                                <td>
                                    <span :class="'bold text-'+project.verified_state.classname">
                                        <span>{{ project.verified_state.label }}</span>
                                    </span>
                                </td>
                                <td>
                                    <button v-if="userCan('offices')" @click="toggleVerify" class="btn btn-icon btn-secondary">
                                        Upravit
                                        <icon name="edit"/>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="bold">Fáze:</td>
                                <td>
                                    {{ project.state.label }}
                                    <span v-if="project.state.slug == 'closed'">({{ localizeDate(project.finished_at) }})</span>
                                </td>
                                <td>
                                    <template v-if="userCan('offices')">
                                        <template v-if="project.state.slug == 'planned'">
                                            <app-button @click="setStatus('public')" slug="btn_setStatus" class="btn-success">
                                                Zveřejnit zakázku
                                            </app-button>
                                        </template>
                                        <template v-else-if="project.state.slug == 'closed'">
                                            <app-button @click="setStatus('open')" slug="btn_setStatus" class="btn-secondary">
                                                Otevřít zakázku
                                            </app-button>
                                        </template>
                                        <template v-else>
                                            <app-button @click="setStatus('planned')" slug="btn_setStatus" class="btn-secondary">
                                                Do plánovaných
                                            </app-button>
                                            <app-button @click="setStatus('close')" slug="btn_setStatus" class="btn-secondary btn-success">
                                                Uzavřít zakázku
                                            </app-button>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="projectStates.invoicing.allowed && userCan('offices')">
                                <td class="bold">Fakturace:</td>
                                <td>
                                    <span class="bold" :class="projectStates.invoicing.labelClass">{{ projectStates.invoicing.label }}</span>
                                    <span v-if="project.is_invoiced == '1' && project?.invoice_numbers">({{ project.invoice_number }})</span>
                                    <div v-if="project.is_invoiced == '2' && project?.meta?.invoice_reason">({{ project.meta.invoice_reason }})</div>
                                </td>
                                <td>
                                    <button class="btn btn-secondary btn-icon" @click="invoiceModal.visible = true">
                                        {{ projectStates.invoicing.button }}
                                        <icon name="edit"/>
                                    </button>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>

                <div class="print-sheet">
                    <div class="row align-items-end justify-content-between">
                        <div class="col-12 col-lg-6">
                            <h3>Odpracované hodiny</h3>
                            <div><b>Celkem - {{ getTotalHours }} hod.</b></div>
                            <div v-for="(hours, rolename) in hoursByRoles">{{ rolename }} - {{ hours }} hod.</div>
                        </div>
                        <div class="col-12 col-lg-3 d-flex flex-column align-items-end">
                            <button class="btn btn-secondary" @click="showFilterFromTo = !showFilterFromTo">Filtrovat od-do</button>
                            <div v-if="showFilterFromTo" class="d-flex flex-column">
                                <br>
                                <input class="form-control" type="date" v-model="filter_from"/>
                                <input class="form-control" type="date" v-model="filter_to"/>
                                <div class="flex-buttons">
                                    <button class="btn btn-primary" @click="filterHours(filter_from, filter_to)">Vyfiltrovat</button>
                                    <button class="btn btn-danger" @click="() => {filterHours(null, null); showFilterFromTo = false}">Zrušit</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="table-wrapper">
                        <table class="table table-striped table-hours mt-3">
                            <tbody>
                                <tr class="table__mainrow">
                                    <td>Uživatel</td>
                                    <td v-for="day in projectDays" v-html="day.label" :key="day.key" class="td--day"></td>
                                    <td class="td--total">Celkem</td>
                                </tr>
                                <tr v-if="user.id" v-for="user in projectUsers" :key="user.id">
                                    <td>
                                        <user-link :item="user"/>
                                    </td>
                                    <td v-for="day in projectDays" :key="day.key" class="td--day">
                                        <a class="no-underline" @click="updateHours(user.id, day.key)">{{ getUserHours(user.id, day.key) || "-" }}</a>
                                    </td>
                                    <td class="td--total">{{ getUserTotalHours(user.id) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="print-sheet">
                    <div class="d-flex align-items-center justify-content-between">
                        <h3>Materiál z obchodu</h3>
                    </div>

                    <div class="table-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="table__mainrow">
                                    <td>Název</td>
                                    <td>Částečný</td>
                                    <td>Fotky ({{ costsFromShop.map(i => (i.files ?? []).length).sum() }})</td>
                                    <td width="10%">Hodnota</td>
                                    <td width="10%">Číslo dokladu</td>
                                    <td width="10%">Zapsal</td>
                                </tr>
                                <tr v-for="item in costsFromShop" :key="item.id">
                                    <td>
                                        <cost-link :item="item"/>
                                    </td>
                                    <td>
                                        {{ item.parent_id ? "Ano" : "Ne" }}
                                    </td>
                                    <td>
                                        <images-info :item="item"/>
                                    </td>
                                    <td>
                                        {{ item.value }}
                                    </td>
                                    <td>
                                        {{ item?.parent ? item.parent.meta?.document_number : item.meta?.document_number }}
                                    </td>
                                    <td>
                                        {{ item?.user?.name ?? "Anonym" }},
                                        {{ localizeDate(item.created_at) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="print-sheet">
                    <div class="d-flex align-items-center justify-content-between">
                        <h3>Materiál ze skladu</h3>
                    </div>

                    <div class="table-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="table__mainrow">
                                    <td>Název</td>
                                    <td>Částečný</td>
                                    <td>Fotky ({{ costsFromStock.map(i => (i.files ?? []).length).sum() }})</td>
                                    <td width="10%">Zapsal</td>
                                </tr>
                                <tr v-for="item in costsFromStock" :key="item.id">
                                    <td>
                                        <cost-link :item="item"/>
                                    </td>
                                    <td>
                                        {{ item.parent_id ? "Ano" : "Ne" }}
                                    </td>
                                    <td>
                                        <images-info :item="item"/>
                                    </td>
                                    <td>
                                        {{ item?.user?.name ?? "Anonym" }}, {{ localizeDate(item.created_at) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="print-sheet">
                    <div class="d-flex align-items-center justify-content-between">
                        <h3>Vozidla</h3>
                    </div>

                    <div class="table-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr class="table__mainrow">
                                    <td>Vozidlo</td>
                                    <td>Fotky ({{ costsFromTransport.map(i => (i.files ?? []).length).sum() }})</td>
                                    <td width="10%">Kolikrát na stavbě ({{ costsFromTransport.map(i => (parseInt(i.meta.count || 0))).sum() }})</td>
                                    <td width="10%">Zapsal</td>
                                </tr>
                                <tr v-for="item in costsFromTransport" :key="item.id">
                                    <td>
                                        <cost-link :item="item"/>
                                    </td>
                                    <td>
                                        <images-info :item="item"/>
                                    </td>
                                    <td>{{ item.meta.count }}</td>
                                    <td>{{ item?.user?.name ?? "Anonym" }},
                                        {{ localizeDate(item.created_at) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                    <h3>Dokumenty</h3>
                </div>
                <project-files :files="project.documents" model-type="project" :model-id="project?.id" :allow-upload="isAdmin()" @updated="fetchData" column="documents"/>
                <div class="d-flex align-items-center justify-content-between">
                    <h3>Fotky</h3>
                </div>
                <project-files :files="project.files" model-type="project" :model-id="project?.id" :allow-upload="true" @updated="fetchData"/>
            </template>

            <template v-if="tab == 'history'">

                <div class="print-sheet">
                    <div class="cards cards--hours">
                        <div class="crd" v-for="day in projectHoursByDays">
                            <div class="crd__title">{{ $moment(day.day).format("DD. MM. YYYY") }}</div>
                            <div class="crd__subtitle">
                                <weather-widget class="weather--small" :item="day?.items[0].meta?.weather"/>
                            </div>
                            <div class="crd__body" v-for="item in day.items">
                                <div class="crd__name">
                                    <user-link v-if="item?.user?.name" :item="item?.user" :position="false"/>
                                    <span v-else>Anonoym</span>
                                    <span v-if="item.amount">({{ item.amount }}h)</span>
                                </div>
                                <div class="crd__text" v-if="item.note">{{ item.note }}</div>
                                <project-files class="gallery--small" :files="item.files" :model-type="item.type" :model-id="item.id" @imageDeleted="fetchData"/>
                            </div>
                        </div>
                    </div>
                </div>

            </template>

        </template>
    </div>
</template>

<script>
import projectService from "@services/projectService";
import AddHoursModal from "@components/addHoursModal";
import ImagesInfo from "@components/imagesInfo";
import addCostModal from "@components/addCostModal";
import WeatherWidget from "@components/weatherWidget";
import CloseProjectModal from "@components/closeProjectModal";
import AddEvent from "@components/addEvent";
import ProjectInvoicesModal from "@components/ProjectInvoicesModal.vue";

export default {
    name: "project_show",
    components: {
        ProjectInvoicesModal,
        AddEvent,
        CloseProjectModal,
        WeatherWidget,
        AddHoursModal,
        ImagesInfo,
        addCostModal
    },

    mounted() {
        this.fetchData();
    },

    methods: {

        filterHours(from, to) {
            from = this.removeReactivity(from);
            to = this.removeReactivity(to);
            this.hoursFilter = [from, to];
        },

        addNote() {
            this.$refs.addEventModal.open({
                type: "images",
                model_id: this.project.id,
                model_type: "project",
            });
        },

        createProposal() {
            this.$refs.closeProjectModal.pid = this.project.id;
            this.$refs.closeProjectModal.createProposal();
        },

        toggleVerify() {
            if (!confirm("Opravdu změnit verifikaci?"))
                return;

            let status = !this.project.is_verified;

            let data = {
                status,
                project_id: this.project.id
            };

            this.setLoading("btn_verify")
            projectService.verifyProject(data).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_verify")
            });
        },

        toggleInvoice() {

        },

        updateHours(user_id, day) {
            let item = this.project.hours.find(i => i.user_id == user_id && i.day == day);
            if (item) {
                this.$refs.addHoursModal.view(item);
            }
        },

        async deleteCost(item) {
            let cost_id = item.id;
            projectService.deleteCost(cost_id).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            });
        },

        addOtherCost(type = 'shop', def = {}) {

            let hiddenFields = [
                {name: "project_id", value: this.project.id},
                {name: "cost_id", value: def?.id ?? null},
                {name: "type", value: type},
            ];

            this.$refs.addCostModal.hiddenFields = hiddenFields;
            this.$refs.addCostModal.composeBody(type, def);
            this.$refs.addCostModal.open();
        },

        setStatus(status) {
            let data = {
                status,
                project_id: this.project.id
            };

            this.setLoading("btn_setStatus")
            projectService.setStatus(data).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_setStatus")
            });
        },

        hoursSaveToday() {

            let params = {
                project_id: this.project.id,
            };

            if ([1, 2].includes(this.getRoleID())) {
                params = {
                    ...params,
                    select_user: true,
                    select_date: true,
                }
            }

            this.$refs.addHoursModal.open(params);
        },

        fetchData() {
            this.pageLoading = true;

            let payload = {
                project_id: this.$route.params.project_id,
            };

            projectService.getProjectCosts(payload).then(res => {
                let data = res.data;
                this.project = data.project;
                this.roles = data.roles;
                this.hours = this.project?.hours ?? [];

                this.invoiceModal.invoiced = this.project.is_invoiced ? "" + this.project.is_invoiced : '0';
                this.invoiceModal.invoices = this.removeReactivity(this.project.meta?.invoices ?? []);
                this.invoiceModal.invoice_reason = this.removeReactivity(this.project.meta?.invoice_reason ?? "");
                this.invoiceModal.invoice_files = this.removeReactivity(this.project.meta?.invoice_files ?? []);

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });

        },

        getDayTitle(date) {
            let date_obj = new Date(date);
            let day = date_obj.getDate();
            let m = date_obj.getMonth() + 1;
            let y = date_obj.getFullYear();
            return day + "/" + m + "<br>" + y;
        },

        getUserHours(user_id, day) {
            let item = this.hours.find(item => {
                return item.user_id == user_id && item.day == day;
            });
            if (!item) {
                return 0;
            }
            if (!item.amount) {
                return 0;
            }
            return parseFloat(item.amount);
        },

        getUserTotalHours(user_id) {
            let sum = 0;
            this.projectDays.forEach(day => {
                let h = this.getUserHours(user_id, day.key);
                sum += parseFloat(h);
            });
            return sum;
        },

    },

    computed: {

        projectStates: function () {
            let states = {};

            let finished = this.project.finished_at;
            let invoiced = this.project.is_invoiced;

            let label, labelClass;

            if (invoiced == "1") {
                label = "Vyfakturováno";
                labelClass = "text-success";
            } else if (invoiced == "2") {
                label = "Fakturace se neřeší";
                labelClass = "text-info";
            } else if (invoiced == "3") {
                label = "Částečná fakturace";
                labelClass = "text-info";
            } else {
                label = "Nevyfakturováno";
                labelClass = "text-danger";
            }

            states.invoicing = {
                allowed: finished ? true : false,
                label,
                labelClass,
                button: "Upravit"
            };

            return states;
        },

        projectHoursByDays() {
            let days = {};
            (this.project?.hours ?? []).forEach(item => {
                let day = item.day;

                if (!days?.[day]) {
                    let item = {};
                    item.day = day;
                    item.items = [];
                    days[day] = item;
                }
                item.type = "project_hour";
                days[day].items.push(item);
            });

            (this.project?.additional_images ?? []).forEach(item => {

                if (!item?.files?.length)
                    return;

                let day = this.$moment(item.created_at).format("YYYY-MM-DD");

                if (!days?.[day]) {
                    let item = {};
                    item.day = day;
                    item.items = [];
                    days[day] = item;
                }
                item.type = "event";
                item.note = item.text;
                days[day].items.push(item);
            });

            const ordered = Object.keys(days).sort().reduce(
                (obj, key) => {
                    obj[key] = days[key];
                    return obj;
                },
                {}
            );

            return Object.values(ordered).reverse();

        },

        costs: function () {
            return this.project.final_costs ?? [];
        },

        costsFromShop: function () {
            return this.costs.filter(item => item.type == 'shop');
        },

        costsFromStock: function () {
            return this.costs.filter(item => item.type == 'stock');
        },

        costsFromTransport: function () {
            return this.costs.filter(item => item.type == 'transport');
        },

        projectDays: function () {
            let days = {};
            this.hours.forEach(item => {
                let day = {
                    key: item.day,
                    label: this.getDayTitle(item.day)
                };

                days[item.day] = day;
            });
            let daysKeys = Object.keys(days).sort();
            let all_days = daysKeys.map(d => {
                return days[d]
            });

            let filter = this.hoursFilter;

            if (filter[0] && filter[1]) {

                let $from = this.$moment(filter[0]);
                let $to = this.$moment(filter[1]);

                all_days = all_days.filter(day => {
                    let $day = this.$moment(day.key);
                    return $day.isBetween($from, $to, null, "[]");
                });

            }

            return all_days;
        },

        projectUsers: function () {
            let users = {};
            this.hours.forEach(item => {
                let user = item.user;
                if(user?.id)
                users[user.id] = user;
            });
            return Object.values(users);
        },

        getTotalHours: function () {
            let sum = 0;

            this.projectUsers.forEach(user => {
                sum += this.getUserTotalHours(user?.id) ?? 0;
            });

            return sum;
        },

        hoursByRoles: function () {
            let hours = {};
            this.projectUsers.forEach((user) => {
                let user_id = user?.id;
                let user_role = user.role_id;
                let roleName = this.roles.find(role => {
                    return role.id == user_role;
                });
                if (!roleName) {
                    return;
                }
                roleName = roleName.name;
                if (!hours.hasOwnProperty(roleName)) {
                    hours[roleName] = 0;
                }
                hours[roleName] += this.getUserTotalHours(user_id);
            });
            return hours;
        },

        showClosingProposal: function () {

            if (this.project?.state?.slug == 'closed')
                return false;

            if (this.project.closing_proposal)
                return false;

            return true;
        }

    },

    data() {
        return {
            hoursFilter: [null, null],
            showFilterFromTo: false,
            filter_from: this.$moment().format("YYYY-MM-DD"),
            filter_to: this.$moment().format("YYYY-MM-DD"),
            hours: [],
            roles: [],
            project: {meta: {}},
            pageLoading: true,
            tab: "main",
            invoiceModal: {
                visible: false,
                invoices: [],
                invoiced: "0",
                invoice_reason: "",
                new_files: [],
                new_invoice_number: ""
            }
        };
    }
}
</script>