<template>
    <router-link :to="{name: 'task_show', params: {task_id: item.id}}">
        <span title="Můj úkol" v-if="showMe">⭐</span>
        {{ item.name }}
    </router-link>
</template>

<script>
export default {
    name: "taskLink",
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        higlighMyTask: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        showMe: function () {
            if (this.higlighMyTask) {
                let item = this.item;
                return [item.created_by, item.created_for].includes(this.getUserID());
            }
            return false;
        }
    }
}
</script>