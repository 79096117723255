<template>
    <div>
        <modal v-if="modal" @close="close">
            <form @submit.prevent="submit">
                <h3>Poznámka</h3>
                <div class="form-group">
                    <label>Text</label>
                    <textarea name="text" v-model="item.text" class="form-control"></textarea>
                </div>
                <div class="form-group">
                    <label>Priorita</label>
                    <select class="form-control" name="priority" v-model="item.priority">
                        <option v-for="item in priorities" :value="item.value" :key="item.value">{{ item.label }}</option>
                    </select>
                </div>
                <div class="form-group" v-if="userCan('offices')">
                    <label>Viditelné pro</label>
                    <select class="form-control" name="priority" v-model="item.group">
                        <option value="personal">Mne</option>
                        <option value="offices">Kancelář</option>
                        <option value="all">Všechny</option>
                        <option value="positions">Pozice</option>
                        <option value="users">Konkrétně</option>
                    </select>
                </div>
                <div v-if="item.group == 'positions'" class="form-group">
                    <label v-for="position in positions">
                        <input type="checkbox" :value="position.value" v-model="item.group_items"/>
                        {{ position.label }}
                    </label>
                </div>
                <div v-if="item.group == 'users'" class="form-group">
                    <label v-for="guser in users">
                        <input type="checkbox" :value="guser.value" v-model="item.group_items"/>
                        {{ guser.label }}
                    </label>
                </div>
                <div class="form-group">
                    <label>Fotky</label>
                    <project-files :files="item.files" :allow-upload="true" :handle-upload="false" model-type="note" :model-id="item.id" @newFiles="files => appendFiles = files" @imageDeleted="fetchData"/>
                </div>
                <app-button slug="btn_updateNote" class="btn-primary">Uložit</app-button>
                <app-button v-if="item.id" slug="btn_noteDelete" class="btn-danger" @click="deleteNote(item.id)" type="button">Smazat</app-button>
            </form>
        </modal>

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="flex-items">
                    <h3>Moje poznámky</h3>
                    <button v-if="myNotes && !onlyView" class="btn btn-primary" @click="addNote">+</button>
                    <router-link v-if="myNotes && onlyView" class="btn btn-primary" :to="{name: 'pinboard'}">Více</router-link>
                    <app-button v-if="saveSort" slug="btn_saveSort" @click="saveSortAction" class="btn-primary">Uložit pořadí</app-button>
                </div>
                <div class="notes">
                    <page-loader v-if="loading"/>
                    <table v-else class="table">
                        <thead>
                            <tr>
                                <th width="50%">Název</th>
                                <th>Fotky</th>
                                <th width="100px" v-if="myNotes && !onlyView"></th>
                            </tr>
                        </thead>
                        <draggable tag="tbody" v-model="notes" handle=".table--move" @update="sortChanged">
                            <tr v-for="item in notes" :key="item.id">
                                <td>
                                    <span v-if="item.priority !== 'none'" :class="'priority priority--'+item.priority" :title="item.priority_label"></span>
                                    {{ item.text }}
                                </td>
                                <td>
                                    <images-info :item="item"/>
                                </td>
                                <td v-if="myNotes && !onlyView">
                                    <button class="btn btn-icon btn-danger" @click="editItem(item)">
                                        <icon name="edit"/>
                                    </button>
                                    <button class="btn btn-icon table--move">✥</button>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="flex-items">
                    <h3>Sdílené se mnou</h3>
                </div>
                <div class="notes">
                    <page-loader v-if="loading"/>
                    <table v-else class="table">
                        <thead>
                            <tr>
                                <th width="50%">Název</th>
                                <th>Fotky</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in shared" :key="item.id">
                                <td>
                                    <span v-if="item.priority !== 'none'" :class="'priority priority--'+item.priority" :title="item.priority_label"></span>
                                    {{ item.text }}
                                </td>
                                <td>
                                    <images-info :item="item"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

import noteService from "@services/noteService";
import ImagesInfo from "@components/imagesInfo";
import generalService from "@services/generalService";

export default {
    name: "personalNotes",
    components: {ImagesInfo},
    props: {
        user: {
            type: Number,
            default: null,
        },
        priority: {
            type: String,
            default: "all"
        },
        onlyView: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {

        saveSortAction() {
            this.setLoading("btn_saveSort");

            let data = {
                model: "notes",
                items: this.removeReactivity(this.notes).map(i => i.id),
            };

            generalService.saveOrder(data).then(res => {

                this.$toast.success("Uloženo");
                this.saveSort = false;
                this.fetchData();

            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_saveSort");
            });

        },

        sortChanged(e = null) {
            this.saveSort = true;
        },

        deleteNote(id) {

            let data = {
                id,
            };

            this.setLoading("btn_noteDelete");
            noteService.deleteNote(data).then(r => {
                this.fetchData();
                this.close();
                this.$toast.success("Smazáno.");
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_noteDelete");
            });
        },

        close() {
            this.modal = false;
            this.item = {};
        },

        editItem(item) {
            this.item = item;
            this.modal = true;
        },

        fetchData() {

            let data = {};

            if (this.user) {
                data.user_id = this.user;
            }

            if (this.priority) {
                data.priority = this.priority;
            }

            this.loading = true;
            noteService.getNotes(data).then(res => {
                this.notes = res.data.items;
                this.shared = res.data.shared;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.loading = false;
            });

        },

        submit(e) {

            let group = this.item.group;
            let checked_items = this.removeReactivity(this.item.group_items);
            if (["personal", "offices", "all"].includes(group)) {
                checked_items = [];
            } else if (group == "positions") {
                checked_items = checked_items.filter(i => {
                    return i.includes("position_");
                });
            } else if (group == "users") {
                checked_items = checked_items.filter(i => {
                    return i.includes("user_");
                });
            }

            this.setLoading("btn_updateNote");

            let payload = {
                id: this.item.id ?? null,
                text: this.item.text ?? "",
                priority: this.item.priority ?? "",
                new_files: this.appendFiles,
                group,
                group_items: checked_items
            };

            noteService.updateNote(payload).then(res => {
                this.appendFiles = [];
                this.$toast.success("Uloženo");
                this.close();

                this.fetchData();

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_updateNote");
            });
        },

        addNote() {
            this.item = {
                priority: "none",
                group: "personal",
                group_items: [],
            };
            this.modal = true;
        },
    },

    computed: {

        myNotes: function () {
            if (!this.user)
                return true;
            if (this.user == this.getUserID()) {
                return true;
            }
            return false;
        },

        positions: function () {
            return this.removeReactivity(this.$store.state.allPositions).map(item => {
                return {
                    value: "position_" + item.id,
                    label: item.name,
                }
            });
        },

        users: function () {
            return this.removeReactivity(this.$store.state.allUsers).map(item => {
                return {
                    value: "user_" + item.id,
                    label: item.name_with_position,
                }
            });
        },

    },

    data() {
        return {
            saveSort: false,
            loading: false,
            appendFiles: [],
            modal: false,
            item: {
                group_items: [],
            },
            notes: [],
            shared: [],
            priorities: [
                {
                    value: "none",
                    label: "Žádná"
                },
                {
                    value: "small",
                    label: "Mírná"
                },
                {
                    value: "medium",
                    label: "Střední"
                },
                {
                    value: "big",
                    label: "Velká"
                },
            ]
        }
    }
}
</script>