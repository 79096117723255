var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('rent-modal', {
    ref: "rentModal"
  }), _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.userCan('things.create') ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'thing_create'
      }
    }
  }, [_vm._v(" Nová položka ")]) : _vm._e()], 1)]), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("Název")]), _c('th', [_vm._v("Popis")]), _c('th', [_vm._v("Evidenční číslo")]), _c('th', [_vm._v("Používá se")]), _vm.isAdmin() ? [_c('th', [_vm._v("Nákupní cena")]), _c('th', [_vm._v("Koupeno")])] : _vm._e(), _c('th')], 2), _vm._l(_vm.items, function (item) {
    var _item$meta, _item$meta2, _item$meta3, _item$meta4, _item$meta5;

    return _c('tr', [_c('td', [_vm._v(_vm._s(item.id))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.text))]), _c('td', [_vm._v(_vm._s((_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.internal_number))]), _c('td', [_vm._v(_vm._s(((_item$meta2 = item.meta) === null || _item$meta2 === void 0 ? void 0 : _item$meta2.still_used) == "yes" ? "Ano" : "Ne"))]), _vm.isAdmin() ? [_c('td', [_vm._v(_vm._s((_item$meta3 = item.meta) !== null && _item$meta3 !== void 0 && _item$meta3.price ? ((_item$meta4 = item.meta) === null || _item$meta4 === void 0 ? void 0 : _item$meta4.price) + " Kč" : ""))]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item === null || item === void 0 ? void 0 : (_item$meta5 = item.meta) === null || _item$meta5 === void 0 ? void 0 : _item$meta5.bought_at)) + " ")])] : _vm._e(), _c('td', {
      staticClass: "flex-buttons"
    }, [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function ($event) {
          return _vm.$refs.rentModal.open({
            thing_id: item.id
          });
        }
      }
    }, [_vm._v(" Půjčit si ")]), _vm.isAdmin() ? _c('router-link', {
      staticClass: "btn btn-danger",
      attrs: {
        "to": {
          name: 'thing_create',
          params: {
            thing_id: item.id
          }
        }
      }
    }, [_vm._v(" Upravit ")]) : _vm._e()], 1)], 2);
  })], 2)])]], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('h2', [_vm._v("Správa majetku")])]);
}]

export { render, staticRenderFns }