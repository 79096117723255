export default function (rows, filters, mode = 'normal', index = null) {
    let engines = [];

    if (mode == "normal") {
        engines = [null];
    } else if (mode == 'all_engines') {
        engines = ['google', 'seznam'];
    } else {
        engines = [mode];
    }

    let engines_count = engines.length;

    return rows.filter((item) => {

        let engine_stop_count = 0;

        engines.forEach(engine => {

            let stop = false;

            filters.forEach((filter) => {

                let filter_key = filter.key;
                let filter_type = filter.type;
                let filter_value = filter.value ? JSON.parse(JSON.stringify(filter.value)) : null;
                let operator = filter.operator;

                let value = null;
                if (engine) {
                    value = item?.[engine]?.[filter_key] ?? null;
                } else {
                    value = item?.[filter_key] ?? null;
                }

                if (filter_type == 'string' && filter_value) {

                    if (!value) {
                        stop = true;
                        return;
                    }

                    let contains = value.indexOf(filter_value) >= 0;

                    if (
                        (operator == "contains" && !contains) ||
                        (operator == "not_contains" && contains)
                    ) {
                        stop = true;
                        return;
                    }

                }

                if (filter_type == 'between') {

                    let minVal = filter_value.hasOwnProperty('from') ? filter_value.from : null;
                    let maxVal = filter_value.hasOwnProperty('to') ? filter_value.to : null;

                    value = parseInt(value);

                    // if value is not a number
                    if (value === null || typeof value === 'undefined' || isNaN(value)) {
                        stop = true;
                        return;
                    }

                    // if is setted minval and is not a number
                    if (minVal !== null && isNaN(parseInt(minVal))) {
                        stop = true;
                        return;
                    }

                    // if is setted maxval and is not a number
                    if (maxVal !== null && isNaN(parseInt(maxVal))) {
                        stop = true;
                        return;
                    }

                    if (minVal !== null && value < parseInt(minVal)) {
                        stop = true;
                        return;
                    }

                    if (maxVal !== null && value > parseInt(maxVal)) {
                        stop = true;
                        return;
                    }

                }

                if (filter_type == 'multiselect') {

                    let matches = [];

                    if (!Array.isArray(value)) {
                        stop = true;
                        return;
                    }

                    if (operator == "and") {

                        let local_stop = false;

                        filter_value.forEach(searched => {

                            if (value.indexOf(searched) == -1) {
                                local_stop = true;
                                return;
                            }

                        });

                        if (!local_stop) {
                            matches.push(value);
                        }

                    } else {

                        matches = value.filter((val) => {
                            return filter_value.indexOf(val) >= 0 ? true : false;
                        });
                    }

                    if (!matches.length) {
                        stop = true;
                        return;
                    }
                }

                if (filter_type == 'select') {
                    if (filter_value && value !== filter_value) {
                        stop = true;
                        return;
                    }
                }

            });

            if (stop) {
                engine_stop_count++;
            }

        });

        if (engine_stop_count == engines_count)
            return false;

        return true;
    });
}