<template>
    <router-link :to="{name: 'project_show',params: {project_id: computedItem.id}}" :class="computedItem.is_verified ? '' : 'text-danger'">
        {{ label }}
    </router-link>
</template>

<script>
export default {
    name: "projectLink",
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        hideClient: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedItem: function () {
            if (this.params?.data?.id)
                return this.params.data;
            return this.item;
        },
        label: function () {
            let label = "";

            if (!this.hideClient)
                label += (this.computedItem?.client?.name || "") + " - ";

            label += this.computedItem?.name;
            return label;
        }
    }
}
</script>