var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Obec")]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Obec")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.city.name,
      expression: "city.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.city.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.city, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("PSČ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.city.zipcode,
      expression: "city.zipcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.city.zipcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.city, "zipcode", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("GPS Lat")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.city.lat,
      expression: "city.lat"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.city.lat
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.city, "lat", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("GPS Lng")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.city.lng,
      expression: "city.lng"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.city.lng
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.city, "lng", $event.target.value);
      }
    }
  })]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_updatecity",
      "type": "submit"
    }
  }, [_vm._v("Uložit")]), _vm.isAdmin() && _vm.city_id ? _c('app-button', {
    staticClass: "btn btn-danger",
    attrs: {
      "slug": "btn_deletecity",
      "type": "button"
    },
    on: {
      "click": _vm.deleteCity
    }
  }, [_c('icon', {
    attrs: {
      "name": "trash"
    }
  })], 1) : _vm._e()], 1)])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }