var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Detail uživatele")]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Jméno")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.name,
      expression: "user.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.user.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("E-mail")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email,
      expression: "user.email"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.user.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Telefon")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.meta.phone,
      expression: "user.meta.phone"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.user.meta.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user.meta, "phone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Role")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.role_id,
      expression: "user.role_id"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.user, "role_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.roles, function (item) {
    return _c('option', {
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Pozice")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.position_id,
      expression: "user.position_id"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.user, "position_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.positions, function (item) {
    return _c('option', {
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Živnostník")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.contractor,
      expression: "user.contractor"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.user, "contractor", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("Ne")]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Ano")])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Aktivní")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.active,
      expression: "user.active"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.user, "active", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Ano")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("Ne")])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Nové heslo")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_password,
      expression: "new_password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password"
    },
    domProps: {
      "value": _vm.new_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.new_password = $event.target.value;
      }
    }
  })]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_updateUser",
      "type": "submit"
    }
  }, [_vm._v("Uložit")])], 1)])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }