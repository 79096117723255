var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('merge-shops-modal', {
    ref: "mergeShopsModal",
    on: {
      "refresh": _vm.fetchData
    }
  }), _c('h2', [_vm._v("Správa obchodů")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.$refs.mergeShopsModal.open();
      }
    }
  }, [_vm._v("Spojit obchody")]), _c('br'), _c('br'), _vm.pageLoading ? _c('page-loader') : _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.saveRow.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("Název")]), _vm.allowedEditing ? _c('th') : _vm._e()]), _vm._l(_vm.items, function (item) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(item.id))]), _c('td', [_vm.editing == item.id ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.name,
        expression: "item.name"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.name
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "name", $event.target.value);
        }
      }
    }) : _c('div', [_vm._v(_vm._s(item.name))])]), _vm.allowedEditing ? _c('td', {
      staticClass: "flex-buttons"
    }, [_vm.editing == item.id ? [_c('button', {
      key: "edit_save",
      staticClass: "btn btn-primary",
      attrs: {
        "type": "submit"
      }
    }, [_vm._v(" Uložit ")]), _c('button', {
      key: "edit_cancel",
      staticClass: "btn btn-danger",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          _vm.editing = null;
        }
      }
    }, [_vm._v(" Zrušit ")])] : [_c('button', {
      key: "edit",
      staticClass: "btn btn-danger btn-icon",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          _vm.editing = item.id;
          _vm.addNew.visible = false;
        }
      }
    }, [_c('icon', {
      attrs: {
        "name": "edit"
      }
    })], 1), _c('button', {
      key: "delete",
      staticClass: "btn btn-danger btn-icon",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteItem(item);
        }
      }
    }, [_c('icon', {
      attrs: {
        "name": "trash"
      }
    })], 1)]], 2) : _vm._e()]);
  }), _vm.allowedEditing ? _c('tr', [_c('td'), _c('td', [_vm.addNew.visible ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.addNew.name,
      expression: "addNew.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.addNew.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.addNew, "name", $event.target.value);
      }
    }
  }) : _vm._e()]), _c('td', {
    staticClass: "flex-buttons"
  }, [_vm.addNew.visible ? [_c('button', {
    key: "new_save",
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    key: "new_cancel",
    staticClass: "btn btn-danger",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.addNew.visible = false;
      }
    }
  }, [_vm._v(" Zrušit ")])] : _c('button', {
    key: "new",
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function () {
        _vm.addNew.visible = true;
        _vm.editing = null;
      }
    }
  }, [_vm._v(" Nový obchod ")])], 2)]) : _vm._e()], 2)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }