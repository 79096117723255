<template>
    <modal v-show="visible" @close="close">
        <h3>Návrh na uzavření zakázky</h3>

        <div class="form-group">
            <label>Zakázka</label>
            <app-select auto-load="projects" v-model="pid"/>
        </div>

        <app-button slug="btn_createProposal" @click="createProposal" class="btn-primary">Odeslat</app-button>
    </modal>
</template>

<script>
import projectService from "@services/projectService";

export default {
    name: "closeProjectModal",

    mounted() {
        // this.fetchData();
    },

    methods: {

        createProposal() {
            this.setLoading("btn_createProposal");

            let data = {
                project_id: this.pid
            };

            projectService.createClosingProposal(data).then(res => {
                this.$emit("updated");
                this.$toast.success("Návrh podán.");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_createProposal");
            });

        },


        open(atts = {}) {
            this.visible = true;
        },

        close() {
            this.$emit("close");
            this.visible = false;
        }

    },

    data() {
        return {
            visible: false,
            pid: null
        }
    }

}
</script>