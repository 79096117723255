var render = function () {
  var _vm$gasByCar$total$li, _vm$gasByCar4, _vm$gasByCar4$total, _vm$gasByCar5, _vm$gasByCar5$total, _vm$gasByCar$total$pr, _vm$gasByCar6, _vm$gasByCar6$total;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("Měsíční report tankování")]), _c('p', [_vm._v("Celkem lístků: " + _vm._s(_vm.receiptsCount))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('div', {
    staticClass: "report_arrows"
  }, [_c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": {
        name: 'car_reports',
        params: {
          date: _vm.prevDate
        }
      }
    }
  }, [_vm._v(" ⇚ ")]), _c('div', {
    staticClass: "report_label"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": {
        name: 'car_reports',
        params: {
          date: _vm.nextDate
        }
      }
    }
  }, [_vm._v(" ⇛ ")])], 1)]), _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_c('a', {
    staticClass: "btn btn-primary",
    attrs: {
      "href": _vm.xlsLink,
      "download": ""
    }
  }, [_vm._v("Stáhnout XLS")])])]), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("Jméno")]), _vm._l(_vm.items, function (item) {
    return _c('th', [_vm._v(_vm._s(_vm._f("moment")(item.date, "D.")))]);
  }), _c('th', [_vm._v("Celkem")])], 2), _vm._l(_vm.cars, function (car) {
    var _vm$gasByCar$car$id$l, _vm$gasByCar, _vm$gasByCar$car$id, _vm$gasByCar2, _vm$gasByCar2$car$id, _vm$gasByCar$car$id$p, _vm$gasByCar3, _vm$gasByCar3$car$id;

    return _c('tr', [_c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'car_detail',
          params: {
            car_id: car.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(car.fullname) + " ")])], 1), _vm._l(_vm.items, function (item) {
      return _c('td', [_c('div', {
        staticStyle: {
          "display": "flex",
          "flex-flow": "column",
          "gap": "4px"
        }
      }, _vm._l(item.items.filter(function (i) {
        var _i$meta;

        return ((_i$meta = i.meta) === null || _i$meta === void 0 ? void 0 : _i$meta.car_id) == car.id;
      }), function (event) {
        var _event$meta$liters, _event$meta, _event$value;

        return _c('div', [_c('router-link', {
          attrs: {
            "to": {
              name: 'cost_show',
              params: {
                cost_id: event.id
              }
            }
          }
        }, [_vm._v(_vm._s((_event$meta$liters = (_event$meta = event.meta) === null || _event$meta === void 0 ? void 0 : _event$meta.liters) !== null && _event$meta$liters !== void 0 ? _event$meta$liters : 0) + " l")]), _c('br'), _vm._v(_vm._s(parseFloat((_event$value = event.value) !== null && _event$value !== void 0 ? _event$value : 0).formatCurrency()) + " ")], 1);
      }), 0)]);
    }), _c('td', [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-flow": "column",
        "gap": "4px"
      }
    }, [_vm._v(" " + _vm._s(((_vm$gasByCar$car$id$l = (_vm$gasByCar = _vm.gasByCar) === null || _vm$gasByCar === void 0 ? void 0 : (_vm$gasByCar$car$id = _vm$gasByCar[car.id]) === null || _vm$gasByCar$car$id === void 0 ? void 0 : _vm$gasByCar$car$id.liters) !== null && _vm$gasByCar$car$id$l !== void 0 ? _vm$gasByCar$car$id$l : 0).toFixed(2)) + " l "), (_vm$gasByCar2 = _vm.gasByCar) !== null && _vm$gasByCar2 !== void 0 && (_vm$gasByCar2$car$id = _vm$gasByCar2[car.id]) !== null && _vm$gasByCar2$car$id !== void 0 && _vm$gasByCar2$car$id.liters ? _c('div', [_vm._v(_vm._s(parseFloat((_vm$gasByCar$car$id$p = (_vm$gasByCar3 = _vm.gasByCar) === null || _vm$gasByCar3 === void 0 ? void 0 : (_vm$gasByCar3$car$id = _vm$gasByCar3[car.id]) === null || _vm$gasByCar3$car$id === void 0 ? void 0 : _vm$gasByCar3$car$id.price) !== null && _vm$gasByCar$car$id$p !== void 0 ? _vm$gasByCar$car$id$p : 0).formatCurrency()))]) : _vm._e()])])], 2);
  }), _c('tr', [_c('td', [_vm._v(" Celkem za všechny vozidla ")]), _c('td', {
    attrs: {
      "colspan": _vm.items.length
    }
  }), _c('td', [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-flow": "column",
      "gap": "4px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$gasByCar$total$li = (_vm$gasByCar4 = _vm.gasByCar) === null || _vm$gasByCar4 === void 0 ? void 0 : (_vm$gasByCar4$total = _vm$gasByCar4["total"]) === null || _vm$gasByCar4$total === void 0 ? void 0 : _vm$gasByCar4$total.liters) !== null && _vm$gasByCar$total$li !== void 0 ? _vm$gasByCar$total$li : 0).toFixed(2)) + " l "), (_vm$gasByCar5 = _vm.gasByCar) !== null && _vm$gasByCar5 !== void 0 && (_vm$gasByCar5$total = _vm$gasByCar5['total']) !== null && _vm$gasByCar5$total !== void 0 && _vm$gasByCar5$total.liters ? _c('div', [_vm._v(_vm._s(parseFloat((_vm$gasByCar$total$pr = (_vm$gasByCar6 = _vm.gasByCar) === null || _vm$gasByCar6 === void 0 ? void 0 : (_vm$gasByCar6$total = _vm$gasByCar6['total']) === null || _vm$gasByCar6$total === void 0 ? void 0 : _vm$gasByCar6$total.price) !== null && _vm$gasByCar$total$pr !== void 0 ? _vm$gasByCar$total$pr : 0).formatCurrency()))]) : _vm._e()])])])], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }