import homepage from "@pages/homepage";
import client_index from "@pages/client/client_index";
import client_create from "@pages/client/client_create";
import project_show from "@pages/project/project_show";
import project_index from "@pages/project/project_index";
import project_edit from "@pages/project/project_edit";
import task_index from "@pages/task/task_index";
import task_create from "@pages/task/task_create";
import task_show from "@pages/task/task_show";
import user_index from "@pages/user/user_index";
import user_show from "@pages/user/user_show";
import user_create from "@pages/user/user_create";
import user_reports from "@pages/user/user_reports";
import user_roles from "@pages/user/user_roles";
import user_positions from "@pages/user/user_positions";
import cost_show from "@pages/project/cost_show";
import cost_archive from "@pages/project/cost_archive";
import thing_index from "@pages/things/thing_index";
import thing_create from "@pages/things/thing_create";
import thing_show from "@pages/things/thing_show";
import thing_rents from "@pages/things/thing_rents";
import car_index from "@pages/car/car_index";
import car_update from "@pages/car/car_update";
import car_detail from "@pages/car/car_detail";
import plan_index from "@pages/plan/plan_index";
import car_reports from "@pages/car/car_reports";
import login from "@pages/auth/login";
import project_merge from "@pages/project/project_merge";
import user_position_report from "@pages/user/user_position_report";
import client_merge from "@pages/client/client_merge";
import client_detail from "@pages/client/client_detail";
import city_index from "@pages/city/city_index";
import city_create from "@pages/city/city_create";
import city_merge from "@pages/city/city_merge";
import searchPage from "@pages/search/searchPage";
import shop_index from "@pages/project/shop_index";
import pinboard from "@pages/pinboard/pinboard";
import paidCosts from "@pages/costs/paidCosts";
import project_internal from "@pages/project/project_internal";

export default [
    {
        path: '/',
        name: 'homepage',
        component: homepage,
    },
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: '/clients',
        name: 'client_index',
        component: client_index,
    },
    {
        path: '/clients/merge',
        name: 'client_merge',
        component: client_merge,
    },
    {
        path: '/clients/create/:client_id?',
        name: 'client_create',
        component: client_create,
    },
    {
        path: '/clients/detail/:client_id',
        name: 'client_detail',
        component: client_detail,
    },
    // Projects
    {
        path: '/projects',
        name: 'project_index',
        component: project_index
    },
    {
        path: '/projects/show/:project_id',
        name: 'project_show',
        component: project_show,
    },
    {
        path: '/projects/edit/:project_id?',
        name: 'project_edit',
        component: project_edit,
    },
    {
        path: '/projects/cost/:cost_id',
        name: 'cost_show',
        component: cost_show,
    },
    {
        path: '/projects/cost-archive',
        name: 'cost_archive',
        component: cost_archive,
    },
    {
        path: '/projects/merge',
        name: 'project_merge',
        component: project_merge,
    },
    {
        path: '/projects/internal',
        name: 'project_internal',
        component: project_internal,
    },
    // Users
    {
        path: '/users',
        name: 'user_index',
        component: user_index,
    },
    {
        path: '/users/show/:user_id/:date?',
        name: 'user_show',
        component: user_show,
    },
    {
        path: '/users/create/:user_id?',
        name: 'user_create',
        component: user_create,
    },
    {
        path: '/users/report/:date?',
        name: 'user_reports',
        component: user_reports,
    },
    {
        path: '/users/roles',
        name: 'user_roles',
        component: user_roles,
    },
    {
        path: '/users/positions',
        name: 'user_positions',
        component: user_positions,
    },
    {
        path: '/users/profile/:date?',
        name: 'user_profile',
        component: user_show,
    },
    {
        path: '/users/position-report/:date?',
        name: 'user_position_report',
        component: user_position_report,
    },
    // Tasks
    {
        path: '/task',
        name: 'task_index',
        component: task_index,
    },
    {
        path: '/task/create/:task_id?',
        name: 'task_create',
        component: task_create,
    },
    {
        path: '/task/show/:task_id',
        name: 'task_show',
        component: task_show,
    },
    // Thing
    {
        path: '/thing/list',
        name: 'thing_list',
        component: thing_index,
    },
    {
        path: '/thing/create/:thing_id?',
        name: 'thing_create',
        component: thing_create,
    },
    {
        path: '/thing/show/:thing_id',
        name: 'thing_show',
        component: thing_show,
    },
    {
        path: '/thing/rent/list',
        name: 'thing_rents',
        component: thing_rents,
    },
    // Car
    {
        path: '/car/list',
        name: 'car_index',
        component: car_index,
    },
    {
        path: '/car/update/:car_id?',
        name: 'car_update',
        component: car_update,
    },
    {
        path: '/car/detail/:car_id',
        name: 'car_detail',
        component: car_detail,
    },
    {
        path: '/car/report/:date?',
        name: 'car_reports',
        component: car_reports,
    },
    // Planning
    {
        path: '/plan/:week?',
        name: 'plan_index',
        component: plan_index,
    },
    // City
    {
        path: '/city',
        name: 'city_index',
        component: city_index,
    },
    {
        path: '/city/update/:city_id?',
        name: 'city_create',
        component: city_create,
    },
    {
        path: '/city/merge',
        name: 'city_merge',
        component: city_merge,
    },
    {
        path: '/search',
        name: 'search',
        component: searchPage,
    },
    {
        path: '/shops',
        name: 'shop_index',
        component: shop_index,
    },
    {
        path: '/pinboard',
        name: 'pinboard',
        component: pinboard,
    },
    {
        path: '/paidCosts',
        name: 'paidCosts',
        component: paidCosts,
    },
]