import axios from "@app/helpers/apiServer";

export default {

    search(query) {
        return axios({
            url: "/ajax-search/search",
            method: "post",
            responseType: "json",
            data: {
                query
            }
        });
    },
}