var render = function () {
  var _vm$holiday_data, _vm$holiday_data2, _vm$holiday_data3, _vm$holiday_data4, _vm$holiday_data5, _vm$spentHours2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showNotesModal ? _c('modal', {
    on: {
      "close": function ($event) {
        _vm.showNotesModal = false;
      }
    }
  }, [_c('personal-notes', {
    attrs: {
      "user": _vm.user.id
    }
  })], 1) : _vm._e(), _c('add-holidays-modal', {
    ref: "addHolidaysModal",
    on: {
      "saved": _vm.fetchData
    }
  }), _c('add-hours-modal', {
    ref: "hoursModal",
    on: {
      "saved": _vm.fetchData
    }
  }), _vm.changePassModal.visible ? _c('modal', {
    on: {
      "close": function ($event) {
        _vm.changePassModal.visible = false;
      }
    }
  }, [_c('div', {
    staticClass: "popup__content"
  }, [_c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Nové heslo:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.changePassModal.pass,
      expression: "changePassModal.pass"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password"
    },
    domProps: {
      "value": _vm.changePassModal.pass
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.changePassModal, "pass", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Nové heslo pro kontrolu:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.changePassModal.pass2,
      expression: "changePassModal.pass2"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password"
    },
    domProps: {
      "value": _vm.changePassModal.pass2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.changePassModal, "pass2", $event.target.value);
      }
    }
  })]), _vm.passFail ? _c('div', [_c('br'), _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v("Hesla se neshodují")])]) : _vm._e()]), _c('div', {
    staticClass: "popup__buttons"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_changePass",
      "type": "submit",
      "disabled": _vm.passFail
    },
    on: {
      "click": _vm.changePass
    }
  }, [_vm._v(" Změnit heslo ")]), _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.changePassModal.visible = false;
      }
    }
  }, [_vm._v("Zrušit")])], 1)]) : _vm._e(), _vm.holidayFundModal.visible ? _c('modal', {
    staticClass: "popup--large",
    on: {
      "close": function ($event) {
        _vm.holidayFundModal.visible = false;
      }
    }
  }, [_c('h3', [_vm._v("Fond dovolené")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("Rok")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("Fond dní")]), _c('th', [_vm._v("Poznámka")])])]), _vm._l(_vm.user_holiday_funds, function (item) {
    return _c('tr', [_c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.year,
        expression: "item.year"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.year
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "year", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.fund,
        expression: "item.fund"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.fund
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "fund", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.note,
        expression: "item.note"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.note
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "note", $event.target.value);
        }
      }
    })])]);
  })], 2), _c('div', {
    staticClass: "popup__buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addHolidayFund
    }
  }, [_vm._v("Přidat řádek")]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_saveHolidayFunds",
      "type": "button"
    },
    on: {
      "click": _vm.saveHolidayFunds
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.holidayFundModal.visible = false;
      }
    }
  }, [_vm._v("Zrušit")])], 1)]) : _vm._e(), _vm.advanceModal.visible ? _c('modal', {
    on: {
      "close": function ($event) {
        _vm.advanceModal.visible = false;
      }
    }
  }, [_c('div', {
    staticClass: "popup__content"
  }, [_c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Den zálohy:")]), _c('app-date', {
    model: {
      value: _vm.advanceModal.day,
      callback: function ($$v) {
        _vm.$set(_vm.advanceModal, "day", $$v);
      },
      expression: "advanceModal.day"
    }
  })], 1), _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Částka:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.advanceModal.amount,
      expression: "advanceModal.amount"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.advanceModal.amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.advanceModal, "amount", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Popis:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.advanceModal.desc,
      expression: "advanceModal.desc"
    }],
    staticClass: "form-control",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "rows": "5"
    },
    domProps: {
      "value": _vm.advanceModal.desc
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.advanceModal, "desc", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "popup__buttons"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_addAdvance",
      "type": "submit"
    },
    on: {
      "click": _vm.advanceModalSave
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.advanceModal.visible = false;
      }
    }
  }, [_vm._v("Zrušit")]), _vm.advanceModal.item_id ? _c('app-button', {
    staticClass: "btn btn-danger",
    attrs: {
      "slug": "btn_addAdvance",
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.advanceModalSave({
          delete_item: true
        });
      }
    }
  }, [_vm._v(" Smazat ")]) : _vm._e()], 1)]) : _vm._e(), _vm.loading ? _c('page-loader') : [_c('div', {
    staticClass: "row align-items-center no-print"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }), _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_vm.canAddHolidaysToUser(_vm.user_id) ? _c('a', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.editSickDay();
      }
    }
  }, [_vm._v("Zadat dovolenou")]) : _vm._e(), _vm.userCan('profile-add-advance') ? _c('a', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.editAdvance();
      }
    }
  }, [_vm._v("Zadat zálohu")]) : _vm._e(), _vm.user_id == _vm.$store.state.user.id ? _c('a', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.changePassModal.visible = true;
      }
    }
  }, [_vm._v("Změnit moje heslo")]) : _vm._e(), _c('a', {
    staticClass: "btn btn-secondary",
    on: {
      "click": _vm.print
    }
  }, [_vm._v("Tisk")])])]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Detail uživatele - " + _vm._s(_vm.user.name) + " - " + _vm._s(_vm.currentDate))]), _vm.userCan('office') ? _c('button', {
    staticClass: "btn btn-secondary no-print",
    on: {
      "click": _vm.showNotes
    }
  }, [_vm._v("Zobrazit poznámky")]) : _vm._e()]), _c('div', {
    staticClass: "col-12 col-lg-6 d-flex justify-content-end no-print"
  }, [_c('div', {
    staticClass: "report_arrows"
  }, [_c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": _vm.prevMonth
    }
  }, [_vm._v("⇚")]), _c('div', {
    staticClass: "report_label"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": _vm.nextMonth
    }
  }, [_vm._v("⇛")])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Odpracované hodiny")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('th', [_vm._v("V pracovních dnech:")]), _c('td', {
    attrs: {
      "width": "180"
    }
  }, [_vm._v(_vm._s(_vm.workingStats.week) + " h")])]), _c('tr', [_c('th', [_vm._v("V sobotu:")]), _c('td', {
    staticClass: "td--saturday"
  }, [_vm._v(_vm._s(_vm.workingStats.saturday) + " h")])]), _c('tr', [_c('th', [_vm._v("V neděli:")]), _c('td', {
    staticClass: "td--sunday"
  }, [_vm._v(_vm._s(_vm.workingStats.sunday) + " h")])]), _c('tr', [_c('th', [_vm._v("Ve státních svátcích:")]), _c('td', {
    staticClass: "td--holiday"
  }, [_vm._v(_vm._s(_vm.workingStats.holiday) + " h")])]), _c('tr', [_c('th', [_vm._v("Dohromady odpracováno:")]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.userAllHours) + " h")])])]), _vm.notContractor ? _c('tr', [_c('th', [_vm._v("Fond pracovních hodin:")]), _c('td', [_vm._v(" " + _vm._s(_vm.workingHoursInCurrentMonth) + " h ")])]) : _vm._e(), _vm.notContractor ? _c('tr', [_c('th', [_vm._v("Odpracováno + DP + §P + N:")]), _c('td', [_vm._v(" " + _vm._s(_vm.totalHoursForCurrentMonth) + " h "), _vm.totalHoursMissingForCurrentMonth ? _c('span', [_vm._v("(chybí " + _vm._s(_vm.totalHoursMissingForCurrentMonth) + " h)")]) : _vm._e(), _vm.totalHoursOverForCurrentMonth ? _c('span', [_vm._v("(přesčas " + _vm._s(_vm.totalHoursOverForCurrentMonth) + " h)")]) : _vm._e()])]) : _vm._e()]), _c('holiday-legend', {
    attrs: {
      "type": "spent"
    }
  })], 1), _c('div', {
    staticClass: "no-print"
  }, [_c('h3', [_vm._v("Zálohy")]), _vm.advances.length ? _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('th', [_vm._v("Den zálohy")]), _c('th', [_vm._v("Částka")]), _c('th', [_vm._v("Popis")]), _c('th')]), _vm._l(_vm.advances, function (item) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.target_date)) + " ")]), _c('td', [_vm._v(_vm._s(item.amount.formatCurrency()))]), _c('td', [_vm._v(_vm._s(item.description))]), _c('td', [_vm.userCan('profile-edit-advance') ? _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function ($event) {
          return _vm.editAdvance(item);
        }
      }
    }, [_vm._v("Upravit")]) : _vm._e()])]);
  }), _c('tr', [_c('td', [_c('b', [_vm._v("Celkem")])]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.advanceTotal.formatCurrency()))])]), _c('td'), _c('td')])], 2)]) : _c('div', [_vm._v("Žádné zálohy nebyly vystaveny")])])]), _c('div', {
    staticClass: "col-12 col-lg-6",
    class: {
      'no-print': !_vm.notContractor
    }
  }, [_c('h3', [_vm._v("Dovolené")]), _vm.currentMonthHolidays.length ? _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('th', {
    attrs: {
      "width": "200"
    }
  }, [_vm._v("Termín")]), _c('th', [_vm._v("Typ")]), _c('th', [_vm._v("Popis")]), _c('th')]), _vm._l(_vm.currentMonthHolidays, function (item) {
    var _item$working_days_sp;

    return _c('tr', [_c('td', {
      staticClass: "td--userHoliday-label"
    }, [_vm._v(" " + _vm._s(item.range_label) + " (" + _vm._s((_item$working_days_sp = item === null || item === void 0 ? void 0 : item.working_days_spent_hours) !== null && _item$working_days_sp !== void 0 ? _item$working_days_sp : 0) + "h) ")]), _c('td', [_vm._v(_vm._s(item.type_label))]), _c('td', [_vm._v(_vm._s(item.description))]), _c('td', [_vm.canEditHolidays(item) ? _c('button', {
      staticClass: "btn btn-danger no-print",
      on: {
        "click": function ($event) {
          return _vm.editSickDay(item);
        }
      }
    }, [_vm._v("Upravit")]) : _vm._e()])]);
  }), _vm.currentMonthPaidHolidays || _vm.currentMonthNotPaidHolidays ? _c('tr', [_c('td', [_vm._v("Tento měsíc na dovolené")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentMonthPaidHolidays) + " placených hodin "), _c('br'), _vm._v(" " + _vm._s(_vm.currentMonthNotPaidHolidays) + " neplacených hodin ")])]) : _vm._e(), _vm.paidParagraphTotal || _vm.notPaidParagraphTotal ? _c('tr', [_c('th', [_vm._v("Paragraf")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.paidParagraphTotal) + " placených hodin "), _c('br'), _vm._v(" " + _vm._s(_vm.notPaidParagraphTotal) + " neplacených hodin ")])]) : _vm._e(), _vm.sickdayTotal ? _c('tr', [_c('th', [_vm._v("Nemocenská")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.sickdayTotal) + " hodin ")])]) : _vm._e()], 2)]) : _c('div', [_vm._v("Žádné dovolené v tomto měsíci")]), _vm.notContractor ? _c('div', {
    staticClass: "no-print"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', [_vm._v("Souhrn dovolené " + _vm._s(_vm.holiday_data.year))])]), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.isAdmin() ? _c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        _vm.holidayFundModal.visible = true;
      }
    }
  }, [_vm._v("Fond dovolené")]) : _vm._e()])]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('td', [_vm._v("Celkový fond")]), _c('td', [_vm._v(_vm._s((((_vm$holiday_data = _vm.holiday_data) === null || _vm$holiday_data === void 0 ? void 0 : _vm$holiday_data.fund_hours) / 8).toFixed(1)) + " dní (" + _vm._s((_vm$holiday_data2 = _vm.holiday_data) === null || _vm$holiday_data2 === void 0 ? void 0 : _vm$holiday_data2.fund_hours) + " h)")]), _c('td', [_vm._v(_vm._s((_vm$holiday_data3 = _vm.holiday_data) === null || _vm$holiday_data3 === void 0 ? void 0 : _vm$holiday_data3.fund_hours_note))])]), _c('tr', [_c('td', [_vm._v("Vyčerpáno")]), _c('td', [_vm._v(_vm._s((((_vm$holiday_data4 = _vm.holiday_data) === null || _vm$holiday_data4 === void 0 ? void 0 : _vm$holiday_data4.current_year_spent_hours) / 8).toFixed(1)) + " dní (" + _vm._s((_vm$holiday_data5 = _vm.holiday_data) === null || _vm$holiday_data5 === void 0 ? void 0 : _vm$holiday_data5.current_year_spent_hours) + " h)")]), _c('td')]), _c('tr', [_c('td', [_vm._v("Můžete čerpat")]), _c('td', [_vm._v(_vm._s((_vm.restPaidHours / 8).toFixed(1)) + " dní (" + _vm._s(_vm.restPaidHours) + " h)")]), _c('td')])])])]) : _vm._e()])]), _c('div', {
    staticClass: "pagebreak"
  }), _c('div', {
    staticClass: "print-sheet"
  }, [_c('h3', [_vm._v("Zakázky")]), _c('holiday-legend'), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped table--spenthours"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "table--project-title"
  }, [_vm._v("Zakázka")]), _vm._l(_vm.daysInMonth, function (day, index) {
    return _c('th', {
      class: {
        'th--today': day.isToday
      }
    }, [_vm._v(" " + _vm._s(day.index) + ". ")]);
  }), _c('th', [_vm._v("Celkem")])], 2)]), _c('tbody', [_c('tr', [_c('td', [_c('b', [_vm._v("Celkem")])]), _vm._l(_vm.daysInMonth, function (day, index) {
    var _vm$spentHours;

    return _c('td', {
      class: _vm.getDayClass(day, index)
    }, [_c('holiday-cell', {
      attrs: {
        "item": _vm.findHoliday(day)
      }
    }), _c('b', [_c('a', {
      staticClass: "no-underline"
    }, [_vm._v(_vm._s(((_vm$spentHours = _vm.spentHours) === null || _vm$spentHours === void 0 ? void 0 : _vm$spentHours[day.key]) || "-"))])])], 1);
  }), _c('td', [_c('b', [_vm._v(_vm._s((_vm$spentHours2 = _vm.spentHours) === null || _vm$spentHours2 === void 0 ? void 0 : _vm$spentHours2.totalHours))])])], 2), _vm._l(_vm.daysByProject, function (project) {
    return _c('tr', [_c('td', {
      staticClass: "table--project-title"
    }, [project !== null && project !== void 0 && project.id ? _c('project-link', {
      attrs: {
        "item": project,
        "hide-client": true
      }
    }) : _c('div', [_vm._v("-")])], 1), _vm._l(_vm.daysInMonth, function (day, index) {
      var _project$spentHours;

      return _c('td', {
        class: _vm.getDayClass(day, index)
      }, [_c('holiday-cell', {
        attrs: {
          "item": _vm.findHoliday(day)
        }
      }), _c('a', {
        staticClass: "no-underline",
        on: {
          "click": function ($event) {
            return _vm.updateHours(project.id, day.key);
          }
        }
      }, [_vm._v(_vm._s((project === null || project === void 0 ? void 0 : (_project$spentHours = project.spentHours) === null || _project$spentHours === void 0 ? void 0 : _project$spentHours[index]) || "-"))])], 1);
    }), _c('td', [_vm._v(_vm._s(project.totalHours))])], 2);
  })], 2)])])], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }