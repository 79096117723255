var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAdmin() ? [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'user_create'
      }
    }
  }, [_vm._v(" Nový uživatel ")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'user_roles'
      }
    }
  }, [_vm._v("Správa rolí")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'user_positions'
      }
    }
  }, [_vm._v("Správa pozic")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'user_reports'
      }
    }
  }, [_vm._v("Měsíční report")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'user_position_report'
      }
    }
  }, [_vm._v("Report dle pozice")])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }