var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v(_vm._s(!_vm.task_id ? 'Nový úkol' : 'Úprava úkolu'))]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Název")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.name,
      expression: "task.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.task.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.task, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.text,
      expression: "task.text"
    }],
    staticClass: "form-control",
    attrs: {
      "required": ""
    },
    domProps: {
      "value": _vm.task.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.task, "text", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Pro koho")]), _c('app-multiselect', {
    attrs: {
      "value": _vm.allUsers.find(function (i) {
        return i.value == _vm.task.created_for;
      }),
      "options": _vm.allUsers,
      "track-by": "value",
      "label": "label",
      "allow-empty": false
    },
    on: {
      "input": function (v) {
        return _vm.task.created_for = v.value;
      }
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Stav")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.state,
      expression: "task.state"
    }],
    staticClass: "form-control",
    attrs: {
      "required": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.task, "state", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "open"
    }
  }, [_vm._v("Otevřený")]), _c('option', {
    attrs: {
      "value": "check"
    }
  }, [_vm._v("Kontrola")]), _c('option', {
    attrs: {
      "value": "closed"
    }
  }, [_vm._v("Zavřený")])])]), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_createTask",
      "disabled": !_vm.task.created_for
    }
  }, [_vm._v("Uložit")])], 1)])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }