<template>
    <router-link :to="{name: 'cost_show', params: {cost_id: cost_id}}" :class="computedItem.approved ? '' : 'text-danger'">
        {{ label }}
    </router-link>
</template>

<script>
export default {
    name: "costLink",
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    watch: {
        params: {
            handler() {
                let data = this.params?.data;

                if (data) {
                    this.paramValue = data;
                }
            },
            immediate: true
        }
    },
    computed: {

        computedItem() {
            if (this.paramValue) {
                return this.paramValue;
            }

            return this.item;
        },

        label: function () {
            let parent = this.computedItem.parent;
            let shop = parent?.meta?.shop_name;

            if (parent) {
                if (shop) {
                    return shop + " (" + this.computedItem.title + ")";
                }
            }

            shop = this.computedItem?.meta?.shop_name;
            if (shop) {
                return shop + " (" + this.computedItem.title + ")";
            }

            return this.computedItem.title;
        },

        cost_id() {
            if (this.computedItem.parent_id) {
                return this.computedItem.parent_id;
            }

            return this.computedItem.id;
        },
    },
    data() {
        return {
            paramValue: null,
        }
    }
}
</script>