<template>
    <multiselect
        v-bind="$attrs"
        v-on="$listeners"
        placeholder="Vyberte"
        selectLabel="Vybrat"
        selectGroupLabel="Vybrat"
        deselectLabel="Odebrat"
        deselectGroupLabel="Odebrat"
        selectedLabel="Vybráno"
        tagPlaceholder="Vybrat"
    >
        <span slot="noResult">Žádný výsledek</span>
        <span slot="noOptions">Žádné možnosti</span>
    </multiselect>
</template>

<script>
export default {
    name: "appMultiselect"
}
</script>