var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('h3', [_vm._v("Pokladna")]), _c('div', {
    staticClass: "d-flex align-items-center",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Od")]), _c('app-date', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "clearable": true
    },
    model: {
      value: _vm.dateFrom,
      callback: function ($$v) {
        _vm.dateFrom = $$v;
      },
      expression: "dateFrom"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Do")]), _c('app-date', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "clearable": true
    },
    model: {
      value: _vm.dateTo,
      callback: function ($$v) {
        _vm.dateTo = $$v;
      },
      expression: "dateTo"
    }
  })], 1), _c('div', [_vm._v("Suma: " + _vm._s(_vm.filteredItemsSum))])]), _c('cost-table', {
    attrs: {
      "items": _vm.computedItems
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }