import axios from "@app/helpers/apiServer";

export default {

    createEvent(data = null) {
        return axios({
            url: "/ajax-event/create",
            method: "post",
            responseType: "json",
            data,
        });
    },
}
