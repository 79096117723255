var render = function () {
  var _vm$computedItem;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userCan('view-another-user') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'user_show',
        params: {
          user_id: (_vm$computedItem = _vm.computedItem) === null || _vm$computedItem === void 0 ? void 0 : _vm$computedItem.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.name) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }