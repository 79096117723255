<template>
    <modal v-show="visible" @close="close" class="popup--large">

        <h3>Odpracované hodiny</h3>
        <div class="report_arrows">
            <a @click="addDay(-1)" class="report_arrow">⇚</a>
            <div class="report_label">{{ targetDay | moment('DD. MM. YYYY') }}</div>
            <a @click="addDay(1)" class="report_arrow">⇛</a>
        </div>

        <page-loader v-if="loading"/>
        <app-table v-else v-bind="table_attributes_wip" :items="items" :local-sort="true">
            <template slot="tbody" slot-scope="data">
                <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                    <td>
                        {{ parseFloat(row.amount).formatNumber(2) }}
                    </td>
                    <td>
                        <project-link :item="row?.project" :hide-client="true"/>
                        <br>
                        <client-link :item="row?.project?.client"/>
                    </td>
                    <td>
                        <images-info :item="row"/>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        Celkem: {{ data.rows.map(r => parseFloat(r.amount)).sum().formatNumber(2) }} h
                    </td>
                </tr>
            </template>
        </app-table>
    </modal>
</template>

<script>
import userService from "@services/userService";
import ImagesInfo from "@components/imagesInfo";

export default {
    name: "mySpentHours",
    components: {ImagesInfo},
    methods: {
        open(atts = {}) {

            let {targetDay} = atts;

            if (!targetDay) {
                targetDay = this.$moment().format("YYYY-MM-DD");
            }

            this.targetDay = targetDay;
            this.visible = true;

            this.fetchData();
        },
        close() {
            this.visible = false;
        },

        fetchData() {
            this.loading = true;

            let payload = {
                targetDay: this.targetDay
            };

            userService.getSpentHoursByDay(payload).then(res => {
                this.items = res.data.items;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.loading = false;
            });

        },

        addDay(days) {
            let targetDay = this.$moment(this.targetDay).add(days, "days").format("YYYY-MM-DD");
            this.targetDay = targetDay;
            this.fetchData();
        },
    },
    data() {
        return {
            loading: false,
            visible: false,
            targetDay: null,
            items: [],
            table_attributes_wip: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'amount', label: "Počet hodin", sortable: true, width: "100px"},
                    {key: 'project.client.name', label: "Zakázka", sortable: true},
                    {key: 'amount', label: "Fotky", width: "100px"},
                ],
                pagination: true,
            },
        }
    }
}
</script>