var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon"
  }, [_c('use', {
    attrs: {
      "xlink:href": '/sprite_symbol.svg#' + _vm.name
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }