var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loader"
  }, [_c('svg', {
    staticStyle: {
      "margin": "auto",
      "background": "none",
      "display": "block",
      "shape-rendering": "auto"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "200px",
      "height": "200px",
      "viewBox": "0 0 100 100",
      "preserveAspectRatio": "xMidYMid"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50",
      "fill": "#000f5c",
      "stroke": "none"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "type": "rotate",
      "dur": "1s",
      "repeatCount": "indefinite",
      "keyTimes": "0;1",
      "values": "0 50 51;360 50 51"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }