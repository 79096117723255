var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.addPairModal.visible,
      expression: "addPairModal.visible"
    }],
    on: {
      "close": function ($event) {
        _vm.addPairModal.visible = false;
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Auto")]), _c('app-select', {
    attrs: {
      "options": _vm.$store.state.allCars,
      "option-key": "id",
      "option-label": "name"
    },
    model: {
      value: _vm.addPairModal.car_id,
      callback: function ($$v) {
        _vm.$set(_vm.addPairModal, "car_id", $$v);
      },
      expression: "addPairModal.car_id"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Uživatel")]), _c('app-select', {
    attrs: {
      "options": _vm.$store.state.allUsers,
      "option-key": "id",
      "option-label": "name"
    },
    model: {
      value: _vm.addPairModal.user_id,
      callback: function ($$v) {
        _vm.$set(_vm.addPairModal, "user_id", $$v);
      },
      expression: "addPairModal.user_id"
    }
  })], 1), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "type": "button",
      "slug": "btn_addPair"
    },
    on: {
      "click": _vm.addPair
    }
  }, [_vm._v("Uložit")])], 1), _c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.isAdmin() ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openModal
    }
  }, [_vm._v("Přidat")]) : _vm._e()])]), _vm.loading ? _c('page-loader') : _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Uživatel")]), _c('th', [_vm._v("Auto")]), _c('th')])]), _c('tbody', _vm._l(_vm.items, function (item) {
    return _c('tr', [_c('td', [_c('user-link', {
      attrs: {
        "item": item.user
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.car.name))]), _c('td', [_c('div', {
      staticClass: "flex-buttons"
    }, [_vm.isAdmin() ? _c('button', {
      staticClass: "btn btn-danger btn-icon",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.deletePair(item.user.id, item.car.id);
        }
      }
    }, [_c('icon', {
      attrs: {
        "name": "trash"
      }
    })], 1) : _vm._e()])])]);
  }), 0)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('h3', [_vm._v("Přiřazené osoby")])]);
}]

export { render, staticRenderFns }