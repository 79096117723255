var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    on: {
      "close": _vm.close
    }
  }, [_c('h3', [_vm._v("Fakturace zakázky")]), _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Stav")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.invoiceModal.invoiced,
      expression: "invoiceModal.invoiced"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.invoiceModal, "invoiced", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Vyfakturováno")]), _c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("Částečně")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("Nevyfakturováno")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Fakturace se neřeší")])])]), ['0', '2'].includes(_vm.invoiceModal.invoiced) ? [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.invoiceModal.invoice_reason,
      expression: "invoiceModal.invoice_reason"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.invoiceModal.invoice_reason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.invoiceModal, "invoice_reason", $event.target.value);
      }
    }
  })])] : _vm._e(), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_invoice"
    },
    on: {
      "click": _vm.saveInvoiceModal
    }
  }, [_vm._v("Uložit")]), ['1', '3'].includes(_vm.invoiceModal.invoiced) ? _c('div', {
    staticClass: "my-4"
  }, [_c('hr'), _c('div', {
    staticClass: "text-2xl my-4"
  }, [_vm._v("Faktury")]), _vm.invoiceModal.invoices.length ? _c('div', {
    staticClass: "my-4"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('td', [_vm._v("Název")]), _c('td', [_vm._v("Fotky")]), _c('td', [_vm._v("Vytvořeno")]), _c('td')])]), _c('tbody', _vm._l(_vm.invoiceModal.invoices, function (item, item_index) {
    return _c('tr', {}, [_c('td', [_vm._v(_vm._s(item.invoice_number))]), _c('td', [_c('ImagesInfo', {
      attrs: {
        "all-files": item.invoice_files
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.date))]), _c('td', [_c('a', {
      staticClass: "text-red-400",
      on: {
        "click": function ($event) {
          var _item$id;

          return _vm.removeInvoiceAction((_item$id = item.id) !== null && _item$id !== void 0 ? _item$id : 'none');
        }
      }
    }, [_vm._v("Smazat")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "my-4"
  }, [_vm._v("Zatím žádná faktura nevytvořena.")]), !_vm.addInvoiceModal ? _c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        _vm.addInvoiceModal = true;
      }
    }
  }, [_vm._v("Přidat fakturu")]) : _vm._e(), _vm.addInvoiceModal ? _c('div', [_c('hr', {
    staticClass: "my-4"
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Interní číslo faktury")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.invoiceModal.new_invoice_number,
      expression: "invoiceModal.new_invoice_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "input"
    },
    domProps: {
      "value": _vm.invoiceModal.new_invoice_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.invoiceModal, "new_invoice_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Faktura")]), _c('project-files', {
    attrs: {
      "allow-upload": true,
      "handle-upload": false
    },
    on: {
      "newFiles": function (files) {
        return _vm.invoiceModal.new_files = files;
      }
    }
  })], 1), _vm.invoiceModal.invoiced == '2' ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.invoiceModal.invoice_reason,
      expression: "invoiceModal.invoice_reason"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.invoiceModal.invoice_reason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.invoiceModal, "invoice_reason", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_invoice"
    },
    on: {
      "click": _vm.addInvoiceAction
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        _vm.addInvoiceModal = false;
      }
    }
  }, [_vm._v(" Zrušit fakturu ")])], 1) : _vm._e()]) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }