var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('span', {
    staticClass: "td--userHoliday"
  }, [_c('span', [_vm._v(_vm._s(_vm.computedItem.letter))]), _vm.computedItem.hours ? _c('span', [_vm._v("(" + _vm._s(_vm.computedItem.hours) + ")")]) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }