<template>
    <div class="table-wrapper">
        <table class="table table--app">
            <thead>
                <tr>
                    <th v-for="(item, key) in filteredHead" :key="key" :class="getClasses(item)" @click="sort(item)" :style="getStyles(item)">
                        <slot :name="'HEAD_' + item.key" :rows="finalRows">
                            <div class="table__th" :class="{'sortable': originalFields?.[item.key]?.['sortable']}">
                                <div>
                                    {{ item.label }}
                                </div>
                                <div v-if="originalFields?.[item.key]?.['sortable'] && !hideSorting" class="table__sort">
                                    <div class="table__sort-arrow table__sort-arrow--top" :class="{'active': mutatedSortBy === item.key && mutatedSortDirection === 'asc' }">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                            <g>
                                                <g>
                                                    <path d="M6.068 5.49a.594.594 0 0 1-.42.175h-.3a.61.61 0 0 1-.42-.174L.868 1.42a.396.396 0 0 1 0-.561l.561-.562a.388.388 0 0 1 .554 0L5.498 3.82 9.013.298a.396.396 0 0 1 .562 0l.555.562a.396.396 0 0 1 0 .562z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="table__sort-arrow" :class="{'active': mutatedSortBy === item.key && mutatedSortDirection === 'desc' }">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
                                            <g>
                                                <g>
                                                    <path d="M6.068 5.49a.594.594 0 0 1-.42.175h-.3a.61.61 0 0 1-.42-.174L.868 1.42a.396.396 0 0 1 0-.561l.561-.562a.388.388 0 0 1 .554 0L5.498 3.82 9.013.298a.396.396 0 0 1 .562 0l.555.562a.396.396 0 0 1 0 .562z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </th>
                </tr>
            </thead>
            <slot name="table-content" :rows="finalRows">
                <tbody>
                    <template v-if="loading">
                        <tr v-for="(row, row_index) in perPage" :key="row_index">
                            <td v-for="(cell, cell_index) in fields" :key="cell_index">
                                <div class="table__loading"></div>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <slot name="tbody" :rows="finalRows">
                            <tr v-for="(row, row_key) in finalRows" :key="row_key">
                                <td v-for="(item, cell_key) in row" :key="cell_key">
                                    <slot :name="cell_key" :item="row">{{ item }}</slot>
                                </td>
                            </tr>
                        </slot>
                    </template>
                </tbody>
            </slot>
        </table>
        <app-pagination v-if="pagination && perPage < items.length" :per-page="perPage" :total-rows="items.length" :current-page.sync="mutatedCurrentPage" :select-page-as-dot="true"/>
    </div>
</template>

<script>
import {orderBy} from "lodash-es"
import localSort from "../other/localSort";

export default {
    name: "appTable",
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        fields: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        perPage: {
            type: Number,
            default: 20,
        },
        localSort: {
            type: Boolean,
            default: false,
        },
        unusedFields: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: String,
            default: '',
        },
        sortDirection: {
            type: String,
            default: 'desc',
        },
        primarySortedBy: {
            type: Array,
            default: () => [],
        },
        filterItems: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        pagination: {
            type: Boolean,
            default: false
        },
        hideSorting: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            mutatedSortBy: this.sortBy,
            mutatedSortDirection: this.sortDirection,
            mutatedCurrentPage: this.currentPage,
        };
    },

    watch: {
        filteredRows: {
            immediate: true,
            handler() {
                this.$emit('filtered', this.filteredRows);
            }
        }
    },

    methods: {

        getStyles(item) {
            let out = {};
            if (item.width) {
                out["width"] = item.width;
            }
            return out;
        },

        getClasses(item) {
            let out = [];

            let mainCls = this.originalFields?.[item.key]?.['class'];

            if (mainCls)
                out.push(mainCls);

            if (item.sortable) {
                out.push("sortable");
            }

            if (this.isSorted(item.key)) {
                out.push("isSorted");
            }
            return out.join(" ");
        },

        isSorted(key) {
            return this.mutatedSortBy == key;
        },

        reorderRow(row) {
            let keys = this.fields.map(field => {
                return field.key;
            });

            let new_row = {};
            keys.forEach(key => {
                new_row[key] = row[key];
            });

            return new_row;
        },

        sort(field) {
            if (field.sortable === true) {
                let key = field.key;

                if (this.mutatedSortBy === key) {
                    this.mutatedSortDirection = this.mutatedSortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.mutatedSortBy = key;
                    this.mutatedSortDirection = 'asc';
                }

                this.$emit("update:sortBy", this.mutatedSortBy);
                this.$emit("update:sortDirection", this.mutatedSortDirection);
                this.$emit("sort-changed", {
                    sortBy: this.mutatedSortBy,
                    sortDirection: this.mutatedSortDirection,
                });
            }
        },

        doLocalSorting(items) {
            let sortConfig = this.sortingConfig;
            let keys = sortConfig.keys;
            let directions = sortConfig.directions;

            let sortableItems = [];
            let unSortableItems = [];

            items.forEach(item => {

                if (item.hasOwnProperty('_disableSort')) {
                    if (item._disableSort == true) {
                        unSortableItems.push(item);
                        return;
                    }
                }
                sortableItems.push(item);
            });

            let sortedItems = orderBy(sortableItems, keys, directions);

            let all_items = [
                ...sortedItems,
                ...unSortableItems
            ]

            return all_items;
        },

    },

    computed: {

        originalFields: function () {
            let rows = {};
            this.fields.forEach(row => {
                let k = row.key;
                rows[k] = row;
            });
            return rows;
        },

        filteredHead: function () {
            return this.fields;
        },

        sortingConfig() {

            let keys = [];
            let directions = [];

            if (this.primarySortedBy.length) {
                keys.push(this.primarySortedBy[0]);
                directions.push(this.primarySortedBy[1]);
            }

            if (this.mutatedSortBy && this.mutatedSortDirection) {
                keys.push(this.mutatedSortBy);
                directions.push(this.mutatedSortDirection);
            }

            return {
                keys,
                directions
            }

        },

        filteredRows: function () {
            let items = this.removeReactivity(this.items);
            let filter = this.filterItems;
            items = localSort(items, filter);
            return items;
        },

        finalRows: function () {
            let start = (this.mutatedCurrentPage - 1) * this.perPage;
            let end = this.mutatedCurrentPage * this.perPage;

            let items = this.filteredRows;

            if (this.localSort) {
                items = this.doLocalSorting(items);
            }

            items = items.filter((row, index) => {
                if (index >= start && index < end) return true;
            });

            return items;
        }
    }
};
</script>