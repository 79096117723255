var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    on: {
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Půjčená věc")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rent.thing_id,
      expression: "rent.thing_id"
    }],
    staticClass: "form-control",
    attrs: {
      "disabled": !_vm.select_thing || _vm.returnMode
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.rent, "thing_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.things, function (item) {
    return _c('option', {
      domProps: {
        "value": item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)]), !_vm.returnMode ? [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Kdo si půjčuje")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rent.user_id,
      expression: "rent.user_id"
    }],
    staticClass: "form-control",
    attrs: {
      "disabled": !_vm.select_user
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.rent, "user_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.$store.state.allUsers, function (item) {
    return _c('option', {
      domProps: {
        "value": item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Poznámka")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rent.text,
      expression: "rent.text"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.rent.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.rent, "text", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Datum zapůjčení")]), _c('app-date', {
    model: {
      value: _vm.rent.rented_at,
      callback: function ($$v) {
        _vm.$set(_vm.rent, "rented_at", $$v);
      },
      expression: "rent.rented_at"
    }
  })], 1)] : _vm._e(), _vm.isOffice() || _vm.returnMode ? [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Datum vrácení")]), _c('app-date', {
    model: {
      value: _vm.rent.returned_at,
      callback: function ($$v) {
        _vm.$set(_vm.rent, "returned_at", $$v);
      },
      expression: "rent.returned_at"
    }
  })], 1)] : _vm._e(), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_createRent"
    },
    on: {
      "click": _vm.createRent
    }
  }, [_vm._v("Uložit")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }