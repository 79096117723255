var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('h2', [_vm._v("Výpis uživatelů")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('users-nav')], 1), _vm.isAdmin() ? _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [!_vm.table_attributes.sortable ? _c('button', {
    key: "chen",
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.table_attributes.sortable = true;
      }
    }
  }, [_vm._v("Změnit pořadí")]) : _c('div', [_c('app-button', {
    key: "dochange",
    staticClass: "btn btn-success",
    attrs: {
      "slug": "btn_saveOrder"
    },
    on: {
      "click": _vm.saveOrder
    }
  }, [_vm._v("Uložit pořadí")]), _c('button', {
    key: "cancelchange",
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.table_attributes.sortable = false;
      }
    }
  }, [_vm._v("Zrušit změnu")])], 1)]) : _vm._e()]), _c('br'), _vm._v(" "), _c('br'), _c('app-table', _vm._b({
    attrs: {
      "fields": _vm.computedFields,
      "items": _vm.activeUsers,
      "local-sort": !_vm.table_attributes.sortable,
      "hide-sorting": _vm.table_attributes.sortable
    },
    scopedSlots: _vm._u([{
      key: "table-content",
      fn: function (data) {
        return [_c('draggable', {
          attrs: {
            "tag": "tbody",
            "options": {
              disabled: !_vm.table_attributes.sortable
            }
          },
          model: {
            value: _vm.items,
            callback: function ($$v) {
              _vm.items = $$v;
            },
            expression: "items"
          }
        }, _vm._l(data.rows, function (row, key) {
          var _row$meta;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(_vm._s(row.order))]), _c('td', [_c('user-link', {
            attrs: {
              "item": row,
              "position": false
            }
          })], 1), _c('td', [(_row$meta = row.meta) !== null && _row$meta !== void 0 && _row$meta.phone ? _c('a', {
            attrs: {
              "href": 'tel:' + row.meta.phone
            }
          }, [_vm._v(" " + _vm._s(row.meta.phone) + " ")]) : _vm._e()]), _vm.userCan('offices') ? _c('td', [_vm._v(_vm._s(row.rolename))]) : _vm._e(), _c('td', [_vm._v(_vm._s(row.positionName))]), _vm.isAdmin() ? _c('td', [_c('div', {
            class: [row.has_current_holiday_fund ? 'user--active' : 'user--inactive']
          }, [_vm._v(" " + _vm._s(row.has_current_holiday_fund ? "Ano" : "Ne") + " ")])]) : _vm._e(), _vm.isAdmin() ? _c('td', [_c('div', {
            class: [row.active ? 'user--active' : 'user--inactive']
          }, [_vm._v(" " + _vm._s(row.active ? 'Aktivní' : 'Neaktivní') + " ")])]) : _vm._e(), _vm.isAdmin() ? _c('td', [_c('router-link', {
            staticClass: "btn btn-danger",
            attrs: {
              "to": {
                name: 'user_create',
                params: {
                  user_id: row.id
                }
              }
            }
          }, [_vm._v(" Upravit ")])], 1) : _vm._e()]);
        }), 0)];
      }
    }])
  }, 'app-table', _vm.table_attributes, false)), _c('br'), _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.inactiveTable = !_vm.inactiveTable;
      }
    }
  }, [_vm._v("Zobrazit neaktivní uživatele")]), _c('br'), _c('br'), _vm.inactiveTable ? _c('app-table', _vm._b({
    attrs: {
      "fields": _vm.computedFields,
      "items": _vm.inactiveUsers,
      "local-sort": !_vm.table_attributes.sortable,
      "hide-sorting": _vm.table_attributes.sortable
    },
    scopedSlots: _vm._u([{
      key: "table-content",
      fn: function (data) {
        return [_c('tbody', _vm._l(data.rows, function (row, key) {
          var _row$meta2;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(_vm._s(row.order))]), _c('td', [_c('user-link', {
            attrs: {
              "item": row,
              "position": false
            }
          })], 1), _c('td', [(_row$meta2 = row.meta) !== null && _row$meta2 !== void 0 && _row$meta2.phone ? _c('a', {
            attrs: {
              "href": 'tel:' + row.meta.phone
            }
          }, [_vm._v(" " + _vm._s(row.meta.phone) + " ")]) : _vm._e()]), _vm.userCan('offices') ? _c('td', [_vm._v(_vm._s(row.rolename))]) : _vm._e(), _c('td', [_vm._v(_vm._s(row.positionName))]), _vm.isAdmin() ? _c('td', [_c('div', {
            class: [row.has_current_holiday_fund ? 'user--active' : 'user--inactive']
          }, [_vm._v(" " + _vm._s(row.has_current_holiday_fund ? "Ano" : "Ne") + " ")])]) : _vm._e(), _vm.isAdmin() ? _c('td', [_c('div', {
            class: [row.active ? 'user--active' : 'user--inactive']
          }, [_vm._v(" " + _vm._s(row.active ? 'Aktivní' : 'Neaktivní') + " ")])]) : _vm._e(), _vm.isAdmin() ? _c('td', [_c('router-link', {
            staticClass: "btn btn-danger",
            attrs: {
              "to": {
                name: 'user_create',
                params: {
                  user_id: row.id
                }
              }
            }
          }, [_vm._v(" Upravit ")])], 1) : _vm._e()]);
        }), 0)];
      }
    }], null, false, 22935550)
  }, 'app-table', _vm.table_attributes, false)) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }