<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <h2>Zákazníci</h2>
            <router-link :to="{name: 'client_create'}" class="btn btn-primary">
                Nový zákazník
            </router-link>
            <router-link :to="{name: 'client_merge'}" class="btn btn-secondary" v-if="userCan('client.merge')">
                Spojit zákazníky
            </router-link>
            <br><br>

            <app-table class="table" v-bind="table_attributes" :items="items" :local-sort="true" :sortBy.sync="table_attributes.sortBy" :sortDirection.sync="table_attributes.sortDirection">
                <template slot="tbody" slot-scope="data">
                    <tr v-for="(row, key) in data.rows" :key="row.id" class="hpreport__item" :class="row.color">
                        <td>
                            <client-link :item="row"/>
                        </td>
                        <td>
                            {{row.address}}
                        </td>
                        <td class="flex-buttons">
                            <router-link v-if="userCan('offices')" :to="{name: 'client_create', params: {client_id: row.id}}" class="btn btn-danger">
                                Upravit
                            </router-link>
                        </td>
                    </tr>
                </template>
            </app-table>

        </template>
    </div>
</template>

<script>
import clientService from "@services/clientService";

export default {
    name: "client_index",
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.pageLoading = true;
            clientService.getAllClients().then(res => {
                this.items = res.data.clients;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        }

    },
    data() {
        return {
            items: [],
            pageLoading: true,
            table_attributes: {
                currentPage: 1,
                perPage: 20,
                pagination: true,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {
                        key: 'name',
                        label: "Název",
                        sortable: true,
                    },
                    {
                        key: 'address',
                        label: "Adresa",
                    },
                    {
                        key: 'controls',
                        label: "",
                    },
                ]
            }
        }
    }
}
</script>