<template>
    <div>
        <modal v-if="opened" @close="opened = false">
            <h2>Přiložené fotky a soubory</h2>
            <project-files :files="computedImages"/>
        </modal>
        <a @click="openGallery">{{ computedImages.length }}</a>
    </div>
</template>

<script>
export default {
    name: "imagesInfo",
    props: {
        item: {
            type: Object,
            default: null,
        },
        objectKey: {
            type: String,
            default: "files"
        },
        allFiles: {
            type: Array,
            default: () => []
        }
    },

    methods: {

        containsHttpProtocol(str) {
            const pattern = /^https?:\/\//i;
            return pattern.test(str);
        },

        openGallery() {
            let items = this.removeReactivity(this.computedImages);
            let length = items.length;

            if (!length)
                return;

            let images = items.filter(item => ["image"].includes(item.type));
            if (images.length == items.length) {

                images = images.map(image => {
                    return {
                        src: this.containsHttpProtocol(image.url) ? image.url : "/" + image.url,
                        type: image.type,
                    }
                });

                window.Fancybox.show(images, {
                    infinite: false,
                });

            } else {

                if (length == 1) {
                    let file = items[0];
                    if (file.type == "pdf") {
                        window.Fancybox.show([
                            {
                                src: this.containsHttpProtocol(file.url) ? file.url : "/" + file.url,
                                type: 'pdf',
                            },
                        ], {});
                        return;
                    }
                }

                this.opened = true;
            }
        }
    },

    computed: {

        files: function () {

            if(this.allFiles.length)
                return this.allFiles;

            let item = this.item;

            if (item.parent) {
                return item.parent[this.objectKey];
            }

            return item[this.objectKey] ?? [];
        },

        computedImages: function () {
            if (this.files && Array.isArray(this.files)) {
                return this.files;
            } else {
                return [];
            }
        }

    },
    data() {
        return {
            opened: false,
        }
    }
}
</script>