var render = function () {
  var _vm$user, _vm$user2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "popup",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "popup__wrapper"
  }, [_c('div', {
    staticClass: "popup__background",
    on: {
      "click": _vm.close
    }
  }), _c('div', {
    staticClass: "popup__body"
  }, [_c('div', {
    staticClass: "popup__content"
  }, [_vm.item.selectType ? _c('div', [_c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.item.type == 'holiday'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setType('holiday');
      }
    }
  }, [_vm._v("Dovolená")]), !((_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.contractor) ? _c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.item.type == 'sickday'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setType('sickday');
      }
    }
  }, [_vm._v("Nemoc")]) : _vm._e(), !((_vm$user2 = _vm.user) !== null && _vm$user2 !== void 0 && _vm$user2.contractor) ? _c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.item.type == 'paragraph'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setType('paragraph');
      }
    }
  }, [_vm._v("Paragraf")]) : _vm._e(), _c('br'), _vm._v(" "), _c('br'), _c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.item.range_type == 'part'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setRangeType('part');
      }
    }
  }, [_vm._v("Část dne")]), _c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.item.range_type == 'day'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setRangeType('day');
      }
    }
  }, [_vm._v("Jeden den")]), _c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.item.range_type == 'days'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setRangeType('days');
      }
    }
  }, [_vm._v("Více dní")])]) : _vm._e(), _vm.item.range_type == 'part' || _vm.item.range_type == 'day' ? [_c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Den:")]), _c('app-date', {
    attrs: {
      "disabled-date": _vm.maxPreviousDayFrom,
      "disabled": _vm.item.did_start && !_vm.isAdmin()
    },
    model: {
      value: _vm.item.date_from,
      callback: function ($$v) {
        _vm.$set(_vm.item, "date_from", $$v);
      },
      expression: "item.date_from"
    }
  })], 1)] : _vm._e(), _vm.hoursSetable ? [_c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Počet hodin:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.hours,
      expression: "item.hours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.item.hours
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.item, "hours", $event.target.value);
      }
    }
  })])] : _vm._e(), _vm.item.range_type == 'days' ? [_c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Od kdy:")]), _c('app-date', {
    attrs: {
      "disabled-date": _vm.maxPreviousDayFrom,
      "disabled": _vm.item.did_start && !_vm.isAdmin()
    },
    model: {
      value: _vm.item.date_from,
      callback: function ($$v) {
        _vm.$set(_vm.item, "date_from", $$v);
      },
      expression: "item.date_from"
    }
  })], 1), _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Do kdy:")]), _c('app-date', {
    attrs: {
      "disabled-date": _vm.maxPreviousDayTo,
      "disabled": (_vm.item.did_end || !_vm.item.date_from) && !_vm.isAdmin()
    },
    model: {
      value: _vm.item.date_to,
      callback: function ($$v) {
        _vm.$set(_vm.item, "date_to", $$v);
      },
      expression: "item.date_to"
    }
  })], 1)] : _vm._e(), _vm.paidHolidaySetable ? _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v(_vm._s(_vm.paidLabel) + ":")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.paid,
      expression: "item.paid"
    }],
    staticStyle: {
      "width": "auto"
    },
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.item.paid) ? _vm._i(_vm.item.paid, null) > -1 : _vm.item.paid
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.item.paid,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.item, "paid", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.item, "paid", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.item, "paid", $$c);
        }
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Popis:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.description,
      expression: "item.description"
    }],
    staticClass: "form-control",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "rows": "5",
      "required": _vm.item.type == 'paragraph'
    },
    domProps: {
      "value": _vm.item.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.item, "description", $event.target.value);
      }
    }
  })]), _vm.item.type == 'paragraph' ? _c('div', {
    staticClass: "popup__control"
  }, [_c('div', {
    staticClass: "popup__label"
  }, [_vm._v("Fotky:")]), _vm.visible ? _c('project-files', {
    attrs: {
      "allow-upload": true,
      "handle-upload": false,
      "files": _vm.item.files,
      "model-id": _vm.item.id,
      "model-type": "holiday"
    },
    on: {
      "imageDeleted": function ($event) {
        return _vm.$emit('saved');
      },
      "newFiles": function (files) {
        return _vm.appendFiles = files;
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 2), _c('div', {
    staticClass: "popup__buttons"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_addSickDay",
      "type": "submit"
    }
  }, [_vm._v(" Uložit ")]), _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Zrušit")]), _vm.canDelete ? _c('app-button', {
    staticClass: "btn btn-danger",
    attrs: {
      "type": "button",
      "slug": "btn_deleteSickDay"
    },
    on: {
      "click": _vm.deleteItem
    }
  }, [_c('icon', {
    attrs: {
      "name": "trash"
    }
  })], 1) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }