<template>
    <div>
        <div class="form-group">
            <label>Hledaný dotaz</label>
            <input type="text" v-model="input" class="form-control" id="search"/>
        </div>
        <div v-if="input.length < min_length">
            Minimálně {{ min_length }} písmena.
        </div>
        <div v-else>
            <h2>Výsledky - {{ query }}</h2>
            <page-loader v-if="loading"/>
            <div v-else class="row">
                <div class="col-12 col-lg-6" v-for="model in models">
                    <h3>{{ modelsLabels[model] ?? "" }}</h3>
                    <search-table :model="model" :items="(items[model] ?? [])" :word="query"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import searchService from "@services/searchService";
import SearchTable from "@pages/search/searchTable";

export default {
    name: "searchPage",
    components: {SearchTable},
    watch: {
        query: {
            handler() {
                this.search(this.query);
            }
        }
    },

    mounted() {

        this.$nextTick(() => {
            document.querySelector("#search").focus();
        });

        let query = this.$route.query?.q;
        if (query) {
            this.input = query;
        }

    },

    methods: {

        search(query) {

            if (query.length < this.min_length) {
                this.items = {};
                return;
            }


            this.loading = true;
            searchService.search(query).then(res => {
                this.items = res.data.results;
            }).catch(e => {
                this.items = {};
            }).finally(r => {

                let currentQuery = this.$route.query?.q;
                if (currentQuery !== query) {
                    this.$router.replace({
                        query: {q: query},
                    });
                }

                this.loading = false;
            });
        },

    },

    computed: {
        input: {
            get() {
                return this.query
            },
            set(val) {
                if (this.timeout) clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    this.query = val;
                }, 500)
            }
        },

        models: function () {
            return Object.keys(this.items);
        },

    },

    data() {
        return {
            query: "",
            loading: false,
            items: {},
            timeout: null,
            min_length: 3,
            modelsLabels: {
                "car": "Auta",
                "city": "Obec",
                "client": "Zákazníci",
                "project": "Zakázky",
                "projectcost": "Náklady",
                "task": "Úkoly",
                "thing": "Majetek",
                "user": "Uživatelé",
            }
        }
    }
}
</script>