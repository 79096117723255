import axios from "axios";

const API_ENDPOINT_URL = process.env.VUE_APP_API_URL ?? "/api"

const axiosApi = axios.create({
    baseURL: API_ENDPOINT_URL,
    withCredentials: true,
});

axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {

        const ignoreRedirects = ["/login"];
        const currentUrl = new URL(location.href);
        const pathname = currentUrl.pathname;

        const shouldLogout = error.response?.data?.action == "logout";

        if (!ignoreRedirects.includes(pathname) && shouldLogout) {
            window.location.href = "/login";
        }

        throw error;
    });

export default axiosApi;