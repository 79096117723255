<template>
    <modal v-if="visible" @close="visible = false">
        <page-loader v-if="pageLoading"/>
        <div v-else class="row align-items-center">
            <div class="col-12 col-lg-5">
                <div class="form-group">
                    <label class="text-pid1">Hlavní obchod</label>
                    <app-select :options="shops" v-model="shop1"/>
                </div>
            </div>
            <div class="col-12 col-lg-5" v-if="shop1">
                <div class="form-group">
                    <label class="text-pid2">Duplicitní obchod</label>
                    <app-select :options="shops.filter(s => s.value !== shop1)" v-model="shop2"/>
                </div>
            </div>
            <div class="col-12 col-lg-2">
                <app-button slug="btn_mergeShops" class="btn btn-primary" @click="startMerge">Spojit</app-button>
            </div>
        </div>
    </modal>
</template>

<script>
import projectService from "@services/projectService";

export default {
    name: "mergeShopsModal",
    methods: {
        open() {
            this.shop1 = "";
            this.shop2 = "";
            this.visible = true;
            this.loadShops();
        },

        loadShops() {
            this.pageLoading = true;
            projectService.getAllShops().then(res => {
                this.items = res.data.items;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },

        startMerge() {

            this.setLoading("btn_mergeShops");

            let payload = {
                shop1: this.shop1,
                shop2: this.shop2,
            };

            projectService.mergeShops(payload).then(res => {
                this.showSuccess("Obchody spojeny.");
                this.visible = false;
                this.$emit("refresh");
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_mergeShops");
            });

        }
    },

    computed: {

        shops() {
            return this.items.map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            });
        }

    },

    data() {
        return {
            visible: false,
            shop1: "",
            shop2: "",
            pageLoading: false,
            items: [],
        }
    }
}
</script>