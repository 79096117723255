import axios from "@app/helpers/apiServer";

export default {

    getTasks() {
        return axios({
            url: "/ajax-task/list",
            method: "get",
            responseType: "json"
        });
    },

    getTask(task_id) {
        return axios({
            url: "/ajax-task/show/"+task_id,
            method: "get",
            responseType: "json"
        });
    },

    createTask(data) {
        return axios({
            url: "/ajax-task/create",
            method: "post",
            responseType: "json",
            data
        });
    },

    setTaskStatus(data) {
        return axios({
            url: "/ajax-task/set-status",
            method: "post",
            responseType: "json",
            data
        });
    },

}