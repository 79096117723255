var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('h2', [_vm._v(_vm._s(_vm.client_id ? 'Úprava zákazníka' : 'Nový zákazník'))]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Informace")]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Jméno zákazníka")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.client.name,
      expression: "client.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.client.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.client, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.client.note,
      expression: "client.note"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.client.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.client, "note", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Obec")]), _c('app-select', {
    attrs: {
      "auto-load": "cities"
    },
    model: {
      value: _vm.client.city_id,
      callback: function ($$v) {
        _vm.$set(_vm.client, "city_id", $$v);
      },
      expression: "client.city_id"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Adresa")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.client.address,
      expression: "client.address"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.client.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.client, "address", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("IČO")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.client.meta.ico,
      expression: "client.meta.ico"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.client.meta.ico
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.client.meta, "ico", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("DIČ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.client.meta.dic,
      expression: "client.meta.dic"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.client.meta.dic
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.client.meta, "dic", $event.target.value);
      }
    }
  })]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_saveClient",
      "type": "submit"
    }
  }, [_vm._v("Uložit")])], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Kontaktní osoby")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Skrytý")]), _c('th', [_vm._v("Jméno")]), _c('th', [_vm._v("Pozice")]), _c('th', [_vm._v("E-mail")]), _c('th', [_vm._v("Telefon")])])]), _vm._l(_vm.people, function (item) {
    return _c('tr', [_c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.hidden,
        expression: "item.hidden"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(item.hidden) ? _vm._i(item.hidden, null) > -1 : item.hidden
      },
      on: {
        "change": function ($event) {
          var $$a = item.hidden,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "hidden", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "hidden", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "hidden", $$c);
          }
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.name,
        expression: "item.name"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.name
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "name", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.position,
        expression: "item.position"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.position
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "position", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.email,
        expression: "item.email"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.email
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "email", $event.target.value);
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.tel,
        expression: "item.tel"
      }],
      staticClass: "form-control",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.tel
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "tel", $event.target.value);
        }
      }
    })])]);
  })], 2), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.people.push({
          hidden: true,
          name: '',
          email: '',
          tel: ''
        });
      }
    }
  }, [_vm._v("Přidat řádek")])])])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }