var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('h2', [_vm._v("Úkoly")]), _vm.userCan('task.create') ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'task_create'
      }
    }
  }, [_vm._v(" Nový úkol ")]) : _vm._e(), _c('br'), _c('br'), _c('h2', [_vm._v("Úkoly ke kontrole")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Pro koho")]), _c('th', [_vm._v("Od koho")]), _c('th', [_vm._v("Stav")])]), _vm._l(_vm.items.to_check, function (item) {
    return _c('tr', [_c('td', [_c('task-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.created_for_user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.created_by_user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.nice_status) + " ")])]);
  })], 2)]), _c('br'), _c('br'), _c('h2', [_vm._v("Otevřené úkoly")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Pro koho")]), _c('th', [_vm._v("Od koho")]), _c('th', [_vm._v("Stav")])]), _vm._l(_vm.items.opened, function (item) {
    return _c('tr', [_c('td', [_c('task-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.created_for_user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.created_by_user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.nice_status) + " ")])]);
  })], 2)]), _c('br'), _c('br'), _vm.userCan('task.show_finished') ? [_c('h2', [_vm._v("Hotové úkoly")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Pro koho")]), _c('th', [_vm._v("Od koho")]), _c('th', [_vm._v("Stav")]), _c('th', [_vm._v("Hotovo")])]), _vm._l(_vm.items.finished, function (item) {
    return _c('tr', [_c('td', [_c('task-link', {
      attrs: {
        "item": item
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(item.created_for_user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.created_by_user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.nice_status) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.finished_at)) + " ")])]);
  })], 2)])] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }