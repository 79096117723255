<script>
import {gql} from "graphql-tag";

const getItems = gql`
    query {
        advances {
            id
            user_id
            user {
                id
                name
            }
            meta {
                amount
                description
            }
        }
    }
`

export default {
    name: "DashboardAdvancesList",
    data() {
        return {
            items: []
        }
    },
    computed: {
        computedItems() {
            let advances = this.items;
            let groupedAdvances = advances.reduce((acc, item) => {
                // Group items by 'user_id'
                (acc[item.user_id] = acc[item.user_id] || []).push(item);
                return acc;
            }, {});

            let result = Object.values(groupedAdvances).map(items => {
                // Sum 'meta.amount' for each group
                let sum = items.reduce((acc, item) => acc + parseFloat(item.meta.amount), 0);
                return {
                    user: items[0].user,
                    total: sum
                };
            });
            return result;
        }
    },
    methods: {
        fetchData() {
            this.apolloQuery({
                query: getItems,
            }).then(res => {
                this.items = res.data.advances;
            })
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>

<template>
    <div>
        <h3>Dlužné částky</h3>
        <div class="table-wrapper">
            <table class="table table-striped" v-if="computedItems.length">
                <tr>
                    <th>Uživatel</th>
                    <th>Celková částka</th>
                </tr>
                <tr v-for="item in computedItems">
                    <td>
                        <user-link :item="item.user"/>
                    </td>
                    <td>{{ item?.total?.formatCurrency() }}</td>
                </tr>
            </table>
            <div v-else class="hp__check">
                <Icon name="check"/>
            </div>
        </div>
    </div>
</template>