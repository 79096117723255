<template>
    <modal v-show="visible" @close="close">

        <form @submit.prevent="submit">
            <input v-for="field in hiddenFields" type="hidden" :name="field.name" :value="field.value"/>

            <div v-for="field in fields" class="form-group">
                <label>{{ field.label }}</label>
                <div v-if="field.type == 'radio'" class="form-control form-radios">
                    <label v-for="option in field.options">
                        <input type="radio" :checked="option.value == field.default" :name="field.key" :value="option.value" :disabled="viewMode"/>
                        {{ option.label }}
                    </label>
                </div>
                <div v-else-if="field.type == 'tag'">
                    <app-multiselect :value="formValues[field.key]" :options="shops" :disabled="viewMode" :taggable="true" :multiple="false" @tag="val => addShop(val, field.key)" @input="val => addShop(val, field.key)"/>
                    <input type="hidden" :key="field.key" :name="field.key" :value="formValues[field.key][0] ?? ''"/>
                </div>
                <div v-else-if="field.type == 'dropdown'">
                    <app-multiselect v-model="formValues[field.key]" :options="field.options" track-by="value" label="label" :disabled="viewMode" placeholder="Vyberte"/>
                    <input type="hidden" :name="field.key" :value="JSON.stringify(formValues[field.key])"/>
                </div>
                <div v-else-if="field.type == 'files'">
                    <project-files v-if="visible" :handle-upload="false" :allow-upload="true" :files="field.default" model-type="cost" :model-id="fields?.id" @newFiles="files => appendFiles = files"/>
                </div>
                <div v-else-if="field.type == 'date'">
                    <app-date v-model="formValues[field.key]" :name="field.key" :disabled-date="field?.disabledDate"/>
                </div>
                <div v-else-if="field.type == 'number'">
                    <input class="form-control" type="number" step=".01" v-model="formValues[field.key]" :name="field.key" :disabled="viewMode"/>
                </div>
                <div v-else>
                    <input class="form-control" type="text" v-model="formValues[field.key]" :name="field.key" :disabled="viewMode"/>
                </div>
            </div>

            <template v-if="!viewMode">
                <app-button slug="btn_addCost" class="btn-primary">Uložit</app-button>
                <a class="btn btn-secondary" @click="close">Zrušit</a>
            </template>
        </form>

    </modal>
</template>

<script>
import imageService from "@services/imageService";
import projectService from "@services/projectService";

export default {
    name: "addCostModal",
    mounted() {
        this.fetchData();
    },

    methods: {

        loadShops() {
            projectService.getAllShops().then(res => {
                this.shops = res.data.items.map(i => i.name);
            }).catch(e => {
                this.showError(e);
                this.shops = [];
            });
        },

        addShop(val, key) {
            let values = this.removeReactivity(this.formValues);
            values[key] = [val];
            this.formValues = values;
        },

        fetchData() {
        },

        composeBody(type, def = {}) {
            let modalFields = [];
            this.composeBodyType = type;

            let myCars = this.$store.state.myCars ?? [];
            myCars = myCars.map(i => i.id);

            let cars = this.$store.state.allCars;

            let cars1 = cars.filter(c => myCars.includes(c.id));
            let cars2 = cars.filter(c => !myCars.includes(c.id));

            cars = [
                ...cars1,
                ...cars2,
            ];

            let default_car = def?.meta?.car_id ?? "";
            if (cars1.length && !default_car) {
                default_car = cars1[0].id;
            }

            if (type == "gas_receipt") {
                cars = cars.filter(c => !(c?.meta?.disable_refueling ?? false));
            }

            cars = cars.map(car => {
                let name = car.fullname;
                return {
                    value: car.id,
                    label: name,
                }
            });

            if (type == 'transport') {

                modalFields = [
                    {
                        type: "dropdown",
                        key: "meta_car",
                        label: "Název auta",
                        default: def?.meta?.car_id ?? "",
                        options: cars,
                    },
                    {
                        type: "number",
                        key: "meta_count",
                        label: "Kolikrát tento den na stavbě",
                        default: def.meta?.count ?? 1,
                    },
                ];
            }

            if (type == 'shop') {
                this.loadShops();
                modalFields = [
                    {
                        type: "radio",
                        key: "meta_document_type",
                        label: "Dodací list",
                        default: def.meta?.document_type ?? "receipt",
                        options: [
                            {
                                value: "delivery",
                                label: "Dodací list",
                            },
                            {
                                value: "receipt",
                                label: "Doklad o zaplacení",
                            },
                            {
                                value: "other",
                                label: "Ostatní",
                            },
                        ]
                    },
                    {
                        type: "tag",
                        key: "meta_shop_name",
                        label: "Název obchodu",
                        default: (def?.meta?.shop_name?.split(",") ?? []),
                        options: this.removeReactivity(this.shops),
                    },
                    {
                        key: "title",
                        label: "Popis",
                        default: def.title ?? "",
                    },
                    {
                        key: "meta_document_number",
                        label: "Číslo dokladu",
                        default: def?.meta?.document_number ?? "",
                    },
                    {
                        type: "number",
                        key: "value",
                        label: "Placeno (Kč)",
                        default: def.value ?? "",
                    },
                    {
                        type: "radio",
                        key: "meta_payment_type",
                        label: "Placeno",
                        default: def.meta?.payment_type ?? "company",
                        options: [
                            {
                                value: "none",
                                label: "Neplaceno",
                            },
                            {
                                value: "company",
                                label: "Firemní finance",
                            },
                            {
                                value: "personal",
                                label: "Osobní finance",
                            },
                        ]
                    },
                ];
            }

            if (type == 'gas_receipt') {
                modalFields = [
                    {
                        type: "dropdown",
                        key: "meta_car",
                        label: "Název auta",
                        default: default_car,
                        options: cars,
                    },
                    {
                        type: "number",
                        key: "meta_liters",
                        label: "Tankováno (počet litrů)",
                        default: def.meta?.liters ?? "",
                    },
                    {
                        type: "number",
                        key: "value",
                        label: "Placeno (Kč)",
                        default: def.value ?? "",
                    },
                    {
                        type: "radio",
                        key: "meta_payment_type",
                        label: "Placeno",
                        default: def.meta?.payment_type ?? "company",
                        options: [
                            {
                                value: "company",
                                label: "Firemní finance",
                            },
                            {
                                value: "personal",
                                label: "Osobní finance",
                            },
                        ]
                    },
                    {
                        type: 'date',
                        key: "target_date",
                        label: "Tankováno (den)",
                        default: def.target_date ?? this.$moment().format("YYYY-MM-DD"),
                        disabledDate: (day) => {
                            if (this.isAdmin()) {
                                return false;
                            }

                            let $day = this.$moment(day);

                            if ($day.isBefore(this.maxDayForGas)) {
                                return true;
                            }

                            let $now = this.$moment();
                            if ($day.isAfter($now))
                                return true;
                        }
                    },
                ];
            }

            if (type == 'stock') {
                modalFields = [
                    {
                        key: "title",
                        label: "Položka",
                        default: def.title ?? "",
                    },
                ];

            }

            modalFields.push({
                type: "files",
                key: "files",
                label: "Fotky",
                default: def?.files ?? [],
                required: type == "gas_receipt" && !def.id ? true : false
            });

            modalFields.forEach(field => {
                if (field.type == "dropdown") {
                    let selected = field.options.find(option => option.value == field.default);
                    this.formValues[field.key] = selected ?? null;
                } else {
                    this.formValues[field.key] = field.default ?? null;
                }
            });

            this.fields = modalFields;
        },

        open(atts = {}) {
            this.viewMode = atts.viewMode ?? false;
            this.visible = true;
        },
        close() {
            this.$emit("close");
            this.visible = false;
        },

        submit(e) {
            let guest_pass = null;

            if (!this.getUserID()) {
                guest_pass = window.prompt("Zadejte heslo");
                if (!guest_pass)
                    return;
            }

            // if(this.appendFiles.length === 0 && !this.formValues.files?.length && this.composeBodyType !== "gas_receipt") {
            //     alert("Nahrajte alespoň jednu fotku.");
            //     return;
            // }

            let formData = new FormData(e.target);
            formData.append("guest_pass", guest_pass);

            let payload = {};
            for (let pair of formData.entries()) {
                let key = pair[0];
                let value = pair[1];
                payload[key] = value;
            }

            payload["new_files"] = this.appendFiles;

            this.setLoading("btn_addCost");
            imageService.uploadNewImages(payload).then(res => {
                this.appendFiles = [];
                this.$emit("updated");
                this.$toast.success("Uloženo.");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_addCost");
            });

        },

    },

    computed: {

        maxDayForGas() {
            return this.$moment().add(-14, "day").format("YYYY-MM-DD");
        }

    },

    data() {
        return {
            visible: false,
            success: null,
            fields: [],
            type: null,
            formValues: {
                meta_car: {},
                target_date: null,
            },
            hiddenFields: [],
            viewMode: false,
            shops: [],
            appendFiles: [],
            composeBodyType: ""
        }
    }
}
</script>