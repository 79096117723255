var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("Měsíční report dle pozic")]), _c('users-nav'), _c('add-hours-modal', {
    ref: "hoursModal"
  }), _c('div', {
    staticClass: "report_arrows"
  }, [_c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": {
        name: 'user_position_report',
        params: {
          date: _vm.prev_month
        }
      }
    }
  }, [_vm._v(" ⇚ ")]), _c('div', {
    staticClass: "report_label"
  }, [_vm._v(_vm._s(_vm.currentDateLabel))]), _c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": {
        name: 'user_position_report',
        params: {
          date: _vm.next_month
        }
      }
    }
  }, [_vm._v(" ⇛ ")])], 1), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h4', [_vm._v("Filtrovat dle pozice")]), _c('div', {
    staticClass: "flex-items"
  }, _vm._l(_vm.positions, function (item) {
    return _c('button', {
      staticClass: "btn btn-secondary",
      class: {
        'btn-primary': _vm.showPositions.includes(item)
      },
      on: {
        "click": function ($event) {
          return _vm.showPositions.toggle(item);
        }
      }
    }, [_vm._v(_vm._s(item))]);
  }), 0)]), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h4', [_vm._v("Filtrovat dle zakázky")]), _c('app-select', {
    attrs: {
      "auto-load": "projects",
      "clearable": true
    },
    model: {
      value: _vm.filter_project_id,
      callback: function ($$v) {
        _vm.filter_project_id = $$v;
      },
      expression: "filter_project_id"
    }
  }), _vm.filter_project_id ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        _vm.filter_project_id = null;
      }
    }
  }, [_vm._v("Vymazat filtr")]) : _vm._e()], 1)]), _c('br'), _c('holiday-legend'), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("Jméno")]), _vm._l(_vm.daysInMonth, function (day) {
    return _c('th', [_vm._v(" " + _vm._s(day.index) + ".")]);
  }), _c('th', [_vm._v("Celkem")])], 2), _vm._l(_vm.allUsers, function (user) {
    return [_c('tr', {
      staticClass: "report__user"
    }, [_c('td', {
      attrs: {
        "colspan": 2 + _vm.daysInMonth.length
      }
    }, [_c('h5', {
      staticClass: "table-open",
      on: {
        "click": function ($event) {
          return _vm.openedUsers.toggle(user.id);
        }
      }
    }, [_c('button', {
      staticClass: "btn btn-primary"
    }, [_vm._v(_vm._s(_vm.openedUsers.includes(user.id) ? "-" : "+"))]), _vm._v(" " + _vm._s(user.name)), _c('br'), _vm._v(" " + _vm._s(user.positionName) + " ")])])]), _vm.openedUsers.includes(user.id) ? _vm._l(user.projects, function (project) {
      var _project$hours;

      return _c('tr', {
        staticClass: "report__items"
      }, [_c('td', [_c('project-link', {
        attrs: {
          "item": project.project,
          "hide-client": true
        }
      })], 1), _vm._l(_vm.daysInMonth, function (day) {
        var _project$hours$find$a, _project$hours$find;

        return _c('td', {
          class: _vm.localRenderDayClass(day, user)
        }, [_c('holiday-cell', {
          attrs: {
            "item": _vm.findHoliday(user, day)
          }
        }), _c('a', {
          staticClass: "no-underline",
          on: {
            "click": function ($event) {
              _vm.openHours(project.hours.find(function (h) {
                return h.day == day.key;
              }));
            }
          }
        }, [_vm._v(" " + _vm._s((_project$hours$find$a = (_project$hours$find = project.hours.find(function (h) {
          return h.day == day.key;
        })) === null || _project$hours$find === void 0 ? void 0 : _project$hours$find.amount) !== null && _project$hours$find$a !== void 0 ? _project$hours$find$a : "-") + " ")])], 1);
      }), _c('td', [_vm._v(" " + _vm._s((_project$hours = project.hours) === null || _project$hours === void 0 ? void 0 : _project$hours.sum("amount")))])], 2);
    }) : _vm._e()];
  })], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }