<template>
    <div>
        <router-link v-if="link" :to="link" v-html="label"></router-link>
        <span v-else v-html="label"></span>
    </div>
</template>

<script>
export default {
    name: "modelLink",
    props: {
        model: {
            default: null,
        },
        modelId: {
            default: null,
        },
        label: {
            default: null
        }
    },
    computed: {

        link: function () {
            let id = this.modelId;

            if (this.model == "car") {
                return {name: 'car_detail', params: {car_id: id}};
            }
            if (this.model == "client") {
                return {name: 'client_detail', params: {client_id: id}};
            }
            if (this.model == "project") {
                return {name: 'project_show', params: {project_id: id}};
            }
            if (this.model == "projectcost") {
                return {name: 'cost_show', params: {cost_id: id}}
            }
            if (this.model == "task") {
                return {name: 'task_show', params: {task_id: id}}
            }
            if (this.model == "thing") {
                return {name: 'thing_show', params: {thing_id: id}}
            }
            if (this.model == "user") {
                return {name: 'user_show', params: {user_id: id}}
            }

            return null;
        }

    }
}
</script>