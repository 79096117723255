<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-6">

                    <div class="tabs">
                        <h2 class="tab__item" @click="tab = 'notapproved'" :class="{'active': tab=='notapproved'}">Neschválené</h2>
                        <h2 class="tab__item" @click="tab = 'approved'" :class="{'active': tab=='approved'}">Schválené</h2>
                        <h2 class="tab__item" @click="tab = 'gas'" :class="{'active': tab=='gas'}">Tankování</h2>
                        <h2 class="tab__item" @click="tab = 'trash'" :class="{'active': tab=='trash'}">Koš</h2>
                    </div>

                    <div class="tabs" v-if="tab == 'notapproved' || tab=='approved'">
                        <h3 class="tab__item" @click="filterType = 'shop'" :class="{'active': filterType=='shop'}">Obchod</h3>
                        <h3 class="tab__item" @click="filterType = 'stock'" :class="{'active': filterType=='stock'}">Sklad</h3>
                        <h3 class="tab__item" @click="filterType = 'transport'" :class="{'active': filterType=='transport'}">Doprava</h3>
                    </div>

                </div>
                <div class="col-6 flex-buttons"></div>
            </div>

            <template v-if="tab == 'notapproved'">
                <h3>Zařazené náklady</h3>
                <cost-table :items="naa_items"/>
                <h3>Nezařazené náklady</h3>
                <cost-table :items="nan_items"/>
            </template>

            <template v-if="tab == 'approved'">
                <h3>Zařazené náklady</h3>
                <cost-table :items="aa_items"/>
                <h3>Nezařazené náklady</h3>
                <cost-table :items="an_items"/>
            </template>

            <template v-if="tab == 'gas'">
                <cost-table :items="gas_receipts"/>
            </template>

            <template v-if="tab == 'trash'">
                <app-table v-bind="tableAtts_trashed" :items="trashed" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                <cost-link :item="row"/>
                            </td>
                            <td>
                                {{ row.meta?.document_number ?? "" }}
                            </td>
                            <td>
                                <user-link :item="row.user" :position="false"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                <app-button class="btn btn-danger" :slug="'btn_forceDeleteCost_'+row.id" @click="forceDelete(row)">
                                    Trvalé odstranění
                                </app-button>
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>
        </template>

    </div>
</template>

<script>
import projectService from "@services/projectService";
import CostTable from "@components/costTable";

export default {
    name: "cost_archive",
    components: {CostTable},
    mounted() {
        this.fetchData();
    },

    methods: {

        forceDelete(item) {

            let data = {
                cost_id: item.id,
                force: true,
            };

            this.setLoading("btn_forceDeleteCost_" + item.id);
            projectService.forceDeleteCost(data).then(res => {
                this.fetchData();
                this.$toast.success("Odstraněno.");
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_forceDeleteCost_" + item.id);
            });

        },

        fetchData() {
            this.pageLoading = true;
            projectService.getAllCosts().then(res => {

                this.notapproved_assigned = res.data.notapproved_assigned;
                this.notapproved_notassigned = res.data.notapproved_notassigned;
                this.approved_assigned = res.data.approved_assigned;
                this.approved_notassigned = res.data.approved_notassigned;
                this.gas_receipts = res.data.gas_receipts;
                this.trashed = res.data.trashed;

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },
    },

    computed: {

        naa_items: function () {
            return this.notapproved_assigned.filter(i => i.type == this.filterType);
        },
        nan_items: function () {
            return this.notapproved_notassigned.filter(i => i.type == this.filterType);
        },
        aa_items: function () {
            return this.approved_assigned.filter(i => i.type == this.filterType);
        },
        an_items: function () {
            return this.approved_notassigned.filter(i => i.type == this.filterType);
        },

    },

    data() {
        return {
            pageLoading: true,
            notapproved_assigned: [],
            notapproved_notassigned: [],
            approved_assigned: [],
            approved_notassigned: [],
            gas_receipts: [],
            trashed: [],
            tab: 'notapproved',

            tableAtts_trashed: {
                currentPage: 1,
                perPage: 20,
                pagination: true,
                sortBy: 'title',
                sortDirection: 'asc',
                fields: [
                    {key: 'title', label: "Název", sortable: true},
                    {key: 'meta.document_number', label: "Číslo dokladu", sortable: true, width: '200px'},
                    {key: 'user.name', label: "Vytvořil", sortable: true, width: '180px'},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '130px'},
                    {key: '', label: "", width: '130px'},
                ],
            },
            filterType: 'shop',
        }
    }
}
</script>

