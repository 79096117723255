var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('h2', [_vm._v("Správa aut")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_vm.userCan('car.update') ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'car_update'
      }
    }
  }, [_vm._v(" Nové auto ")]) : _vm._e(), _vm.userCan('car.update') ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'car_reports'
      }
    }
  }, [_vm._v(" Měsíční reporty ")]) : _vm._e()], 1), _vm.isAdmin() ? _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [!_vm.table_attributes.sortable ? _c('button', {
    key: "chen",
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.table_attributes.sortable = true;
      }
    }
  }, [_vm._v("Změnit pořadí")]) : _c('div', [_c('app-button', {
    key: "dochange",
    staticClass: "btn btn-success",
    attrs: {
      "slug": "btn_saveOrder"
    },
    on: {
      "click": _vm.saveOrder
    }
  }, [_vm._v("Uložit pořadí")]), _c('button', {
    key: "cancelchange",
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.table_attributes.sortable = false;
      }
    }
  }, [_vm._v("Zrušit změnu")])], 1)]) : _vm._e()]), _c('br'), _vm._v(" "), _c('br'), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.items,
      "local-sort": !_vm.table_attributes.sortable,
      "hide-sorting": _vm.table_attributes.sortable
    },
    scopedSlots: _vm._u([{
      key: "table-content",
      fn: function (data) {
        return [_c('draggable', {
          attrs: {
            "tag": "tbody",
            "options": {
              disabled: !_vm.table_attributes.sortable
            }
          },
          model: {
            value: _vm.items,
            callback: function ($$v) {
              _vm.items = $$v;
            },
            expression: "items"
          }
        }, _vm._l(data.rows, function (row, key) {
          var _row$meta, _row$meta2, _row$meta3;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(_vm._s(row.order))]), _c('td', [_c('router-link', {
            attrs: {
              "to": {
                name: 'car_detail',
                params: {
                  car_id: row.id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(row.name) + " ")])], 1), _c('td', _vm._l(row.owners, function (user) {
            return _c('div', [_c('user-link', {
              key: user.id,
              attrs: {
                "item": user
              }
            })], 1);
          }), 0), _c('td', [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$meta = row.meta) === null || _row$meta === void 0 ? void 0 : _row$meta.plate))]), _c('td', [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : (_row$meta2 = row.meta) === null || _row$meta2 === void 0 ? void 0 : _row$meta2.info))]), _c('td', {
            class: {
              'td--invalid': row.stkExpires
            }
          }, [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.stkExpirationDate))]), _c('td', {
            class: {
              'td--invalid': row.tollLicenseExpires
            }
          }, [_vm._v(" " + _vm._s(_vm.localizeDate(row === null || row === void 0 ? void 0 : (_row$meta3 = row.meta) === null || _row$meta3 === void 0 ? void 0 : _row$meta3.toll_license_expires, 'D. M. Y')) + " ")])]);
        }), 0)];
      }
    }])
  }, 'app-table', _vm.table_attributes, false))]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }