<template>
    <div>
<!--        <ag-grid-vue :localeText="locale" @grid-ready="onGridReady" domLayout='autoHeight' class="ag-theme-alpine" :columnDefs="fields" :rowData="items" :pagination="true" :paginationPageSize="perPage"></ag-grid-vue>-->
    </div>
</template>

<script>
// import {AgGridVue} from "ag-grid-vue";

export default {
    name: "appGrid",
    // components: {AgGridVue},

    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.sizeColumnsToFit();
        },
    },

    props: {
        fields: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        },
        perPage: {
            type: Number,
            default: 10
        },
    },
    data() {
        return {
            gridApi: null,
            locale: {
                selectAll: '(Select All)',
                selectAllSearchResults: '(Select All Search Results)',
                searchOoo: 'Search...',
                blanks: '(Blanks)',
                noMatches: 'No matches',

                // Number Filter & Text Filter
                filterOoo: 'Filter...',
                equals: 'Equals',
                notEqual: 'Not equal',
                empty: 'Choose One',

                // Number Filter
                lessThan: 'Less than',
                greaterThan: 'Greater than',
                lessThanOrEqual: 'Less than or equal',
                greaterThanOrEqual: 'Greater than or equal',
                inRange: 'In range',
                inRangeStart: 'from',
                inRangeEnd: 'to',

                // Text Filter
                contains: 'Contains',
                notContains: 'Not contains',
                startsWith: 'Starts with',
                endsWith: 'Ends with',

                // Date Filter
                dateFormatOoo: 'yyyy-mm-dd',

                // Filter Conditions
                andCondition: 'AND',
                orCondition: 'OR',

                // Filter Buttons
                applyFilter: 'Apply',
                resetFilter: 'Reset',
                clearFilter: 'Clear',
                cancelFilter: 'Cancel',

                // Filter Titles
                textFilter: 'Text Filter',
                numberFilter: 'Number Filter',
                dateFilter: 'Date Filter',
                setFilter: 'Set Filter',

                // Side Bar
                columns: 'Columns',
                filters: 'Filters',

                // columns tool panel
                pivotMode: 'Pivot Mode',
                groups: 'Row Groups',
                rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
                values: 'Values',
                valueColumnsEmptyMessage: 'Drag here to aggregate',
                pivots: 'Column Labels',
                pivotColumnsEmptyMessage: 'Drag here to set column labels',

                // Header of the Default Group Column
                group: 'Group',

                // Other
                loadingOoo: 'Loading...',
                noRowsToShow: 'No Rows To Show',
                enabled: 'Enabled',

                // Menu
                pinColumn: 'Pin Column',
                pinLeft: 'Pin Left',
                pinRight: 'Pin Right',
                noPin: 'No Pin',
                valueAggregation: 'Value Aggregation',
                autosizeThiscolumn: 'Autosize This Column',
                autosizeAllColumns: 'Autosize All Columns',
                groupBy: 'Group by',
                ungroupBy: 'Un-Group by',
                resetColumns: 'Reset Columns',
                expandAll: 'Expand All',
                collapseAll: 'Close All',
                copy: 'Copy',
                ctrlC: 'Ctrl+C',
                copyWithHeaders: 'Copy With Headers',
                paste: 'Paste',
                ctrlV: 'Ctrl+V',
                export: 'Export',
                csvExport: 'CSV Export',
                excelExport: 'Excel Export',

                // Enterprise Menu Aggregation and Status Bar
                sum: 'Sum',
                min: 'Min',
                max: 'Max',
                none: 'None',
                count: 'Count',
                avg: 'Average',
                filteredRows: 'Filtered',
                selectedRows: 'Selected',
                totalRows: 'Total Rows',
                totalAndFilteredRows: 'Rows',
                more: 'More',
                to: '-',
                of: 'z',
                page: 'Stránka',
                nextPage: 'Next Page',
                lastPage: 'Last Page',
                firstPage: 'First Page',
                previousPage: 'Previous Page',

                // Pivoting
                pivotColumnGroupTotals: 'Total',

                // Enterprise Menu (Charts)
                pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
                pivotChart: 'Pivot Chart',
                chartRange: 'Chart Range',

                columnChart: 'Column',
                groupedColumn: 'Grouped',
                stackedColumn: 'Stacked',
                normalizedColumn: '100% Stacked',

                barChart: 'Bar',
                groupedBar: 'Grouped',
                stackedBar: 'Stacked',
                normalizedBar: '100% Stacked',

                pieChart: 'Pie',
                pie: 'Pie',
                doughnut: 'Doughnut',

                line: 'Line',

                xyChart: 'X Y (Scatter)',
                scatter: 'Scatter',
                bubble: 'Bubble',

                areaChart: 'Area',
                area: 'Area',
                stackedArea: 'Stacked',
                normalizedArea: '100% Stacked',

                histogramChart: 'Histogram',

                // Charts
                pivotChartTitle: 'Pivot Chart',
                rangeChartTitle: 'Range Chart',
                settings: 'Settings',
                data: 'Data',
                format: 'Format',
                categories: 'Categories',
                defaultCategory: '(None)',
                series: 'Series',
                xyValues: 'X Y Values',
                paired: 'Paired Mode',
                axis: 'Axis',
                navigator: 'Navigator',
                color: 'Color',
                thickness: 'Thickness',
                xType: 'X Type',
                automatic: 'Automatic',
                category: 'Category',
                number: 'Number',
                time: 'Time',
                xRotation: 'X Rotation',
                yRotation: 'Y Rotation',
                ticks: 'Ticks',
                width: 'Width',
                height: 'Height',
                length: 'Length',
                padding: 'Padding',
                spacing: 'Spacing',
                chart: 'Chart',
                title: 'Title',
                titlePlaceholder: 'Chart title - double click to edit',
                background: 'Background',
                font: 'Font',
                top: 'Top',
                right: 'Right',
                bottom: 'Bottom',
                left: 'Left',
                labels: 'Labels',
                size: 'Size',
                minSize: 'Minimum Size',
                maxSize: 'Maximum Size',
                legend: 'Legend',
                position: 'Position',
                markerSize: 'Marker Size',
                markerStroke: 'Marker Stroke',
                markerPadding: 'Marker Padding',
                itemSpacing: 'Item Spacing',
                itemPaddingX: 'Item Padding X',
                itemPaddingY: 'Item Padding Y',
                layoutHorizontalSpacing: 'Horizontal Spacing',
                layoutVerticalSpacing: 'Vertical Spacing',
                strokeWidth: 'Stroke Width',
                offset: 'Offset',
                offsets: 'Offsets',
                tooltips: 'Tooltips',
                callout: 'Callout',
                markers: 'Markers',
                shadow: 'Shadow',
                blur: 'Blur',
                xOffset: 'X Offset',
                yOffset: 'Y Offset',
                lineWidth: 'Line Width',
                normal: 'Normal',
                bold: 'Bold',
                italic: 'Italic',
                boldItalic: 'Bold Italic',
                predefined: 'Predefined',
                fillOpacity: 'Fill Opacity',
                strokeOpacity: 'Line Opacity',
                histogramBinCount: 'Bin count',
                columnGroup: 'Column',
                barGroup: 'Bar',
                pieGroup: 'Pie',
                lineGroup: 'Line',
                scatterGroup: 'X Y (Scatter)',
                areaGroup: 'Area',
                histogramGroup: 'Histogram',
                groupedColumnTooltip: 'Grouped',
                stackedColumnTooltip: 'Stacked',
                normalizedColumnTooltip: '100% Stacked',
                groupedBarTooltip: 'Grouped',
                stackedBarTooltip: 'Stacked',
                normalizedBarTooltip: '100% Stacked',
                pieTooltip: 'Pie',
                doughnutTooltip: 'Doughnut',
                lineTooltip: 'Line',
                groupedAreaTooltip: 'Area',
                stackedAreaTooltip: 'Stacked',
                normalizedAreaTooltip: '100% Stacked',
                scatterTooltip: 'Scatter',
                bubbleTooltip: 'Bubble',
                histogramTooltip: 'Histogram',
                noDataToChart: 'No data available to be charted.',
                pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
                chartSettingsToolbarTooltip: 'Menu',
                chartLinkToolbarTooltip: 'Linked to Grid',
                chartUnlinkToolbarTooltip: 'Unlinked from Grid',
                chartDownloadToolbarTooltip: 'Download Chart',

                // ARIA
                ariaHidden: 'hidden',
                ariaVisible: 'visible',
                ariaChecked: 'checked',
                ariaUnchecked: 'unchecked',
                ariaIndeterminate: 'indeterminate',
                ariaDefaultListName: 'List',
                ariaColumnSelectAll: 'Toggle Select All Columns',
                ariaInputEditor: 'Input Editor',
                ariaDateFilterInput: 'Date Filter Input',
                ariaFilterList: 'Filter List',
                ariaFilterInput: 'Filter Input',
                ariaFilterColumnsInput: 'Filter Columns Input',
                ariaFilterValue: 'Filter Value',
                ariaFilterFromValue: 'Filter from value',
                ariaFilterToValue: 'Filter to value',
                ariaFilteringOperator: 'Filtering Operator',
                ariaColumn: 'Column',
                ariaColumnList: 'Column List',
                ariaColumnGroup: 'Column Group',
                ariaRowSelect: 'Press SPACE to select this row',
                ariaRowDeselect: 'Press SPACE to deselect this row',
                ariaRowToggleSelection: 'Press Space to toggle row selection',
                ariaRowSelectAll: 'Press Space to toggle all rows selection',
                ariaToggleVisibility: 'Press SPACE to toggle visibility',
                ariaSearch: 'Search',
                ariaSearchFilterValues: 'Search filter values',

                ariaLabelColumnMenu: 'Column Menu',
                ariaLabelCellEditor: 'Cell Editor',
                ariaLabelDialog: 'Dialog',
                ariaLabelSelectField: 'Select Field',
                ariaLabelTooltip: 'Tooltip',
                ariaLabelContextMenu: 'Context Menu',
                ariaLabelSubMenu: 'SubMenu',
                ariaLabelAggregationFunction: 'Aggregation Function'
            }
        }
    }
}
</script>
<style lang="scss">
//@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
//@import "@scss/app/ag-grid.scss";
</style>