<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>{{ !task_id ? 'Nový úkol' : 'Úprava úkolu' }}</h2>
                    <form method="POST" autocomplete="off" @submit.prevent="submit">
                        <div class="form-group">
                            <label>Název</label>
                            <input class="form-control" type="text" v-model="task.name" required>
                        </div>
                        <div class="form-group">
                            <label>Popis</label>
                            <textarea class="form-control" v-model="task.text" required></textarea>
                        </div>
                        <div class="form-group">
                            <label>Pro koho</label>
                            <app-multiselect @input="v => task.created_for = v.value" :value="allUsers.find(i => i.value == task.created_for)" :options="allUsers" track-by="value" label="label" :allow-empty="false"/>
                        </div>
                        <div class="form-group">
                            <label>Stav</label>
                            <select class="form-control" v-model="task.state" required>
                                <option value="open">Otevřený</option>
                                <option value="check">Kontrola</option>
                                <option value="closed">Zavřený</option>
                            </select>
                        </div>
                        <app-button class="btn-primary" slug="btn_createTask" :disabled="!task.created_for">Uložit</app-button>
                    </form>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import taskService from "@services/taskService";

export default {
    name: "task_create",

    mounted() {
        this.task_id = this.$route.params?.task_id ?? null;
        this.fetchData();
    },

    methods: {

        fetchData() {
            let task_id = this.task_id;

            if (!task_id)
                return;

            this.pageLoading = true;
            taskService.getTask(task_id).then(res => {
                this.task = res.data.task;
                this.pageLoading = true;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

        submit() {
            this.setLoading("btn_createTask");

            let data = {
                task: this.task,
                task_id: this.task.id ?? null,
            };

            taskService.createTask(data).then(res => {
                let data = res.data;
                if (data.is_new) {
                    this.$router.push({name: "task_show", params: {task_id: data.task_id}});
                } else {
                    this.fetchData();
                }

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_createTask");
            });

        },
    },

    computed: {

        allUsers() {
            return this.$store.state.allUsers.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                }
            });
        }

    },

    data() {
        return {
            pageLoading: false,
            task_id: null,
            task: {
                state: "open",
                created_for: null,
                created_by: null,
            }
        }
    }
}
</script>