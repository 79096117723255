var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-date form-control"
  }, [_c('div', {
    staticClass: "dp__label"
  }, [_vm._v(" " + _vm._s(_vm.dayLabel) + " ")]), _c('datepicker', _vm._b({
    staticClass: "form-date1",
    attrs: {
      "input-attr": _vm.inputAtts,
      "value-type": "format",
      "editable": false,
      "clearable": _vm.clearable,
      "value": _vm.value
    },
    on: {
      "input": _vm.setVal
    }
  }, 'datepicker', _vm.$attrs, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }