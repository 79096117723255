<template>
    <router-link v-if="client_id" :to="{name: 'client_detail', params: {client_id: client_id}}">
        {{ item.name }}
    </router-link>
</template>

<script>
export default {
    name: "clientLink",
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        client_id() {
            return this?.item?.id;
        },
    }
}
</script>