<template>
    <div class="popup">
        <div class="popup__wrapper">
            <div class="popup__background" @click="close"></div>
            <div class="popup__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "modal",
    methods: {
        close() {
            this.$emit("close");
        }
    }
}
</script>
