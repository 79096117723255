var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "popup--large",
    on: {
      "close": _vm.close
    }
  }, [_c('h3', [_vm._v("Odpracované hodiny")]), _c('div', {
    staticClass: "report_arrows"
  }, [_c('a', {
    staticClass: "report_arrow",
    on: {
      "click": function ($event) {
        return _vm.addDay(-1);
      }
    }
  }, [_vm._v("⇚")]), _c('div', {
    staticClass: "report_label"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.targetDay, 'DD. MM. YYYY')))]), _c('a', {
    staticClass: "report_arrow",
    on: {
      "click": function ($event) {
        return _vm.addDay(1);
      }
    }
  }, [_vm._v("⇛")])]), _vm.loading ? _c('page-loader') : _c('app-table', _vm._b({
    attrs: {
      "items": _vm.items,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return [_vm._l(data.rows, function (row, key) {
          var _row$project;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s(parseFloat(row.amount).formatNumber(2)) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : row.project,
              "hide-client": true
            }
          }), _c('br'), _c('client-link', {
            attrs: {
              "item": row === null || row === void 0 ? void 0 : (_row$project = row.project) === null || _row$project === void 0 ? void 0 : _row$project.client
            }
          })], 1), _c('td', [_c('images-info', {
            attrs: {
              "item": row
            }
          })], 1)]);
        }), _c('tr', [_c('td', {
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v(" Celkem: " + _vm._s(data.rows.map(function (r) {
          return parseFloat(r.amount);
        }).sum().formatNumber(2)) + " h ")])])];
      }
    }])
  }, 'app-table', _vm.table_attributes_wip, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }