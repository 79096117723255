var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container container--small"
  }, [_vm._m(0), _c('add-cost-modal', {
    ref: "costModal"
  }), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("E-mail")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "email",
      "type": "email",
      "name": "email",
      "value": "",
      "required": "",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "password"
    }
  }, [_vm._v("Heslo")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "password",
      "type": "password",
      "name": "password",
      "required": ""
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_login",
      "type": "submit"
    }
  }, [_vm._v(" Přihlásit se ")]), _c('a', {
    staticClass: "btn btn-link",
    on: {
      "click": function ($event) {
        return _vm.addCost('shop');
      }
    }
  }, [_vm._v("Nahrát náklad")])], 1)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('h2', [_vm._v("Revis - interní systém")])]);
}]

export { render, staticRenderFns }