<template>
    <modal v-show="visible" @close="close">
        <form @submit.prevent="submit">
            <div class="form-group" v-if="showNote">
                <label>Poznámka</label>
                <textarea class="form-control" name="text" v-model="item.text"></textarea>
            </div>
            <div class="form-group">
                <label>Fotky</label>
                <project-files v-if="visible" :allow-upload="true" :handle-upload="false" :files="item.files" model-type="event" :model-id="item.id" @newFiles="files => appendFiles = files"/>
            </div>
            <app-button slug="btn_createEvent" class="btn-primary">Uložit</app-button>
        </form>
    </modal>
</template>

<script>
import eventService from "@services/eventService";

export default {
    name: "addEvent",
    mounted() {
        this.fetchData();
    },

    methods: {

        submit(e) {

            let payload = {
                id: this.item?.id ?? null,
                type: this.config?.type,
                model_id: this.config?.model_id,
                model_type: this.config?.model_type,
                new_files: this.appendFiles,
                text: this.item.text ?? "",
            };

            if (this.appendFiles.length === 0) {
              alert("Nahrajte alespoň jednu fotku.");
              return;
            }

            this.setLoading("btn_createEvent");

            this.setLoading("btn_createEvent");
            eventService.createEvent(payload).then(res => {
                this.appendFiles = [];
                this.$emit("updated");
                this.$toast.success("Uloženo");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_createEvent");
            });

        },

        fetchData() {
        },

        open(config = {}) {

            config.type = config.type ?? "event";
            let item = this.removeReactivity(config.item ?? null);

            this.item.id = item?.id ?? 0;
            this.item.text = item?.text ?? "";
            this.item.files = item?.files ?? [];

            this.config = config;
            this.visible = true;
        },

        close() {
            this.$emit("close");

            if (this.closing) {
                clearTimeout(this.closing);
                this.closing = null;
            }

            this.visible = false;
        }

    },

    computed: {

        showNote() {
            return true;
        }

    },

    data() {
        return {
            files: [],
            visible: false,
            text: null,
            closing: null,
            config: {},
            appendFiles: [],
            item: {
                id: 0,
                text: "",
                files: []
            },
        }
    }

}
</script>