<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <h2>{{ client_id ? 'Úprava zákazníka' : 'Nový zákazník' }}</h2>

            <form method="POST" autocomplete="off" @submit.prevent="submit">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h3>Informace</h3>
                        <div class="form-group">
                            <label>Jméno zákazníka</label>
                            <input class="form-control" type="text" v-model="client.name" required>
                        </div>
                        <div class="form-group">
                            <label>Popis</label>
                            <textarea class="form-control" type="text" v-model="client.note"></textarea>
                        </div>
                        <div class="form-group">
                            <label>Obec</label>
                            <app-select auto-load="cities" v-model="client.city_id"/>
                        </div>
                        <div class="form-group">
                            <label>Adresa</label>
                            <input class="form-control" type="text" v-model="client.address">
                        </div>
                        <div class="form-group">
                            <label>IČO</label>
                            <input class="form-control" type="text" v-model="client.meta.ico">
                        </div>
                        <div class="form-group">
                            <label>DIČ</label>
                            <input class="form-control" type="text" v-model="client.meta.dic">
                        </div>
                        <!--                Adresa, Ičo, Dič, Zodpovědná osoba (jméno, tel, email) n-krát-->
                        <app-button slug="btn_saveClient" type="submit" class="btn btn-primary">Uložit</app-button>
                    </div>
                    <div class="col-12 col-lg-6">
                        <h3>Kontaktní osoby</h3>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Skrytý</th>
                                    <th>Jméno</th>
                                    <th>Pozice</th>
                                    <th>E-mail</th>
                                    <th>Telefon</th>
                                </tr>
                            </thead>
                            <tr v-for="item in people">
                                <td>
                                    <input class="form-control" type="checkbox" v-model="item.hidden"/>
                                </td>
                                <td>
                                    <input class="form-control" type="text" v-model="item.name"/>
                                </td>
                                <td>
                                    <input class="form-control" type="text" v-model="item.position"/>
                                </td>
                                <td>
                                    <input class="form-control" type="text" v-model="item.email"/>
                                </td>
                                <td>
                                    <input class="form-control" type="text" v-model="item.tel"/>
                                </td>
                            </tr>
                        </table>
                        <button type="button" class="btn btn-primary" @click="people.push({hidden: true, name: '', email: '', tel: ''})">Přidat řádek</button>
                    </div>
                </div>
            </form>
        </template>
    </div>
</template>

<script>
import clientService from "@services/clientService";

export default {
    name: "client_create",
    mounted() {
        let client_id = this.$route.params.client_id ?? null;
        this.client_id = client_id;

        if (client_id) {
            this.fetchData();
        } else {
            this.pageLoading = false;
        }
    },
    methods: {

        submit(e) {

            let client = this.removeReactivity(this.client);
            let people = this.removeReactivity(this.people);

            let meta = client.meta;
            meta["people"] = people.filter(i => i.name);
            client.meta = meta;

            let data = {
                item: client,
            };

            this.setLoading("btn_saveClient");
            clientService.clientUpdate(data).then(res => {
                this.$toast.success("Zákazník uložen.");
                this.$router.push({name: "client_detail", params: {client_id: res.data.client_id}});
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_saveClient");
            });
        },

        fetchData() {
            this.pageLoading = true;
            clientService.clientDetail(this.client_id).then(res => {

                let {client} = res.data;
                client.meta = this.transformMeta(client.meta);

                this.client = client;
                this.projects = client.projects ?? [];
                let people =  (client.meta?.people ?? []).map(item => {
                   item.hidden = item.hidden ?? true;
                   return item;
                });

                this.people = people;

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        }
    },
    data() {
        return {
            pageLoading: true,
            client_id: null,
            client: {
                meta: {}
            },
            projects: [],
            people: []
        }
    }
}
</script>