<template>
    <div>
        <add-cost-modal ref="costModal" @updated="fetchData"/>
        <page-loader v-if="pageLoading"/>
        <div v-else>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>{{ cost.title }} -
                        <span v-if="cost.deleted_at" class="text-danger">SMAZÁNO</span>
                        <span v-else-if="cost.approved" class="text-success">Schváleno</span>
                        <span v-else class="text-danger">Neschváleno</span>
                    </h2>
                </div>
                <div class="col-12 col-lg-6 flex-buttons" v-if="!cost.deleted_at">
                    <button class="btn btn-danger" @click="editCost">Upravit náklad</button>
                    <app-button v-if="cost.approved" slug="btn_approval" @click="setApproval(0)" class="btn-danger">Neschválit</app-button>
                    <app-button v-else slug="btn_approval" @click="setApproval(1)" class="btn-success">Schválit</app-button>
                    <app-button v-if="isAdmin()" slug="btn_deleteCost" @click="deleteCost" class="btn-danger">Smazat náklad</app-button>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h3>Informace</h3>

                    <div class="task__row">
                        <div class="task__label">Vytvořil:</div>
                        {{ cost?.user?.name ?? "Anonym" }} v {{ localizeDate(cost.created_at) }}
                    </div>
                    <div class="task__row">
                        <div class="task__label">Typ nákladu:</div>
                        {{ cost.type_name ?? "Nezadáno" }}
                    </div>
                    <div class="task__row">
                        <div class="task__label">Hodnota:</div>
                        {{ cost.value ? cost.value + " Kč" : "Nezadáno" }}
                    </div>

                    <h3>Meta informace</h3>

                    <div class="task__row" v-for="meta in metas">
                        <div class="task__label">{{ meta.key }}:</div>
                        {{ meta.value }}
                    </div>

                    <div v-if="cost?.files?.length">
                        <h3>Soubory</h3>
                        <project-files :files="cost.files" model-type="cost" :model-id="cost?.id"/>
                    </div>

                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-6">
                            <h3>Zakázky</h3>
                        </div>
                        <div class="col-6 flex-buttons" v-if="!cost_project && !cost_projects.length && !cost.deleted_at">
                            <button class="btn btn-primary" @click="addToProjects(false)">Přidat k jediné zakázce</button>
                            <button class="btn btn-primary" @click="addToProjects(true)">Přidat k více zakázkám</button>
                        </div>
                    </div>

                    <div v-if="cost_projects.length">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Zakázka</th>
                                    <th>Název položky</th>
                                    <th>Částka</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in cost_projects">
                                    <td>
                                        <project-link :item="item.project"/>
                                    </td>
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.value }}</td>
                                    <td>
                                        <button class="btn btn-danger" @click="editSubCost(item)">Upravit</button>
                                        <app-button :slug="'btn_unassignProjectToCost_'+item.id" class="btn btn-danger" @click="deleteSubcost(item.id)">Odebrat</app-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="flex-buttons">
                            <button class="btn btn-primary" @click="addToProjects(true)">Přidat další zakázku</button>
                        </div>
                    </div>
                    <div v-else-if="cost.project">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Hlavní zakázka</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <project-link :item="cost.project"/>
                                    </td>
                                    <td>
                                        <app-button :slug="'btn_unassignProjectToCost'" class="btn btn-danger" @click="unassignProject()">Odebrat</app-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <form @submit.prevent="assignProjectToCost" v-if="assignProject.show">
                        <input type="hidden" name="cost_id" :value="cost_id"/>
                        <input type="hidden" name="subcost_id" :value="assignProject.subcost_id"/>
                        <input type="hidden" name="multiple" :value="assignProject.multiple ? 1 : 0"/>
                        <div class="form-group">
                            <label>Zakázka</label>
                            <app-select auto-load="projects" name="project_id" v-model="assignProject.project_id" placeholder="Vyberte zakázku" required/>
                        </div>
                        <template v-if="assignProject.multiple">
                            <div class="form-group">
                                <label>Název položky</label>
                                <input type="text" class="form-control" name="cost_name" v-model="assignProject.cost_name" required/>
                            </div>
                            <div class="form-group">
                                <label>Hodnota</label>
                                <input type="text" class="form-control" name="cost_value" v-model="assignProject.cost_value"/>
                            </div>
                        </template>
                        <div class="flex-buttons">
                            <app-button class="btn-primary" slug="btn_assignProjectToCost">
                                Uložit
                            </app-button>
                            <button class="btn btn-danger" @click="assignProject.show = false">Zrušit</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import projectService from "@services/projectService";
import AddCostModal from "@components/addCostModal";

export default {
    name: "cost_show",
    components: {AddCostModal},
    mounted() {
        this.cost_id = this.$route.params.cost_id;
        this.fetchData();
    },

    methods: {

        deleteCost() {

            if (window.confirm("Opravdu smazat?")) {

                let data = {
                    cost_id: this.cost.id,
                    force: false,
                };

                this.setLoading("btn_deleteCost");
                projectService.forceDeleteCost(data).then(res => {
                    this.fetchData();
                    this.$toast.success("Náklad odstraněn.");
                }).catch(e => {
                    this.showError(e);
                }).finally(res => {
                    this.unsetLoading("btn_deleteCost");
                });

            }

        },

        editCost() {
            let cost = this.cost;
            let type = cost.type;

            this.$refs.costModal.open();
            this.$refs.costModal.composeBody(type, cost);
            this.$refs.costModal.hiddenFields = [
                {name: "cost_id", value: cost.id},
                {name: "type", value: type},
            ];

        },

        setApproval(state) {
            this.unsetLoading("btn_approval");
            projectService.approveCost({
                cost_id: this.cost_id,
                approval: state
            }).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_approval");
                this.assignProject.show = false;
            });
        },

        convertToMultipleCosts() {

            this.assignProject.multiple = true;
            this.assignProject.show = true;
            this.assignProject.project_id = this.cost.project_id;

        },

        addToProjects(multiple = false) {
            this.assignProject.multiple = multiple;
            this.assignProject.show = true;
            this.clearForm();
        },

        editSubCost(item) {
            this.assignProject.multiple = true;
            this.assignProject.show = true;

            this.assignProject.project_id = item.project_id;
            this.assignProject.cost_name = item.title;
            this.assignProject.cost_value = item.value;
            this.assignProject.subcost_id = item.id;
        },

        clearForm() {
            this.assignProject.project_id = null;
            this.assignProject.cost_name = null;
            this.assignProject.cost_value = null;
            this.assignProject.cost_id = null;
        },

        updateSubCost() {

            this.clearForm();
        },

        unassignProject() {
            let cost_id = this.cost_id;
            if (confirm("Opravdu odebrat zakázku?")) {
                this.setLoading("btn_unassignProjectToCost");

                projectService.assignProjectToCost({
                    cost_id,
                    project_id: null
                }).then(res => {
                    this.fetchData();
                }).catch(e => {
                    this.showError(e);
                }).finally(r => {
                    this.unsetLoading("btn_unassignProjectToCost");
                    this.assignProject.show = false;
                });

            }
        },

        deleteSubcost(cost_id) {

            if (confirm("Opravdu smazat?")) {
                this.setLoading("btn_unassignProjectToCost", cost_id);
                projectService.deleteSubcost({cost_id}).then(r => {
                    this.fetchData();
                }).catch(e => {
                    this.showError(e);
                }).finally(r => {
                    this.unsetLoading("btn_unassignProjectToCost", cost_id);
                });
            }

        },

        assignProjectToCost(e) {
            let data = new FormData(e.target);
            this.setLoading("btn_assignProjectToCost");
            projectService.assignProjectToCost(data).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_assignProjectToCost");
                this.assignProject.show = false;
            });
        },

        fetchData() {
            this.pageLoading = true;
            let cost_id = this.cost_id;
            projectService.getCost(cost_id).then(res => {
                this.cost = res.data.cost;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

        translate(str = null) {
            return this.translations[str] ?? str;
        },

    },

    computed: {

        cost_projects() {
            return this.cost?.subcosts ?? [];
        },

        cost_project() {
            return this.cost?.project;
        },

        metas() {
            let metas = this.cost.meta ?? {};
            let keys = Object.keys(metas);
            let items = keys.map(key => {
                let value = metas[key] ?? null;

                if (key == "car_id") {

                    let car = this.$store.state.allCars.find(c => c.id == value);

                    return {
                        key: "Auto",
                        value: car ? car.fullname : "",
                    }
                }

                return {
                    key: this.translate(key),
                    value: this.translate(value),
                }
            });

            if(this.cost.type == "gas_receipt") {
                items.push({
                   key: "Datum tankování",
                   value: this.$moment(this.cost.target_date).format("DD. MM. YYYY")
                });
            }

            return items;

        },

    },

    data() {
        return {
            pageLoading: true,
            cost: {},
            cost_id: null,
            assignProject: {
                show: false,
                multiple: false,
                project_id: null,
                cost_name: null,
                cost_value: null,
                cost_id: null,
            },
            translations: {
                document_type: "Typ dokumentu",
                document_number: "Číslo dokladu",
                payment_type: "Způsob platby",
                delivery: "Dodací list",
                other: "Ostatní",
                receipt: "Doklad",
                personal: "Osobní",
                company: "Firemní",
                none: "Nezaplaceno",
                gas: "Tankováno (l)",
                count: "Počet jízd",
                liters: "Tankováno (l)",
                shop_name: "Název obchodu",
            }
        }
    }
}
</script>