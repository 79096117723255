var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [_vm._v("Rozpracované zakázky")]), _vm.userCan('office') ? [_c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.filterProjectCategory == null
    },
    on: {
      "click": function ($event) {
        _vm.filterProjectCategory = null;
      }
    }
  }, [_vm._v("Vše")]), _c('button', {
    staticClass: "btn btn-secondary",
    class: {
      'btn-primary': _vm.filterProjectCategory == 8
    },
    on: {
      "click": function ($event) {
        _vm.filterProjectCategory = 8;
      }
    }
  }, [_vm._v("Projekce")])] : _vm._e(), _vm._l(_vm.projectsByCities, function (city) {
    return _c('div', [_c('h5', {
      staticClass: "table-open",
      on: {
        "click": function ($event) {
          return _vm.openedCities.toggle(city.id);
        }
      }
    }, [_c('button', {
      staticClass: "btn btn-primary"
    }, [_vm._v(_vm._s(_vm.openedCities.includes(city.id) ? "-" : "+"))]), _vm._v(" " + _vm._s(city.name) + " ")]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.openedCities.includes(city.id),
        expression: "openedCities.includes(city.id)"
      }],
      staticClass: "table-wrapper"
    }, [city.projects.length ? _c('table', {
      staticClass: "table table-striped"
    }, [_vm._m(0, true), _vm._l(city.projects, function (item) {
      return _c('tr', [_c('td', [_c('project-link', {
        attrs: {
          "item": item,
          "hide-client": true
        }
      })], 1), _c('td', [_c('client-link', {
        attrs: {
          "item": item === null || item === void 0 ? void 0 : item.client
        }
      })], 1)]);
    })], 2) : _c('div', {
      staticClass: "hp__check"
    }, [_c('Icon', {
      attrs: {
        "name": "check"
      }
    })], 1)])]);
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Název")]), _c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Zákazník")])]);
}]

export { render, staticRenderFns }