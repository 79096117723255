<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <h3>Pokladna</h3>

            <div class="d-flex align-items-center" style="gap: 20px">
                <div class="form-group">
                    <label>Od</label>
                    <app-date v-model="dateFrom" :clearable="true" style="width: 200px"/>
                </div>
                <div class="form-group">
                    <label>Do</label>
                    <app-date v-model="dateTo" :clearable="true" style="width: 200px"/>
                </div>
                <div>Suma: {{filteredItemsSum}}</div>
            </div>

            <cost-table :items="computedItems"/>
        </template>

    </div>
</template>

<script>
import projectService from "@services/projectService";
import CostTable from "@components/costTable";

export default {
    name: "paidCosts",
    components: {CostTable},
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {

            this.pageLoading = true;
            projectService.getPaidCosts().then(res => {
                this.items = res.data.items;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });

        },
    },

    computed: {
        computedItems() {

            if (this.dateFrom && this.dateTo) {
                return this.items.filter(item => {
                    return this.$moment(item.created_at).isBetween(this.dateFrom, this.dateTo, undefined, "[]");
                });
            } else if (this.dateFrom) {
                return this.items.filter(item => {
                    return this.$moment(item.created_at).isSameOrAfter(this.dateFrom);
                });
            } else if (this.dateTo) {
                return this.items.filter(item => {
                    return this.$moment(item.created_at).isSameOrBefore(this.dateTo);
                });
            }

            return this.items;
        },

        filteredItemsSum() {
            let sum = this.computedItems.map(i => parseFloat(i.value ?? 0)).sum();
            return sum.formatCurrency();
        }

    },
    data() {
        return {
            items: [],
            pageLoading: false,
            dateFrom: null,
            dateTo: null,
        }
    }
}
</script>