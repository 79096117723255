<template>
    <div>
        <h2>Spojování obcí</h2>
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <div class="form-group">
                    <label class="text-pid1">Hlavní obec</label>
                    <app-select auto-load="cities" v-model="cid1"/>
                </div>
            </div>
            <div class="col-12 col-lg-5">
                <div class="form-group">
                    <label class="text-pid2">Duplicitní obec</label>
                    <app-select auto-load="cities" v-model="cid2"/>
                </div>
            </div>
            <div class="col-12 col-lg-2">
                <app-button slug="btn_mergeClients" class="btn btn-primary" @click="startMerge">Spojit</app-button>
            </div>
        </div>
    </div>
</template>

<script>
import cityService from "@services/cityService";

export default {
    name: "city_merge",
    methods: {
        startMerge() {

            let data = {
                cid1: this.cid1,
                cid2: this.cid2,
            };

            this.setLoading("btn_mergeClients");
            cityService.mergeCities(data).then(res => {
                this.$toast.success("Obce spojeny.");

                setTimeout(() => {
                    this.$router.push({name: "city_index"});
                }, 3000);

            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_mergeClients");
            });

        }
    },
    data() {
        return {
            cid1: null,
            cid2: null,
        }
    }
}
</script>