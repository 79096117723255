<template>
    <div class="mmPagination">

        <div v-if="selectPageAsDot && pagesList.length > 1" class="table__pagination">
            <a class="page-link" @click="prev" :class="{'hidden': prevHidden}">‹</a>
            <a
                v-for="page in pagesList"
                class="page-link"
                @click="mutableCurrentPage = page"
                :class="{
                    'active': page == mutableCurrentPage
                }"
            >{{ page }}
            </a>
            <a class="page-link" @click="next" :class="{'hidden': nextHidden}">›</a>
        </div>

        <div v-if="selectPageAsDropdown" class="table__pagination">
            <a class="page-link" @click="prev" :class="{'hidden': prevHidden}">‹</a>
            <select v-model="mutableCurrentPage" class="pag__select">
                <option v-for="n in selectOptions" :value="n">{{ n }}</option>
            </select>
            <a class="page-link" @click="next" :class="{'hidden': nextHidden}">›</a>
        </div>
    </div>
</template>

<script>

export default {
    name: "appPagination",

    props: {

        selectPageAsDropdown: {
            type: Boolean,
            default: false,
        },

        selectPageAsDot: {
            type: Boolean,
            default: false,
        },

        perPageDropdown: {
            type: Boolean,
            default: false,
        },

        perPageValues: {
            type: Array,
            default: () => [{label: 10}, {label: 25}, {label: 50}, {label: 100}],
        },

        perPage: {
            type: Number,
            default: 10
        },

        totalRows: {
            type: Number,
            default: 1
        },

        currentPage: {
            type: Number,
            default: 1
        },
    },

    watch: {

        currentPage: {
            immediate: true,
            handler(v) {
                this.mutableCurrentPage = v;
            }
        },

        perPage: {
            immediate: true,
            handler(v) {
                this.mutablePerPage = v;
            }
        },

        mutableCurrentPage: {
            handler(n) {
                let value = parseInt(n);
                this.$emit('update:currentPage', value);
            }
        },

    },

    methods: {

        perPageChanged() {
            this.$emit('update:currentPage', 1);
            this.$emit('update:perPage', this.mutablePerPage);
        },

        prev() {
            let newVal = this.mutableCurrentPage - 1;

            if (newVal >= 1)
                this.mutableCurrentPage = newVal;

        },

        next() {
            let newVal = this.mutableCurrentPage + 1;

            if (newVal <= this.selectOptions)
                this.mutableCurrentPage = newVal;

        },

        getSelectedValueFromSelect(event) {
            let v = event.target.value;
            this.selectPage(v);
        },

        selectPage(n) {
            let value = parseInt(n);
            this.mutableCurrentPage = value;
            this.$emit('update:currentPage', value);
        }

    },

    computed: {

        pagesList: function () {

            let addRight = 3;
            let showPages = 7;
            let maxNumber = this.selectOptions;
            let currentPage = this.mutableCurrentPage;

            let endNumber = currentPage + addRight;

            let over = showPages - endNumber;
            let startNumber = endNumber - showPages;

            if (over >= 0) {
                startNumber = 1;
                endNumber = endNumber + over;
            } else {
                startNumber = startNumber + 1;
            }

            if (endNumber > maxNumber) {
                endNumber = maxNumber;
                startNumber = endNumber - showPages + 1;

                if (startNumber < 1)
                    startNumber = 1;

            }

            let list = [];
            for (let i = startNumber; i <= endNumber; i++) {
                list.push(i);
            }
            return list;
        },

        selectOptions: function () {
            return Math.ceil(this.totalRows / this.perPage);
        },

        prevHidden: function () {
            return this.mutableCurrentPage == 1;
        },

        nextHidden: function () {
            return this.mutableCurrentPage == this.selectOptions;
        },

    },

    data() {

        return {
            mutableCurrentPage: 0,
            mutablePerPage: 0
        }

    }

}
</script>