var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.opened ? _c('modal', {
    on: {
      "close": function ($event) {
        _vm.opened = false;
      }
    }
  }, [_c('h2', [_vm._v("Přiložené fotky a soubory")]), _c('project-files', {
    attrs: {
      "files": _vm.computedImages
    }
  })], 1) : _vm._e(), _c('a', {
    on: {
      "click": _vm.openGallery
    }
  }, [_vm._v(_vm._s(_vm.computedImages.length))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }