var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modal ? _c('modal', {
    on: {
      "close": _vm.close
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('h3', [_vm._v("Poznámka")]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Text")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.text,
      expression: "item.text"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "text"
    },
    domProps: {
      "value": _vm.item.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.item, "text", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Priorita")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.priority,
      expression: "item.priority"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "priority"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.item, "priority", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.priorities, function (item) {
    return _c('option', {
      key: item.value,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0)]), _vm.userCan('offices') ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Viditelné pro")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.group,
      expression: "item.group"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "priority"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.item, "group", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "personal"
    }
  }, [_vm._v("Mne")]), _c('option', {
    attrs: {
      "value": "offices"
    }
  }, [_vm._v("Kancelář")]), _c('option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v("Všechny")]), _c('option', {
    attrs: {
      "value": "positions"
    }
  }, [_vm._v("Pozice")]), _c('option', {
    attrs: {
      "value": "users"
    }
  }, [_vm._v("Konkrétně")])])]) : _vm._e(), _vm.item.group == 'positions' ? _c('div', {
    staticClass: "form-group"
  }, _vm._l(_vm.positions, function (position) {
    return _c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.item.group_items,
        expression: "item.group_items"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": position.value,
        "checked": Array.isArray(_vm.item.group_items) ? _vm._i(_vm.item.group_items, position.value) > -1 : _vm.item.group_items
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.item.group_items,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = position.value,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.item, "group_items", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.item, "group_items", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.item, "group_items", $$c);
          }
        }
      }
    }), _vm._v(" " + _vm._s(position.label) + " ")]);
  }), 0) : _vm._e(), _vm.item.group == 'users' ? _c('div', {
    staticClass: "form-group"
  }, _vm._l(_vm.users, function (guser) {
    return _c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.item.group_items,
        expression: "item.group_items"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": guser.value,
        "checked": Array.isArray(_vm.item.group_items) ? _vm._i(_vm.item.group_items, guser.value) > -1 : _vm.item.group_items
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.item.group_items,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = guser.value,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.item, "group_items", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.item, "group_items", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.item, "group_items", $$c);
          }
        }
      }
    }), _vm._v(" " + _vm._s(guser.label) + " ")]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Fotky")]), _c('project-files', {
    attrs: {
      "files": _vm.item.files,
      "allow-upload": true,
      "handle-upload": false,
      "model-type": "note",
      "model-id": _vm.item.id
    },
    on: {
      "newFiles": function (files) {
        return _vm.appendFiles = files;
      },
      "imageDeleted": _vm.fetchData
    }
  })], 1), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_updateNote"
    }
  }, [_vm._v("Uložit")]), _vm.item.id ? _c('app-button', {
    staticClass: "btn-danger",
    attrs: {
      "slug": "btn_noteDelete",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteNote(_vm.item.id);
      }
    }
  }, [_vm._v("Smazat")]) : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('div', {
    staticClass: "flex-items"
  }, [_c('h3', [_vm._v("Moje poznámky")]), _vm.myNotes && !_vm.onlyView ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v("+")]) : _vm._e(), _vm.myNotes && _vm.onlyView ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pinboard'
      }
    }
  }, [_vm._v("Více")]) : _vm._e(), _vm.saveSort ? _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_saveSort"
    },
    on: {
      "click": _vm.saveSortAction
    }
  }, [_vm._v("Uložit pořadí")]) : _vm._e()], 1), _c('div', {
    staticClass: "notes"
  }, [_vm.loading ? _c('page-loader') : _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Název")]), _c('th', [_vm._v("Fotky")]), _vm.myNotes && !_vm.onlyView ? _c('th', {
    attrs: {
      "width": "100px"
    }
  }) : _vm._e()])]), _c('draggable', {
    attrs: {
      "tag": "tbody",
      "handle": ".table--move"
    },
    on: {
      "update": _vm.sortChanged
    },
    model: {
      value: _vm.notes,
      callback: function ($$v) {
        _vm.notes = $$v;
      },
      expression: "notes"
    }
  }, _vm._l(_vm.notes, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', [item.priority !== 'none' ? _c('span', {
      class: 'priority priority--' + item.priority,
      attrs: {
        "title": item.priority_label
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1), _vm.myNotes && !_vm.onlyView ? _c('td', [_c('button', {
      staticClass: "btn btn-icon btn-danger",
      on: {
        "click": function ($event) {
          return _vm.editItem(item);
        }
      }
    }, [_c('icon', {
      attrs: {
        "name": "edit"
      }
    })], 1), _c('button', {
      staticClass: "btn btn-icon table--move"
    }, [_vm._v("✥")])]) : _vm._e()]);
  }), 0)], 1)], 1)]), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_vm._m(0), _c('div', {
    staticClass: "notes"
  }, [_vm.loading ? _c('page-loader') : _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Název")]), _c('th', [_vm._v("Fotky")])])]), _c('tbody', _vm._l(_vm.shared, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', [item.priority !== 'none' ? _c('span', {
      class: 'priority priority--' + item.priority,
      attrs: {
        "title": item.priority_label
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]), _c('td', [_c('images-info', {
      attrs: {
        "item": item
      }
    })], 1)]);
  }), 0)])], 1)])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-items"
  }, [_c('h3', [_vm._v("Sdílené se mnou")])]);
}]

export { render, staticRenderFns }