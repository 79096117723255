<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Detail uživatele</h2>
                    <form method="POST" @submit.prevent="submit" autocomplete="off">
                        <div class="form-group">
                            <label>Jméno</label>
                            <input class="form-control" type="text" v-model="user.name">
                        </div>

                        <div class="form-group">
                            <label>E-mail</label>
                            <input class="form-control" type="text" v-model="user.email">
                        </div>

                        <div class="form-group">
                            <label>Telefon</label>
                            <input class="form-control" type="text" v-model="user.meta.phone">
                        </div>

                        <div class="form-group">
                            <label>Role</label>
                            <select v-model="user.role_id" class="form-control">
                                <option v-for="item in roles" :value="item.value">
                                    {{ item.label }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Pozice</label>
                            <select v-model="user.position_id" class="form-control">
                                <option v-for="item in positions" :value="item.value">
                                    {{ item.label }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Živnostník</label>
                            <select v-model="user.contractor" class="form-control">
                                <option value="0">Ne</option>
                                <option value="1">Ano</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Aktivní</label>
                            <select v-model="user.active" class="form-control">
                                <option value="1">Ano</option>
                                <option value="0">Ne</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Nové heslo</label>
                            <input class="form-control" type="password" v-model="new_password">
                        </div>

                        <app-button slug="btn_updateUser" type="submit" class="btn btn-primary">Uložit</app-button>
                    </form>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
import userService from "@services/userService";

export default {
    name: "user_create",

    mounted() {
        this.user_id = this.$route.params?.user_id ?? null;
        this.fetchData();
    },

    methods: {

        fetchData() {
            let user_id = this.user_id;

            if (!user_id)
                return;

            this.pageLoading = true;
            userService.getUser(user_id).then(res => {
                let user = res.data.user;

                user.meta = this.transformMeta(user.meta);

                this.user = user;
                this.pageLoading = true;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

        submit() {
            this.setLoading("btn_updateUser");

            let data = {
                user: this.user,
                user_id: this.user.id ?? null,
                new_password: this.new_password ?? null,
            };

            userService.saveUser(data).then(res => {
                let data = res.data;
                if (data.is_new) {
                    this.$router.push({name: "user_show", params: {user_id: data.user_id}});
                } else {
                    this.new_password = null;
                    this.fetchData();
                }
                this.$toast.success("Uloženo!");
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_updateUser");
            });

        },
    },

    computed: {

        positions() {
            return this.$store.state.allPositions.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                }
            });
        },

        roles() {
            return this.$store.state.allRoles.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                }
            });
        },

    },

    data() {
        return {
            pageLoading: false,
            user_id: null,
            user: {
                meta: {},
            },
            new_password: null,
        }
    }
}
</script>