var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v(_vm._s(!_vm.project_id ? 'Nová zakázka' : 'Úprava zakázky'))]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm.userCan('project.set-planned') ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Plánovaná zakázka")]), _c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": !_vm.project.is_public
    },
    on: {
      "change": function (val) {
        return _vm.project.is_public = !val.target.checked;
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Název")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.project.name,
      expression: "project.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.project.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.project, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.project.note,
      expression: "project.note"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.project.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.project, "note", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Kategorie")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.project.category,
      expression: "project.category"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.project, "category", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.project_categories, function (item) {
    return _c('option', {
      domProps: {
        "value": item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Zákazník")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-9"
  }, [_c('app-select', {
    ref: "clientSelect",
    attrs: {
      "auto-load": "clients"
    },
    model: {
      value: _vm.project.client_id,
      callback: function ($$v) {
        _vm.$set(_vm.project, "client_id", $$v);
      },
      expression: "project.client_id"
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-3"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "slug": "btn_createClient"
    },
    on: {
      "click": _vm.createClient
    }
  }, [_vm._v("Nový zákazník")])], 1)])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Obec")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-9"
  }, [_c('app-select', {
    ref: "citySelect",
    attrs: {
      "auto-load": "cities"
    },
    model: {
      value: _vm.project.city_id,
      callback: function ($$v) {
        _vm.$set(_vm.project, "city_id", $$v);
      },
      expression: "project.city_id"
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-3"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "slug": "btn_createCity"
    },
    on: {
      "click": _vm.createCity
    }
  }, [_vm._v("Nová obec")])], 1)])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Online tabulka")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.project.meta.online_document,
      expression: "project.meta.online_document"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "input"
    },
    domProps: {
      "value": _vm.project.meta.online_document
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.project.meta, "online_document", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Adresa")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.project.meta.address,
      expression: "project.meta.address"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "input"
    },
    domProps: {
      "value": _vm.project.meta.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.project.meta, "address", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Odkaz na mapu")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.project.meta.map_link,
      expression: "project.meta.map_link"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "input"
    },
    domProps: {
      "value": _vm.project.meta.map_link
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.project.meta, "map_link", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Interní zakázka")]), _c('div', [_c('vue-multiselect', {
    attrs: {
      "placeholder": "Vyberte uživatele",
      "options": _vm.users,
      "searchable": true,
      "label": "name",
      "multiple": true,
      "track-by": "id"
    },
    model: {
      value: _vm.selectedUsers,
      callback: function ($$v) {
        _vm.selectedUsers = $$v;
      },
      expression: "selectedUsers"
    }
  })], 1)]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_saveProject",
      "type": "submit"
    }
  }, [_vm._v("Uložit")]), _vm.project.id ? _c('app-button', {
    staticClass: "btn-danger",
    attrs: {
      "type": "button",
      "slug": "btn_deleteProject"
    },
    on: {
      "click": _vm.deleteProject
    }
  }, [_vm._v("Smazat zakázku")]) : _vm._e()], 1)])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }