<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <template v-if="wipProjects.length">
                <h3>Rozpracované zakázky</h3>

                <app-table v-bind="table_attributes_wip" :items="wipProjects" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                <client-link :item="row?.client"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                {{ localizeDate(row.updated_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

            <template v-if="computedFinished.length && userCan('project.see-finished')">
                <h3>Hotové zakázky</h3>
                <app-table v-bind="table_attributes_finished" :items="computedFinished" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                <client-link :item="row?.client"/>
                            </td>
                            <td>
                                {{ localizeDate(row.finished_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                            <td>
                                 <span v-if="row.finished_at">
                                     <span v-if="row.is_invoiced == 1" class="text-success bold">Ano</span>
                                    <span v-else-if="row.is_invoiced == 2" class="text-info bold">Neřeší se</span>
                                     <span v-else-if="row.is_invoiced == 3" class="text-info bold">Částečně</span>
                                    <span v-else class="text-danger bold">Ne</span>
                                </span>
                            </td>
                            <td>
                                {{ row.invoice_numbers }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>
        </template>
    </div>
</template>

<script>
import projectService from "@services/projectService";

export default {
    name: "project_internal",
    components: {},
    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {
            this.pageLoading = true;
            projectService.getInternalProjects().then(res => {
                this.items = res.data.wipProjects;
                this.finishedProjects = res.data.finishedProjects;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

    },

    computed: {

        computedFinished: function () {
            let items = this.removeReactivity(this.finishedProjects);
            return items;
        },

        computedItems: function () {
            let items = this.removeReactivity(this.items);
            return items;
        },

        wipProjects() {
            return this.computedItems.filter(i => i.is_public);
        },

        newProjects() {
            return this.computedItems.filter(i => !i.is_public);
        },

        verifiedItems: function () {
            return this.wipProjects.filter(i => i.is_verified);
        },

        notVerifiedItems: function () {
            return this.wipProjects.filter(i => !i.is_verified);
        },

    },

    data() {
        return {
            filterProjectCategory: null,
            pageLoading: true,
            items: [],
            finishedProjects: [],
            table_attributes_wip: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true, width: '10%'},
                    {key: 'name', label: "Název", sortable: true, width: '30%'},
                    {key: 'client.name', label: "Zákazník", sortable: true, width: '30%'},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '6%'},
                    {key: 'updated_at', label: "Datum změny", sortable: true, width: '10%'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '14%'},
                ],
                pagination: true,
            },
            table_attributes_finished: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true, width: '10%'},
                    {key: 'name', label: "Název", sortable: true, width: '30%'},
                    {key: 'client.name', label: "Zákazník", sortable: true, width: '30%'},
                    {key: 'finished_at', label: "Hotovo", sortable: true, width: '6%'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '10%'},
                    {key: 'is_invoiced', label: "Vyfakturováno", sortable: true, width: '7%'},
                    {key: 'invoice_numbers', label: "Číslo faktury", sortable: true, width: '7%'},
                ],
                pagination: true,
            },
        }
    }
}
</script>
