<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <h2>Detail zákazníka - {{ client.name }}</h2>
                </div>
                <div class="col-12 col-lg-6 flex-buttons">
                    <router-link :to="{name: 'client_create', params: {client_id: client_id}}" class="btn btn-danger">Upravit</router-link>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <h3>Informace</h3>
                    <div><b>Popis</b>: {{ client.note }}</div>
                    <div><b>IČO</b>: {{ client.meta?.ico }}</div>
                    <div><b>DIČ</b>: {{ client.meta?.dic }}</div>
                    <br>
                    <div><b>Město</b>: {{ client?.city?.name ?? "Nevyplněno" }}</div>
                    <div><b>Adresa</b>: {{ client.address }}</div>
                </div>
                <div class="col-12 col-lg-6">
                    <h3>Kontaktní osoby</h3>

                    <table v-if="people.length" class="table">
                        <thead>
                            <tr>
                                <th>Jméno</th>
                                <th>Pozice</th>
                                <th>E-mail</th>
                                <th>Telefon</th>
                            </tr>
                        </thead>
                        <tr v-for="item in people">
                            <td>
                                <span title="Skrytá osoba" v-if="item.hidden">👁️‍🗨️ </span>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.position }}
                            </td>
                            <td>
                                <a v-if="item.email" :href="'mailto:'+item.email">
                                    {{ item.email }}
                                </a>
                            </td>
                            <td>
                                <a v-if="item.tel" :href="'tel:'+item.tel">
                                    {{ item.tel }}
                                </a>
                            </td>
                        </tr>
                    </table>
                    <div v-else>Žádné kontaktní osoby</div>

                </div>
            </div>

            <template v-if="wipProjects.length">
                <h3>Rozpracované zakázky</h3>

                <app-table v-bind="table_attributes_wip" :items="wipProjects" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                {{ localizeDate(row.updated_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

            <template v-if="newProjects.length && userCan('project.see-planned')">
                <h3>Plánované zakázky</h3>

                <app-table v-bind="table_attributes_planned" :items="newProjects" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

            <template v-if="finishedProjects.length && userCan('project.see-finished')">
                <h3>Hotové zakázky</h3>
                <app-table v-bind="table_attributes_finished" :items="finishedProjects" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                {{ localizeDate(row.finished_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                            <td>
                                <span v-if="row.finished_at">
                                    <span v-if="row.is_invoiced == 1" class="text-success bold">Ano</span>
                                    <span v-else-if="row.is_invoiced == 2" class="text-info bold">Neřeší se</span>
                                    <span v-else-if="row.is_invoiced == 3" class="text-info bold">Částečně</span>
                                    <span v-else class="text-danger bold">Ne</span>
                                </span>
                            </td>
                            <td>
                                {{ row.invoice_numbers }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

        </template>
    </div>
</template>

<script>
import clientService from "@services/clientService";

export default {
    name: "client_detail",
    mounted() {
        let client_id = this.$route.params.client_id ?? null;
        this.client_id = client_id;
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.pageLoading = true;
            clientService.clientDetail(this.client_id).then(res => {

                let {client} = res.data;
                this.client = client;
                this.projects = client.projects ?? [];

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        }
    },

    computed: {

        wipProjects() {
            return this.projects.filter(i => i.is_public && !i.finished_at);
        },

        newProjects() {
            return this.projects.filter(i => !i.is_public && !i.finished_at);
        },

        finishedProjects() {
            return this.projects.filter(i => i.finished_at);
        },

        people: function () {
            let people = (this.client.meta?.people ?? []);

            people = people.map(item => {
                item.hidden = item?.hidden ?? true;
                return item;
            });

            if (!this.userCan('offices')) {
                people = people.filter(item => {
                    let hidden = item?.hidden ?? true;
                    return !hidden;
                });
            }

            return people;
        }

    },

    data() {
        return {
            pageLoading: true,
            client_id: null,
            client: {},
            projects: [],

            table_attributes_wip: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true},
                    {key: 'name', label: "Název", sortable: true},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '130px'},
                    {key: 'updated_at', label: "Datum změny", sortable: true, width: '130px'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '130px'},
                ],
                pagination: true,
            },

            table_attributes_planned: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true},
                    {key: 'name', label: "Název", sortable: true},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '130px'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '130px'},
                ],
                pagination: true,
            },

            table_attributes_finished: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true},
                    {key: 'name', label: "Název", sortable: true},
                    {key: 'finished_at', label: "Hotovo", sortable: true, width: '130px'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '130px'},
                    {key: 'is_invoiced', label: "Vyfakturováno", sortable: true, width: '130px'},
                    {key: 'invoice_numbers', label: "Číslo faktury", sortable: true, width: '130px'},
                ],
                pagination: true,
            },

        }
    }
}
</script>