<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>{{ !project_id ? 'Nová zakázka' : 'Úprava zakázky' }}</h2>
                    <form method="POST" autocomplete="off" @submit.prevent="submit">
                        <div class="form-group" v-if="userCan('project.set-planned')">
                            <label>Plánovaná zakázka</label>
                            <input type="checkbox" :checked="!project.is_public" @change="(val) => project.is_public = !val.target.checked"/>
                        </div>
                        <div class="form-group">
                            <label>Název</label>
                            <input class="form-control" type="text" v-model="project.name" required>
                        </div>
                        <div class="form-group">
                            <label>Popis</label>
                            <textarea class="form-control" v-model="project.note"></textarea>
                        </div>
                        <div class="form-group">
                            <label>Kategorie</label>
                            <select v-model="project.category" class="form-control">
                                <option v-for="item in project_categories" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Zákazník</label>
                            <div class="row">
                                <div class="col-12 col-lg-9">
                                    <app-select ref="clientSelect" auto-load="clients" v-model="project.client_id"/>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <app-button type="button" @click="createClient" slug="btn_createClient" class="btn btn-primary">Nový zákazník</app-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Obec</label>
                            <div class="row">
                                <div class="col-12 col-lg-9">
                                    <app-select ref="citySelect" auto-load="cities" v-model="project.city_id"/>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <app-button type="button" @click="createCity" slug="btn_createCity" class="btn btn-primary">Nová obec</app-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Online tabulka</label>
                            <input class="form-control" type="input" v-model="project.meta.online_document"/>
                        </div>
                        <div class="form-group">
                            <label>Adresa</label>
                            <input class="form-control" type="input" v-model="project.meta.address"/>
                        </div>
                        <div class="form-group">
                            <label>Odkaz na mapu</label>
                            <input class="form-control" type="input" v-model="project.meta.map_link"/>
                        </div>
                        <div class="form-group">
                            <label>Interní zakázka</label>
                            <div>
                                <vue-multiselect placeholder="Vyberte uživatele" :options="users" :searchable="true" label="name" :multiple="true" track-by="id" v-model="selectedUsers"/>
                            </div>
                        </div>

                        <app-button slug="btn_saveProject" type="submit" class="btn btn-primary">Uložit</app-button>
                        <app-button type="button" slug="btn_deleteProject" class="btn-danger" @click="deleteProject" v-if="project.id">Smazat zakázku</app-button>
                    </form>
                </div>
            </div>


        </template>
    </div>
</template>

<script>
import clientService from "@services/clientService";
import projectService from "@services/projectService";
import cityService from "@services/cityService";

export default {
    name: "project_edit",
    mounted() {
        let project_id = this.$route.params.project_id ?? null;
        this.project_id = project_id;

        this.fetchData();
    },
    methods: {

        deleteProject() {

            let c = confirm("Opravdu smazat zakázku?");
            if (c) {
                c = prompt("Napište slovo SMAZAT");
                if (c == "SMAZAT") {
                    this.setLoading("btn_deleteProject");

                    let data = {
                        project_id: this.project_id
                    };

                    projectService.deleteProject(data).then(res => {
                        this.$toast.success("Zakázka smazána.");
                        this.$router.push({name: "project_index"});
                    }).catch(e => {
                        this.showError(e);
                    }).finally(r => {
                        this.unsetLoading("btn_deleteProject");
                    });
                } else {
                    this.$toast.error("Zakázka nesmazána.");
                }
            }

        },

        submit(e) {
            let data = {
                name: this.project.name,
                client_id: this.project.client_id,
                note: this.project.note,
                category: this.project.category,
                finished_at: this.project.finished_at,
                id: this.project_id,
                meta: this.project.meta,
                city_id: this.project.city_id,
                is_public: this.project.is_public,
                selected_users: this.selectedUsers.map(i => i.id)
            };

            this.setLoading("btn_saveProject");
            projectService.updateProject(data).then(res => {
                this.$toast.success("Zakázka uložena");
                this.$router.push({name: "project_show", params: {project_id: res.data.project_id}});
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_saveProject");
            });
        },

        fetchData() {
            let promisese = [];
            this.pageLoading = true;

            let payload = {
                project_id: this.project_id,
            };
            let p1 = projectService.getProjectCosts(payload).then(res => {
                let data = res.data;

                let project = this.transformMeta(data.project);
                let project_users = data.project_users;

                if (project.finished_at) {
                    project.finished_at = this.$moment(project.finished_at).format("YYYY-MM-DD");
                }

                project.meta = this.transformMeta(project.meta ?? {});
                project.city_id = project.city_id ?? null;
                project.is_public = project.is_public ?? true;
                project.client_id = project.client_id ?? null;
                project.category = project.category ?? 1;

                if (project_users.length) {

                    this.selectedUsers = project_users.map(i => {
                        return {
                            id: i.id,
                            name: i.name_with_position,
                        }
                    });

                } else {
                    this.selectedUsers = [];
                }

                this.project = project;

                let cats = data.project_categories;
                this.project_categories = Object.keys(cats).map(key => {
                    return {
                        id: key,
                        name: cats[key],
                    }
                });
            }).catch(e => {
                this.showError(e);
            });
            promisese.push(p1);

            Promise.all(promisese).finally(res => {
                this.pageLoading = false;
            });

        },

        createClient() {
            let p = prompt("Název zákazníka");
            if (p) {
                let data = {
                    item: {name: p}
                };
                this.setLoading("btn_createClient");
                clientService.clientUpdate(data).then(res => {
                    let cid = res.data.client_id;

                    this.$refs.clientSelect.loadClients().then(r => {
                        this.project.client_id = cid;
                        this.$toast.success("Zákazník uložen");
                    }).catch(e => {
                        this.showError(e);
                    }).finally(r => {
                        this.unsetLoading("btn_createClient");
                    });

                }).catch(e => {
                    this.showError(e);
                    this.unsetLoading("btn_createClient");
                });

            }
        },

        createCity() {
            let p = prompt("Název obce");
            if (p) {
                let data = {
                    name: p
                };
                this.setLoading("btn_createCity");
                cityService.createCity(data).then(res => {
                    let cid = res.data.city_id;

                    this.$refs.citySelect.loadCities().then(res => {
                        this.project.city_id = cid;
                        this.$toast.success("Obec uložena");
                    }).catch(e => {
                        this.showError(e);
                    }).finally(r => {
                        this.unsetLoading("btn_createCity");
                    });

                }).catch(e => {
                    this.showError(e);
                    this.unsetLoading("btn_createCity");
                });

            }
        },

    },

    computed: {

        users: function () {
            return this.removeReactivity(this.$store.state.allUsers).map(item => {
                return {
                    id: item.id,
                    name: item.name_with_position,
                }
            });
        },

    },

    data() {
        return {
            newClientId: 99999999,
            pageLoading: true,
            project_id: null,
            project: {
                city_id: null,
                meta: {},
                is_public: true,
                client_id: null,
                category: 1,
            },
            clients: [],
            project_categories: [],
            selectedUsers: []
        }
    }
}
</script>