<template>
    <div>
        <rent-modal ref="rentModal"/>
        <div class="row">
            <div class="col-6">
                <h2>Správa majetku</h2>
            </div>
            <div class="col-6 flex-buttons">
                <router-link v-if="userCan('things.create')" :to="{name: 'thing_create'}" class="btn btn-primary">
                    Nová položka
                </router-link>
            </div>
        </div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>ID</th>
                        <th>Název</th>
                        <th>Popis</th>
                        <th>Evidenční číslo</th>
                        <th>Používá se</th>
                        <template v-if="isAdmin()">
                            <th>Nákupní cena</th>
                            <th>Koupeno</th>
                        </template>
                        <th></th>
                    </tr>
                    <tr v-for="item in items">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.text }}</td>
                        <td>{{ item.meta?.internal_number }}</td>
                        <td>{{ item.meta?.still_used == "yes" ? "Ano" : "Ne" }}</td>
                        <template v-if="isAdmin()">
                            <td>{{ item.meta?.price ? item.meta?.price + " Kč" : "" }}</td>
                            <td>
                                {{ localizeDate(item?.meta?.bought_at) }}
                            </td>
                        </template>
                        <td class="flex-buttons">
                            <button @click="$refs.rentModal.open({thing_id: item.id})" class="btn btn-primary">
                                Půjčit si
                            </button>
                            <router-link v-if="isAdmin()" :to="{name: 'thing_create', params: {thing_id: item.id}}" class="btn btn-danger">
                                Upravit
                            </router-link>
                        </td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import thingService from "@services/thingService";
import RentModal from "@components/rentModal";

export default {
    name: "thing_index",
    components: {RentModal},
    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {
            this.pageLoading = true;
            thingService.thingsList().then(res => {
                this.items = res.data.things;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });
        },
    },

    data() {
        return {
            pageLoading: true,
        }
    }
}
</script>