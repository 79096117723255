var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.client_id ? _c('router-link', {
    attrs: {
      "to": {
        name: 'client_detail',
        params: {
          client_id: _vm.client_id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }