<template>
    <div>

        <modal v-if="showNotesModal" @close="showNotesModal = false">
            <personal-notes :user="user.id"/>
        </modal>

        <add-holidays-modal ref="addHolidaysModal" @saved="fetchData"/>
        <add-hours-modal ref="hoursModal" @saved="fetchData"/>
        <modal v-if="changePassModal.visible" @close="changePassModal.visible=false">
            <div class="popup__content">
                <div class="popup__control">
                    <div class="popup__label">Nové heslo:</div>
                    <input class="form-control" type="password" v-model="changePassModal.pass">
                </div>
                <div class="popup__control">
                    <div class="popup__label">Nové heslo pro kontrolu:</div>
                    <input class="form-control" type="password" v-model="changePassModal.pass2">
                </div>

                <div v-if="passFail">
                    <br>
                    <div class="alert alert-danger">Hesla se neshodují</div>
                </div>
            </div>
            <div class="popup__buttons">
                <app-button slug="btn_changePass" type="submit" class="btn btn-primary" @click="changePass" :disabled="passFail">
                    Změnit heslo
                </app-button>
                <button type="button" class="btn btn-secondary" @click="changePassModal.visible = false">Zrušit</button>
            </div>
        </modal>
        <modal v-if="holidayFundModal.visible" @close="holidayFundModal.visible=false" class="popup--large">
            <h3>Fond dovolené</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 100px">Rok</th>
                        <th style="width: 100px">Fond dní</th>
                        <th>Poznámka</th>
                    </tr>
                </thead>
                <tr v-for="item in user_holiday_funds">
                    <td>
                        <input class="form-control" type="text" v-model="item.year"/>
                    </td>
                    <td>
                        <input class="form-control" type="text" v-model="item.fund"/>
                    </td>
                    <td>
                        <input class="form-control" type="text" v-model="item.note"/>
                    </td>
                </tr>
            </table>
            <div class="popup__buttons">
                <button type="button" class="btn btn-primary" @click="addHolidayFund">Přidat řádek</button>
                <app-button slug="btn_saveHolidayFunds" type="button" class="btn btn-primary" @click="saveHolidayFunds">
                    Uložit
                </app-button>
                <button type="button" class="btn btn-secondary" @click="holidayFundModal.visible = false">Zrušit</button>
            </div>
        </modal>

        <modal v-if="advanceModal.visible" @close="advanceModal.visible=false">
            <div class="popup__content">
                <div class="popup__control">
                    <div class="popup__label">Den zálohy:</div>
                    <app-date v-model="advanceModal.day"/>
                </div>
                <div class="popup__control">
                    <div class="popup__label">Částka:</div>
                    <input class="form-control" type="number" v-model="advanceModal.amount">
                </div>
                <div class="popup__control">
                    <div class="popup__label">Popis:</div>
                    <textarea class="form-control" v-model="advanceModal.desc" style="width: 100%" rows="5"></textarea>
                </div>
            </div>
            <div class="popup__buttons">
                <app-button slug="btn_addAdvance" type="submit" class="btn btn-primary" @click="advanceModalSave">
                    Uložit
                </app-button>
                <button type="button" class="btn btn-secondary" @click="advanceModal.visible = false">Zrušit</button>
                <app-button v-if="advanceModal.item_id" slug="btn_addAdvance" type="submit" class="btn btn-danger" @click="advanceModalSave({delete_item: true})">
                    Smazat
                </app-button>
            </div>
        </modal>

        <page-loader v-if="loading"/>
        <template v-else>

            <div class="row align-items-center no-print">
                <div class="col-12 col-lg-6"></div>
                <div class="col-12 col-lg-6 flex-buttons">
                    <a v-if="canAddHolidaysToUser(user_id)" @click="editSickDay()" class="btn btn-primary">Zadat dovolenou</a>
                    <a v-if="userCan('profile-add-advance')" @click="editAdvance()" class="btn btn-primary">Zadat zálohu</a>
                    <a @click="changePassModal.visible = true" class="btn btn-danger" v-if="user_id == $store.state.user.id">Změnit moje heslo</a>
                    <a @click="print" class="btn btn-secondary">Tisk</a>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <h2>Detail uživatele - {{ user.name }} - {{ currentDate }}</h2>

                    <button v-if="userCan('office')" class="btn btn-secondary no-print" @click="showNotes">Zobrazit poznámky</button>

                </div>
                <div class="col-12 col-lg-6 d-flex justify-content-end no-print">
                    <div class="report_arrows">
                        <router-link class="report_arrow" :to="prevMonth">&lAarr;</router-link>
                        <div class="report_label">{{ currentDate }}</div>
                        <router-link class="report_arrow" :to="nextMonth">&rAarr;</router-link>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h3>Odpracované hodiny</h3>
                    <div class="table-wrapper">
                        <table class="table">
                            <tr>
                                <th>V pracovních dnech:</th>
                                <td width="180">{{ workingStats.week }} h</td>
                            </tr>
                            <tr>
                                <th>V sobotu:</th>
                                <td class="td--saturday">{{ workingStats.saturday }} h</td>
                            </tr>
                            <tr>
                                <th>V neděli:</th>
                                <td class="td--sunday">{{ workingStats.sunday }} h</td>
                            </tr>
                            <tr>
                                <th>Ve státních svátcích:</th>
                                <td class="td--holiday">{{ workingStats.holiday }} h</td>
                            </tr>
                            <tr>
                                <th>Dohromady odpracováno:</th>
                                <td>
                                    <b>{{ userAllHours }} h</b>
                                </td>
                            </tr>
                            <tr v-if="notContractor">
                                <th>Fond pracovních hodin:</th>
                                <td>
                                    {{ workingHoursInCurrentMonth }} h
                                </td>
                            </tr>
                            <tr v-if="notContractor">
                                <th>Odpracováno + DP + §P + N:</th>
                                <td>
                                    {{ totalHoursForCurrentMonth }} h
                                    <span v-if="totalHoursMissingForCurrentMonth">(chybí {{ totalHoursMissingForCurrentMonth }} h)</span>
                                    <span v-if="totalHoursOverForCurrentMonth">(přesčas {{ totalHoursOverForCurrentMonth }} h)</span>
                                </td>
                            </tr>
                        </table>
                        <holiday-legend type="spent"/>
                    </div>

                    <div class="no-print">
                        <h3>Zálohy</h3>

                        <div class="table-wrapper" v-if="advances.length">
                            <table class="table">
                                <tr>
                                    <th>Den zálohy</th>
                                    <th>Částka</th>
                                    <th>Popis</th>
                                    <th></th>
                                </tr>
                                <tr v-for="item in advances">
                                    <td>
                                        {{ localizeDate(item.target_date) }}
                                    </td>
                                    <td>{{ item.amount.formatCurrency() }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>
                                        <button v-if="userCan('profile-edit-advance')" class="btn btn-danger" @click="editAdvance(item)">Upravit</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Celkem</b></td>
                                    <td><b>{{ advanceTotal.formatCurrency() }}</b></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                        <div v-else>Žádné zálohy nebyly vystaveny</div>
                    </div>

                </div>

                <div class="col-12 col-lg-6" :class="{'no-print': !notContractor}">
                    <h3>Dovolené</h3>

                    <div class="table-wrapper" v-if="currentMonthHolidays.length">
                        <table class="table">
                            <tr>
                                <th width="200">Termín</th>
                                <th>Typ</th>
                                <th>Popis</th>
                                <th></th>
                            </tr>
                            <tr v-for="item in currentMonthHolidays">
                                <td class="td--userHoliday-label">
                                    {{ item.range_label }} ({{ item?.working_days_spent_hours ?? 0 }}h)
                                </td>
                                <td>{{ item.type_label }}</td>
                                <td>{{ item.description }}</td>
                                <td>
                                    <button v-if="canEditHolidays(item)" class="btn btn-danger no-print" @click="editSickDay(item)">Upravit</button>
                                </td>
                            </tr>
                            <tr v-if="currentMonthPaidHolidays || currentMonthNotPaidHolidays">
                                <td>Tento měsíc na dovolené</td>
                                <td colspan="3">
                                    {{ currentMonthPaidHolidays }} placených hodin
                                    <br> {{ currentMonthNotPaidHolidays }} neplacených hodin
                                </td>
                            </tr>
                            <tr v-if="paidParagraphTotal || notPaidParagraphTotal">
                                <th>Paragraf</th>
                                <td colspan="3">
                                    {{ paidParagraphTotal }} placených hodin
                                    <br> {{ notPaidParagraphTotal }} neplacených hodin
                                </td>
                            </tr>
                            <tr v-if="sickdayTotal">
                                <th>Nemocenská</th>
                                <td colspan="3">
                                    {{ sickdayTotal }} hodin
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else>Žádné dovolené v tomto měsíci</div>
                    <div class="no-print" v-if="notContractor">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <h3>Souhrn dovolené {{ holiday_data.year }}</h3>
                            </div>
                            <div class="col-6 flex-buttons">
                                <button v-if="isAdmin()" class="btn btn-secondary" @click="holidayFundModal.visible = true">Fond dovolené</button>
                            </div>
                        </div>
                        <div class="table-wrapper">
                            <table class="table">
                                <tr>
                                    <td>Celkový fond</td>
                                    <td>{{ (holiday_data?.fund_hours / 8).toFixed(1) }} dní ({{ holiday_data?.fund_hours }} h)</td>
                                    <td>{{ holiday_data?.fund_hours_note }}</td>
                                </tr>
                                <tr>
                                    <td>Vyčerpáno</td>
                                    <td>{{ (holiday_data?.current_year_spent_hours / 8).toFixed(1) }} dní ({{ holiday_data?.current_year_spent_hours }} h)</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Můžete čerpat</td>
                                    <td>{{ (restPaidHours / 8).toFixed(1) }} dní ({{ restPaidHours }} h)</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pagebreak"></div>

            <div class="print-sheet">
                <h3>Zakázky</h3>
                <holiday-legend/>
                <div class="table-wrapper">
                    <table class="table table-striped table--spenthours">
                        <thead>
                            <tr>
                                <th class="table--project-title">Zakázka</th>
                                <th v-for="(day, index) in daysInMonth" :class="{'th--today': day.isToday}">
                                    {{ day.index }}.
                                </th>
                                <th>Celkem</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Celkem</b></td>
                                <td v-for="(day, index) in daysInMonth" :class="getDayClass(day, index)">
                                    <holiday-cell :item="findHoliday(day)"/>
                                    <b><a class="no-underline">{{ spentHours?.[day.key] || "-" }}</a>
                                    </b>
                                </td>
                                <td><b>{{ spentHours?.totalHours }}</b></td>
                            </tr>
                            <tr v-for="project in daysByProject">
                                <td class="table--project-title">
                                    <project-link v-if="project?.id" :item="project" :hide-client="true"/>
                                    <div v-else>-</div>
                                </td>
                                <td v-for="(day, index) in daysInMonth" :class="getDayClass(day, index)">
                                    <holiday-cell :item="findHoliday(day)"/>
                                    <a class="no-underline" @click="updateHours(project.id, day.key)">{{ project?.spentHours?.[index] || "-" }}</a>
                                </td>
                                <td>{{ project.totalHours }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
import userService from "@services/userService";
import AddHoursModal from "@components/addHoursModal";
import AddHolidaysModal from "@components/addHolidaysModal";
import PersonalNotes from "@components/personalNotes";
import HolidayLegend from "@components/holidayLegend";

export default {
    name: "user_show",
    components: {HolidayLegend, PersonalNotes, AddHolidaysModal, AddHoursModal},
    mounted() {
        this.user_id = this.$route.params.user_id ?? this.$store.state.user.id;
        this.filterDate = this.$route.params.date ?? this.$moment().format("YYYY-MM");

        if (!this.isThisUserMe(this.user_id) && !this.userCan("view-another-user")) {
            this.$router.push({name: "homepage"});
            return;
        }

        this.fetchData();
    },

    methods: {

        showNotes() {
            this.showNotesModal = true;
        },

        findHoliday(day) {
            let day_key = day.key;
            return this.holiday_data.current_month_holidays_per_day.find(item => item.day == day_key);
        },

        addHolidayFund() {
            this.user_holiday_funds.push({year: this.$moment().format('YYYY'), fund: '20', note: ''})
        },

        saveHolidayFunds() {

            let data = {
                user_id: this.user.id,
                items: this.user_holiday_funds
            };

            this.setLoading("btn_saveHolidayFunds");
            userService.saveHolidayFunds(data).then(res => {
                this.fetchData();
                this.$toast.success("Uloženo!");
                this.holidayFundModal.visible = false;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_saveHolidayFunds");
            });
        },

        canEditHolidays(item) {
            if (this.isAdmin()) {
                return true;
            }

            return (!item?.in_progress?.did_start || !item?.in_progress?.did_end);
        },

        updateHours(project_id, day) {
            let item = this.user.hours.find(i => i.project_id == project_id && i.day == day);
            if (item) {
                this.$refs.hoursModal.view(item);
            }
        },

        changePass() {

            let data = {
                pass: this.changePassModal.pass,
            };

            this.setLoading("btn_changePass");
            userService.changeMyPass(data).then(res => {
                this.$toast.success("Heslo změněno!");
                this.changePassModal.pass = "";
                this.changePassModal.pass2 = "";
                this.changePassModal.visible = false;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_changePass");
            });

        },

        print() {
            window.print();
        },

        editAdvance(item = {}) {
            this.advanceModal.item_id = item?.id;
            this.advanceModal.day = this.$moment(item?.target_date).format("YYYY-MM-DD");
            this.advanceModal.desc = item?.meta?.description;
            this.advanceModal.amount = item?.meta?.amount;
            this.advanceModal.visible = true
        },

        editSickDay(item = {}) {

            // if (!item.id) {
                item = {
                    ...item,
                    user_id: this.user_id,
                    user: this.user
                }
            // }

            this.$refs.addHolidaysModal.open(item);
        },

        getDayClass(day, index) {

            let classes = [];
            let day_class = this.renderDayClass(day);
            if (day_class)
                classes.push(day_class);

            // if (this.allUserHolidays?.[day.key])
            //     classes.push("td--userHoliday");

            return classes.join(" ");
        },

        fetchData() {

            let payload = {
                user_id: this.user_id,
                date: this.filterDate
            };

            this.loading = true;
            userService.getUserReport(payload).then(res => {
                let data = res.data;
                this.user = data.user;
                this.user_advances = data.user_advances;
                this.holiday_data = data.holiday_data;
                this.loading = false;

                this.user_holiday_funds = data.user.holiday_fund ?? [];

            }).catch(e => {
                this.showError(e);
            });

        },

        advanceModalSave(atts = {}) {
            let {delete_item} = atts;

            let payload = {
                user_id: this.user_id,
                amount: this.advanceModal.amount,
                day: this.advanceModal.day,
                description: this.advanceModal.desc,
                item_id: this.advanceModal.item_id,
                delete_item,
            };

            this.setLoading("btn_addAdvance");
            userService.addAdvance(payload).then(res => {
                this.fetchData();
                this.advanceModal.visible = false;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_addAdvance");
            });

        },
    },

    computed: {

        notContractor: function () {
            return !this.user?.contractor;
        },

        hoursBilanceForCurrentMonth: function () {
            return this.workingHoursInCurrentMonth - this.totalHoursForCurrentMonth;
        },

        totalHoursMissingForCurrentMonth: function () {
            let bilance = this.hoursBilanceForCurrentMonth;
            if (bilance < 0) {
                return 0;
            }
            return bilance;
        },

        totalHoursOverForCurrentMonth: function () {
            let bilance = this.hoursBilanceForCurrentMonth;
            if (bilance < 0) {
                return bilance * -1;
            }
            return 0;
        },

        totalHoursForCurrentMonth: function () {
            return this.userAllHours + this.currentMonthPaidHolidays + this.paidParagraphTotal + this.sickdayTotal;
        },

        sickdayTotal: function () {
            return this.removeReactivity(this.currentMonthHolidays).filter(p => p.type == 'sickday').map(item => {
                return item?.working_days_in_current_month?.spent_hours ?? 0;
            }).sum();

            return this.currentMonthHolidays.filter(item => item.type == 'sickday').map(item => parseFloat(item.hours)).sum();
        },

        paidParagraphTotal: function () {
            return this.removeReactivity(this.currentMonthHolidays).filter(p => p.paid && p.type == 'paragraph').map(item => {
                return item?.working_days_in_current_month?.spent_hours ?? 0;
            }).sum();

            return this.currentMonthHolidays.filter(item => item.type == 'paragraph' && item.paid).map(item => parseFloat(item.hours)).sum();
        },

        notPaidParagraphTotal: function () {
            return this.removeReactivity(this.currentMonthHolidays).filter(p => !p.paid && p.type == 'paragraph').map(item => {
                return item?.working_days_in_current_month?.spent_hours ?? 0;
            }).sum();

            // return this.currentMonthHolidays.filter(item => item.type == 'paragraph' && !item.paid).map(item => parseFloat(item.hours)).sum();
        },

        spentHours: function () {
            let out = {};
            let total = 0;
            this.daysInMonth.forEach((day, index) => {
                this.daysByProject.forEach(project => {
                    let h = parseFloat(project?.spentHours?.[index] || 0);

                    if (!out?.[day.key]) {
                        out[day.key] = 0;
                    }

                    out[day.key] += h;
                    total += h;
                });
            });
            out.totalHours = total;
            return out;
        },

        workingHoursInCurrentMonth() {
            return this.holiday_data?.working_hours_current_month ?? 0;
        },

        currentMonthPaidHolidays() {
            return this.removeReactivity(this.currentMonthHolidays).filter(p => p.paid && p.type == 'holiday').map(item => {
                return item?.working_days_in_current_month?.spent_hours ?? 0;
            }).sum();
        },

        currentMonthNotPaidHolidays() {
            return this.removeReactivity(this.currentMonthHolidays).filter(p => !p.paid && p.type == 'holiday').map(item => {
                return item?.working_days_in_current_month?.spent_hours ?? 0;
            }).sum();
        },

        currentMonthHolidays() {
            return (this.holiday_data?.current_month_holidays ?? []);
        },

        prevMonth() {
            let routeName = this.$route.name == "user_profile" ? "user_profile" : "user_show";
            return {name: routeName, params: {date: this.prev_date}};
        },

        nextMonth() {
            let routeName = this.$route.name == "user_profile" ? "user_profile" : "user_show";
            return {name: routeName, params: {date: this.next_date}};
        },

        restPaidHours() {
            return (this.holiday_data.fund_hours ?? 0) - (this.holiday_data?.current_year_spent_hours ?? 0);
        },

        prev_date() {
            let date = this.$moment(this.filterDate).add(-1, 'months');
            return date.format("YYYY-MM");
        },

        next_date() {
            let date = this.$moment(this.filterDate).add(1, 'months');
            return date.format("YYYY-MM");
        },

        passFail() {
            return this.changePassModal.pass && (this.changePassModal.pass !== this.changePassModal.pass2);
        },

        userAllHours: function () {
            return this.workingStats.holiday + this.workingStats.saturday + this.workingStats.sunday + this.workingStats.week;
        },

        hoursByProject: function () {
            let projects = {};

            if (!this.user?.hours)
                return [];

            this.user.hours.forEach(item => {
                let project = item.project;
                let pid = project.id;
                let day = item.day;
                if (!projects.hasOwnProperty(pid)) {
                    projects[pid] = project;
                    projects[pid].days = {};
                }
                let day_data = item;
                delete (day_data.project);
                projects[pid].days[day] = day_data;
            });
            return Object.values(projects);
        },

        daysByProject: function () {
            let currentDate = this.$moment(this.filterDate);

            this.workingStats = {
                holiday: 0,
                saturday: 0,
                sunday: 0,
                week: 0,
            };

            let hours = this.hoursByProject ?? [];

            if (!hours.length)
                hours = [null];

            return hours.map(project => {
                let allDays = [];
                let total = 0;
                this.daysInMonth.forEach(item => {

                    let {key, isHoliday, isSaturday, isSunday} = item;

                    let hoursThisDay = "";

                    let hasVal = project?.days?.[key] ?? null;
                    if (hasVal) {
                        hoursThisDay = parseFloat(project.days[key].amount);
                        total += hoursThisDay;
                    }

                    if (hoursThisDay) {
                        if (isHoliday) {
                            this.workingStats["holiday"] += hoursThisDay;
                        } else if (isSaturday) {
                            this.workingStats["saturday"] += hoursThisDay;
                        } else if (isSunday) {
                            this.workingStats["sunday"] += hoursThisDay;
                        } else {
                            this.workingStats["week"] += hoursThisDay;
                        }
                    }

                    allDays.push(hoursThisDay);
                });

                if (project) {
                    project.spentHours = allDays;
                    project.totalHours = total;
                    return project;
                }

                return {}
            });
        },

        daysInMonth: function () {

            let today = this.$moment().format("YYYY-MM-DD");

            let days = [];
            let count = this.$moment(this.filterDate).daysInMonth();
            for (let i = 1; i <= count; i++) {
                let $day = this.$moment(this.filterDate).date(i);
                let dayOfWeek = $day.day();
                let dayKey = $day.format("YYYY-MM-DD");
                let isSaturday = dayOfWeek == 6;
                let isSunday = dayOfWeek == 0;
                let isToday = dayKey == today;
                let day = {
                    index: i,
                    key: dayKey,
                    isSaturday,
                    isSunday,
                    isToday,
                };
                days.push(day);
            }
            return days;
        },

        currentDate() {
            return this.$moment(this.filterDate).format("MMMM YYYY");
        },

        advances() {
            return this.user_advances.map(e => {
                e.amount = e.meta.amount;
                e.description = e.meta.description;
                return e;
            });
        },

        advanceTotal() {
            let total = 0;
            this.advances.forEach(item => {
                total += parseFloat(item.amount);
            });
            return total;
        },

        allUserHolidays() {
            let dates = {};
            this.holiday_data.current_month_holidays.forEach(e => {
                dates = {
                    ...dates,
                    ...e?.working_days_in_current_month?.items ?? {}
                };
            });
            return dates;
        },

    },

    data() {
        return {
            showNotesModal: false,
            user_id: null,
            user: {},
            user_advances: [],
            holiday_data: {items: []},
            filterDate: null,
            loading: false,
            advanceModal: {
                item_id: null,
                visible: false,
                day: null,
                amount: null,
                desc: null
            },
            changePassModal: {
                visible: false,
                pass: null,
                pass2: null,
                success: null,
            },
            workingStats: {
                holiday: 0,
                weekend: 0,
                week: 0,
            },
            holidayFundModal: {
                visible: false,
            },
            user_holiday_funds: [],
        }
    }
}
</script>
