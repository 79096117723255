var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.rentModalVisible ? _c('rent-modal', {
    ref: "rentModal",
    on: {
      "updated": _vm.fetchData
    }
  }) : _vm._e(), _vm.hoursModalVisible ? _c('add-hours-modal', {
    ref: "addHoursModal"
  }) : _vm._e(), _vm.costModalVisible ? _c('add-cost-modal', {
    ref: "addCostModal",
    on: {
      "updated": _vm.fetchData
    }
  }) : _vm._e(), _vm.closeProjectModalVisible ? _c('close-project-modal', {
    ref: "closeProjectModal",
    on: {
      "updated": _vm.fetchData
    }
  }) : _vm._e(), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "buttons"
  }, [_c('a', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addHours
    }
  }, [_vm._v("Zapsat dnešní hodiny")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addCostModal
    }
  }, [_vm._v("Doklad / dodací list")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addGasReceipt
    }
  }, [_vm._v("Tankování")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.openCloseProjectModal
    }
  }, [_vm._v("Návrh na uzavření zakázky")])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-3"
  }, [_c('h3', [_vm._v("Úkoly pro mě")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_vm.opened_tasks.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Od koho")]), _c('th', [_vm._v("Vytvořeno")])]), _vm._l(_vm.opened_tasks, function (item) {
    return _c('tr', [_c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'task_show',
          params: {
            task_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('td', [_vm._v(_vm._s(item.created_by_user.name))]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.created_at)) + " ")])]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)]), _vm.isAdmin() ? [_c('h3', [_vm._v("Úkoly mnou zadané")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_vm.tasks_to_check.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Pro koho")]), _c('th', [_vm._v("Stav")]), _c('th', [_vm._v("Vytvořeno")])]), _vm._l(_vm.tasks_to_check, function (item) {
    return _c('tr', [_c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'task_show',
          params: {
            task_id: item.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('td', [_vm._v(_vm._s(item.created_for_user.name))]), _c('td', [_c('div', {
      class: [item.state == 'open' ? 'task--open' : 'task--check']
    }, [_vm._v(" " + _vm._s(item.nice_status) + " ")])]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.created_at)) + " ")])]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)])] : _vm._e()], 2), _vm.isAdmin() ? _c('div', {
    staticClass: "col-12 col-lg-3"
  }, [_vm.isAdmin() && _vm.userCan('offices') ? _c('DashboardAdvancesList') : _vm._e(), _vm.isAdmin() ? [_c('h3', [_vm._v("Zapůjčený majetek")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_vm.rented_things.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Předmět")]), _c('th', [_vm._v("Zapůjčeno")]), _c('th', [_vm._v("Popis")]), _c('th')]), _vm._l(_vm.rented_things, function (item) {
    var _item$thing;

    return _c('tr', [_c('td', [_vm._v(_vm._s((_item$thing = item.thing) === null || _item$thing === void 0 ? void 0 : _item$thing.name))]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.rented_at)) + " ")]), _c('td', [_vm._v(_vm._s(item.text))]), _c('td', {
      staticClass: "flex-buttons"
    }, [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function ($event) {
          return _vm.openRentModal(item);
        }
      }
    }, [_vm._v("Vrátit")])])]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)]), _vm.notVerifiedItems && _vm.isAdmin() ? [_c('h3', [_vm._v("Neverifikované zakázky")]), _c('div', {
    staticClass: "hp__check error"
  }, [_vm._v(" " + _vm._s(_vm.notVerifiedItems) + " ")])] : _vm._e()] : _vm._e()], 2) : _vm._e(), _vm.isAdmin() ? _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('DashboardNotApprovedCosts'), _c('h3', [_vm._v("Upozornění")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_vm.unprocessedEvents.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Poznámka")]), _c('th', [_vm._v("Upozornit")]), _c('th', [_vm._v("Týká se")])]), _vm._l(_vm.unprocessedEvents, function (event) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(event.text))]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(event.notify_at)) + " ")]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'car_detail',
          params: {
            car_id: event.car.id
          }
        }
      }
    }, [_vm._v(_vm._s(event.car.fullname))])], 1)]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)])], 1) : _vm._e()]), _c('br'), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('DashboardWipProjects')], 1), _vm.userCan('office_and_mister') ? _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Návrhy na uzavření zakázek")]), _vm.closingProposals.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_c('tr', [_c('th', [_vm._v("Název")]), _c('th', [_vm._v("Podal")]), _c('th', [_vm._v("Podáno")]), _c('th')]), _vm._l(_vm.closingProposals, function (item) {
    return _c('tr', [_c('td', [_c('project-link', {
      attrs: {
        "item": item.project
      }
    })], 1), _c('td', [_c('user-link', {
      attrs: {
        "item": item.user
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(item.created_at)) + " ")]), _c('td', [_vm.userCan('create-closing-proposal') ? _c('app-button', {
      staticClass: "btn btn-success",
      attrs: {
        "slug": 'btn_dismisProposal_' + item.id
      },
      on: {
        "click": function ($event) {
          return _vm.dismisProposal(item);
        }
      }
    }, [_vm._v("Smazat návrh")]) : _vm._e()], 1)]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)]) : _vm._e()])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }