var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    attrs: {
      "to": {
        name: 'task_show',
        params: {
          task_id: _vm.item.id
        }
      }
    }
  }, [_vm.showMe ? _c('span', {
    attrs: {
      "title": "Můj úkol"
    }
  }, [_vm._v("⭐")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.item.name) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }