var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("Měsíční report")]), _c('users-nav'), _c('div', {
    staticClass: "report_arrows"
  }, [_c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": _vm.prevMonth
    }
  }, [_vm._v("⇚")]), _c('div', {
    staticClass: "report_label"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": _vm.nextMonth
    }
  }, [_vm._v("⇛")])], 1), _vm.pageLoading ? _c('page-loader') : [_c('holiday-legend'), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("Jméno")]), _vm._l(_vm.daysInMonth, function (day, index) {
    return _c('th', {
      class: {
        'th--today': day.isToday
      }
    }, [_vm._v(" " + _vm._s(day.index) + ". ")]);
  }), _c('th', [_vm._v("C(h)")]), _c('th', [_vm._v("C(D+§)")]), _c('th', [_vm._v("C")])], 2), _vm._l(_vm.users, function (user) {
    var _vm$tableGrid2, _vm$tableGrid2$items, _vm$tableGrid2$items$, _vm$tableGrid3, _vm$tableGrid3$items, _vm$tableGrid3$items$, _vm$tableGrid4, _vm$tableGrid4$items, _vm$tableGrid4$items$;

    return _c('tr', [_c('td', [_c('user-link', {
      attrs: {
        "item": user
      }
    })], 1), _vm._l(_vm.daysInMonth, function (item) {
      var _vm$tableGrid, _vm$tableGrid$items, _vm$tableGrid$items$u;

      return _c('td', {
        class: _vm.localRenderDayClass(item, user)
      }, [_c('holiday-cell', {
        attrs: {
          "item": _vm.findHoliday(user, item)
        }
      }), _c('span', {
        domProps: {
          "innerHTML": _vm._s((_vm$tableGrid = _vm.tableGrid) === null || _vm$tableGrid === void 0 ? void 0 : (_vm$tableGrid$items = _vm$tableGrid.items) === null || _vm$tableGrid$items === void 0 ? void 0 : (_vm$tableGrid$items$u = _vm$tableGrid$items[user.user_id]) === null || _vm$tableGrid$items$u === void 0 ? void 0 : _vm$tableGrid$items$u[item.key])
        }
      })], 1);
    }), _c('td', [_vm._v(_vm._s((_vm$tableGrid2 = _vm.tableGrid) === null || _vm$tableGrid2 === void 0 ? void 0 : (_vm$tableGrid2$items = _vm$tableGrid2.items) === null || _vm$tableGrid2$items === void 0 ? void 0 : (_vm$tableGrid2$items$ = _vm$tableGrid2$items[user.user_id]) === null || _vm$tableGrid2$items$ === void 0 ? void 0 : _vm$tableGrid2$items$.spent_hours_total))]), _c('td', [_vm._v(_vm._s((_vm$tableGrid3 = _vm.tableGrid) === null || _vm$tableGrid3 === void 0 ? void 0 : (_vm$tableGrid3$items = _vm$tableGrid3.items) === null || _vm$tableGrid3$items === void 0 ? void 0 : (_vm$tableGrid3$items$ = _vm$tableGrid3$items[user.user_id]) === null || _vm$tableGrid3$items$ === void 0 ? void 0 : _vm$tableGrid3$items$.spent_holidays_total))]), _c('td', [_vm._v(_vm._s((_vm$tableGrid4 = _vm.tableGrid) === null || _vm$tableGrid4 === void 0 ? void 0 : (_vm$tableGrid4$items = _vm$tableGrid4.items) === null || _vm$tableGrid4$items === void 0 ? void 0 : (_vm$tableGrid4$items$ = _vm$tableGrid4$items[user.user_id]) === null || _vm$tableGrid4$items$ === void 0 ? void 0 : _vm$tableGrid4$items$.spent_total))])], 2);
  })], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }