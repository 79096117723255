var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [_vm._v("Dlužné částky")]), _c('div', {
    staticClass: "table-wrapper"
  }, [_vm.computedItems.length ? _c('table', {
    staticClass: "table table-striped"
  }, [_vm._m(0), _vm._l(_vm.computedItems, function (item) {
    var _item$total;

    return _c('tr', [_c('td', [_c('user-link', {
      attrs: {
        "item": item.user
      }
    })], 1), _c('td', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$total = item.total) === null || _item$total === void 0 ? void 0 : _item$total.formatCurrency()))])]);
  })], 2) : _c('div', {
    staticClass: "hp__check"
  }, [_c('Icon', {
    attrs: {
      "name": "check"
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("Uživatel")]), _c('th', [_vm._v("Celková částka")])]);
}]

export { render, staticRenderFns }