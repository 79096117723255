var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    on: {
      "close": _vm.close
    }
  }, [_c('h3', [_vm._v("Návrh na uzavření zakázky")]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Zakázka")]), _c('app-select', {
    attrs: {
      "auto-load": "projects"
    },
    model: {
      value: _vm.pid,
      callback: function ($$v) {
        _vm.pid = $$v;
      },
      expression: "pid"
    }
  })], 1), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_createProposal"
    },
    on: {
      "click": _vm.createProposal
    }
  }, [_vm._v("Odeslat")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }