export default {
    methods: {

        localizeDate(value, format= "DD. MM. YYYY v HH:mm") {

            if (value) {
                // Convert the UTC time to local time
                return this.$moment.utc(value).local().format(format);
            }
            return value;

        }

    }
}