<template>
    <form @submit.prevent="save" class="popup" v-show="visible">
        <div class="popup__wrapper">
            <div class="popup__background" @click="close"></div>
            <div class="popup__body">
                <div class="popup__content">

                    <div v-if="item.selectType">
                        <button type="button" class="btn btn-secondary" :class="{'btn-primary':item.type == 'holiday'}" @click="setType('holiday')">Dovolená</button>
                        <button v-if="!user?.contractor" type="button" class="btn btn-secondary" :class="{'btn-primary':item.type == 'sickday'}" @click="setType('sickday')">Nemoc</button>
                        <button v-if="!user?.contractor" type="button" class="btn btn-secondary" :class="{'btn-primary':item.type == 'paragraph'}" @click="setType('paragraph')">Paragraf</button>
                        <br> <br>
                        <button type="button" class="btn btn-secondary" :class="{'btn-primary':item.range_type == 'part'}" @click="setRangeType('part')">Část dne</button>
                        <button type="button" class="btn btn-secondary" :class="{'btn-primary':item.range_type == 'day'}" @click="setRangeType('day')">Jeden den</button>
                        <button type="button" class="btn btn-secondary" :class="{'btn-primary':item.range_type == 'days'}" @click="setRangeType('days')">Více dní</button>
                    </div>

                    <template v-if="item.range_type == 'part' || item.range_type == 'day'">
                        <div class="popup__control">
                            <div class="popup__label">Den:</div>
                            <app-date v-model="item.date_from" :disabled-date="maxPreviousDayFrom" :disabled="item.did_start && !isAdmin()"/>
                        </div>
                    </template>
                    <template v-if="hoursSetable">
                        <div class="popup__control">
                            <div class="popup__label">Počet hodin:</div>
                            <input class="form-control" type="number" v-model="item.hours">
                        </div>
                    </template>
                    <template v-if="item.range_type == 'days'">
                        <div class="popup__control">
                            <div class="popup__label">Od kdy:</div>
                            <app-date v-model="item.date_from" :disabled-date="maxPreviousDayFrom" :disabled="item.did_start && !isAdmin()"/>
                        </div>
                        <div class="popup__control">
                            <div class="popup__label">Do kdy:</div>
                            <app-date v-model="item.date_to" :disabled-date="maxPreviousDayTo" :disabled="(item.did_end || !item.date_from) && !isAdmin()"/>
                        </div>
                    </template>
                    <div class="popup__control" v-if="paidHolidaySetable">
                        <div class="popup__label">{{ paidLabel }}:</div>
                        <input type="checkbox" v-model="item.paid" style="width: auto">
                    </div>
                    <div class="popup__control">
                        <div class="popup__label">Popis:</div>
                        <textarea class="form-control" v-model="item.description" style="width: 100%" rows="5" :required="item.type == 'paragraph'"></textarea>
                    </div>
                    <div class="popup__control" v-if="item.type == 'paragraph'">
                        <div class="popup__label">Fotky:</div>
                        <project-files v-if="visible" :allow-upload="true" :handle-upload="false" :files="item.files" :model-id="item.id" model-type="holiday" @imageDeleted="$emit('saved')" @newFiles="files => appendFiles = files"/>
                    </div>
                </div>
                <div class="popup__buttons">
                    <app-button slug="btn_addSickDay" type="submit" class="btn btn-primary">
                        Uložit
                    </app-button>
                    <button type="button" class="btn btn-secondary" @click="close">Zrušit</button>
                    <app-button type="button" v-if="canDelete" slug="btn_deleteSickDay" class="btn btn-danger" @click="deleteItem">
                        <icon name="trash"/>
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import userService from "@services/userService";

export default {
    name: "addHolidaysModal",
    watch: {
        "item.date_from": {
            handler() {
                if (!this.item.date_to)
                    return;

                let from = this.$moment(this.item.date_from);
                let to = this.$moment(this.item.date_to);
                if (from.isAfter(to)) {
                    this.item.date_to = this.item.date_from;
                }

            }
        }
    },

    methods: {

        setRangeType(type) {
            this.item.range_type = type;
        },

        setType(type) {
            this.item.type = type;
            let user = this.user;

            if (type == 'holiday') {
                this.item.paid = user?.contractor ? false : true;
                return;
            }

            if (type == 'sickday') {
                this.item.paid = user?.contractor ? false : true;
                return;
            }

            if (type == 'paragraph') {
                this.item.paid = false;
                return;
            }

            this.item.paid = true;
        },

        open(item) {
            this.user = null;

            if (item.id) {
                item.selectType = false;
            } else {
                item.range_type = "part";
                item.type = "holiday";
                item.selectType = true;
                item.paid = true;

                let now = this.$moment().format("YYYY-MM-DD");
                item.date_from = now;
                item.date_to = now;
            }

            item.did_start = item.in_progress?.did_start ?? false;
            item.did_end = item.in_progress?.did_end ?? false;

            if (!item.id && item?.user?.contractor) {
                item.paid = false;
            }

            this.item = item;
            this.visible = true;
            this.user = item.user ?? null;
        },

        deleteItem() {
            this.setLoading("btn_deleteSickDay");

            let payload = {
                id: this.item.id,
                delete_item: 1,
            };

            let formData = new FormData();
            Object.keys(payload).forEach(key => {
                if (payload[key])
                    formData.append(key, payload[key]);
            });

            userService.addHolidays(formData).then(res => {
                this.$toast.success("Smazáno!");
                this.$emit("saved");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_deleteSickDay");
            });
        },

        save(e) {
            let item = this.item;
            let from = item.date_from;
            let to = item.date_to;
            let hours = null;

            if (item.range_type == "part" || item.range_type == "day") {
                to = from;
            }

            if (item.range_type == "part") {
                hours = this.item.hours ?? 0;
            }

            let payload = {
                id: item.id,
                user_id: item.user_id,
                paid: item.paid ? 1 : 0,
                date_from: from,
                date_to: to,
                description: item.description,
                range_type: item.range_type,
                type: item.type,
                hours,
                new_files: this.appendFiles,
            };

            this.setLoading("btn_addSickDay");
            userService.addHolidays(payload).then(res => {
                this.appendFiles = [];
                this.$toast.success("Uloženo!");
                this.$emit("saved");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_addSickDay");
            });
        },

        close() {
            this.$emit('close');
            this.visible = false;
        },

        maxPreviousDayFrom(day) {
            if (this.isAdmin())
                return false;

            let min = this.$moment().format("YYYY-MM-DD");
            return this.$moment(day).isBefore(min);
        },
        maxPreviousDayTo(day) {
            if (this.isAdmin())
                return false;

            let $day = this.$moment(day);
            let min = this.$moment().add(-1, "days").format("YYYY-MM-DD");

            let from = this.item.date_from;
            let $from = from ? this.$moment(from) : null;

            if ($from && $day.isBefore($from))
                return true;

            if ($day.isBefore(min))
                return true;

            return false;
        },

    },

    computed: {

        canDelete() {
            if (!this.item.id)
                return false;

            if (this.isAdmin() || !this.item.did_start) {
                return true;
            }

            return false;
        },

        paidLabel: function () {
            return this.item.type == "paragraph" ? "Placený paragraf" : "Placená dovolená";
        },

        hoursSetable: function () {

            if (this.item.range_type == 'part')
                return true;

            return false;
        },

        paidHolidaySetable: function () {

            if (this.user?.contractor)
                return false;

            if (this.item.type == 'paragraph') {
                if (this.userCan('update-paragraph') && this.item.id) {
                    return true;
                } else {
                    return false;
                }
            }

            if (this.item.type == 'sickday') {
                return false;
            }

            return true;
        }

    },

    data() {
        return {
            user: null,
            visible: false,
            item: {
                user_id: null,
                day: null,
                hours: 0,
                description: null,
                paid: true,
                id: null,
                range_type: 'part',
                date_from: null,
                date_to: null,
                selectType: true,
                did_start: false,
                did_end: false,
                type: "holiday",
            },
            appendFiles: [],
        }
    }
}
</script>