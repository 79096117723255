<template>
    <div>
        <add-event ref="addEvent" @updated="fetchData"/>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="row">
                <div class="col-6">
                    <h2>Úkol - {{ task.name }}</h2>
                </div>
                <div class="col-6 flex-buttons">
                    <template v-if="task.created_by == this.getUserID()">
                        <app-button v-if="task.state !== 'open'" @click="setState('open')" slug="btn_task_set_open" class="btn btn-secondary">
                            Znovu otevřít úkol
                        </app-button>
                        <app-button v-if="task.state !== 'done'" @click="setState('done')" slug="btn_task_set_done" class="btn btn-success">
                            Označit jako hotový
                        </app-button>
                        <router-link class="btn btn-danger" :to="{name: 'task_create', params: {task_id: task.id}}">Upravit</router-link>
                    </template>
                    <template v-if="task.state == 'open' && task.created_for == this.getUserID()">
                        <div v-if="!task.notes.length">Nejprve přidejte poznámku</div>
                        <app-button @click="setState('check')" slug="btn_task_set_check" class="btn btn-success" :disabled="!task.notes.length">
                            Odeslat ke kontrole
                        </app-button>
                    </template>
                </div>
            </div>
            <div class="task__row">
                <div class="task__label">Stav:</div>
                {{ task.nice_status }}
            </div>
            <div class="task__row">
                <div class="task__label">Zadáno:</div>
                {{ localizeDate(task.created_at) }}
            </div>
            <div class="task__row" v-if="task.state == 'done'">
                <div class="task__label">Dokončeno:</div>
                {{ localizeDate(task.finished_at) }}
            </div>
            <div class="task__row">
                <div class="task__label">Zadal:</div>
                {{ task.created_by_user.name }}
            </div>
            <div class="task__row">
                <div class="task__label">Pověřená osoba:</div>
                {{ task.created_for_user.name }}
            </div>
            <p><b>Zadání:</b> {{ task.text }}</p>
            <hr>

            <div class="flex-buttons mb-2">
                <button v-if="task.state != 'done'" @click="addNote" class="btn btn-primary">Přidat poznámku</button>
            </div>

            <div class="cards">
                <div class="card" v-for="item in task.notes">
                    <div class="card-body row">
                        <div class="col-12 col-lg-6">
                            <h5 class="card-title">{{ item?.user?.name }}</h5>
                            <h6 class="card-subtitle text-muted">{{ localizeDate(item.created_at) }}</h6>
                            <p class="card-text">{{ item.text }}</p>
                        </div>
                        <div class="col-12 col-lg-6">
                            <project-files class="gallery" :files="item.files"/>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
import taskService from "@services/taskService";
import AddEvent from "@components/addEvent";

export default {
    name: "task_show",
    components: {AddEvent},
    mounted() {

        this.task_id = this.$route.params.task_id;
        this.fetchData();

    },

    methods: {

        addNote() {

            this.$refs.addEvent.open({
                type: "note",
                model_id: this.task.id,
                model_type: "task",
            });

        },

        setState(state) {
            let data = {
                task_id: this.task_id,
                state,
            };
            this.setLoading("btn_task_set_" + state);
            taskService.setTaskStatus(data).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_task_set_" + state);
            });
        },

        fetchData() {
            this.pageLoading = true;

            let task_id = this.task_id;
            taskService.getTask(task_id).then(res => {

                this.task = res.data.task;

            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });

        },

    },

    data() {
        return {
            pageLoading: true,
            task: {},
            task_id: null,
        }
    }
}
</script>
