export default {
    methods: {
        removeReactivity(val) {
            if (typeof val == "object") {
                return JSON.parse(JSON.stringify(val));
            }
            return val;
        },

        setLoading(slug, optional_id = null) {
            if (optional_id)
                slug = slug + "_" + optional_id;
            this.$store.commit("setLoading", slug);
        },

        unsetLoading(slug, optional_id = null) {
            if (optional_id)
                slug = slug + "_" + optional_id;
            this.$store.commit("unsetLoading", slug);
        },

        afterLoginActions() {
            this.$store.dispatch("loadMainConfig");
        },

        transformMeta(arr) {

            if (!arr)
                return {};

            return Array.isArray(arr) ? {} : arr;
        },

        getDaysInMonth(config_date) {
            let today = this.$moment().format("YYYY-MM-DD");
            let days = [];
            let count = this.$moment(config_date).daysInMonth();
            for (let i = 1; i <= count; i++) {
                let $day = this.$moment(config_date).date(i);
                let dayOfWeek = $day.day();
                let dayKey = $day.format("YYYY-MM-DD");
                let isSaturday = dayOfWeek == 6;
                let isSunday = dayOfWeek == 0;
                let isToday = dayKey == today;
                let day = {
                    index: i,
                    key: dayKey,
                    isSaturday,
                    isSunday,
                    isToday,
                };
                days.push(day);
            }
            return days;
        },

        renderDayClass(day = {}) {
            if (day.isUserHoliday)
                return "td--userHoliday";

            if (this.$store.state.publicHolidays.includes(day.key))
                return "td--holiday";

            if (day.isSaturday)
                return "td--saturday";

            if (day.isSunday)
                return "td--sunday";
        },

        isMobile() {
            return window.innerWidth <= 991;
        }

    }
}