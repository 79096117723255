var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "viewBox": "0 0 38 38",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke": "#fff"
    }
  }, [_c('g', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "transform": "translate(1 1)",
      "stroke-width": "2"
    }
  }, [_c('circle', {
    attrs: {
      "stroke-opacity": ".5",
      "cx": "18",
      "cy": "18",
      "r": "18"
    }
  }), _c('path', {
    attrs: {
      "d": "M36 18c0-9.94-8.06-18-18-18"
    }
  }, [_c('animateTransform', {
    attrs: {
      "attributeName": "transform",
      "type": "rotate",
      "from": "0 18 18",
      "to": "360 18 18",
      "dur": "1s",
      "repeatCount": "indefinite"
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }