<template>
    <div>
        <h2>Měsíční report dle pozic</h2>
        <users-nav/>
        <add-hours-modal ref="hoursModal"/>

        <div class="report_arrows">
            <router-link :to="{name: 'user_position_report', params: {date: prev_month}}" class="report_arrow">
                &lAarr;
            </router-link>
            <div class="report_label">{{ currentDateLabel }}</div>
            <router-link :to="{name: 'user_position_report', params: {date: next_month}}" class="report_arrow">
                &rAarr;
            </router-link>
        </div>

        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h4>Filtrovat dle pozice</h4>
                    <div class="flex-items">
                        <button @click="showPositions.toggle(item)" v-for="item in positions" class="btn btn-secondary" :class="{'btn-primary': showPositions.includes(item)}">{{ item }}</button>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <h4>Filtrovat dle zakázky</h4>
                    <app-select auto-load="projects" v-model="filter_project_id" :clearable="true"/>
                    <button class="btn btn-primary" v-if="filter_project_id" @click="filter_project_id = null">Vymazat filtr</button>
                </div>
            </div>
            <br>
            <holiday-legend/>
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>Jméno</th>
                        <th v-for="day in daysInMonth"> {{ day.index }}.</th>
                        <th>Celkem</th>
                    </tr>
                    <template v-for="user in allUsers">
                        <tr class="report__user">
                            <td :colspan="(2 + daysInMonth.length)">
                                <h5 class="table-open" @click="openedUsers.toggle(user.id)">
                                    <button class="btn btn-primary">{{ openedUsers.includes(user.id) ? "-" : "+" }}</button>
                                    {{ user.name }}<br> {{ user.positionName }}
                                </h5>
                            </td>
                        </tr>
                        <template v-if="openedUsers.includes(user.id)">
                            <tr class="report__items" v-for="project in user.projects">
                                <td>
                                    <project-link :item="project.project" :hide-client="true"/>
                                </td>
                                <td v-for="day in daysInMonth" :class="localRenderDayClass(day, user)">
                                    <holiday-cell :item="findHoliday(user, day)"/>
                                    <a class="no-underline" @click="openHours(project.hours.find(h => h.day == day.key))">
                                        {{ project.hours.find(h => h.day == day.key)?.amount ?? "-" }}
                                    </a>
                                </td>
                                <td> {{ project.hours?.sum("amount") }}</td>
                            </tr>
                        </template>
                    </template>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import userService from "@services/userService";
import AddHoursModal from "@components/addHoursModal";
import UsersNav from "@components/usersNav";
import HolidayLegend from "@components/holidayLegend";

export default {
    name: "user_position_report",
    components: {HolidayLegend, UsersNav, AddHoursModal},
    mounted() {
        this.fetchData();
    },

    methods: {

        findHoliday(user, day) {
            let day_key = day.key;
            return user.holidays?.find(item => item.day == day_key);
        },

        fetchData() {

            this.pageLoading = true;
            let data = {
                date: this.$route.params.date ?? null,
            };

            userService.getAllUsersAndHoursByMonth(data).then(res => {
                let data = res.data;
                this.currentYM = data.currentYM;
                this.users = data.users;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },

        openHours(item) {

            if (item) {
                this.$refs.hoursModal.view({id: item.id});
            }

        },

        localRenderDayClass(item, user = null) {
            let classes = [];
            let day_class = this.renderDayClass(item);
            if (day_class)
                classes.push(day_class);

            return classes.join(" ");
        },

    },

    computed: {

        currentDateLabel() {
            if (!this.currentDate)
                return "";

            return this.$moment(this.currentDate).format("MMMM YYYY");
        },

        prev_month() {
            return this.currentDate?.clone()?.subtract(1, "month").format("YYYY-MM");
        },
        next_month() {
            return this.currentDate?.clone()?.add(1, "month").format("YYYY-MM");
        },

        currentDate() {
            if (this.currentYM) {
                return this.$moment(this.currentYM, "YYYY-MM");
            }
            return null;
        },

        daysInMonth: function () {
            return this.getDaysInMonth(this.currentDate.clone());
        },

        positions() {
            let items = this.users;
            let positions = items.map(item => item.positionName);

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }

            return positions.filter(onlyUnique);
        },

        allUsers() {

            let items = this.removeReactivity(this.users);
            items = items.filter(user => {
                return user.projects.length;
            });

            if (this.showPositions.length) {

                items = items.filter(user => {
                    return this.showPositions.includes(user.positionName);
                });

            }

            if (this.filter_project_id) {
                let pid = this.filter_project_id;
                items = items.map(user => {
                    user.projects = user.projects.filter(p => p.project.id == pid);
                    return user;
                }).filter(user => user.projects.length);
            }

            items = items.orderBy("order", true);
            return items;
        },

    },

    data() {
        return {
            users: [],
            pageLoading: true,
            currentYM: null,
            openedUsers: [],
            showPositions: [],
            filter_project_id: null,
        }
    }
}
</script>