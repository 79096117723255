<template>
    <div>
        <ag-grid :fields="columnDefs" :data="computedItems"></ag-grid>
    </div>
</template>

<script>
export default {
    name: "costTable",
    props: {
        items: {
            type: Array,
            default: []
        }
    },
    computed: {
        computedItems() {
            return this.items.map(row => {
                return {
                    ...row,
                    document_number: row?.parent ? row.parent.meta?.document_number : row.meta?.document_number,
                }
            });
        },
    },
    data() {
        return {
            columnDefs: [
                {key: 'title', label: "Název", sortable: true, type: "costLink"},
                {key: 'document_number', label: "Číslo dokladu", sortable: true, type: "string"},
                {key: 'user.name', label: "Vytvořil", sortable: true, type: "userLink"},
                {key: 'created_at', label: "Vytvořeno", sortable: true, type: "string"},
            ],
        }
    }
}
</script>