<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Týdenní plán</h2>
                    <div class="report_arrows">
                        <router-link :to="{name: 'plan_index', params: {week: prevWeek}}" class="report_arrow">
                            &lAarr;
                        </router-link>
                        <div class="report_label">{{ currentWeek }}</div>
                        <router-link :to="{name: 'plan_index', params: {week: nextWeek}}" class="report_arrow">
                            &rAarr;
                        </router-link>
                    </div>

                </div>
                <div class="col-12 col-lg-6 flex-buttons" v-if="isAdmin()">
                    <button v-if="editMode" class="btn btn-danger" @click="editMode = false">Zrušit úpravy</button>
                    <button v-else class="btn btn-danger" @click="editMode = true">Upravit</button>
                </div>
            </div>

            <div class="row" v-if="editMode">
                <div class="col-12 col-lg-6">

                    <button class="btn btn-primary" @click="selectedUsers = []">Odznačit uživatelé</button>
                    <button class="btn btn-primary" @click="selectedDays = []">Odznačit dny</button>

                </div>
                <div class="col-12 col-lg-6">
                    <form class="weekPlan__form" @submit.prevent="addToPlan">
                        <div class="row">
                            <div class="col-10">
                                <app-select v-model="item.project_id" :options="allProjects" option-key="id" option-label="label"/>
                            </div>
                            <div class="col-2">
                                <app-button class="btn-primary" slug="btn_addToPlan" type="submit">Uložit</app-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <holiday-legend/>
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>Jméno</th>
                        <th v-for="day in daysInWeek" width="12%">
                            {{ day.isToday ? "dnes" : "" }}<br>
                            <button v-if="editMode" class="btn btn-secondary" @click="selectedDays.toggle(day.key)" :class="{'btn-success': selectedDays.includes(day.key)}">
                                {{ day.label + " " + day.nice }}
                            </button>
                            <div v-else>{{ day.label + " " + day.nice }}</div>
                            <weather-widget v-if="day.weather && showWeather" :item="day?.weather"/>
                        </th>
                    </tr>
                    <tr v-for="user in users" :class="{'tr--current': user.id == $store.state.user.id && !editMode}">
                        <td>
                            <button v-if="editMode" class="btn btn-secondary" @click="selectedUsers.toggle(user.id)" :class="{'btn-success': selectedUsers.includes(user.id)}">
                                {{ user.name }}
                            </button>
                            <user-link v-else :item="user" :position="false"/>
                            - {{ user.positionName }}
                        </td>
                        <td v-for="day in daysInWeek" :class="{'td--selected': selectedUsers.includes(user.id) && selectedDays.includes(day.key) && editMode}">
                            <div v-for="(day_item, key) in getPlanItems(user.id, day.key)">
                                <div v-if="day_item.object_key == 'holiday'" class="dplan__holiday">
                                    <holiday-cell :item="day_item"/>
                                </div>
                                <div v-else class="dplan__item">
                                    <router-link v-if="day_item.project_id" :to="{name:'project_show', params: {project_id: day_item.project_id}}" v-html="getProjectName(day_item.project_id)"></router-link>
                                    <app-button v-if="editMode" class="btn-icon text-danger" :slug="'btn_deletePlan_'+day_item.id" @click="deletePlan(day_item)">
                                        <Icon name="trash"/>
                                    </app-button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import projectService from "@services/projectService";
import WeatherWidget from "@components/weatherWidget";
import HolidayLegend from "@components/holidayLegend";

export default {
    name: "plan_index",
    components: {HolidayLegend, WeatherWidget},
    mounted() {
        let date_from_route = this.$route.params.week;
        if (!date_from_route) {
            date_from_route = this.$moment().format("YYYY-W");
        }

        this.currentDate = date_from_route;

        projectService.getWather().then(r => {
            this.weather = r.data.weather;
        }).catch(e => {
            this.showError(e);
        });

        this.getAllProjects();
        this.fetchData();
    },

    methods: {

        deletePlan(day_item) {
            this.setLoading('btn_deletePlan_' + day_item.id);

            let data = {
                id: day_item.id,
            }

            projectService.deletePlan(data).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading('btn_deletePlan_' + day_item.id);
            });
        },

        getProjectName(pid) {

            let project = this.allProjects.find(p => p.id == pid);
            if (project)
                return project?.client?.name + "<br>" + project.name;

            return "-";
        },

        getPlanItems(user_id, day) {
            return this.items?.[user_id]?.[day] ?? [];
        },

        addToPlan() {
            this.setLoading("btn_addToPlan");

            let data = {
                project_id: this.item.project_id,
                users: this.selectedUsers,
                dates: this.selectedDays,
            };

            projectService.addToPlan(data).then(res => {
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_addToPlan");
            });

        },

        getAllProjects() {

            projectService.getAllActiveProjects().then(res => {
                this.allProjects = res.data.projects.map(item => {
                    return {
                        ...item,
                        label: item?.client?.name + " - " + item.name,
                    }
                });
            }).catch(e => {
                this.showError(e);
            });

        },

        fetchData() {
            projectService.getWeekPlan(this.currentDate).then(res => {
                let items = res.data?.items ?? [];
                this.items = items;
            }).catch(e => {
                this.showError(e);
            });
        },
    },

    computed: {

        users() {
            let items = this.$store.state.allUsers;

            let me = items.find(u => u.id == this.getUserID());
            if (me) {
                items = items.filter(u => u.id !== me.id);
                items = [
                    me,
                    ...items,
                ];
            }

            return items;
        },

        prevWeek() {
            let current = this.currentDateObject.clone();
            return current.subtract("1", "w").format("YYYY-W");
        },

        currentWeek() {
            let current = this.currentDateObject.clone();
            return current.format("W. [týden roku] YYYY");
        },

        nextWeek() {
            let current = this.currentDateObject.clone();
            return current.add("1", "w").format("YYYY-W");
        },

        currentUser() {
            let uid = this.item.user_id;
            if (!uid)
                return "";

            return this.$store.state.allUsers.find(u => u.id == uid)?.name ?? "";
        },

        currentDateObject() {
            let date = this.currentDate;
            return this.$moment(date, "YYYY-W");
        },

        daysInWeek() {
            let today = this.$moment().format("YYYY-MM-DD");

            let days = [];
            let currentDate = this.currentDateObject.clone();
            let days_indexes = [1, 2, 3, 4, 5, 6, 7];
            days_indexes.forEach((dayOfWeek, index) => {
                let $day = currentDate.clone().day(dayOfWeek);
                let dayKey = $day.format("YYYY-MM-DD");
                let isSunday = dayOfWeek == 7;
                let isSaturday = dayOfWeek == 6;
                let isToday = dayKey == today;
                let day = {
                    index,
                    key: dayKey,
                    isSaturday,
                    isSunday,
                    isToday,
                    label: $day.format("dd"),
                    nice: $day.format("DD. MM. YYYY"),
                    weather: this.weather.find(w => w.day == dayKey),
                };
                days.push(day);
            });

            return days;

        }

    },

    data() {
        return {
            currentDate: null,
            items: {},
            pageLoading: false,
            item: {
                project_id: null,
                user_id: null,
                date: null,
            },

            selectedDays: [],
            selectedUsers: [],

            allProjects: [],
            editMode: false,
            weather: [],
            showWeather: true,
        }
    }
}
</script>