var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Majetek " + _vm._s(_vm.thing_id ? " - " + _vm.thing.name : ""))]), _c('form', {
    attrs: {
      "method": "POST",
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Název")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.thing.name,
      expression: "thing.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.thing.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.thing, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Popis")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.thing.text,
      expression: "thing.text"
    }],
    staticClass: "form-control",
    domProps: {
      "value": _vm.thing.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.thing, "text", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Evidenční číslo")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.thing.meta.internal_number,
      expression: "thing.meta.internal_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.thing.meta.internal_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.thing.meta, "internal_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Nákupní cena")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.thing.meta.price,
      expression: "thing.meta.price"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.thing.meta.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.thing.meta, "price", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Datum nákupu")]), _c('app-date', {
    model: {
      value: _vm.thing.meta.bought_at,
      callback: function ($$v) {
        _vm.$set(_vm.thing.meta, "bought_at", $$v);
      },
      expression: "thing.meta.bought_at"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Stav")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.thing.meta.still_used,
      expression: "thing.meta.still_used"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.thing.meta, "still_used", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "yes"
    }
  }, [_vm._v("Používá se")]), _c('option', {
    attrs: {
      "value": "no"
    }
  }, [_vm._v("Nepoužívá se")])])]), _c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_createThing",
      "type": "submit"
    }
  }, [_vm._v("Uložit")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }