var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Týdenní plán")]), _c('div', {
    staticClass: "report_arrows"
  }, [_c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": {
        name: 'plan_index',
        params: {
          week: _vm.prevWeek
        }
      }
    }
  }, [_vm._v(" ⇚ ")]), _c('div', {
    staticClass: "report_label"
  }, [_vm._v(_vm._s(_vm.currentWeek))]), _c('router-link', {
    staticClass: "report_arrow",
    attrs: {
      "to": {
        name: 'plan_index',
        params: {
          week: _vm.nextWeek
        }
      }
    }
  }, [_vm._v(" ⇛ ")])], 1)]), _vm.isAdmin() ? _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_vm.editMode ? _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.editMode = false;
      }
    }
  }, [_vm._v("Zrušit úpravy")]) : _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": function ($event) {
        _vm.editMode = true;
      }
    }
  }, [_vm._v("Upravit")])]) : _vm._e()]), _vm.editMode ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        _vm.selectedUsers = [];
      }
    }
  }, [_vm._v("Odznačit uživatelé")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        _vm.selectedDays = [];
      }
    }
  }, [_vm._v("Odznačit dny")])]), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('form', {
    staticClass: "weekPlan__form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addToPlan.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-10"
  }, [_c('app-select', {
    attrs: {
      "options": _vm.allProjects,
      "option-key": "id",
      "option-label": "label"
    },
    model: {
      value: _vm.item.project_id,
      callback: function ($$v) {
        _vm.$set(_vm.item, "project_id", $$v);
      },
      expression: "item.project_id"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_addToPlan",
      "type": "submit"
    }
  }, [_vm._v("Uložit")])], 1)])])])]) : _vm._e(), _c('holiday-legend'), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped",
    attrs: {
      "colspan": "0",
      "rowspan": "0",
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', [_vm._v("Jméno")]), _vm._l(_vm.daysInWeek, function (day) {
    return _c('th', {
      attrs: {
        "width": "12%"
      }
    }, [_vm._v(" " + _vm._s(day.isToday ? "dnes" : "")), _c('br'), _vm.editMode ? _c('button', {
      staticClass: "btn btn-secondary",
      class: {
        'btn-success': _vm.selectedDays.includes(day.key)
      },
      on: {
        "click": function ($event) {
          return _vm.selectedDays.toggle(day.key);
        }
      }
    }, [_vm._v(" " + _vm._s(day.label + " " + day.nice) + " ")]) : _c('div', [_vm._v(_vm._s(day.label + " " + day.nice))]), day.weather && _vm.showWeather ? _c('weather-widget', {
      attrs: {
        "item": day === null || day === void 0 ? void 0 : day.weather
      }
    }) : _vm._e()], 1);
  })], 2), _vm._l(_vm.users, function (user) {
    return _c('tr', {
      class: {
        'tr--current': user.id == _vm.$store.state.user.id && !_vm.editMode
      }
    }, [_c('td', [_vm.editMode ? _c('button', {
      staticClass: "btn btn-secondary",
      class: {
        'btn-success': _vm.selectedUsers.includes(user.id)
      },
      on: {
        "click": function ($event) {
          return _vm.selectedUsers.toggle(user.id);
        }
      }
    }, [_vm._v(" " + _vm._s(user.name) + " ")]) : _c('user-link', {
      attrs: {
        "item": user,
        "position": false
      }
    }), _vm._v(" - " + _vm._s(user.positionName) + " ")], 1), _vm._l(_vm.daysInWeek, function (day) {
      return _c('td', {
        class: {
          'td--selected': _vm.selectedUsers.includes(user.id) && _vm.selectedDays.includes(day.key) && _vm.editMode
        }
      }, _vm._l(_vm.getPlanItems(user.id, day.key), function (day_item, key) {
        return _c('div', [day_item.object_key == 'holiday' ? _c('div', {
          staticClass: "dplan__holiday"
        }, [_c('holiday-cell', {
          attrs: {
            "item": day_item
          }
        })], 1) : _c('div', {
          staticClass: "dplan__item"
        }, [day_item.project_id ? _c('router-link', {
          attrs: {
            "to": {
              name: 'project_show',
              params: {
                project_id: day_item.project_id
              }
            }
          },
          domProps: {
            "innerHTML": _vm._s(_vm.getProjectName(day_item.project_id))
          }
        }) : _vm._e(), _vm.editMode ? _c('app-button', {
          staticClass: "btn-icon text-danger",
          attrs: {
            "slug": 'btn_deletePlan_' + day_item.id
          },
          on: {
            "click": function ($event) {
              return _vm.deletePlan(day_item);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "trash"
          }
        })], 1) : _vm._e()], 1)]);
      }), 0);
    })], 2);
  })], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }