<template>
    <div v-if="item?.day" class="weather">
        {{ item?.weather?.[0]?.description }}, {{ item.temp.day }} °C, {{ item.wind_speed }} m/s
        <img loading="lazy" v-if="!small" :src="'http://openweathermap.org/img/wn/'+item?.weather?.[0]?.icon+'.png'">
    </div>
</template>

<script>
export default {
    name: "weatherWidget",
    props: {
        item: {
            type: Object,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
    }
}
</script>