<template>
    <div>
        <template v-if="isAdmin()">
            <router-link :to="{name: 'user_create'}" class="btn btn-primary">
                Nový uživatel
            </router-link>
            <router-link :to="{name: 'user_roles'}" class="btn btn-primary">Správa rolí</router-link>
            <router-link :to="{name: 'user_positions'}" class="btn btn-primary">Správa pozic</router-link>
            <router-link :to="{name: 'user_reports'}" class="btn btn-primary">Měsíční report</router-link>
            <router-link :to="{name: 'user_position_report'}" class="btn btn-primary">Report dle pozice</router-link>
        </template>
    </div>
</template>

<script>
export default {
    name: "usersNav"
}
</script>