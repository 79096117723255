<template>
    <div class="container container--small">
        <div class="text-center">
            <h2>Revis - interní systém</h2>
        </div>

        <add-cost-modal ref="costModal"/>

        <form @submit.prevent="submit">
            <div class="form-group">
                <label for="email">E-mail</label>
                <input id="email" type="email" class="form-control" v-model="email" name="email" value="" required autofocus>
            </div>

            <div class="form-group">
                <label for="password">Heslo</label>
                <input id="password" type="password" v-model="password" class="form-control" name="password" required>
            </div>

            <div class="form-group">
                <app-button slug="btn_login" type="submit" class="btn btn-primary">
                    Přihlásit se
                </app-button>
                <a class="btn btn-link" @click="addCost('shop')">Nahrát náklad</a>
<!--                <a class="btn btn-link" @click="addCost('gas_receipt')">Tankování</a>-->
            </div>
        </form>
    </div>
</template>

<script>
import userService from "@services/userService";
import AddCostModal from "@components/addCostModal";

export default {
    name: "login",
    components: {AddCostModal},

    methods: {

        addCost(type = "shop") {
            this.$refs.costModal.composeBody(type, {});
            this.$refs.costModal.open();
        },

        submit() {

            let payload = {
                email: this.email,
                password: this.password,
            };

            this.setLoading("btn_login");

            userService.login(payload).then(res => {
                let data = res.data;

                this.$store.commit("setCurrentUser", data.user_data);
                this.afterLoginActions();
                this.$router.push({name: "homepage"});

            }).catch(e => {
                this.$toast.error("Špatné přihlašovací údaje.");
            }).finally(res => {
                this.unsetLoading("btn_login");
            });
        }

    },
    data() {
        return {
            email: "",
            password: "",
        }
    }
}
</script>