var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup__wrapper"
  }, [_c('div', {
    staticClass: "popup__background",
    on: {
      "click": _vm.close
    }
  }), _c('div', {
    staticClass: "popup__body"
  }, [_vm._t("default")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }