var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Hledaný dotaz")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.input,
      expression: "input"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "search"
    },
    domProps: {
      "value": _vm.input
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.input = $event.target.value;
      }
    }
  })]), _vm.input.length < _vm.min_length ? _c('div', [_vm._v(" Minimálně " + _vm._s(_vm.min_length) + " písmena. ")]) : _c('div', [_c('h2', [_vm._v("Výsledky - " + _vm._s(_vm.query))]), _vm.loading ? _c('page-loader') : _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.models, function (model) {
    var _vm$modelsLabels$mode, _vm$items$model;

    return _c('div', {
      staticClass: "col-12 col-lg-6"
    }, [_c('h3', [_vm._v(_vm._s((_vm$modelsLabels$mode = _vm.modelsLabels[model]) !== null && _vm$modelsLabels$mode !== void 0 ? _vm$modelsLabels$mode : ""))]), _c('search-table', {
      attrs: {
        "model": model,
        "items": (_vm$items$model = _vm.items[model]) !== null && _vm$items$model !== void 0 ? _vm$items$model : [],
        "word": _vm.query
      }
    })], 1);
  }), 0)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }