<template>
    <component :is="handleUpload ? 'form' : 'div'" @submit.prevent="uploadNewImages">
        <div class="gallery">
            <div class="gallery__itemw no-print" v-if="allowUpload">
                <div class="gallery__upload uploader" @click="addFile('camera')">+ Vyfotit</div>
                <div class="gallery__upload uploader" @click="addFile('gallery')">+ Přidat z galerie</div>
                <input style="display: none" type="file" :id="dom_slugCam" @change="fileSelected" multiple :accept="acceptFormatsCam" capture="environment"/>
                <input style="display: none" type="file" :id="dom_slugGal" @change="fileSelected" multiple :accept="acceptFormats"/>
            </div>
            <div class="gallery__itemw" v-for="(item, index) in items">

                <template v-if="item.type == 'image'">
                    <div class="gallery__item" @click="openImage(index)">
                        <img loading="lazy" :src="item.url"/>
                    </div>
                </template>
                <template v-else-if="item.type == 'video'">
                    <a class="gallery__item" data-fancybox :href="item.url">{{ item.name || "Video" }}</a>
                </template>
                <template v-else-if="item.type == 'audio'">
                    <a class="gallery__item" data-fancybox :href="item.url" data-type="html5video">{{ item.name || "Audio" }}</a>
                </template>
                <template v-else-if="item.type == 'pdf'">
                    <a class="gallery__item" data-fancybox data-type="pdf" :href="item.url">{{ item.name || "PDF" }}</a>
                </template>
                <template v-else>
                    <a class="gallery__item" download :href="item.url">{{ item.name || "Soubor" }}</a>
                </template>

                <div v-if="hasModel && userCan('detach-image')" class=" no-print">
                    <a class="gallery__delete" @click="detachImage(item)">Smazat</a>
                </div>
            </div>
            <template v-if="allowUpload">
                <div class="gallery__itemw" v-for="(item, index) in allNewFiles">
                    <div class="gallery__item">
                        <img loading="lazy" v-if="item.type == 'image'" :src="item.url"/>
                        <span v-else>{{ item.name || item.type }}</span>
                    </div>
                    <a class="gallery__delete newone no-print" @click="deleteFromUpload(item, index)">Odebrat</a>
                </div>
            </template>
        </div>

        <div v-if="allNewFiles.length && handleUpload" style="width: 100%" class=" no-print">
            <div class="d-flex justify-content-center">
                <app-button slug="btn_addNewImages" class="btn btn-primary">Nahrát nové fotky</app-button>
            </div>
        </div>

    </component>
</template>

<script>
import imageService from "@services/imageService";

export default {
    name: "projectFiles",
    props: {
        files: {
            type: Array,
            default: () => []
        },
        modelType: {
            type: String,
            default: null,
        },
        modelId: {
            type: [Number, String],
            default: null,
        },
        allowUpload: {
            type: Boolean,
            default: false
        },
        handleUpload: {
            type: Boolean,
            default: true
        },
        column: {
            type: String,
            default: "files"
        }
    },

    methods: {

        getBase64(config) {
            return new Promise((res) => {

                let file = config?.file;
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {

                    let dataurl = reader.result;
                    delete config?.file;

                    res({
                        ...config,
                        url: dataurl,
                    });
                };

            });
        },

        resizeFile(config) {
            return new Promise((res) => {
                let file = config.file;

                let img = document.createElement("img");
                img.onload = function (event) {
                    let canvas = document.createElement("canvas");

                    let max_size = 1200,
                        width = img.width,
                        height = img.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    let dataurl = canvas.toDataURL('image/jpeg', 0.9);

                    delete config?.file;

                    res({
                        ...config,
                        url: dataurl,
                    });
                }
                img.src = URL.createObjectURL(file);
            });
        },

        deleteFromUpload(item, index) {
            this.allNewFiles.splice(index, 1);
        },

        uploadNewImages() {
            let payload = {
                new_files: this.allNewFiles,
                column: this.column,
                model_id: this.modelId,
                model_type: this.modelType,
            };

            this.setLoading("btn_addNewImages");
            imageService.addToEntity(payload).then(res => {
                this.$emit("updated");
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_addNewImages");
            });

        },

        addFile(type) {
            if(type == "camera") {
                this.elementCam.click();
            } else {
                this.elementGal.click();
            }
        },

        getDomFiles() {
            this.dom_files = [
                ...this.elementCam.files ?? [],
                ...this.elementGal.files ?? [],
            ]
        },

        fileSelected($event) {
            this.getDomFiles();

            let allFiles = [];

            let files = this.dom_files;
            let size = files.length;

            for (let i = 0; i < size; i++) {
                let file = files[i];
                let type = this.getFileType(file.name);

                let config = null;

                if (type == "image") {
                    config = this.resizeFile({
                        file,
                        type,
                        name: file.name,
                    });
                } else {
                    config = this.getBase64({
                        file,
                        type,
                        name: file.name,
                    });
                }

                allFiles.push(config);
            }

            Promise.all(allFiles).then(items => {
                items.forEach(item => {
                    this.allNewFiles.push(item);
                });

                this.$emit("newFiles", this.allNewFiles);
            });
            return;

            let name = this.dom_files?.name;
            if (name) {
                this.fileName = name;
                this.currentFile = "local";
            }
        },

        detachImage(item) {

            let conf = window.confirm("Opravdu odebrat?");
            if (conf) {

                let data = {
                    model_id: this.modelId,
                    model_type: this.modelType,
                    url: item.ourl,
                };

                imageService.removeFromEntity(data).then(r => {
                    this.$emit("imageDeleted", {success: true});
                    this.hiddenFiles.push(item.ourl);
                }).catch(e => {
                    this.showError(e);
                    this.$emit("imageDeleted", {success: false});
                });

            }

        },

        openImage(index) {

            let images = this.removeReactivity(this.images).map(i => {
                return {
                    src: i.url,
                    type: "image",
                }
            });


            const fancybox = window.Fancybox.show(images, {
                infinite: false,
                startIndex: index,
            });

        },

        getFileType(file) {
            let ext = file.split(".").reverse()[0].toLowerCase();
            if (this.formats.video.includes(ext)) return "video";
            if (this.formats.audio.includes(ext)) return "audio";
            if (this.formats.image.includes(ext)) return "image";
            if (this.formats.pdf.includes(ext)) return "pdf";
        },

        containsHttpProtocol(str) {
            const pattern = /^https?:\/\//i;
            return pattern.test(str);
        },

    },

    computed: {

        acceptFormats: function () {

            let formats = "image/*";
            if (!this.isMobile()) {
                formats += ",.pdf";
            }

            return formats;
        },

        acceptFormatsCam: function () {

            let formats = "capture=camera,image/*";
            if (!this.isMobile()) {
                formats += ",.pdf";
            }

            return formats;
        },

        dom_slugCam: function () {
            return "image_files_cam_" + this._uid;
        },

        dom_slugGal: function () {
            return "image_files_gal_" + this._uid;
        },

        elementCam: function () {
            return document.getElementById(this.dom_slugCam);
        },

        elementGal: function () {
            return document.getElementById(this.dom_slugGal);
        },

        hasModel() {
            return this.modelId && this.modelType;
        },

        images() {
            return this.items.filter(i => i.type == "image");
        },

        items() {

            let files = this.files;
            if (files && Array.isArray(files)) {
                return this.files.map(file => {
                    return {
                        ...file,
                        url: this.containsHttpProtocol(file.url) ? file.url : "/" + file.url,
                        ourl: file.url,
                    };
                }).filter(item => {
                    return !this.hiddenFiles.includes(item.ourl);
                });
            }

            return [];
        }
    },

    data() {
        return {
            formats: {
                video: ["mp4", "avi", "mpg", "mpeg", "wmv", "mov", "ogg", "webm"],
                audio: ["wma", "aac", "wav", "ogg", "mp3"],
                image: ["gif", "ico", "jpeg", "jpg", "png", "svg"],
                pdf: ["pdf"],
            },
            hiddenFiles: [],
            allNewFiles: [],
        }
    }
}
</script>