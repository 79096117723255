var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible ? _c('modal', {
    on: {
      "close": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm.pageLoading ? _c('page-loader') : _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "text-pid1"
  }, [_vm._v("Hlavní obchod")]), _c('app-select', {
    attrs: {
      "options": _vm.shops
    },
    model: {
      value: _vm.shop1,
      callback: function ($$v) {
        _vm.shop1 = $$v;
      },
      expression: "shop1"
    }
  })], 1)]), _vm.shop1 ? _c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "text-pid2"
  }, [_vm._v("Duplicitní obchod")]), _c('app-select', {
    attrs: {
      "options": _vm.shops.filter(function (s) {
        return s.value !== _vm.shop1;
      })
    },
    model: {
      value: _vm.shop2,
      callback: function ($$v) {
        _vm.shop2 = $$v;
      },
      expression: "shop2"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "col-12 col-lg-2"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_mergeShops"
    },
    on: {
      "click": _vm.startMerge
    }
  }, [_vm._v("Spojit")])], 1)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }