var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    on: {
      "close": _vm.close
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm.showNote ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Poznámka")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.text,
      expression: "item.text"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "text"
    },
    domProps: {
      "value": _vm.item.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.item, "text", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Fotky")]), _vm.visible ? _c('project-files', {
    attrs: {
      "allow-upload": true,
      "handle-upload": false,
      "files": _vm.item.files,
      "model-type": "event",
      "model-id": _vm.item.id
    },
    on: {
      "newFiles": function (files) {
        return _vm.appendFiles = files;
      }
    }
  }) : _vm._e()], 1), _c('app-button', {
    staticClass: "btn-primary",
    attrs: {
      "slug": "btn_createEvent"
    }
  }, [_vm._v("Uložit")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }