var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('h2', [_vm._v("Zákazníci")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'client_create'
      }
    }
  }, [_vm._v(" Nový zákazník ")]), _vm.userCan('client.merge') ? _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'client_merge'
      }
    }
  }, [_vm._v(" Spojit zákazníky ")]) : _vm._e(), _c('br'), _c('br'), _c('app-table', _vm._b({
    staticClass: "table",
    attrs: {
      "items": _vm.items,
      "local-sort": true,
      "sortBy": _vm.table_attributes.sortBy,
      "sortDirection": _vm.table_attributes.sortDirection
    },
    on: {
      "update:sortBy": function ($event) {
        return _vm.$set(_vm.table_attributes, "sortBy", $event);
      },
      "update:sort-by": function ($event) {
        return _vm.$set(_vm.table_attributes, "sortBy", $event);
      },
      "update:sortDirection": function ($event) {
        return _vm.$set(_vm.table_attributes, "sortDirection", $event);
      },
      "update:sort-direction": function ($event) {
        return _vm.$set(_vm.table_attributes, "sortDirection", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          return _c('tr', {
            key: row.id,
            staticClass: "hpreport__item",
            class: row.color
          }, [_c('td', [_c('client-link', {
            attrs: {
              "item": row
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(row.address) + " ")]), _c('td', {
            staticClass: "flex-buttons"
          }, [_vm.userCan('offices') ? _c('router-link', {
            staticClass: "btn btn-danger",
            attrs: {
              "to": {
                name: 'client_create',
                params: {
                  client_id: row.id
                }
              }
            }
          }, [_vm._v(" Upravit ")]) : _vm._e()], 1)]);
        });
      }
    }])
  }, 'app-table', _vm.table_attributes, false))]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }