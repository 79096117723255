var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('multiselect', _vm._g(_vm._b({
    attrs: {
      "placeholder": "Vyberte",
      "selectLabel": "Vybrat",
      "selectGroupLabel": "Vybrat",
      "deselectLabel": "Odebrat",
      "deselectGroupLabel": "Odebrat",
      "selectedLabel": "Vybráno",
      "tagPlaceholder": "Vybrat"
    }
  }, 'multiselect', _vm.$attrs, false), _vm.$listeners), [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Žádný výsledek")]), _c('span', {
    attrs: {
      "slot": "noOptions"
    },
    slot: "noOptions"
  }, [_vm._v("Žádné možnosti")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }