<template>
    <div>
        <add-hours-modal ref="hoursModal"/>
        <h2>Spojování zakázek</h2>
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <div class="form-group">
                    <label class="text-pid1">Hlavní zakázka</label>
                    <app-select auto-load="projects" v-model="pid1"/>
                </div>
            </div>
            <div class="col-12 col-lg-5">
                <div class="form-group">
                    <label class="text-pid2">Duplicitní zakázka</label>
                    <app-select auto-load="projects" v-model="pid2"/>
                </div>
            </div>
            <div class="col-12 col-lg-2">
                <button class="btn btn-primary" @click="startMerge">Načíst data</button>
            </div>
        </div>

        <form v-if="showColumns" @submit.prevent="doMerge">
            <h3>Sloučení hodin</h3>
            <input type="hidden" name="project1" :value="pid1"/>
            <input type="hidden" name="project2" :value="pid2"/>

            <table class="table table-striped table-hours mt-3">
                <tbody>
                    <tr class="table__mainrow">
                        <td>Uživatel</td>
                        <td v-for="day in projectDays" v-html="day.label" :key="day.key" class="td--day"></td>
                    </tr>
                    <tr v-for="item in projectHours" :key="item.user.id">
                        <td>
                            <user-link :item="item.user"/>
                        </td>
                        <td v-for="day in item.days" :key="day.key" class="td--day">
                            <div v-if="day.total">
                                <div class="merge__items">
                                    <span v-for="day_item in day.items" :class="getProjectCls(day_item.pid)" @click="openHours(day_item.id)">{{ day_item.amount }}</span>
                                </div>
                                <input type="text" :name="'hours['+item.user.id+']['+day.key+']'" :value="day.total"/>
                            </div>
                            <div v-else> -</div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3>Sloučení nákladů</h3>

            <div class="d-flex align-items-center justify-content-between">
                <h4>Materiál z obchodu</h4>
            </div>

            <div class="table-wrapper">
                <table class="table table-striped">
                    <tbody>
                        <tr class="table__mainrow">
                            <td width="70">Zahrnout</td>
                            <td width="20%">Název</td>
                            <td width="70">Fotky</td>
                            <td width="70">Částečný</td>
                            <td>Hodnota</td>
                            <td>Číslo dokladu / poznámka</td>
                            <td width="100">Zapsal</td>
                        </tr>
                        <tr v-for="item in costsFromShop" :key="item.id">
                            <td>
                                <input type="checkbox" :name="'costs[]'" :value="item.id" checked/>
                            </td>
                            <td>
                                <div :class="getProjectCls(item.project_id)">
                                    <cost-link :item="item"/>
                                </div>
                            </td>
                            <td>
                                <images-info :item="item"/>
                            </td>
                            <td>
                                {{ item.parent_id ? "Ano" : "Ne" }}
                            </td>
                            <td>
                                {{ item.value }}
                            </td>
                            <td>
                                {{ item.meta?.note }}
                            </td>
                            <td>
                                {{ item?.user?.name ?? "Anonym" }},
                                {{ localizeDate(item.created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="d-flex align-items-center justify-content-between">
                <h4>Materiál ze skladu</h4>
            </div>

            <div class="table-wrapper">
                <table class="table table-striped">
                    <tbody>
                        <tr class="table__mainrow">
                            <td width="70">Zahrnout</td>
                            <td width="20%">Název</td>
                            <td width="70">Fotky</td>
                            <td width="70">Částečný</td>
                            <td>Zapsal</td>
                        </tr>
                        <tr v-for="item in costsFromStock" :key="item.id">
                            <td>
                                <input type="checkbox" :name="'costs[]'" :value="item.id" checked/>
                            </td>
                            <td>
                                <div :class="getProjectCls(item.project_id)">
                                    <cost-link :item="item"/>
                                </div>
                            </td>
                            <td>
                                <images-info :item="item"/>
                            </td>
                            <td>
                                {{ item.parent_id ? "Ano" : "Ne" }}
                            </td>
                            <td>
                                {{ item?.user?.name ?? "Anonym" }},
                                {{ localizeDate(item.created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex align-items-center justify-content-between">
                <h4>Vozidla</h4>
            </div>

            <div class="table-wrapper">
                <table class="table table-striped">
                    <tbody>
                        <tr class="table__mainrow">
                            <td width="70">Zahrnout</td>
                            <td width="20%">Název</td>
                            <td width="70">Fotky</td>
                            <td>Kolikrát na stavbě</td>
                            <td>Zapsal</td>
                        </tr>
                        <tr v-for="item in costsFromTransport" :key="item.id">
                            <td>
                                <input type="checkbox" :name="'costs[]'" :value="item.id" checked/>
                            </td>
                            <td>
                                <div :class="getProjectCls(item.project_id)">
                                    <cost-link :item="item"/>
                                </div>
                            </td>
                            <td>
                                <images-info :item="item"/>
                            </td>
                            <td>{{ item.meta.count }}</td>
                            <td>{{ item?.user?.name ?? "Anonym" }},
                                {{ localizeDate(item.created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <app-button slug="btn_merge" class="btn-primary">Sloučit</app-button>
        </form>

    </div>
</template>

<script>
import projectService from "@services/projectService";
import AddHoursModal from "@components/addHoursModal";
import imagesInfo from "@components/imagesInfo";

export default {
    name: "project_merge",
    components: {AddHoursModal, imagesInfo},
    mounted() {
        // this.autoRun();
    },

    methods: {

        doMerge(e) {
            let form = new FormData(e.target);
            let data = form;

            this.setLoading("btn_merge");
            projectService.mergeProjects(data).then(res => {
                this.$toast.success("Zakázky spojeny!");

                let newid = res.data.newid;

                setTimeout(() => {
                    this.$router.push({name: "project_show", params: {project_id: newid}});
                }, 2000);

            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_merge");
            });
        },

        openHours(phid) {
            this.$refs.hoursModal.view({id: phid});
        },

        autoRun() {
            this.pid1 = 9;
            this.pid2 = 12;
            this.startMerge();
        },

        startMerge() {
            this.showColumns = false;
            let promises = [];
            let p1 = projectService.getProjectCosts({project_id: this.pid1}).then(res => {
                this.project1 = res.data.project;
            }).catch(e => {
                this.showError(e);
            });
            promises.push(p1);
            let p2 = projectService.getProjectCosts({project_id: this.pid2}).then(res => {
                this.project2 = res.data.project;
            }).catch(e => {
                this.showError(e);
            });
            promises.push(p2);
            Promise.all(promises).finally(res => {
                this.showColumns = true;
            });
        },

        getProjectCls(pid) {
            if (this.pid1 == pid)
                return "text-pid1";
            return "text-pid2";
        },

        getDayTitle(date) {
            let date_obj = new Date(date);
            let day = date_obj.getDate();
            let m = date_obj.getMonth() + 1;
            let y = date_obj.getFullYear();
            return day + "/" + m + "<br>" + y;
        },

        getUserHours(user_id, day) {
            let items = this.hours.filter(item => {
                return item.user_id == user_id && item.day == day;
            });

            if (!items.length) {
                return {
                    total: 0,
                    items: [],
                };
            }

            items = items.map(item => {
                return {
                    pid: item.project_id,
                    amount: parseFloat(item.amount),
                    id: item.id,
                }
            });

            let total = items.sum("amount");

            return {
                total,
                items,
            }
        },

    },

    computed: {

        hours: function () {
            return [
                ...this.project1.hours,
                ...this.project2.hours,
            ];
        },

        allCosts: function () {
            return [
                ...this.project1.final_costs,
                ...this.project2.final_costs,
            ];
        },


        costsFromShop: function () {
            return this.allCosts.filter(item => item.type == 'shop');
        },

        costsFromStock: function () {
            return this.allCosts.filter(item => item.type == 'stock');
        },

        costsFromTransport: function () {
            return this.allCosts.filter(item => item.type == 'transport');
        },

        projectUsers: function () {
            let users = {};
            this.hours.forEach(item => {
                let user = item.user;
                users[user.id] = user;
            });
            return Object.values(users);
        },

        projectDays: function () {
            let days = {};

            [this.project1, this.project2].forEach(project => {
            });

            this.hours.forEach(item => {
                let day = {
                    key: item.day,
                    label: this.getDayTitle(item.day)
                };

                days[item.day] = day;
            });

            let daysKeys = Object.keys(days).sort();
            let all_days = daysKeys.map(d => {
                return days[d]
            });
            return all_days;
        },

        projectHours: function () {

            return this.removeReactivity(this.projectUsers).map(user => {

                let days = this.removeReactivity(this.projectDays).map(day => {
                    let day_data = this.getUserHours(user.id, day.key);
                    day.items = day_data.items;
                    day.total = day_data.total;
                    return day;
                });

                return {
                    user,
                    days,
                }

            });

        },

    },

    data() {
        return {
            pid1: null,
            pid2: null,
            project1: null,
            project2: null,
            showColumns: false,
        }
    }
}
</script>