var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "table table--app"
  }, [_c('thead', [_c('tr', _vm._l(_vm.filteredHead, function (item, key) {
    return _c('th', {
      key: key,
      class: _vm.getClasses(item),
      style: _vm.getStyles(item),
      on: {
        "click": function ($event) {
          return _vm.sort(item);
        }
      }
    }, [_vm._t('HEAD_' + item.key, function () {
      var _vm$originalFields, _vm$originalFields$it, _vm$originalFields2, _vm$originalFields2$i;

      return [_c('div', {
        staticClass: "table__th",
        class: {
          'sortable': (_vm$originalFields = _vm.originalFields) === null || _vm$originalFields === void 0 ? void 0 : (_vm$originalFields$it = _vm$originalFields[item.key]) === null || _vm$originalFields$it === void 0 ? void 0 : _vm$originalFields$it['sortable']
        }
      }, [_c('div', [_vm._v(" " + _vm._s(item.label) + " ")]), (_vm$originalFields2 = _vm.originalFields) !== null && _vm$originalFields2 !== void 0 && (_vm$originalFields2$i = _vm$originalFields2[item.key]) !== null && _vm$originalFields2$i !== void 0 && _vm$originalFields2$i['sortable'] && !_vm.hideSorting ? _c('div', {
        staticClass: "table__sort"
      }, [_c('div', {
        staticClass: "table__sort-arrow table__sort-arrow--top",
        class: {
          'active': _vm.mutatedSortBy === item.key && _vm.mutatedSortDirection === 'asc'
        }
      }, [_c('svg', {
        attrs: {
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "11",
          "height": "6",
          "viewBox": "0 0 11 6"
        }
      }, [_c('g', [_c('g', [_c('path', {
        attrs: {
          "d": "M6.068 5.49a.594.594 0 0 1-.42.175h-.3a.61.61 0 0 1-.42-.174L.868 1.42a.396.396 0 0 1 0-.561l.561-.562a.388.388 0 0 1 .554 0L5.498 3.82 9.013.298a.396.396 0 0 1 .562 0l.555.562a.396.396 0 0 1 0 .562z"
        }
      })])])])]), _c('div', {
        staticClass: "table__sort-arrow",
        class: {
          'active': _vm.mutatedSortBy === item.key && _vm.mutatedSortDirection === 'desc'
        }
      }, [_c('svg', {
        attrs: {
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "11",
          "height": "6",
          "viewBox": "0 0 11 6"
        }
      }, [_c('g', [_c('g', [_c('path', {
        attrs: {
          "d": "M6.068 5.49a.594.594 0 0 1-.42.175h-.3a.61.61 0 0 1-.42-.174L.868 1.42a.396.396 0 0 1 0-.561l.561-.562a.388.388 0 0 1 .554 0L5.498 3.82 9.013.298a.396.396 0 0 1 .562 0l.555.562a.396.396 0 0 1 0 .562z"
        }
      })])])])])]) : _vm._e()])];
    }, {
      "rows": _vm.finalRows
    })], 2);
  }), 0)]), _vm._t("table-content", function () {
    return [_c('tbody', [_vm.loading ? _vm._l(_vm.perPage, function (row, row_index) {
      return _c('tr', {
        key: row_index
      }, _vm._l(_vm.fields, function (cell, cell_index) {
        return _c('td', {
          key: cell_index
        }, [_c('div', {
          staticClass: "table__loading"
        })]);
      }), 0);
    }) : [_vm._t("tbody", function () {
      return _vm._l(_vm.finalRows, function (row, row_key) {
        return _c('tr', {
          key: row_key
        }, _vm._l(row, function (item, cell_key) {
          return _c('td', {
            key: cell_key
          }, [_vm._t(cell_key, function () {
            return [_vm._v(_vm._s(item))];
          }, {
            "item": row
          })], 2);
        }), 0);
      });
    }, {
      "rows": _vm.finalRows
    })]], 2)];
  }, {
    "rows": _vm.finalRows
  })], 2), _vm.pagination && _vm.perPage < _vm.items.length ? _c('app-pagination', {
    attrs: {
      "per-page": _vm.perPage,
      "total-rows": _vm.items.length,
      "current-page": _vm.mutatedCurrentPage,
      "select-page-as-dot": true
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.mutatedCurrentPage = $event;
      },
      "update:current-page": function ($event) {
        _vm.mutatedCurrentPage = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }