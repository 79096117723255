<template>
    <router-link v-if="userCan('view-another-user')" :to="{name: 'user_show', params: {user_id: computedItem?.id}}">
        {{ name }}
    </router-link>
    <span v-else>
        {{ name }}
    </span>
</template>

<script>
export default {
    name: "userLink",
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        position: {
            type: Boolean,
            default: true,
        }
    },
    watch: {
        params: {
            handler() {
                let data = this.params?.data?.user;

                if (data) {
                    this.paramValue = data;
                }
            },
            immediate: true
        }
    },
    computed: {

        computedItem() {
            if (this.paramValue) {
                return this.paramValue;
            }

            return this.item;
        },

        name() {
            let name = this.computedItem?.name;

            if (this.position && this.computedItem?.positionName) {
                name += " (" + this.computedItem?.positionName + ")";
            }

            return name;
        }

    },
    data() {
        return {
            paramValue: null,
        }
    }
}
</script>