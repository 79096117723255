var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', _vm._g({
    staticClass: "btn btn-icon"
  }, _vm.$listeners), [!_vm.loading ? [_vm._t("default")] : _c('loading-icon')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }