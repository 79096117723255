<template>
    <modal v-show="visible" @close="close">
        <div class="form-group">
            <label>Půjčená věc</label>
            <select class="form-control" v-model="rent.thing_id" :disabled="!select_thing || returnMode">
                <option v-for="item in things" :value="item.id">{{ item.name }}</option>
            </select>
        </div>

        <template v-if="!returnMode">
            <div class="form-group">
                <label>Kdo si půjčuje</label>
                <select class="form-control" v-model="rent.user_id" :disabled="!select_user">
                    <option v-for="item in $store.state.allUsers" :value="item.id">{{ item.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Poznámka</label>
                <input class="form-control" type="text" v-model="rent.text"/>
            </div>

            <div class="form-group">
                <label>Datum zapůjčení</label>
                <app-date v-model="rent.rented_at"/>
            </div>
        </template>
        <template v-if="isOffice() || returnMode">
            <div class="form-group">
                <label>Datum vrácení</label>
                <app-date v-model="rent.returned_at"/>
            </div>
        </template>

        <app-button slug="btn_createRent" @click="createRent" class="btn-primary">Uložit</app-button>
    </modal>
</template>

<script>
import thingService from "@services/thingService";

export default {
    name: "rentModal",

    mounted() {
        this.fetchData();
        this.rent.rented_at = this.$moment().format("YYYY-MM-DD");

        if (this.isOffice()) {
            this.select_user = true;
            this.select_thing = true;
        } else {
            this.rent.user_id = this.getUserID();
        }

    },

    methods: {

        createRent() {
            this.setLoading("btn_createRent");

            let data = {
                rent: this.rent,
            };

            thingService.rentThing(data).then(res => {
                this.$emit("updated");
                this.$toast.success("Uloženo.");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_createRent");
            });

        },

        fetchData() {

            thingService.thingsList().then(res => {
                this.things = res.data.things;
            }).catch(e => {
                this.showError(e);
            });

        },

        open(atts = {}, wantReturn = false) {
            let {thing_id, user_id, rented_at, returned_at, text, id} = atts;

            this.rent.id = id;
            this.rent.thing_id = thing_id;

            if (user_id)
                this.rent.user_id = user_id;

            this.rent.text = text;
            this.rent.rented_at = this.$moment(rented_at).format("YYYY-MM-DD");

            if (returned_at) {
                this.rent.returned_at = this.$moment(returned_at).format("YYYY-MM-DD");
            } else {
                this.rent.returned_at = null;
            }

            this.returnMode = wantReturn;

            this.visible = true;
        },

        close() {
            this.$emit("close");
            this.visible = false;
        }

    },

    data() {
        return {
            visible: false,
            rent: {},
            things: [],
            select_user: false,
            select_thing: true,
            returnMode: false,
        }
    }

}
</script>