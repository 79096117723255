import axios from "@app/helpers/apiServer";

export default {

    getNotes(data = {}) {
        return axios.post("/ajax-note/index", data);
    },

    updateNote(data = {}) {
        return axios({
            url: "/ajax-note/update",
            method: "post",
            data,
        });
    },

    deleteNote(data = {}) {
        return axios({
            url: "/ajax-note/delete",
            method: "post",
            data,
        });
    },

}