<template>
    <table class="table">
        <thead>
            <tr>
                <th v-for="item in fields">{{ item }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in items">
                <td v-for="(key, index) in fields">
                    <model-link v-if="index == 0" :model="model" :model-id="item.id" :label="markMatch(item[key])"></model-link>
                    <div v-else-if="model == 'client' && key == 'Osoby'">
                        <search-table :items="item?.[key]" :word="word"/>
                    </div>
                    <div v-else v-html="markMatch(item?.[key])"></div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import ModelLink from "@components/modelLink";
import Mark from "mark.js/dist/mark.es6.min"

export default {
    name: "searchTable",
    components: {ModelLink},
    props: {
        model: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
        word: {
            type: String,
            default: null,
        }
    },

    methods: {
        markMatch(str) {
            let word = this.word;

            let a = document.createElement("span");
            a.innerHTML = str;
            let mark = new Mark(a);
            mark.mark(word);

            return a.innerHTML;

            return str.replace(new RegExp(word, "gi"), match => {
                return '<span class="highlightText">' + match + '</span>';
            });
        }
    },

    computed: {
        fields: function () {
            let item = this.removeReactivity(this.items?.[0] ?? {});
            delete item?.id;
            return Object.keys(item);
        }
    },


}
</script>
<style lang="scss">
.highlightText {
    background: yellow;
    color: black;
}
</style>