// New Date method to return date in format Y-m-d, example: 2019-05-09

Date.prototype.toYMD = function () {
    let date = this;

    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

// capitalize first letter of string
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
};

// Remove from array
Array.prototype.pull = function (value) {
    let index = this.indexOf(value);
    if (index >= 0) {
        this.splice(index, 1);
    }
};
Array.prototype.toggle = function (value) {
    let index = this.indexOf(value);
    if (index >= 0) {
        this.splice(index, 1);
    } else {
        this.push(value);
    }
    return this;
};

function getObjectValueByPath(obj, path = null) {
    if (path.includes(".")) {
        let paths = path.split('.')
            , current = obj
            , i;

        for (i = 0; i < paths.length; ++i) {
            if (current[paths[i]] == undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    } else {
        return obj?.[path];
    }
}

Array.prototype.sum = function (key = null) {
    if (key) {
        return this.reduce((a, b) => {
            let v = getObjectValueByPath(b, key) || 0;
            return a + v;
        }, 0);
    } else {
        return this.reduce((a, b) => a + b, 0);
    }
};

Math.randomNumber = function (min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

Number.prototype.formatNumber = function (decimalCount = 0,
                                          decimal = ",",
                                          thousands = " ") {
    let amount = this;
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ?
                decimal +
                Math.abs(amount - i)
                    .toFixed(decimalCount)
                    .slice(2) :
                "")
        );
    } catch (e) {
    }
};

String.prototype.formatNumber = function () {
    let str = this;
    str = parseFloat(str);
    return str.formatNumber(arguments);
};

String.prototype.translate = function (count = null, params = {}) {
    let key = this;

    if (count !== null) {
        return Translator.transChoice(key, count, params);
    } else {
        return Translator.trans(key, params);
    }
};

let fnc_formatCurrency = function (currency = "", decimalCount = 2) {
    let number = this;

    if (!currency)
        currency = "czk";

    let this_currency = currency.toLowerCase();

    let formatted = number.formatNumber(decimalCount);

    if (this_currency === "czk")
        formatted = formatted + " Kč";

    if (this_currency === "eur")
        formatted = formatted + " €";

    if (this_currency === "pln")
        formatted = formatted + " Zł";

    if (this_currency === "usd")
        formatted = "$" + formatted;

    return formatted;
};

String.prototype.formatCurrency = fnc_formatCurrency;
Number.prototype.formatCurrency = fnc_formatCurrency;

function fnc_removeReactivity() {
    let val = this;
    return JSON.parse(JSON.stringify(val));
}

let removeReactivity = fnc_removeReactivity;

String.prototype.removeReactivity = fnc_removeReactivity;
Number.prototype.removeReactivity = fnc_removeReactivity;
Array.prototype.removeReactivity = fnc_removeReactivity;

Array.prototype.orderBy = function (column = null, isNumber = false, isAsc = true) {
    let arr = this;

    if (isNumber) {
        if (isAsc) {
            return arr.slice().sort((a, b) => a?.[column] > b?.[column]);
        } else {
            return arr.slice().sort((a, b) => b?.[column] < a?.[column]);
        }
    } else {
        if (isAsc) {
            return arr.slice().sort((a, b) => a?.[column].localeCompare(b?.[column]));
        } else {
            return arr.slice().sort((a, b) => b?.[column].localeCompare(a?.[column]));
        }
    }

};