<template>
    <div class="popup" v-show="visible">
        <div class="popup__wrapper">
            <div class="popup__background" @click="close"></div>

            <div v-if="viewLoading">Načítání ...</div>

            <div v-if="viewMode" class="popup__body">
                <div class="row">
                    <div class="col-6">
                        <h3>Strávené hodiny</h3>
                    </div>
                    <div class="col-6 flex-buttons">
                        <button v-if="canEditHours" @click="viewMode = false" class="btn btn-danger">Upravit</button>
                    </div>
                </div>
                <b>Kdo:</b> {{ item?.user?.name }}<br> <b>Kdy:</b> {{ localizeDate(item?.day) }}<br>
                <b>Kolik:</b> {{ item?.amount }} hodin<br> <b>Poznámka:</b> {{ item?.note || "- nezadána -" }}<br> <br>
                <project-files :files="item.files" model-type="project_hour" :model-id="item.id"/>

            </div>
            <form v-else class="popup__body" @submit.prevent="save">
                <template v-if="allLoaded">
                    <template v-if="select_project">
                        <div class="popup__title">Vyberte obec</div>
                        <div class="popup__content">
                            <app-select auto-load="used_cities" v-model="preselectCity"/>
                        </div>
                        <div class="popup__title">Vyberte zakázku</div>
                        <div class="popup__content">
                            <app-select v-model="selectedProject" :options="computedProjects"/>
                        </div>
                    </template>
                    <template v-if="canAddHoursToUser()">
                        <div class="popup__title">Uživatel</div>
                        <div class="popup__content">
                            <app-select v-model="selectedUser" :options="computedUsers" option-key="id" option-label="name"/>
                        </div>
                    </template>
                    <div class="popup__title">Datum</div>
                    <div class="popup__content">
                        <app-date v-model="selectedDate" :disabled-date="isDateDisabled"/>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="popup__title">Počet hodin</div>
                            <div class="popup__content">
                                <input class="form-control" type="number" required v-model="hours">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="popup__title">Počet minut</div>
                            <div class="popup__content">
                                <select class="form-control" required v-model="minutes">
                                    <option value="0">00</option>
                                    <option value="0.25">15</option>
                                    <option value="0.5">30</option>
                                    <option value="0.75">45</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="popup__title">Poznámka</div>
                    <div class="popup__content">
                        <textarea class="form-control" required v-model="note"></textarea>
                    </div>
                    <template v-if="selectedProject">
                        <div class="popup__title">Fotky</div>
                        <div class="popup__content">
                            <project-files v-if="visible" :allow-upload="true" :handle-upload="false" @newFiles="files => appendFiles = files"/>
                        </div>
                    </template>
                    <div class="popup__buttons">
                        <app-button slug="btn_saveTodaysHours" type="submit" class="btn btn-primary">Uložit</app-button>
                        <button type="button" class="btn btn-secondary" @click="close">Zrušit</button>
                    </div>
                </template>
                <div v-else>Načítání ....</div>
            </form>
        </div>
    </div>
</template>
<script>
import projectService from "@services/projectService";
import userService from "@services/userService";

export default {
    name: "addHoursModal",
    mounted() {
        this.fetchData();
    },

    watch: {
        preselectCity: {
            handler() {
                this.selectedProject = null;
            }
        }
    },

    methods: {

        isDateDisabled(day) {
            let $day = this.$moment(day);
            let $now = this.$moment();

            if ($day.isSameOrAfter($now))
                return true;

            let $maxPrev = this.$moment(this.maxPreviousDay);
            return $day.isBefore($maxPrev);
        },

        setView(atts) {
            this.item = atts;
            this.visible = true;
            this.viewMode = true;

            this.select_project = false;
            this.selectedProject = atts.project_id ?? null;

            this.selectedUser = atts.user_id ?? null;

            this.selectedDate = atts.day ?? null;

            let amount = parseFloat(atts.amount);
            let hours = Math.floor(amount);
            let minutes = amount - hours;

            this.hours = hours;
            this.minutes = minutes;

            this.note = atts.note;
        },

        view(atts = {}) {

            if (atts.id) {
                this.viewLoading = true;
                projectService.getProjectHour({id: atts.id}).then(res => {
                    let atts = res.data.item;
                    this.setView(atts);
                }).catch(e => {
                    this.showError(e);
                }).finally(r => {
                    this.viewLoading = false;
                });
                return;
            }

            this.setView(atts);
        },

        open(params = {}) {
            this.viewMode = false;

            let {
                project_id,
                user_id,
            } = params;

            this.selectedProject = project_id ?? null;
            this.select_project = this.selectedProject ? false : true;

            this.selectedUser = user_id ?? this.getUserID();

            this.item = null;
            this.visible = true;
        },

        close() {
            this.$emit('close');
            this.visible = false;
        },

        fetchData() {
            this.allLoaded = false;

            let promises = [];

            let p1 = projectService.getAllActiveProjects().then(res => {
                let data = res.data;
                this.projects = data.projects;
            }).catch(e => {
                this.showError(e);
            });

            promises.push(p1);

            if (this.canAddHoursToUser()) {
                let p2 = userService.getAllUsers().then(res => {
                    this.allUsers = res.data.users;
                }).catch(e => {
                    this.showError(e);
                });
                promises.push(p2);
            }

            Promise.all(promises).finally(res => {
                this.allLoaded = true;
            });

        },

        save(e) {
            let hours = parseInt(this.hours);
            let minutes = parseFloat(this.minutes);

            hours = hours + minutes;

            let payload = {
                project_id: this.selectedProject,
                user_id: this.selectedUser,
                date: this.selectedDate,
                hours,
                note: this.note,
                item_id: this.item?.id ?? null,
                new_files: this.appendFiles,
            };

            this.setLoading("btn_saveTodaysHours");
            projectService.saveUserHoursForProject(payload).then(res => {
                let data = res.data;
                if (data.ok) {
                    this.appendFiles = [];
                    this.$toast.success("Hodiny uloženy.");
                    this.close();
                    this.$emit("saved");
                    this.$events.$emit("assigned-hours", {user_id: this.selectedUser});

                    this.hours = 0;
                    this.minutes = 0;
                }
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_saveTodaysHours");
            });

        },
    },

    computed: {

        canEditHours() {

            let user = this.item?.user;

            if (!user || !this.canAddHoursToUser(user)) {
                return false;
            }

            let $d1 = this.$moment(this.selectedDate);
            let $d2 = this.$moment(this.maxPreviousDay);
            return $d1.isSameOrAfter($d2);
        },

        maxPreviousDay() {
            let d = this.$moment();

            let days_back = null;
            if (this.isThisUserMe(this.selectedUser)) {
                days_back = this.userGateConfig("set_my_hours_days_back");
            } else {
                days_back = this.userGateConfig("set_other_hours_days_back");
            }

            days_back = parseInt(days_back ?? 0) * -1;
            d.add(days_back, "days");
            return d.format("YYYY-MM-DD");
        },

        computedProjects: function () {
            let preselectedCity = this.preselectCity;
            let items = this.removeReactivity(this.projects);
            if (preselectedCity) {
                items = items.filter(item => {
                    return item.city_id == preselectedCity;
                });
            }

            items = items.map(item => {
                let label = item?.client?.name + " - " + item.name;
                return {
                    value: item.id,
                    label
                }
            });

            return items;
        },

        computedUsers: function () {
            let users = this.removeReactivity(this.allUsers);
            users = users.filter(user => {
                if(!user.active) return false;
                return this.canAddHoursToUser(user);
            });
            return users;
        },

    },

    data() {
        return {
            selectedDate: this.$moment().format("YYYY-MM-DD"),
            selectedProject: null,
            selectedUser: null,
            projects: [],
            allLoaded: true,
            hours: 0,
            minutes: 0,
            visible: false,
            select_project: true,
            allUsers: [],
            note: "",
            preselectCity: null,
            viewMode: false,
            item: null,
            viewLoading: false,
            appendFiles: [],
        }
    }

}
</script>
