<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="buttons">
                <router-link v-if="userCan('project.create')" :to="{name: 'project_edit'}" class="btn btn-primary">Nová zakázka</router-link>
                <router-link :to="{name: 'client_index'}" class="btn btn-secondary">Zákazníci</router-link>
                <router-link v-if="userCan('nav-cities')" :to="{name: 'city_index'}" class="btn btn-secondary">Obce</router-link>
                <router-link v-if="hasRole([1])" :to="{name: 'shop_index'}" class="btn btn-secondary">Obchody</router-link>
                <router-link v-if="hasRole([1])" :to="{name: 'project_merge'}" class="btn btn-secondary">Spojování zakázek</router-link>
                <router-link v-if="hasRole([1])" :to="{name: 'project_internal'}" class="btn btn-secondary">Interní zakázky</router-link>
            </div>
            <br><br>
            <h4>Filtrovat dle kategorie</h4>
            <div class="flex-items">
                <button class="btn btn-secondary" :class="{'btn-primary': filterProjectCategory == null}" @click="filterProjectCategory = null">Vše</button>
                <button v-for="item in filterableCategories" class="btn btn-secondary" :class="{'btn-primary': filterProjectCategory == item.id}" @click="filterProjectCategory = item.id">{{ item.label }}</button>
            </div>

            <template v-if="notVerifiedItems.length">
                <h3>Neverifikované zakázky</h3>
                <app-table v-bind="table_attributes_notVerified" :items="notVerifiedItems" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                <client-link :item="row?.client"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                {{ localizeDate(row.updated_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

            <template v-if="verifiedItems.length">
                <h3>Rozpracované zakázky</h3>

                <app-table v-bind="table_attributes_wip" :items="verifiedItems" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                <client-link :item="row?.client"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                {{ localizeDate(row.updated_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

            <template v-if="newProjects.length && userCan('project.see-planned')">
                <h3>Plánované zakázky</h3>

                <app-table v-bind="table_attributes_planned" :items="newProjects" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                <client-link :item="row?.client"/>
                            </td>
                            <td>
                                {{ localizeDate(row.created_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>

            <template v-if="computedFinished.length && userCan('project.see-finished')">
                <h3>Hotové zakázky</h3>
                <app-table v-bind="table_attributes_finished" :items="computedFinished" :local-sort="true">
                    <template slot="tbody" slot-scope="data">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>
                                {{ row.city?.name }}
                            </td>
                            <td>
                                <project-link :item="row" :hide-client="true"/>
                            </td>
                            <td>
                                <client-link :item="row?.client"/>
                            </td>
                            <td>
                                {{ localizeDate(row.finished_at) }}
                            </td>
                            <td>
                                {{ row.category_name }}
                            </td>
                            <td>
                                 <span v-if="row.finished_at">
                                     <span v-if="row.is_invoiced == 1" class="text-success bold">Ano</span>
                                    <span v-else-if="row.is_invoiced == 2" class="text-info bold">Neřeší se</span>
                                     <span v-else-if="row.is_invoiced == 3" class="text-info bold">Částečně</span>
                                    <span v-else class="text-danger bold">Ne</span>
                                </span>
                            </td>
                            <td>
                                {{ row.invoice_numbers }}
                            </td>
                        </tr>
                    </template>
                </app-table>
            </template>
        </template>
    </div>
</template>

<script>
import {gql} from "graphql-tag";

const getItems = gql`
#  workInProgressProjects: workInProgressProjectsDashboard {
query {
  workInProgressProjects {
    id
    name
    category
    city_id
    city {
        id
        name
        city_id
    }
    client {
        id
        name
    }
           is_invoiced
        is_verified
        is_public
    category_name
    created_at
    updated_at
  }
  finishedProjects {
      id
        name
        category
        is_public
        city_id
        city {
            id
            name
            city_id
        }
        client {
            id
            name
        }
        is_invoiced
        is_verified
        is_public
        category_name
        invoice_numbers
        export_link
        finished_at
  }
}
`

export default {
    name: "project_index",
    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.pageLoading = true;

            this.apolloQuery({
                query: getItems
            }).then(res => {
                this.workInProgressProjects = res.data.workInProgressProjects;
                this.finishedProjects = res.data.finishedProjects;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

    },

    computed: {

        computedFinished: function () {
            let items = this.removeReactivity(this.finishedProjects);
            if (this.filterProjectCategory) {
                items = items.filter(item => item.category == this.filterProjectCategory);
            }
            return items;
        },

        computedItems: function () {
            let items = this.removeReactivity(this.workInProgressProjects);
            if (this.filterProjectCategory) {
                items = items.filter(item => item.category == this.filterProjectCategory);
            }
            return items;
        },

        wipProjects() {
            return this.computedItems.filter(i => i.is_public);
        },

        newProjects() {
            return this.computedItems.filter(i => !i.is_public);
        },

        verifiedItems: function () {
            return this.wipProjects.filter(i => i.is_verified);
        },

        notVerifiedItems: function () {
            return this.wipProjects.filter(i => !i.is_verified);
        },

        filterableCategories: function () {
            let items = this.projectCategories;
            return Object.keys(items).map(id => {
                let label = items[id];
                return {
                    id,
                    label,
                };
            });
        },

    },

    data() {
        return {
            filterProjectCategory: null,
            projectCategories: {
                1: 'Oprava',
                2: 'Realizace',
                3: 'Kancelář',
                4: 'Režie',
                5: 'Cenová nabídka',
                6: 'Výroba',
                7: 'Prodej',
                8: 'Projekce',
                9: 'Revize',
            },
            pageLoading: true,
            workInProgressProjects: [],
            finishedProjects: [],
            table_attributes_notVerified: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true, width: '10%'},
                    {key: 'name', label: "Název", sortable: true, width: '30%'},
                    {key: 'client.name', label: "Zákazník", sortable: true, width: '30%'},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '6%'},
                    {key: 'updated_at', label: "Datum změny", sortable: true, width: '10%'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '14%'},
                ],
                pagination: true,
            },
            table_attributes_wip: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true, width: '10%'},
                    {key: 'name', label: "Název", sortable: true, width: '30%'},
                    {key: 'client.name', label: "Zákazník", sortable: true, width: '30%'},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '6%'},
                    {key: 'updated_at', label: "Datum změny", sortable: true, width: '10%'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '14%'},
                ],
                pagination: true,
            },
            table_attributes_planned: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true, width: '10%'},
                    {key: 'name', label: "Název", sortable: true, width: '30%'},
                    {key: 'client.name', label: "Zákazník", sortable: true, width: '30%'},
                    {key: 'created_at', label: "Vytvořeno", sortable: true, width: '6%'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '24%'},
                ],
                pagination: true,
            },
            table_attributes_finished: {
                currentPage: 1,
                sortBy: 'name',
                sortDirection: 'asc',
                fields: [
                    {key: 'city.name', label: "Obec", sortable: true, width: '10%'},
                    {key: 'name', label: "Název", sortable: true, width: '30%'},
                    {key: 'client.name', label: "Zákazník", sortable: true, width: '30%'},
                    {key: 'finished_at', label: "Hotovo", sortable: true, width: '6%'},
                    {key: 'category_name', label: "Kategorie", sortable: true, width: '10%'},
                    {key: 'is_invoiced', label: "Vyfakturováno", sortable: true, width: '7%'},
                    {key: 'invoice_numbers', label: "Číslo faktury", sortable: true, width: '7%'},
                ],
                pagination: true,
            },
        }
    }

}
</script>
