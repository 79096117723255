<template>
    <span v-if="item" class="td--userHoliday">
        <span>{{ computedItem.letter }}</span>
        <span v-if="computedItem.hours">({{ computedItem.hours }})</span>
    </span>
</template>

<script>
export default {
    name: "holidayCell",
    props: {
        item: {
            type: Object,
            default: null,
        }
    },
    computed: {

        computedItem: function () {
            let item = this.item;

            let letter = item.paid ? "DP" : "DN";
            if (item.type == "sickday") {
                letter = "N";
            }

            let hours = item.hours;

            if (item.type == "paragraph") {
                letter = item.paid ? "§P" : "§N";
            }

            if (!item.paid) {
                hours = null;
            }

            return {
                letter,
                hours,
            };
        },
    }
}
</script>