<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <h2>Výpis uživatelů</h2>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <users-nav/>
                </div>
                <div class="col-12 col-lg-6 flex-buttons" v-if="isAdmin()">
                    <button v-if="!table_attributes.sortable" class="btn btn-danger" @click="table_attributes.sortable = true" key="chen">Změnit pořadí</button>
                    <div v-else>
                        <app-button slug="btn_saveOrder" @click="saveOrder" class="btn btn-success" key="dochange">Uložit pořadí</app-button>
                        <button class="btn btn-danger" @click="table_attributes.sortable = false" key="cancelchange">Zrušit změnu</button>
                    </div>
                </div>
            </div>

            <br> <br>
            <app-table v-bind="table_attributes" :fields="computedFields" :items="activeUsers" :local-sort="!table_attributes.sortable" :hide-sorting="table_attributes.sortable">
                <template slot="table-content" slot-scope="data">
                    <draggable tag="tbody" v-model="items" :options="{disabled : !table_attributes.sortable}">
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>{{ row.order }}</td>
                            <td>
                                <user-link :item="row" :position="false"/>
                            </td>
                            <td>
                            <a v-if="row.meta?.phone" :href="'tel:'+row.meta.phone">
                                {{ row.meta.phone }}
                            </a>
                            </td>
                            <td v-if="userCan('offices')">{{ row.rolename }}</td>
                            <td>{{ row.positionName }}</td>
                            <td v-if="isAdmin()">
                                <div :class="[row.has_current_holiday_fund ? 'user--active' : 'user--inactive']">
                                    {{ row.has_current_holiday_fund ? "Ano" : "Ne" }}
                                </div>
                            </td>
                            <td v-if="isAdmin()">
                                <div :class="[row.active ? 'user--active' : 'user--inactive']">
                                    {{ row.active ? 'Aktivní' : 'Neaktivní' }}
                                </div>
                            </td>
                            <td v-if="isAdmin()">
                                <router-link class="btn btn-danger" :to="{name: 'user_create', params: {user_id: row.id}}">
                                    Upravit
                                </router-link>
                            </td>
                        </tr>
                    </draggable>
                </template>
            </app-table>
            <br>
            <button class="btn btn-danger" @click="inactiveTable = !inactiveTable">Zobrazit neaktivní uživatele</button>
            <br><br>
            <app-table v-if="inactiveTable" v-bind="table_attributes" :fields="computedFields" :items="inactiveUsers" :local-sort="!table_attributes.sortable" :hide-sorting="table_attributes.sortable">
                <template slot="table-content" slot-scope="data">
                    <tbody>
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>{{ row.order }}</td>
                            <td>
                                <user-link :item="row" :position="false"/>
                            </td>
                            <td>
                            <a v-if="row.meta?.phone" :href="'tel:'+row.meta.phone">
                                {{ row.meta.phone }}
                            </a>
                            </td>
                            <td v-if="userCan('offices')">{{ row.rolename }}</td>
                            <td>{{ row.positionName }}</td>
                            <td v-if="isAdmin()">
                                <div :class="[row.has_current_holiday_fund ? 'user--active' : 'user--inactive']">
                                    {{ row.has_current_holiday_fund ? "Ano" : "Ne" }}
                                </div>
                            </td>
                            <td v-if="isAdmin()">
                                <div :class="[row.active ? 'user--active' : 'user--inactive']">
                                    {{ row.active ? 'Aktivní' : 'Neaktivní' }}
                                </div>
                            </td>
                            <td v-if="isAdmin()">
                                <router-link class="btn btn-danger" :to="{name: 'user_create', params: {user_id: row.id}}">
                                    Upravit
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </app-table>
        </template>
    </div>
</template>

<script>
import userService from "@services/userService";
import UsersNav from "@components/usersNav";
import generalService from "@services/generalService";

export default {
    name: "user_index",
    components: {UsersNav},
    mounted() {
        this.fetchData();
    },

    methods: {

        saveOrder() {

            let items = this.removeReactivity(this.items).map(item => {
                return item.id;
            });

            let data = {
                model: "users",
                items,
            };

            this.setLoading("btn_saveOrder");
            generalService.saveOrder(data).then(res => {
                this.$toast.success("Uloženo!");
                this.fetchData();
                this.table_attributes.sortable = false;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_saveOrder");
            });
        },

        fetchData() {

            this.pageLoading = true;
            userService.getAllUsers().then(res => {

                let users = res.data.users;

                if (!this.isAdmin()) {
                    users = users.filter(i => i.active);
                }

                this.items = users;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },
    },

    computed: {

        computedFields: function () {
            let out = [
                {key: 'order', label: "Pořadí", sortable: true},
                {key: 'name', label: "Jméno", sortable: true},
                {key: 'meta.phone', label: "Telefon", sortable: true},
                {key: 'rolename', label: "Role", sortable: true},
                {key: 'positionName', label: "Pozice", sortable: true},
                {key: 'has_current_holiday_fund', label: "Nastavený fond dovolené"},
                {key: 'active', label: "Aktivní"},
                {key: 'controls', label: ""},
            ];

            if (!this.userCan("offices")) {
                out = out.filter(i => i.key !== 'rolename');
            }
            if (!this.isAdmin()) {
                out = out.filter(i => !["controls", "active", "has_current_holiday_fund"].includes(i.key));
            }
            return out;
        },

        activeUsers: function () {
            return this.items.filter(item => {
               return item.active;
            });
        },
        inactiveUsers: function () {
            return this.items.filter(item => {
               return !item.active;
            });
        },

    },

    data() {
        return {
            inactiveTable: false,
            items: [],
            pageLoading: false,
            table_attributes: {
                currentPage: 1,
                perPage: 100,
                sortBy: 'order',
                sortDirection: 'asc',
                pagination: true,
                sortable: false,
            },
        }
    }
}
</script>