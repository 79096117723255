var render = function () {
  var _vm$car$meta, _vm$car$meta2, _vm$car$meta3, _vm$car$meta4, _vm$car$meta5, _vm$car$meta6, _vm$car$meta7, _vm$car, _vm$car$meta8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('add-car-notification', {
    ref: "addCarNotification",
    on: {
      "updated": _vm.fetchData
    }
  }), _c('add-event', {
    ref: "addEvent",
    on: {
      "updated": _vm.fetchData
    }
  }), _vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "tabs"
  }, [_c('h2', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'main'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'main';
      }
    }
  }, [_vm._v("Detail auta")]), _c('h2', {
    staticClass: "tab__item",
    class: {
      'active': _vm.tab == 'history'
    },
    on: {
      "click": function ($event) {
        _vm.tab = 'history';
      }
    }
  }, [_vm._v("Historie auta")])])]), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.userCan('car.update') ? _c('router-link', {
    staticClass: "btn btn-danger",
    attrs: {
      "to": {
        name: 'car_update',
        params: {
          car_id: _vm.car.id
        }
      }
    }
  }, [_vm._v(" Upravit auto ")]) : _vm._e()], 1)]), _vm.tab == 'main' ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Název:")]), _vm._v(" " + _vm._s(_vm.car.name) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("SPZ:")]), _vm._v(" " + _vm._s((_vm$car$meta = _vm.car.meta) === null || _vm$car$meta === void 0 ? void 0 : _vm$car$meta.plate) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Popis:")]), _vm._v(" " + _vm._s((_vm$car$meta2 = _vm.car.meta) === null || _vm$car$meta2 === void 0 ? void 0 : _vm$car$meta2.info) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("VIN kód:")]), _vm._v(" " + _vm._s((_vm$car$meta3 = _vm.car.meta) === null || _vm$car$meta3 === void 0 ? void 0 : _vm$car$meta3.vin_number) + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Expirace STK:")]), _vm._v(" " + _vm._s(_vm.car.stkExpirationDate ? _vm.car.stkExpirationDate : "Nezadáno") + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Údržba auta provedena:")]), _vm._v(" " + _vm._s((_vm$car$meta4 = _vm.car.meta) !== null && _vm$car$meta4 !== void 0 && _vm$car$meta4.car_maintenance_at ? _vm.$moment((_vm$car$meta5 = _vm.car.meta) === null || _vm$car$meta5 === void 0 ? void 0 : _vm$car$meta5.car_maintenance_at).format("DD. MM. YYYY") : "Nezadáno") + " ")]), _c('div', {
    staticClass: "task__row"
  }, [_c('div', {
    staticClass: "task__label"
  }, [_vm._v("Dálniční známka do:")]), _vm._v(" " + _vm._s((_vm$car$meta6 = _vm.car.meta) !== null && _vm$car$meta6 !== void 0 && _vm$car$meta6.toll_license_expires ? _vm.$moment((_vm$car$meta7 = _vm.car.meta) === null || _vm$car$meta7 === void 0 ? void 0 : _vm$car$meta7.toll_license_expires).format("DD. MM. YYYY") : "Nezadáno") + " ")]), !((_vm$car = _vm.car) !== null && _vm$car !== void 0 && (_vm$car$meta8 = _vm$car.meta) !== null && _vm$car$meta8 !== void 0 && _vm$car$meta8.disable_refueling) ? [_c('h3', [_vm._v("Historie tankování")]), _vm._l(_vm.gas_receipts, function (event) {
    var _event$meta$liters, _event$meta;

    return _c('div', {
      staticClass: "task__row"
    }, [_c('div', {
      staticClass: "task__label"
    }, [_vm._v(_vm._s(_vm.localizeDate(event.target_date)))]), _c('router-link', {
      attrs: {
        "to": {
          name: 'cost_show',
          params: {
            cost_id: event.id
          }
        }
      }
    }, [_vm._v(_vm._s((_event$meta$liters = event === null || event === void 0 ? void 0 : (_event$meta = event.meta) === null || _event$meta === void 0 ? void 0 : _event$meta.liters) !== null && _event$meta$liters !== void 0 ? _event$meta$liters : 0) + " litrů")])], 1);
  })] : _vm._e()], 2), _c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', [_vm._v("Čekající události")])]), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_vm.userCan('car.update') ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.createNotification
    }
  }, [_vm._v("Vytvořit upomínku")]) : _vm._e()])]), _vm.unProcessedEvents.length ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Poznámka")]), _c('th', [_vm._v("Upozornit")]), _c('th')])]), _c('tbody', _vm._l(_vm.unProcessedEvents, function (event) {
    return _c('tr', [_c('td', [_vm._v(_vm._s(event.text))]), _c('td', [_vm._v(_vm._s(_vm.localizeDate(event.notify_at)))]), _c('td', [_vm.userCan('offices') ? _c('div', {
      staticClass: "flex-buttons"
    }, [_c('app-button', {
      staticClass: "btn-primary",
      attrs: {
        "slug": 'btn_notification_' + event.id
      },
      on: {
        "click": function ($event) {
          return _vm.removeNotification(event);
        }
      }
    }, [_vm._v("Hotovo")]), _c('button', {
      staticClass: "btn btn-danger",
      on: {
        "click": function ($event) {
          return _vm.createNotification(event);
        }
      }
    }, [_vm._v("Upravit")])], 1) : _vm._e()])]);
  }), 0)]) : _c('div', [_vm._v("Žádné čekající události.")]), _vm.car.id ? _c('manage-cars', {
    attrs: {
      "by-car": _vm.car.id
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('h3', [_vm._v("Fotky a soubory")]), _c('project-files', {
    attrs: {
      "allowUpload": _vm.userCan('car.update'),
      "files": _vm.car.files,
      "model-id": _vm.car.id,
      "model-type": "car"
    },
    on: {
      "updated": _vm.fetchData
    }
  })], 1)])] : _vm._e(), _vm.tab == 'history' ? [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', [_vm._v("Historie")])]), _c('div', {
    staticClass: "col-6 flex-buttons"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v("Přidat poznámku")])])]), _c('div', {
    staticClass: "cards"
  }, _vm._l(_vm.historyItems, function (item) {
    var _item$user$name, _item$user;

    return _c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card-body"
    }, [_c('p', {
      staticClass: "card-text"
    }, [_vm._v(_vm._s(item.text))]), _c('project-files', {
      staticClass: "gallery--small",
      attrs: {
        "files": item.files
      }
    }), _c('h6', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s((_item$user$name = item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.name) !== null && _item$user$name !== void 0 ? _item$user$name : "Anonym") + " | " + _vm._s(_vm.localizeDate(item.created_at)))]), _c('div', {
      staticClass: "flex-buttons"
    }, [_vm.userCan('office') ? _c('button', {
      staticClass: "btn btn-danger btn-icon",
      on: {
        "click": function ($event) {
          return _vm.editNote(item);
        }
      }
    }, [_c('icon', {
      attrs: {
        "name": "edit"
      }
    })], 1) : _vm._e()])], 1)]);
  }), 0)])])] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }