import axios from "@app/helpers/apiServer";

export default {

    getCars() {
        return axios({
            url: "/ajax-car/get-cars",
            method: "GET",
            responseType: "json"
        });
    },

    getMonthReport(data) {
        return axios({
            url: "/ajax-car/get-month-report",
            method: "post",
            responseType: "json",
            data,
        });
    },

    getCar(car_id, params = {}) {

        return axios({
            url: "/ajax-car/detail/"+car_id,
            method: "get",
            responseType: "json",
            params
        });
    },

    saveCar(data) {
        return axios({
            url: "/ajax-car/update",
            method: "post",
            responseType: "json",
            data,
        });
    },

    createNotification(data) {
        return axios({
            url: "/ajax-car/create-notification",
            method: "post",
            responseType: "json",
            data,
        });
    },

    processNotification(data) {
        return axios({
            url: "/ajax-car/process-notification",
            method: "post",
            responseType: "json",
            data,
        });
    },

    assignCarsToUsers(data) {
        return axios({
            url: "/ajax-car/assign-users",
            method: "post",
            responseType: "json",
            data,
        });
    },

    detachCar(data) {
        return axios({
            url: "/ajax-car/detach-users",
            method: "post",
            responseType: "json",
            data,
        });
    },

    getCarsByUsers(data) {
        return axios({
            url: "/ajax-car/cars-by-users",
            method: "post",
            responseType: "json",
            data,
        });
    },

}