<template>
    <div>
        <h2>Měsíční report</h2>
        <users-nav/>

        <div class="report_arrows">
            <router-link class="report_arrow" :to="prevMonth">&lAarr;</router-link>
            <div class="report_label">{{ currentDate }}</div>
            <router-link class="report_arrow" :to="nextMonth">&rAarr;</router-link>
        </div>

        <page-loader v-if="pageLoading"/>
        <template v-else>
            <holiday-legend/>
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>Jméno</th>
                        <th v-for="(day, index) in daysInMonth" :class="{'th--today': day.isToday}">
                            {{ day.index }}.
                        </th>
                        <th>C(h)</th>
                        <th>C(D+§)</th>
                        <th>C</th>
                    </tr>
                    <tr v-for="user in users">
                        <td>
                            <user-link :item="user"/>
                        </td>
                        <td v-for="(item) in daysInMonth" :class="localRenderDayClass(item, user)">
                            <holiday-cell :item="findHoliday(user, item)"/>
                            <span v-html="tableGrid?.items?.[user.user_id]?.[item.key]"></span>
                        </td>
                        <td>{{ tableGrid?.items?.[user.user_id]?.spent_hours_total }}</td>
                        <td>{{ tableGrid?.items?.[user.user_id]?.spent_holidays_total }}</td>
                        <td>{{ tableGrid?.items?.[user.user_id]?.spent_total }}</td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import userService from "@services/userService";
import UsersNav from "@components/usersNav";
import HolidayLegend from "@components/holidayLegend";

export default {
    name: "user_reports",
    components: {HolidayLegend, UsersNav},
    mounted() {
        this.target_date = this.$route.params.date ?? this.$moment().format("YYYY-MM");
        this.fetchData();
    },

    methods: {

        findHoliday(user, day) {
            let day_key = day.key;
            return user.items?.[day_key]?.find(item => item.object_key == 'holiday');
        },

        localRenderDayClass(item, user = null) {
            let classes = [];
            let day_class = this.renderDayClass(item);
            if (day_class)
                classes.push(day_class);

            let local_class = this.tableGrid?.classes?.[user.user_id]?.[item.key];
            if (local_class)
                classes.push(local_class);

            return classes.join(" ");
        },

        fetchData() {
            this.pageLoading = true;
            let data = {
                date: this.$route.params.date ?? null,
            };
            userService.getMonthReport(data).then(res => {
                let data = res.data;
                this.users = data.users;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },

    },

    computed: {

        tableGrid() {
            let out = {};
            let classes = {};
            this.users.forEach(user => {
                let user_id = user.user_id;

                out[user_id] = {
                    spent_hours_total: 0,
                    spent_holidays_total: 0,
                    spent_total: 0,
                };
                classes[user_id] = {};

                this.daysInMonth.forEach(day => {
                    let day_key = day.key;
                    let holiday = user?.items?.[day_key]?.find(i => i.object_key == "holiday" && i.paid)?.hours ?? 0;
                    let spent_hours = user?.items?.[day_key]?.find(i => i.object_key == "spent")?.hours ?? 0;

                    spent_hours = parseFloat(spent_hours);
                    holiday = parseFloat(holiday);

                    out[user_id][day.key] = spent_hours || "-";
                    out[user_id].spent_hours_total += spent_hours;
                    out[user_id].spent_holidays_total += holiday;
                    out[user_id].spent_total += spent_hours + holiday;
                });
            });

            return {
                items: out,
                classes
            };
        },

        daysInMonth() {
            if (!this.target_date)
                return [];
            return this.getDaysInMonth(this.target_date);
        },
        currentDate() {
            return this.$moment(this.target_date).format("MMMM YYYY");
        },

        prevMonth() {
            return {
                name: "user_reports",
                params: {date: this.$moment(this.target_date).add(-1, "month").format("YYYY-MM")}
            };
        },

        nextMonth() {
            return {
                name: "user_reports",
                params: {date: this.$moment(this.target_date).add(1, "month").format("YYYY-MM")}
            };
        },
    },

    data() {
        return {
            users: [],
            pageLoading: true,
            target_date: null,
        }
    }
}
</script>