var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.link ? _c('router-link', {
    attrs: {
      "to": _vm.link
    },
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    }
  }) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }