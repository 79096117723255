<template>
    <div>
        <modal v-show="addPairModal.visible" @close="addPairModal.visible = false">
            <div class="form-group">
                <label>Auto</label>
                <app-select :options="$store.state.allCars" option-key="id" option-label="name" v-model="addPairModal.car_id"/>
            </div>

            <div class="form-group">
                <label>Uživatel</label>
                <app-select :options="$store.state.allUsers" option-key="id" option-label="name" v-model="addPairModal.user_id"/>
            </div>

            <app-button type="button" slug="btn_addPair" @click="addPair" class="btn-primary">Uložit</app-button>
        </modal>

        <div class="row align-items-center">
            <div class="col-6">
                <h3>Přiřazené osoby</h3>
            </div>
            <div class="col-6 flex-buttons">
                <button type="button" v-if="isAdmin()" class="btn btn-primary" @click="openModal">Přidat</button>
            </div>
        </div>

        <page-loader v-if="loading"/>
        <table v-else class="table">
            <thead>
                <tr>
                    <th>Uživatel</th>
                    <th>Auto</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items">
                    <td>
                        <user-link :item="item.user"/>
                    </td>
                    <td>{{ item.car.name }}</td>
                    <td>
                        <div class="flex-buttons">
                            <button type="button" v-if="isAdmin()" class="btn btn-danger btn-icon" @click="deletePair(item.user.id, item.car.id)">
                                <icon name="trash"/>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import carService from "@services/carService";

export default {
    name: "manageCars",
    props: {
        byCar: {
            type: Number,
            default: null
        },
        byUser: {
            type: Boolean,
            default: null
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {

        deletePair(uid, cid) {

            let data = {
                user_id: uid,
                car_id: cid,
            };

            carService.detachCar(data).then(res => {
                this.addPairModal.visible = false;
                this.$toast.success("Uloženo.");
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {

            });

        },

        openModal() {
            this.addPairModal.visible = true
            this.addPairModal.user_id = this.removeReactivity(this.uid);
            this.addPairModal.car_id = this.removeReactivity(this.byCar);
        },

        addPair() {

            let data = {
                users: [this.addPairModal.user_id],
                cars: [this.addPairModal.car_id]
            };

            this.setLoading("btn_addPair");
            carService.assignCarsToUsers(data).then(res => {
                this.$toast.success("Uloženo!");
                this.fetchData();
                this.addPairModal.visible = false;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_addPair");
            });

        },

        fetchData() {
            this.loading = true;
            let data = {
                user_id: this.uid,
                car_id: this.byCar,
            };

            carService.getCarsByUsers(data).then(res => {
                let out = [];
                res.data.users.forEach(user => {
                    res.data.cars.forEach(car => {
                        out.push({
                            car,
                            user
                        });
                    });
                });
                this.items = out;
            }).catch(e => {
                this.showError(e);
                this.items = [];
            }).finally(res => {
                this.loading = false;
            });
        }
    },
    computed: {

        uid: function () {
            if (this.byUser) {
                if (Number.isInteger(this.byUser)) {
                    return this.byUser;
                }
                return this.getUserID();
            }
            return null;
        },

    },
    data() {
        return {
            addPairModal: {
                visible: false,
                user_id: null,
                car_id: null,
            },
            items: [],
            loading: false,
        }
    }
}
</script>