<template>
    <div>
        <h2>Měsíční report tankování</h2>
        <p>Celkem lístků: {{ receiptsCount }}</p>

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="report_arrows">
                    <router-link :to="{name: 'car_reports', params: {date: prevDate}}" class="report_arrow">
                        &lAarr;
                    </router-link>
                    <div class="report_label">{{ currentDate }}</div>
                    <router-link :to="{name: 'car_reports', params: {date: nextDate}}" class="report_arrow">
                        &rAarr;
                    </router-link>
                </div>

            </div>
            <div class="col-12 col-lg-6 flex-buttons">
                <a :href="xlsLink" download class="btn btn-primary">Stáhnout XLS</a>
            </div>
        </div>

        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="table-wrapper">
                <table class="table table-striped" colspan="0" rowspan="0" cellspacing="0">
                    <tr>
                        <th>Jméno</th>
                        <th v-for="(item) in items">{{ item.date | moment("D.") }}</th>
                        <th>Celkem</th>
                    </tr>
                    <tr v-for="car in cars">
                        <td>
                            <router-link :to="{name: 'car_detail', params: {car_id: car.id}}">
                                {{ car.fullname }}
                            </router-link>
                        </td>
                        <td v-for="item in items">
                            <div style="display: flex; flex-flow: column; gap: 4px">
                                <div v-for="event in item.items.filter(i => i.meta?.car_id == car.id)">
                                    <router-link :to="{name: 'cost_show', params: {cost_id: event.id}}">{{ event.meta?.liters ?? 0 }} l</router-link>
                                    <br>{{ parseFloat(event.value ?? 0).formatCurrency() }}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="display: flex; flex-flow: column; gap: 4px">
                                {{ (gasByCar?.[car.id]?.liters ?? 0).toFixed(2) }} l
                                <div v-if="gasByCar?.[car.id]?.liters">{{ parseFloat(gasByCar?.[car.id]?.price ?? 0).formatCurrency() }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Celkem za všechny vozidla
                        </td>
                        <td :colspan="items.length"></td>
                        <td>
                            <div style="display: flex; flex-flow: column; gap: 4px">
                                {{ (gasByCar?.["total"]?.liters ?? 0).toFixed(2) }} l
                                <div v-if="gasByCar?.['total']?.liters">{{ parseFloat(gasByCar?.['total']?.price ?? 0).formatCurrency() }}</div>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </template>
    </div>
</template>

<script>
import carService from "@services/carService";

export default {
    name: "car_reports",
    components: {},
    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {
            this.pageLoading = true;
            let data = {
                date: this.$route.params.date ?? null,
            };
            carService.getMonthReport(data).then(res => {
                let data = res.data;
                this.items = data.days;
                this.current_date = this.$moment(data.current_date, "YYYY-MM");

            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });
        }

    },

    computed: {

        gasByCar() {

            let items = {
                total: {
                    liters: 0,
                    price: 0,
                }
            };
            this.items.forEach(item => {
                item.items.forEach(subitem => {
                    let car_id = subitem?.meta?.car_id;
                    let liters = parseFloat(subitem?.meta?.liters ?? 0);

                    if (!items[car_id]) {
                        items[car_id] = {
                            liters: 0,
                            price: 0,
                        };
                    }

                    items["total"].liters += liters;
                    items["total"].price += parseFloat(subitem.value);

                    items[car_id].liters += liters;
                    items[car_id].price += parseFloat(subitem.value);
                });
            });

            return items;
        },

        cars() {
            return this.$store.state.allCars.filter(i => !i.meta?.disable_refueling);
        },

        currentDate() {
            if (!this.current_date)
                return null;

            return this.current_date.format("MMMM YYYY");
        },

        prevDate() {
            if (!this.current_date)
                return null;

            return this.current_date.clone().subtract(1, "M").format("YYYY-MM");
        },

        nextDate() {
            if (!this.current_date)
                return null;

            return this.current_date.clone().add(1, "M").format("YYYY-MM");
        },

        xlsLink() {
            if (!this.current_date)
                return null;

            let ym = this.current_date.clone().format("YYYY-MM");
            return process.env.VUE_APP_API_URL + "/ajax-car/gas-export/" + ym;
        },

        receiptsCount() {
            let count = 0;
            this.items.forEach(day => {
                count += day.items.length ?? 0;
            });
            return count;
        }

    },

    data() {
        return {
            pageLoading: true,
            current_date: null,
            items: [],
        }
    }
}
</script>
