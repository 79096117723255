var render = function () {
  var _vm$user;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-layout"
  }, [_c('my-spent-hours', {
    ref: "mySpentHours"
  }), _c('div', {
    staticClass: "menu__bg",
    class: {
      'opened': _vm.menuOpened
    },
    on: {
      "click": function ($event) {
        _vm.menuOpened = false;
      }
    }
  }), _vm.pageLoading ? _c('page-loader') : [_c('nav', {
    staticClass: "header no-print"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": {
        name: 'homepage'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/revis_logo.svg"
    }
  })]), (_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.id ? _c('a', {
    staticClass: "header__hours",
    on: {
      "click": function ($event) {
        return _vm.$refs.mySpentHours.open();
      }
    }
  }, [_vm._v(" Dnes zapsáno: "), _c('span', [_vm._v(_vm._s(_vm.todaysHours) + "h")]), _vm.todaysWeather ? _c('weather-widget', {
    attrs: {
      "item": _vm.todaysWeather,
      "small": true
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.user.id ? _c('div', {
    staticClass: "header__nav",
    class: {
      'opened': _vm.menuOpened
    }
  }, [_c('a', {
    staticClass: "header__close",
    on: {
      "click": function ($event) {
        _vm.menuOpened = false;
      }
    }
  }, [_vm._v("Zavřít")]), _vm.userCan('offices') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'search'
      }
    }
  }, [_vm._v("Hledat")]) : _vm._e(), _vm.userCan('nav-projects') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'project_index'
      }
    }
  }, [_vm._v("Zakázky")]) : _vm._e(), _vm.userCan('nav-costs') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'cost_archive'
      }
    }
  }, [_vm._v("Náklady")]) : _vm._e(), _vm.userCan('nav-users') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'user_index'
      }
    }
  }, [_vm._v("Uživatelé")]) : _vm._e(), _vm.userCan('nav-cars') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'car_index'
      }
    }
  }, [_vm._v("Auta")]) : _vm._e(), _vm.userCan('nav-plan') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'plan_index'
      }
    }
  }, [_vm._v("Plán")]) : _vm._e(), _vm.userCan('nav-tasks') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'task_index'
      }
    }
  }, [_vm._v("Úkoly")]) : _vm._e(), _vm.userCan('offices') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'paidCosts'
      }
    }
  }, [_vm._v("Pokladna")]) : _vm._e(), _vm.userCan('nav-rents') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'thing_rents'
      }
    }
  }, [_vm._v("Půjčovna")]) : _vm._e(), _vm.userCan('nav-things') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'thing_list'
      }
    }
  }, [_vm._v("Majetek")]) : _vm._e(), _c('router-link', {
    attrs: {
      "to": {
        name: 'pinboard'
      }
    }
  }, [_vm._v("Nástěnka")]), _vm.userCan('nav-profile') ? _c('router-link', {
    attrs: {
      "to": {
        name: 'user_profile'
      }
    }
  }, [_vm._v("Profil (" + _vm._s(_vm.userFullname) + ")")]) : _vm._e(), _c('a', {
    staticClass: "menu__logout",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Odhlásit se")])], 1) : _vm._e(), _vm.user.id ? _c('a', {
    staticClass: "menu-icon",
    on: {
      "click": function ($event) {
        _vm.menuOpened = true;
      }
    }
  }, [_vm._v("Menu")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "container container--layout"
  }, [_c('router-view', {
    key: _vm.$route.path
  })], 1), _c('div', {
    staticClass: "footer no-print"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.revis.eu"
    }
  }, [_vm._v("www.revis.eu")]), _vm._v(" • "), _c('a', {
    attrs: {
      "href": "tel:+420603274206"
    }
  }, [_vm._v("+420 603 274 206")]), _vm._v(" • "), _c('a', {
    attrs: {
      "href": "mailto:info@revis.eu"
    }
  }, [_vm._v("info@revis.eu")]), _vm.installAllowed ? [_vm._v(" • "), _c('a', {
    on: {
      "click": _vm.installApp
    }
  }, [_vm._v("Instalovat")])] : _vm._e(), _c('div', {
    staticClass: "footer__refresh"
  }, [_c('a', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.refresh
    }
  }, [_vm._v("Refresh")])]), _c('div', {
    staticClass: "footer__version"
  }, [_vm._v(" Verze: " + _vm._s(_vm.appVersion) + " ")])], 2)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }