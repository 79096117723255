<script>
import projectService from "@services/projectService";
import axiosApi from "@/helpers/apiServer";
import ImagesInfo from "@components/imagesInfo.vue";

export default {
    name: "ProjectInvoicesModal",
    components: {ImagesInfo},
    props: {
        invoiceData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        projectId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            addInvoiceModal: false,
            invoiceModal: {
                visible: false,
                invoices: [],
                invoiced: "0",
                invoice_reason: "",
                new_files: [],
                new_invoice_number: "",
                new_description: "",
            }
        }
    },
    computed: {},
    watch: {
        invoiceData: {
            handler(v) {
                this.invoiceModal = v;
            },
            immediate: true
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },

        addInvoiceAction() {

            let payload = {
                project_id: this.projectId,
                invoice_number: this.invoiceModal.new_invoice_number,
                invoice_files: this.invoiceModal.new_files,
                description: this.invoiceModal.new_description,
            };

            axiosApi.post("/ajax-project/add-invoice", payload).then(res => {
                this.invoiceModal.new_invoice_number = "";
                this.invoiceModal.new_files = [];
                this.addInvoiceModal = false;

                this.$emit("refresh");
            }).catch(e => {

            }).finally(() => {

            });

        },

        removeInvoiceAction(id) {

            let c = confirm("Opravdu si přejete smazat fakturu?");
            if (!c)
                return;

            let payload = {
                project_id: this.projectId,
                invoice_id: id,
            };

            axiosApi.post("/ajax-project/remove-invoice", payload).then(res => {
                this.$emit("refresh");
            }).catch(e => {

            }).finally(() => {

            });

        },

        saveInvoiceModal() {

            let data = {
                status: this.invoiceModal.invoiced,
                project_id: this.projectId,
                invoice_reason: this.invoiceModal.invoice_reason,
            };

            this.setLoading("btn_invoice");
            projectService.invoiceProject(data).then(res => {
                this.$emit("refresh");
                this.close();
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.unsetLoading("btn_invoice");
            });

        }
    }
}
</script>

<template>
    <modal @close="close">
        <h3>Fakturace zakázky</h3>
        <div>

            <div class="form-group">
                <label>Stav</label>
                <select v-model="invoiceModal.invoiced" class="form-control">
                    <option value="1">Vyfakturováno</option>
                    <option value="3">Částečně</option>
                    <option value="0">Nevyfakturováno</option>
                    <option value="2">Fakturace se neřeší</option>
                </select>
            </div>

            <template v-if="['0', '2'].includes(invoiceModal.invoiced)">
                <div class="form-group">
                    <label>Popis</label>
                    <textarea v-model="invoiceModal.invoice_reason" class="form-control"></textarea>
                </div>
            </template>

            <app-button slug="btn_invoice" class="btn btn-primary" @click="saveInvoiceModal">Uložit</app-button>

            <div v-if="['1', '3'].includes(invoiceModal.invoiced)" class="my-4">

                <hr/>
                <div class="text-2xl my-4">Faktury</div>

                <div v-if="invoiceModal.invoices.length" class="my-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <td>Název</td>
                                <td>Fotky</td>
                                <td>Vytvořeno</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, item_index) in invoiceModal.invoices" class="">
                                <td>{{ item.invoice_number }}</td>
                                <td>
                                    <ImagesInfo :all-files="item.invoice_files"/>
                                </td>
                                <td>{{ item.date }}</td>
                                <td>
                                    <a class="text-red-400" @click="removeInvoiceAction(item.id ?? 'none')">Smazat</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="my-4">Zatím žádná faktura nevytvořena.</div>

                <button v-if="!addInvoiceModal" class="btn btn-secondary" @click="addInvoiceModal = true">Přidat fakturu</button>

                <div v-if="addInvoiceModal">
                    <hr class="my-4"/>

                    <div class="form-group">
                        <label>Interní číslo faktury</label>
                        <input class="form-control" type="input" v-model="invoiceModal.new_invoice_number"/>
                    </div>
                    <div class="form-group">
                        <label>Faktura</label>
                        <project-files :allow-upload="true" :handle-upload="false" @newFiles="files => invoiceModal.new_files = files"/>
                    </div>

                    <div class="form-group" v-if="invoiceModal.invoiced == '2'">
                        <label>Popis</label>
                        <textarea v-model="invoiceModal.invoice_reason" class="form-control"></textarea>
                    </div>

                    <app-button slug="btn_invoice" class="btn btn-primary" @click="addInvoiceAction">
                        Uložit
                    </app-button>

                    <button class="btn btn-secondary" @click="addInvoiceModal = false">
                        Zrušit fakturu
                    </button>

                </div>

            </div>

        </div>
    </modal>
</template>