<template>
    <div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <h2>Majetek {{ thing_id ? " - " + thing.name : "" }}</h2>
                <form method="POST" @submit.prevent="submit" autocomplete="off">
                    <div class="form-group">
                        <label>Název</label>
                        <input class="form-control" type="text" v-model="thing.name">
                    </div>

                    <div class="form-group">
                        <label>Popis</label>
                        <textarea class="form-control" v-model="thing.text"></textarea>
                    </div>

                    <div class="form-group">
                        <label>Evidenční číslo</label>
                        <input class="form-control" type="text" v-model="thing.meta.internal_number">
                    </div>

                    <div class="form-group">
                        <label>Nákupní cena</label>
                        <input class="form-control" type="number" v-model="thing.meta.price">
                    </div>

                    <div class="form-group">
                        <label>Datum nákupu</label>
                        <app-date v-model="thing.meta.bought_at"/>
                    </div>

                    <div class="form-group">
                        <label>Stav</label>
                        <select class="form-control" v-model="thing.meta.still_used">
                            <option value="yes">Používá se</option>
                            <option value="no">Nepoužívá se</option>
                        </select>
                    </div>

                    <app-button slug="btn_createThing" type="submit" class="btn btn-primary">Uložit</app-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import thingService from "@services/thingService";

export default {
    name: "thing_create",

    mounted() {
        this.thing_id = this.$route.params?.thing_id ?? null;
        this.fetchData();
    },

    methods: {

        fetchData() {
            let thing_id = this.thing_id;

            if (!thing_id)
                return;

            this.pageLoading = true;
            thingService.showThing(thing_id).then(res => {

                let thing = res.data.thing;
                thing.meta = this.transformMeta(thing.meta);

                this.thing = thing;
                this.pageLoading = true;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

        submit() {
            this.setLoading("btn_createThing");

            let data = {
                thing: this.thing,
                thing_id: this.thing.id ?? null,
            };

            thingService.createThing(data).then(res => {
                let data = res.data;
                if (data.is_new) {
                    this.$router.push({name: "thing_create", params: {thing_id: data.thing_id}});
                } else {
                    this.fetchData();
                }
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_createThing");
            });

        },
    },

    data() {
        return {
            pageLoading: false,
            thing_id: null,
            thing: {
                meta: {
                    still_used: "yes"
                }
            },
        }
    }
}
</script>