<template>
    <div class="container container--layout">
        <h2>Úprava auta</h2>
        <form method="POST" @submit.prevent="submit" autocomplete="off">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <h3>Základní informace</h3>
                    <div class="form-group">
                        <label>Název</label>
                        <input class="form-control" type="text" v-model="car.name">
                    </div>
                    <div class="form-group">
                        <label>SPZ</label>
                        <input class="form-control" type="text" v-model="car.meta.plate">
                    </div>
                    <div class="form-group">
                        <label>Popis</label>
                        <input class="form-control" type="text" v-model="car.meta.info">
                    </div>
                    <div class="form-group">
                        <label>VIN kód</label>
                        <input class="form-control" type="input" v-model="car.meta.vin_number">
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <h3>Ostatní informace</h3>
                    <div class="form-group">
                        <label>Datum prodloužení STK</label>
                        <app-date v-model="car.meta.stk_done_at" :clearable="true"/>
                    </div>
                    <div class="form-group">
                        <label>STK je validní</label>
                        <select v-model="car.meta.stk_validity" class="form-control">
                            <option value="">Nezadáno</option>
                            <option :value="1">1 rok</option>
                            <option :value="2">2 roky</option>
                            <option :value="3">3 roky</option>
                            <option :value="4">4 roky</option>
                            <option :value="5">5 let</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Údržba auta provedena</label>
                        <app-date v-model="car.meta.car_maintenance_at" :clearable="true"/>
                    </div>
                    <div class="form-group">
                        <label>Dálniční známka do</label>
                        <app-date v-model="car.meta.toll_license_expires" :clearable="true"/>
                    </div>
                    <div class="form-group">
                        <label>Zakázat tankování</label>
                        <input class="form-control" type="checkbox" v-model="car.meta.disable_refueling">
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <manage-cars v-if="car.id" :by-car="car.id"/>
                </div>
            </div>

            <app-button type="submit" slug="btn_saveCar" class="btn btn-primary">Uložit</app-button>
        </form>
    </div>
</template>

<script>
import carService from "@services/carService";
import ManageCars from "@components/manageCars";

export default {
    name: "car_update",
    components: {ManageCars},
    mounted() {
        this.car_id = this.$route.params?.car_id ?? null;
        this.fetchData();
    },

    methods: {

        fetchData() {
            let car_id = this.car_id;

            if (!car_id)
                return;

            this.pageLoading = true;
            carService.getCar(car_id).then(res => {

                let car = res.data.car;
                car.meta = this.transformMeta(car.meta);

                this.car = car;
                this.pageLoading = true;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

        submit() {
            this.setLoading("btn_saveCar");

            let data = {
                car: this.car,
                car_id: this.car.id ?? null,
            };

            carService.saveCar(data).then(res => {
                this.$toast.success("Uloženo.");
                let data = res.data;
                this.$router.push({name: "car_detail", params: {car_id: data.car_id}});
                this.$store.dispatch("loadMainConfig");
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_saveCar");
            });

        },
    },

    data() {
        return {
            pageLoading: false,
            car_id: null,
            car: {
                meta: {}
            },
        }
    }
}
</script>