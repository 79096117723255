<template>
    <div>

        <rent-modal v-if="rentModalVisible" ref="rentModal" @updated="fetchData"/>
        <add-hours-modal v-if="hoursModalVisible" ref="addHoursModal"/>
        <add-cost-modal v-if="costModalVisible" ref="addCostModal" @updated="fetchData"/>
        <close-project-modal v-if="closeProjectModalVisible" ref="closeProjectModal" @updated="fetchData"/>

        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="buttons">
                <a @click="addHours" class="btn btn-primary">Zapsat dnešní hodiny</a>
                <button @click="addCostModal" class="btn btn-primary">Doklad / dodací list</button>
                <button @click="addGasReceipt" class="btn btn-primary">Tankování</button>
                <button @click="openCloseProjectModal" class="btn btn-primary">Návrh na uzavření zakázky</button>
            </div>

            <div class="row">
                <div class="col-12 col-lg-3">
                    <h3>Úkoly pro mě</h3>
                    <div class="table-wrapper">
                        <table class="table table-striped" v-if="opened_tasks.length">
                            <tr>
                                <th>Název</th>
                                <th>Od koho</th>
                                <th>Vytvořeno</th>
                            </tr>
                            <tr v-for="item in opened_tasks">
                                <td>
                                    <router-link :to="{name: 'task_show', params: {task_id: item.id}}">
                                        {{ item.name }}
                                    </router-link>
                                </td>
                                <td>{{ item.created_by_user.name }}</td>
                                <td>
                                    {{ localizeDate(item.created_at) }}
                                </td>
                            </tr>
                        </table>
                        <div v-else class="hp__check">
                            <Icon name="check"/>
                        </div>
                    </div>

                    <template v-if="isAdmin()">
                        <h3>Úkoly mnou zadané</h3>
                        <div class="table-wrapper">
                            <table class="table table-striped" v-if="tasks_to_check.length">
                                <tr>
                                    <th>Název</th>
                                    <th>Pro koho</th>
                                    <th>Stav</th>
                                    <th>Vytvořeno</th>
                                </tr>
                                <tr v-for="item in tasks_to_check">
                                    <td>
                                        <router-link :to="{name: 'task_show', params: {task_id: item.id}}">
                                            {{ item.name }}
                                        </router-link>
                                    </td>
                                    <td>{{ item.created_for_user.name }}</td>
                                    <td>
                                        <div :class="[item.state == 'open' ? 'task--open' : 'task--check']">
                                            {{ item.nice_status }}
                                        </div>
                                    </td>
                                    <td>
                                        {{ localizeDate(item.created_at) }}
                                    </td>
                                </tr>
                            </table>
                            <div v-else class="hp__check">
                                <Icon name="check"/>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="col-12 col-lg-3" v-if="isAdmin()">
                    <DashboardAdvancesList v-if="isAdmin() && userCan('offices')"/>
                    <template v-if="isAdmin()">
                        <h3>Zapůjčený majetek</h3>
                        <div class="table-wrapper">
                            <table class="table table-striped" v-if="rented_things.length">
                                <tr>
                                    <th>Předmět</th>
                                    <th>Zapůjčeno</th>
                                    <th>Popis</th>
                                    <th></th>
                                </tr>
                                <tr v-for="item in rented_things">
                                    <td>{{ item.thing?.name }}</td>
                                    <td>
                                        {{ localizeDate(item.rented_at) }}
                                    </td>
                                    <td>{{ item.text }}</td>
                                    <td class="flex-buttons">
                                        <button class="btn btn-primary" @click="openRentModal(item)">Vrátit</button>
                                    </td>
                                </tr>
                            </table>
                            <div v-else class="hp__check">
                                <Icon name="check"/>
                            </div>
                        </div>

                        <template v-if="notVerifiedItems && isAdmin()">
                            <h3>Neverifikované zakázky</h3>
                            <div class="hp__check error">
                                {{ notVerifiedItems }}
                            </div>
                        </template>

                    </template>
                </div>
                <div class="col-12 col-lg-6" v-if="isAdmin()">
                    <DashboardNotApprovedCosts/>
                    <h3>Upozornění</h3>
                    <div class="table-wrapper">
                        <table class="table table-striped" v-if="unprocessedEvents.length">
                            <tr>
                                <th>Poznámka</th>
                                <th>Upozornit</th>
                                <th>Týká se</th>
                            </tr>
                            <tr v-for="event in unprocessedEvents">
                                <td>{{ event.text }}</td>
                                <td>
                                    {{ localizeDate(event.notify_at) }}
                                </td>
                                <td>
                                    <router-link :to="{name: 'car_detail', params: {car_id: event.car.id}}">{{ event.car.fullname }}</router-link>
                                </td>
                            </tr>
                        </table>
                        <div v-else class="hp__check">
                            <Icon name="check"/>
                        </div>
                    </div>

                </div>
            </div>

            <br><br>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <DashboardWipProjects/>
                </div>
                <div class="col-12 col-lg-6" v-if="userCan('office_and_mister')">
                    <h3>Návrhy na uzavření zakázek</h3>
                    <table class="table table-striped" v-if="closingProposals.length">
                        <tr>
                            <th>Název</th>
                            <th>Podal</th>
                            <th>Podáno</th>
                            <th></th>
                        </tr>
                        <tr v-for="item in closingProposals">
                            <td>
                                <project-link :item="item.project"/>
                            </td>
                            <td>
                                <user-link :item="item.user"/>
                            </td>
                            <td>
                                {{ localizeDate(item.created_at) }}
                            </td>
                            <td>
                                <app-button v-if="userCan('create-closing-proposal')" :slug="'btn_dismisProposal_'+item.id" class="btn btn-success" @click="dismisProposal(item)">Smazat návrh</app-button>
                            </td>
                        </tr>
                    </table>
                    <div v-else class="hp__check">
                        <Icon name="check"/>
                    </div>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
import projectService from "@services/projectService";
import RentModal from "@components/rentModal";
import AddCostModal from "@components/addCostModal";
import addHoursModal from "@components/addHoursModal";

import CloseProjectModal from "@components/closeProjectModal";
import PersonalNotes from "@components/personalNotes";
import DashboardNotApprovedCosts from "@components/Dahboard/NotApprovedCosts.vue";
import DashboardAdvancesList from "@components/Dahboard/AdvancesList.vue";
import DashboardWipProjects from "@components/Dahboard/WipProjects.vue";

export default {
    name: "homepage",
    components: {
        DashboardWipProjects,
        DashboardAdvancesList,
        DashboardNotApprovedCosts,
        PersonalNotes,
        CloseProjectModal,
        AddCostModal,
        RentModal,
        addHoursModal
    },
    mounted() {

        if (!this.$store.state.user?.id) {
            this.$router.push("/login");
            return null;
        }

        this.fetchData();
    },

    methods: {

        async openRentModal(item) {
            this.rentModalVisible = true;
            await this.$nextTick();
            this.$refs.rentModal.open(item, true)
        },

        async openCloseProjectModal() {
            this.closeProjectModalVisible = true;
            await this.$nextTick();
            this.$refs.closeProjectModal.open()
        },

        dismisProposal(item) {
            let data = {
                event_id: item.id
            };

            this.setLoading("btn_dismisProposal_" + item.id);
            projectService.dismisProposal(data).then(res => {
                this.$toast.success("Smazáno.");
                this.fetchData();
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_dismisProposal_" + item.id);
            });
        },

        async addGasReceipt() {

            this.costModalVisible = true;
            await this.$nextTick();

            this.$refs.addCostModal.composeBody("gas_receipt", {});
            this.$refs.addCostModal.hiddenFields = [
                {name: "type", value: "gas_receipt"},
            ]
            this.$refs.addCostModal.open();
        },

        async addCostModal() {
            this.costModalVisible = true;
            await this.$nextTick();

            this.$refs.addCostModal.composeBody("shop", {});
            this.$refs.addCostModal.hiddenFields = [
                {name: "type", value: "shop"},
            ]
            this.$refs.addCostModal.open();
        },

        async addHours() {
            this.hoursModalVisible = true;
            await this.$nextTick();
            this.$refs.addHoursModal.open();
        },

        fetchData() {
            this.pageLoading = true;
            projectService.getDashboard().then(res => {
                this.opened_tasks = res.data.opened_tasks;
                this.tasks_to_check = res.data.tasks_to_check;
                this.unprocessedEvents = res.data?.urgent_events ?? [];
                this.rented_things = res.data.rented_things ?? [];
                this.notVerifiedItems = res.data.notVerifiedItems ?? 0;
                this.closingProposals = res.data.closingProposals ?? [];
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

    },

    data() {
        return {
            pageLoading: true,
            opened_tasks: [],
            tasks_to_check: [],
            unprocessedEvents: [],
            rented_things: [],
            notVerifiedItems: 0,
            closingProposals: [],
            filterProjectCategory: null,
            rentModalVisible: false,
            hoursModalVisible: false,
            costModalVisible: false,
            closeProjectModalVisible: false,
        }
    }

}
</script>
