<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Obec</h2>
                    <form method="POST" @submit.prevent="submit" autocomplete="off">
                        <div class="form-group">
                            <label>Obec</label>
                            <input class="form-control" type="text" v-model="city.name">
                        </div>
                        <div class="form-group">
                            <label>PSČ</label>
                            <input class="form-control" type="text" v-model="city.zipcode">
                        </div>
                        <div class="form-group">
                            <label>GPS Lat</label>
                            <input class="form-control" type="text" v-model="city.lat">
                        </div>
                        <div class="form-group">
                            <label>GPS Lng</label>
                            <input class="form-control" type="text" v-model="city.lng">
                        </div>
                        <app-button slug="btn_updatecity" type="submit" class="btn btn-primary">Uložit</app-button>
                        <app-button slug="btn_deletecity" type="button" @click="deleteCity" v-if="isAdmin() && city_id" class="btn btn-danger">
                            <icon name="trash"/>
                        </app-button>
                    </form>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import cityService from "@services/cityService";

export default {
    name: "city_create",

    mounted() {
        this.city_id = this.$route.params?.city_id ?? null;
        this.fetchData();
    },

    methods: {

        deleteCity() {
            if (!confirm("Opravdu smazat obec?"))
                return;

            this.setLoading("btn_deletecity");

            let data = {
                city_id: this.city.id,
            };

            cityService.deleteCity(data).then(res => {
                this.$toast.success("Obec smazána!");
                this.$router.push({name: "city_index"});
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_deletecity");
            });
        },

        fetchData() {
            let city_id = this.city_id;

            if (!city_id)
                return;

            this.pageLoading = true;
            cityService.getCity(city_id).then(res => {
                let city = res.data.city;
                this.city = city;
                this.pageLoading = true;
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.pageLoading = false;
            });
        },

        submit() {
            this.setLoading("btn_updatecity");

            let data = {
                name: this.city.name,
                zipcode: this.city.zipcode,
                lat: this.city.lat,
                lng: this.city.lng,
                city_id: this.city.id ?? null,
            };

            cityService.createCity(data).then(res => {
                this.$toast.success("Uloženo!");
                // this.city.name = "";
                // this.city.zipcode = "";
                // this.city.lat = "";
                // this.city.lng = "";
                this.$router.push({name: "city_index"});
            }).catch(e => {
                this.showError(e);
            }).finally(res => {
                this.unsetLoading("btn_updatecity");
            });

        },
    },

    data() {
        return {
            pageLoading: false,
            city_id: null,
            city: {},
        }
    }
}
</script>