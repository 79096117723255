var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.columnDefs ? _c('ag-grid-vue', _vm._b({
    ref: "gridEl",
    staticClass: "ag-grid-table ag-theme-alpine",
    class: _vm.gridClasses,
    attrs: {
      "columnDefs": _vm.columnDefs,
      "rowData": _vm.data,
      "pagination": true,
      "paginationPageSize": 10,
      "dom-layout": "autoHeight",
      "suppressFieldDotNotation": false,
      "enableCellTextSelection": true
    },
    on: {
      "grid-ready": _vm.onGridReady,
      "grid-size-changed": _vm.onGridSizeChanged,
      "modelUpdated": function ($event) {
        return _vm.onModelUpdated($event);
      }
    }
  }, 'ag-grid-vue', _vm.gridOptions, false)) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }