<template>
    <div>
        <page-loader v-if="pageLoading"/>
        <template v-else>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2>Výpis obcí</h2>
                </div>
                <div class="col-12 col-lg-6 flex-buttons">
                    <router-link :to="{name: 'city_create'}" class="btn btn-primary">Nová obec</router-link>
                    <router-link v-if="userCan('city.merge')" :to="{name: 'city_merge'}" class="btn btn-secondary">Spojit obce</router-link>
                </div>
            </div>

            <br> <br>
            <app-table v-bind="table_attributes" :fields="computedFields" :items="items" :local-sort="!table_attributes.sortable">
                <template slot="table-content" slot-scope="data">
                    <tbody>
                        <tr v-for="(row, key) in data.rows" :key="row.id" :class="row.color">
                            <td>{{ row.name }}</td>
                            <td>{{ row.zipcode }}</td>
                            <td>{{ row.gps }}</td>
                            <td v-if="isAdmin()">
                                <router-link class="btn btn-danger" :to="{name: 'city_create', params: {city_id: row.id}}">
                                    Upravit
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </app-table>
        </template>
    </div>
</template>

<script>
import cityService from "@services/cityService";

export default {
    name: "city_index",
    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {

            this.pageLoading = true;
            cityService.getCities().then(res => {
                let items = res.data.cities;
                this.items = items;
            }).catch(e => {
                this.showError(e);
            }).finally(r => {
                this.pageLoading = false;
            });

        },
    },

    computed: {

        computedFields: function () {
            let out = [
                {key: 'name', label: "Obec", sortable: true},
                {key: 'zipcode', label: "PSČ", sortable: true},
                {key: 'latlng', label: "GPS"},
                {key: 'controls', label: ""},
            ];
            return out;
        },

    },

    data() {
        return {
            items: [],
            pageLoading: false,
            table_attributes: {
                currentPage: 1,
                perPage: 100,
                sortBy: 'name',
                sortDirection: 'asc',
                pagination: true,
                sortable: false,
            },
        }
    }
}
</script>