import axios from "@app/helpers/apiServer";

export default {

    getCities() {
        return axios({
            method: "GET",
            url: "/ajax-city/index"
        });
    },

    createCity(data) {
        return axios({
            method: "POST",
            url: "/ajax-city/create",
            data
        });
    },

    deleteCity(data) {
        return axios({
            method: "POST",
            url: "/ajax-city/delete",
            data
        });
    },

    mergeCities(data) {
        return axios({
            method: "POST",
            url: "/ajax-city/merge",
            data
        });
    },

    getCity(city_id) {
        return axios({
            method: "get",
            url: "/ajax-city/show/" + city_id,
        });
    },

    getUsedCities() {
        return axios({
            method: "GET",
            url: "/ajax-city/get-used-cities",
        });
    },

}