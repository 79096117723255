var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [_vm._v("Spojování zákazníků")]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "text-pid1"
  }, [_vm._v("Hlavní zákazník")]), _c('app-select', {
    attrs: {
      "auto-load": "clients"
    },
    model: {
      value: _vm.cid1,
      callback: function ($$v) {
        _vm.cid1 = $$v;
      },
      expression: "cid1"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12 col-lg-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "text-pid2"
  }, [_vm._v("Duplicitní zákazník")]), _c('app-select', {
    attrs: {
      "auto-load": "clients"
    },
    model: {
      value: _vm.cid2,
      callback: function ($$v) {
        _vm.cid2 = $$v;
      },
      expression: "cid2"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12 col-lg-2"
  }, [_c('app-button', {
    staticClass: "btn btn-primary",
    attrs: {
      "slug": "btn_mergeClients"
    },
    on: {
      "click": _vm.startMerge
    }
  }, [_vm._v("Spojit")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }