import axios from "@app/helpers/apiServer";

export default {

    uploadNewImages(data = null) {
        return axios({
            url: "/ajax-images/upload-new-images",
            method: "post",
            data,
        });
    },

    addToEntity(data = null) {
        return axios({
            url: "/ajax-images/add-to-entity",
            method: "post",
            data,
        });
    },

    removeFromEntity(data = null) {
        return axios({
            url: "/ajax-images/remove-from-entity",
            method: "post",
            data,
        });
    },
}
