var render = function () {
  var _vm$client$meta, _vm$client$meta2, _vm$client$city$name, _vm$client, _vm$client$city;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Detail zákazníka - " + _vm._s(_vm.client.name))])]), _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_c('router-link', {
    staticClass: "btn btn-danger",
    attrs: {
      "to": {
        name: 'client_create',
        params: {
          client_id: _vm.client_id
        }
      }
    }
  }, [_vm._v("Upravit")])], 1)]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Informace")]), _c('div', [_c('b', [_vm._v("Popis")]), _vm._v(": " + _vm._s(_vm.client.note))]), _c('div', [_c('b', [_vm._v("IČO")]), _vm._v(": " + _vm._s((_vm$client$meta = _vm.client.meta) === null || _vm$client$meta === void 0 ? void 0 : _vm$client$meta.ico))]), _c('div', [_c('b', [_vm._v("DIČ")]), _vm._v(": " + _vm._s((_vm$client$meta2 = _vm.client.meta) === null || _vm$client$meta2 === void 0 ? void 0 : _vm$client$meta2.dic))]), _c('br'), _c('div', [_c('b', [_vm._v("Město")]), _vm._v(": " + _vm._s((_vm$client$city$name = (_vm$client = _vm.client) === null || _vm$client === void 0 ? void 0 : (_vm$client$city = _vm$client.city) === null || _vm$client$city === void 0 ? void 0 : _vm$client$city.name) !== null && _vm$client$city$name !== void 0 ? _vm$client$city$name : "Nevyplněno"))]), _c('div', [_c('b', [_vm._v("Adresa")]), _vm._v(": " + _vm._s(_vm.client.address))])]), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h3', [_vm._v("Kontaktní osoby")]), _vm.people.length ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Jméno")]), _c('th', [_vm._v("Pozice")]), _c('th', [_vm._v("E-mail")]), _c('th', [_vm._v("Telefon")])])]), _vm._l(_vm.people, function (item) {
    return _c('tr', [_c('td', [item.hidden ? _c('span', {
      attrs: {
        "title": "Skrytá osoba"
      }
    }, [_vm._v("👁️‍🗨️ ")]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.position) + " ")]), _c('td', [item.email ? _c('a', {
      attrs: {
        "href": 'mailto:' + item.email
      }
    }, [_vm._v(" " + _vm._s(item.email) + " ")]) : _vm._e()]), _c('td', [item.tel ? _c('a', {
      attrs: {
        "href": 'tel:' + item.tel
      }
    }, [_vm._v(" " + _vm._s(item.tel) + " ")]) : _vm._e()])]);
  })], 2) : _c('div', [_vm._v("Žádné kontaktní osoby")])])]), _vm.wipProjects.length ? [_c('h3', [_vm._v("Rozpracované zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.wipProjects,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city = row.city) === null || _row$city === void 0 ? void 0 : _row$city.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(row.created_at)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(row.updated_at)) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")])]);
        });
      }
    }], null, false, 848072967)
  }, 'app-table', _vm.table_attributes_wip, false))] : _vm._e(), _vm.newProjects.length && _vm.userCan('project.see-planned') ? [_c('h3', [_vm._v("Plánované zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.newProjects,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city2;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city2 = row.city) === null || _row$city2 === void 0 ? void 0 : _row$city2.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(row.created_at)) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")])]);
        });
      }
    }], null, false, 1493030844)
  }, 'app-table', _vm.table_attributes_planned, false))] : _vm._e(), _vm.finishedProjects.length && _vm.userCan('project.see-finished') ? [_c('h3', [_vm._v("Hotové zakázky")]), _c('app-table', _vm._b({
    attrs: {
      "items": _vm.finishedProjects,
      "local-sort": true
    },
    scopedSlots: _vm._u([{
      key: "tbody",
      fn: function (data) {
        return _vm._l(data.rows, function (row, key) {
          var _row$city3;

          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(" " + _vm._s((_row$city3 = row.city) === null || _row$city3 === void 0 ? void 0 : _row$city3.name) + " ")]), _c('td', [_c('project-link', {
            attrs: {
              "item": row,
              "hide-client": true
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.localizeDate(row.finished_at)) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.category_name) + " ")]), _c('td', [row.finished_at ? _c('span', [row.is_invoiced == 1 ? _c('span', {
            staticClass: "text-success bold"
          }, [_vm._v("Ano")]) : row.is_invoiced == 2 ? _c('span', {
            staticClass: "text-info bold"
          }, [_vm._v("Neřeší se")]) : row.is_invoiced == 3 ? _c('span', {
            staticClass: "text-info bold"
          }, [_vm._v("Částečně")]) : _c('span', {
            staticClass: "text-danger bold"
          }, [_vm._v("Ne")])]) : _vm._e()]), _c('td', [_vm._v(" " + _vm._s(row.invoice_numbers) + " ")])]);
        });
      }
    }], null, false, 3019076160)
  }, 'app-table', _vm.table_attributes_finished, false))] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }