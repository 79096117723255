var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pageLoading ? _c('page-loader') : [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('h2', [_vm._v("Výpis obcí")])]), _c('div', {
    staticClass: "col-12 col-lg-6 flex-buttons"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'city_create'
      }
    }
  }, [_vm._v("Nová obec")]), _vm.userCan('city.merge') ? _c('router-link', {
    staticClass: "btn btn-secondary",
    attrs: {
      "to": {
        name: 'city_merge'
      }
    }
  }, [_vm._v("Spojit obce")]) : _vm._e()], 1)]), _c('br'), _vm._v(" "), _c('br'), _c('app-table', _vm._b({
    attrs: {
      "fields": _vm.computedFields,
      "items": _vm.items,
      "local-sort": !_vm.table_attributes.sortable
    },
    scopedSlots: _vm._u([{
      key: "table-content",
      fn: function (data) {
        return [_c('tbody', _vm._l(data.rows, function (row, key) {
          return _c('tr', {
            key: row.id,
            class: row.color
          }, [_c('td', [_vm._v(_vm._s(row.name))]), _c('td', [_vm._v(_vm._s(row.zipcode))]), _c('td', [_vm._v(_vm._s(row.gps))]), _vm.isAdmin() ? _c('td', [_c('router-link', {
            staticClass: "btn btn-danger",
            attrs: {
              "to": {
                name: 'city_create',
                params: {
                  city_id: row.id
                }
              }
            }
          }, [_vm._v(" Upravit ")])], 1) : _vm._e()]);
        }), 0)];
      }
    }])
  }, 'app-table', _vm.table_attributes, false))]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }