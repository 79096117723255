<template>
    <div class="app-layout">
        <my-spent-hours ref="mySpentHours"/>
        <div class="menu__bg" :class="{'opened': menuOpened}" @click="menuOpened = false"></div>
        <page-loader v-if="pageLoading"/>
        <template v-else>
            <nav class="header no-print">
                <div class="container">
                    <router-link :to="{name: 'homepage'}" class="logo">
                        <img src="/images/revis_logo.svg">
                    </router-link>
                    <a v-if="user?.id" class="header__hours" @click="$refs.mySpentHours.open()">
                        Dnes zapsáno:
                        <span>{{ todaysHours }}h</span>
                        <weather-widget v-if="todaysWeather" :item="todaysWeather" :small="true"/>
                    </a>
                    <div class="header__nav" v-if="user.id" :class="{'opened': menuOpened}">
                        <a class="header__close" @click="menuOpened = false">Zavřít</a>
                        <router-link v-if="userCan('offices')" :to="{name: 'search'}">Hledat</router-link>
                        <router-link v-if="userCan('nav-projects')" :to="{name: 'project_index'}">Zakázky</router-link>
                        <router-link v-if="userCan('nav-costs')" :to="{name: 'cost_archive'}">Náklady</router-link>
                        <router-link v-if="userCan('nav-users')" :to="{name: 'user_index'}">Uživatelé</router-link>
                        <router-link v-if="userCan('nav-cars')" :to="{name: 'car_index'}">Auta</router-link>
                        <router-link v-if="userCan('nav-plan')" :to="{name: 'plan_index'}">Plán</router-link>
                        <router-link v-if="userCan('nav-tasks')" :to="{name: 'task_index'}">Úkoly</router-link>
                        <router-link v-if="userCan('offices')" :to="{name: 'paidCosts'}">Pokladna</router-link>
                        <router-link v-if="userCan('nav-rents')" :to="{name: 'thing_rents'}">Půjčovna</router-link>
                        <router-link v-if="userCan('nav-things')" :to="{name: 'thing_list'}">Majetek</router-link>
                        <router-link :to="{name: 'pinboard'}">Nástěnka</router-link>
                        <router-link v-if="userCan('nav-profile')" :to="{name: 'user_profile'}">Profil ({{ userFullname }})</router-link>
                        <a class="menu__logout" @click="logout">Odhlásit se</a>
                    </div>
                    <a v-if="user.id" class="menu-icon" @click="menuOpened = true">Menu</a>
                </div>
            </nav>
            <div class="container container--layout">
                <router-view :key="$route.path"/>
            </div>
            <div class="footer no-print">
                <a target="_blank" href="https://www.revis.eu">www.revis.eu</a> &bull;
                <a href="tel:+420603274206">+420 603 274 206</a> &bull; <a href="mailto:info@revis.eu">info@revis.eu</a>
                <template v-if="installAllowed">
                    &bull; <a @click="installApp">Instalovat</a>
                </template>
                <div class="footer__refresh">
                    <a @click="refresh" class="btn btn-primary">Refresh</a>
                </div>
                <div class="footer__version">
                    Verze: {{ appVersion }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import userService from "@services/userService";
import MySpentHours from "@pages/user/mySpentHours";
import projectService from "@services/projectService";
import WeatherWidget from "@components/weatherWidget";

let installPrompt;

export default {
    name: "layout",
    components: {WeatherWidget, MySpentHours},
    mounted() {

        this.$events.$on("assigned-hours", (data) => {
            if (data?.user_id == this.getUserID())
                this.fetchMyHours();
        });

        this.fetchData();
        this.fetchMyHours();

        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.register("service-worker.js").then(reg => {
                console.log("Registration succesful, scope: " + reg.scope);
            })
                .catch(err => {
                    console.log(err);
                })
        }

        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            installPrompt = e;
            this.installAllowed = true;
        });

        window.addEventListener("appinstalled", e => {
            console.log("A2HS :: already installed");
        });

    },

    watch: {

        "$route.name": {
            handler() {
                this.menuOpened = false;
                if (!this.user && this.$route.name !== "login") {
                    this.$router.push({name: "login"});
                }
            }
        },

        "$store.state.user.id": {
            handler() {
                if (this.user?.id) {
                    this.getWeather();
                }
            }
        }

    },

    methods: {

        getWeather() {
            projectService.getWather().then(r => {
                this.weather = r.data.weather;
            })
        },

        fetchMyHours() {
            userService.getTodaysHours().then(res => {
                this.todaysHours = res.data.hours ?? 0;
            });
        },

        refresh() {
            window.location.reload();
        },

        installApp() {
            installPrompt.prompt();
            installPrompt.userChoice.then(res => {
                if (res.outcome == "accepted") {
                    console.log("A2HS :: installed");
                }
                installPrompt = null;
            });
        },

        fetchData() {
            this.pageLoading = true;
            this.$store.dispatch("getUserData").then(res => {

                const userActive = this.$store.state.user?.active ?? 0;

                if (!userActive) {
                    console.error("You have been logged out");
                    // userService.logout().finally(() => {
                    //     this.$router.push({name: "login"});
                    // });
                    // return;
                }

                this.afterLoginActions();
            }).catch(e => {

                if (this.$route.name !== "login") {
                    this.$router.push({name: "login"});
                }

            }).finally(res => {
                this.pageLoading = false;
            });
        },

        logout() {
            userService.logout().then(res => {
                this.$store.commit("setCurrentUser", {id: null});
                this.$router.push({name: "login"});
            });
        },

    },

    computed: {

        user() {
            return this.$store.state.user;
        },

        userFullname: function () {
            return this.user?.name;
        },

        todaysWeather() {
            return this.weather?.[0] ?? null;
        }

    },

    data() {
        return {
            pageLoading: true,
            menuOpened: false,
            installAllowed: false,
            appVersion: "1.0.1",
            todaysHours: 0,
            weather: [],
        }
    }

}
</script>