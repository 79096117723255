var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', _vm._l(_vm.fields, function (item) {
    return _c('th', [_vm._v(_vm._s(item))]);
  }), 0)]), _c('tbody', _vm._l(_vm.items, function (item) {
    return _c('tr', _vm._l(_vm.fields, function (key, index) {
      return _c('td', [index == 0 ? _c('model-link', {
        attrs: {
          "model": _vm.model,
          "model-id": item.id,
          "label": _vm.markMatch(item[key])
        }
      }) : _vm.model == 'client' && key == 'Osoby' ? _c('div', [_c('search-table', {
        attrs: {
          "items": item === null || item === void 0 ? void 0 : item[key],
          "word": _vm.word
        }
      })], 1) : _c('div', {
        domProps: {
          "innerHTML": _vm._s(_vm.markMatch(item === null || item === void 0 ? void 0 : item[key]))
        }
      })], 1);
    }), 0);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }